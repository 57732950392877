import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import { PERMISSION } from '@/Configs/Constants';
import { useMeChannelsStore, useUserStore } from '@/store/pinia';

export const useContactsAuth = () => {
  const userStore = useUserStore();
  const meChannelsStore = useMeChannelsStore();
  const { channels } = storeToRefs(meChannelsStore);

  const hasViewPermission = (): boolean => {
    return userStore.hasPermission(PERMISSION.CONTACTS__CONTACTS__VIEW);
  };

  const hasPrivateChannels = computed((): boolean => {
    return channels.value?.some((channel) => channel.is_private) ?? false;
  });

  const canAccessContacts = computed((): boolean => {
    return hasViewPermission() || hasPrivateChannels.value;
  });

  const shouldShowAllOptions = (): boolean => {
    return hasViewPermission();
  };

  return {
    canAccessContacts,
    shouldShowAllOptions,
    hasPrivateChannels,
  };
};
