import { PERMISSION } from '@/Configs/Constants';

import type { RouteRecord } from '../types';

const helpcenterRoutes: RouteRecord[] = [
  {
    path: '/help_center',
    component: () => import(/* webpackChunkName: "HelpCenter" */ '../../components/HelpCenter/Index.vue'),
    meta: { permissionRequired: PERMISSION.HELP_CENTER__GENERAL__MANAGE, title: 'Help Center' },
    children: [
      {
        path: '/help_center/:id',
        component: () => import(/* webpackChunkName: "HelpCenter" */ '../../components/HelpCenter/Edit.vue'),
        meta: { permissionRequired: PERMISSION.HELP_CENTER__GENERAL__MANAGE },
        children: [
          {
            path: '/help_center/:id/categories',
            component: () => import(/* webpackChunkName: "HelpCenter" */ '../../components/HelpCenter/Categories.vue'),
            meta: { permissionRequired: PERMISSION.HELP_CENTER__GENERAL__MANAGE },
          },
          {
            path: '/help_center/:id/categories/:category_id',
            component: () => import(/* webpackChunkName: "HelpCenter" */ '../../components/HelpCenter/Category.vue'),
            meta: { permissionRequired: PERMISSION.HELP_CENTER__GENERAL__MANAGE },
          },
          {
            path: '/help_center/:id/articles',
            component: () => import(/* webpackChunkName: "HelpCenter" */ '../../components/HelpCenter/Articles.vue'),
            meta: { permissionRequired: PERMISSION.HELP_CENTER__GENERAL__MANAGE },
          },
          {
            path: '/help_center/:id/articles/create',
            component: () => import(/* webpackChunkName: "HelpCenter" */ '../../components/HelpCenter/Article.vue'),
            meta: { permissionRequired: PERMISSION.HELP_CENTER__GENERAL__MANAGE },
          },
          {
            path: '/help_center/:id/articles/:article_id',
            component: () => import(/* webpackChunkName: "HelpCenter" */ '../../components/HelpCenter/Article.vue'),
            meta: { permissionRequired: PERMISSION.HELP_CENTER__GENERAL__MANAGE },
          },
          {
            path: '/help_center/:id/blocks',
            component: () => import(/* webpackChunkName: "HelpCenter" */ '../../components/HelpCenter/Blocks.vue'),
            meta: { permissionRequired: PERMISSION.HELP_CENTER__GENERAL__MANAGE },
          },
          {
            path: '/help_center/:id/blocks/:blockId',
            component: () => import(/* webpackChunkName: "HelpCenter" */ '../../components/HelpCenter/Block.vue'),
            meta: { permissionRequired: PERMISSION.HELP_CENTER__GENERAL__MANAGE },
          },
          {
            path: '/help_center/:id/settings',
            component: () => import(/* webpackChunkName: "HelpCenter" */ '../../components/HelpCenter/Settings.vue'),
            meta: { permissionRequired: PERMISSION.HELP_CENTER__GENERAL__MANAGE },
          },
          {
            path: '/help_center/:id/authentication',
            component: () => import(/* webpackChunkName: "HelpCenter" */ '../../components/HelpCenter/Authentication.vue'),
            meta: { permissionRequired: PERMISSION.HELP_CENTER__GENERAL__MANAGE },
          },
          {
            path: '/help_center/:id/theme',
            component: () => import(/* webpackChunkName: "HelpCenter" */ '../../components/HelpCenter/Theme.vue'),
            meta: { permissionRequired: PERMISSION.HELP_CENTER__GENERAL__MANAGE },
          },
        ],
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "HelpCenter" */ '../../components/AdminIndexBox.vue'),
        props: { boxName: 'help_center' },
        meta: { permissionRequired: PERMISSION.HELP_CENTER__GENERAL__MANAGE },
      },
    ],
  },
];

export default helpcenterRoutes;
