import { useContactsAuth } from '@/composables/useContactsAuth';
import { SETTINGS_URL } from '@/Configs/Constants/navigation/settingsUrl';

import helpcenter from './admin/helpcenter';
import unauthorized from './admin/unauthorized';
import wabBroadcasting from './admin/wab_broadcasting';
import ai from './ai';
import { ROUTE_NAME } from './constants';
import integrationHub from './integrationHub';
import reporting from './reporting';
import workflows from './workflows';

import type { RouteRecord } from './types';
import type { RouteLocationNormalizedGeneric } from 'vue-router';

const contactsGuard = (to: RouteLocationNormalizedGeneric) => {
  // eslint-disable-next-line vue-composable/composable-placement
  const contactsAuth = useContactsAuth();

  if (!contactsAuth.canAccessContacts.value) {
    return { name: 'unauthorized' };
  }

  if (!contactsAuth.shouldShowAllOptions() && contactsAuth.hasPrivateChannels.value) {
    if (to.path !== '/contacts/mine') {
      return { path: '/contacts/mine' };
    }
  }

  return true;
};

const dashboardRoutes: RouteRecord[] = [
  {
    path: '',
    redirect: '/tickets',
  },
  {
    path: 'ai',
    name: ROUTE_NAME.TRENGO_AI,
    redirect: { name: ROUTE_NAME.WORKFLOWS_OVERVIEW },
  },
  {
    path: 'tickets',
    name: ROUTE_NAME.TICKETS,
    meta: { title: 'Inbox' },
    component: () => import(/* webpackChunkName: "Tickets" */ '../components/Tickets.vue'),
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "LandingPageIndex" */ '../components/LandingPageIndex.vue'),
        name: 'tickets-index',
      },
      {
        path: 'show/:id',
        redirect: (to) => {
          return to.path.replace('/show', '');
        },
      },
      {
        path: ':ticketId',
        component: () => import(/* webpackChunkName: "Ticket" */ '../components/Ticket.vue'),
        name: 'tickets-show',
      },
    ],
  },
  {
    path: '/onboard-channels',
    component: () =>
      import(/* webpackChunkName: "OnboardingChannelLandingPage" */ '../components/OnboardingChannelLandingPage'),
  },
  {
    path: '/welcome',
    component: () => import(/* webpackChunkName: "OnboardingSteps" */ '../components/OnboardingSteps'),
  },
  {
    path: '/contacts',
    component: () => import(/* webpackChunkName: "Contacts" */ '../components/Contacts/Contacts.vue'),
    meta: { title: 'Contacts' },
    beforeEnter: contactsGuard,
    children: [
      {
        path: '/contacts',
        redirect: (to) => {
          return to.path + '/all';
        },
      },
      {
        path: '/contacts/all',
        component: () => import(/* webpackChunkName: "ContactsIndex" */ '../components/Contacts/ContactsIndex.vue'),
        props: { scope: 'public' },
      },
      {
        path: '/contacts/mine',
        component: () => import(/* webpackChunkName: "ContactsIndex" */ '../components/Contacts/ContactsIndex.vue'),
        props: { scope: 'private' },
      },
      {
        path: '/contacts/:id',
        component: () => import(/* webpackChunkName: "Contact" */ '../components/Contacts/Contact.vue'),
        name: 'contacts-show'
      },
      {
        path: '/sms/import',
        component: () => import(/* webpackChunkName: "SmsImport" */ '../components/Sms/Import.vue'),
        beforeEnter: contactsGuard
      },
    ],
  },
  {
    path: '/profiles',
    component: () => import(/* webpackChunkName: "Profiles" */ '../components/Profiles/Profiles.vue'),
    meta: { title: 'Profiles' },
    beforeEnter: contactsGuard,
    children: [
      {
        path: '/profiles',
        component: () => import(/* webpackChunkName: "ProfilesIndex" */ '../components/Profiles/ProfilesIndex.vue')
      },
      {
        path: '/profiles/show/:id',
        redirect: (to) => {
          return to.path.replace('/show', '');
        },
      },
      {
        path: '/profiles/:id',
        component: () => import(/* webpackChunkName: "Profile" */ '../components/Profiles/Profile.vue'),
        name: 'profiles-show'
      },
    ],
  },
  {
    path: '/contact_groups',
    component: () => import(/* webpackChunkName: "ContactGroupsOverview" */ '../components/ContactGroupsDashboard/ContactGroupsOverview/Index.vue'),
    name: ROUTE_NAME.CONTACT_GROUPS_OVERVIEW,
    beforeEnter: contactsGuard,
  },
  {
    path: '/contact_groups/:id',
    component: () => import(/* webpackChunkName: "ContactGroupsDetail" */ '../components/ContactGroupsDashboard/ContactGroupsDetail/Index.vue'),
    name: ROUTE_NAME.CONTACT_GROUPS_DETAIL,
    beforeEnter: contactsGuard,
  },
  {
    path: '/statistics',
    redirect: (to) => {
      return to.path.replace('statistics', 'reports');
    },
  },
  {
    path: '/statistics/:id',
    redirect: (to) => {
      return to.path.replace('statistics', 'reports');
    },
  },
  {
    path: '/sms',
    component: () => import(/* webpackChunkName: "SmsIndex" */ '../components/Sms/Index.vue'),
    children: [
      {
        path: '/sms/send',
        component: () => import(/* webpackChunkName: "SmsQuickSend" */ '../components/Sms/QuickSend.vue')
      },
      {
        path: '/sms/send-bulk',
        component: () => import(/* webpackChunkName: "SmsBulkSms" */ '../components/Sms/BulkSms.vue')
      },
      {
        path: '/sms/wallet',
        component: () => import(/* webpackChunkName: "SmsWallet" */ '../components/Sms/Wallet.vue')
      },
      { path: '/sms/balance', redirect: SETTINGS_URL.ADMIN__BALANCE_SETTINGS },
    ],
  },
  {
    path: '/profile',
    redirect: '/profile/overview',
  },
  {
    path: '/profile',
    component: () => import(/* webpackChunkName: "ProfilePage" */ '../components/Pages/Profile.vue'),
    meta: { title: 'Profile' },
    children: [
      {
        path: '/profile/overview',
        component: () => import(/* webpackChunkName: "UpdateProfile" */ '../components/Pages/Sections/UpdateProfile.vue')
      },
      {
        path: '/profile/password',
        component: () => import(/* webpackChunkName: "UpdatePassword" */ '../components/Pages/Sections/UpdatePassword.vue')
      },
      {
        path: '/profile/2fa',
        component: () => import(/* webpackChunkName: "TwoFactorAuth" */ '../components/Pages/Sections/TwoFactorAuth')
      },
      {
        path: '/profile/preferences',
        component: () => import(/* webpackChunkName: "Preferences" */ '../components/Pages/Sections/Preferences.vue')
      },
      {
        path: '/profile/get-started',
        component: () => import(/* webpackChunkName: "OnboardingSteps" */ '../components/Onboarding/OnboardingSteps.vue')
      },
    ],
  },
  {
    path: '/search',
    component: () => import(/* webpackChunkName: "Search" */ '../components/Pages/Search.vue'),
  },
  {
    path: '/admin/help_center',
    redirect: '/help_center',
  },
  {
    path: '/admin/help_center/:catchAll(.*)',
    redirect: (to) => {
      return `/help_center/${to.params.catchAll}`;
    },
  },
  ...helpcenter,
  ...wabBroadcasting,
  ...integrationHub,
  ...unauthorized,
  ...reporting,
  ...ai,
  ...workflows,
];

export default dashboardRoutes;
