<template>
  <div class="row-col bg-white">
    <portal to="mobile-menu-bar">
      <template v-if="activeTabValue !== null">
        <div class="flex w-full items-center justify-center">
          <div
            v-if="recordIsDirty"
            class="font-base w-80px cursor-pointer self-start font-semibold"
            :class="{ invisible: saving }"
            @click="revertDirtyChanges"
          >
            <span>{{ $t('email_settings.cancel') }}</span>
          </div>
          <div v-else class="w-80px cursor-pointer self-start" @click="activeTabValue = null">
            <img
              class="mr-2 inline-block h-3"
              :src="`${$root.assetsURL}img/action_icons/icon-arrow-left.svg`"
              alt="back"
            />
          </div>
          <span class="mx-auto font-bold">
            {{ computedTabs.find((t) => t.value === activeTabValue).title }}
          </span>
          <div class="font-base w-80px text-right font-semibold">
            <span v-if="recordIsDirty" :class="{ loader: saving }" @click="save">{{ $t('email_settings.save') }}</span>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="flex w-full items-center justify-center">
          <div
            v-if="recordIsDirty"
            class="font-base w-80px cursor-pointer self-start font-semibold"
            @click="revertDirtyChanges"
          >
            <span>{{ $t('email_settings.cancel') }}</span>
          </div>
          <template v-else>
            <router-link :to="'/admin/channels2/' + record.type.toLowerCase()" class="w-80px self-start">
              <img
                class="mr-2 inline-block h-3"
                :src="`${$root.assetsURL}img/action_icons/icon-arrow-left.svg`"
                alt="back"
              />
            </router-link>
          </template>
          <span class="mx-auto font-bold">{{ $t('email_settings.settings') }}</span>
          <div class="font-base w-80px text-right font-semibold">
            <span v-if="recordIsDirty" @click="save">{{ $t('email_settings.save') }}</span>
          </div>
        </div>
      </template>
    </portal>

    <template v-if="loaded">
      <div v-if="isLgOrXl" class="row-row" :class="{ box: isLgOrXl, 'top-banner': isLgOrXl }">
        <div ref="scrollableContainer" class="h-full">
          <div class="p-a p-b-0 container h-full">
            <div class="p-b-0 flex h-full flex-col justify-between px-3 pt-4">
              <router-link :to="'/admin/channels2/' + record.type.toLowerCase()" class="flex items-center">
                <img
                  class="mr-2 inline-block h-3"
                  :src="`${$root.assetsURL}img/action_icons/icon-arrow-left.svg`"
                  alt="back"
                />
                <span class="mx-2 font-semibold">{{ $t('email_settings.back_to_overview') }}</span>
              </router-link>
              <div class="my-6 flex">
                <div class="mr-6 flex">
                  <img v-if="isGmailChannel" :src="`${$root.assetsURL}img/gmail.svg`" width="35px" alt="icon" />
                  <img
                    v-else-if="isOutlookChannel"
                    :src="`${$root.assetsURL}img/outlook.svg`"
                    width="35px"
                    alt="icon"
                  />
                  <div v-else class="flex items-center">
                    <i class="fa fa-at fa-2x" />
                  </div>
                </div>
                <div class="mr-6">
                  <div class="font-2xl font-bold text-grey-900">
                    <span>{{ record.title }}</span>
                  </div>
                  <div class="font-lg font-medium text-grey-600">
                    <span>{{ record.text.split(' • ').pop() }}</span>
                  </div>
                </div>
                <div class="ml-auto flex flex-row space-x-3">
                  <atomic-button
                    v-if="record.id"
                    btn-style="secondary"
                    size="sm"
                    class="flex items-center"
                    @click="openInboxWithChannelFilter"
                  >
                    <span>{{ $t('email_settings.open_inbox') }}</span>
                    <inbox-linear size="1.1rem" class="ml-1.5 inline" />
                  </atomic-button>
                  <t-button
                    v-if="shouldDisplayReconnectButton"
                    btn-style="secondary"
                    size="sm"
                    class="flex items-center"
                    @click="reconnect"
                  >
                    <span class="text-sm font-bold">{{ strippedReconnectString }}</span>
                    <rotate-ccw-linear size="1.1rem" class="ml-2" />
                  </t-button>
                </div>
              </div>
              <div v-if="computedTabs" class="flex flex-row">
                <t-tab-item
                  v-for="(tab, i) in computedTabs"
                  :key="tab.title"
                  :is-active="i === activeTabKey"
                  :label="tab.title"
                  @click="toggleActiveTab(i)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else-if="!isLgOrXl && activeTabValue === null"
        class="overflow-auto bg-beta-100"
        style="height: calc(100vh - 90px)"
      >
        <div class="p-b-0 my-6 flex items-center px-4">
          <div class="mr-4">
            <img v-if="isGmailChannel" :src="`${$root.assetsURL}img/gmail.svg`" width="36px" alt="icon" />
            <img v-else-if="isOutlookChannel" :src="`${$root.assetsURL}img/outlook.svg`" width="36px" alt="icon" />
            <div v-else class="flex items-center">
              <i class="fa fa-at fa-2x" />
            </div>
          </div>
          <div class="flex flex-col items-start">
            <div class="font-2xl font-bold text-grey-900">
              <span>{{ record.title }}</span>
            </div>
            <div class="font-lg font-medium text-grey-600">
              <span>{{ record.text.replace(record.title + ' • ', '') }}</span>
            </div>
          </div>
        </div>
        <div class="flex flex-col bg-white">
          <t-list-item-accordion @click="activeTabValue = 'channel_settings'">
            <template #icon>
              <span class="mobile_settings_icon bg-purple-200 text-xl">
                <i class="fa fa-inbox text-purple-800" />
              </span>
            </template>
            <template #title>
              <span class="font-semibold">{{ $t('email_settings.channel_settings') }}</span>
            </template>
          </t-list-item-accordion>
          <t-list-item-accordion @click="activeTabValue = 'email_settings'">
            <template #icon>
              <span class="mobile_settings_icon bg-sky-200">
                <i class="fa fa-envelope text-sky-800" />
              </span>
            </template>
            <template #title>
              <span class="font-semibold">{{ $t('email_settings.email_settings') }}</span>
            </template>
          </t-list-item-accordion>
          <t-list-item-accordion @click="activeTabValue = 'advanced_settings'">
            <template #icon>
              <span class="mobile_settings_icon bg-galaxy-200 text-xl">
                <i class="fa fa-cogs text-galaxy-800" />
              </span>
            </template>
            <template #title>
              <span class="font-semibold">{{ $t('email_settings.advanced') }}</span>
            </template>
          </t-list-item-accordion>
          <t-list-item-accordion v-if="isGmailChannel" @click="activeTabValue = 'gmail_settings'">
            <template #icon>
              <span class="mobile_settings_icon bg-galaxy-100">
                <img :src="`${$root.assetsURL}img/google.svg`" width="18px" alt="icon" />
              </span>
            </template>
            <template #title>
              <span class="font-semibold">{{ $t('email_settings.gmail_settings') }}</span>
            </template>
          </t-list-item-accordion>
          <t-list-item-accordion v-if="isOutlookChannel" @click="activeTabValue = 'outlook_settings'">
            <template #icon>
              <span class="mobile_settings_icon bg-galaxy-100">
                <img :src="`${$root.assetsURL}img/outlook.svg`" width="18px" alt="icon" />
              </span>
            </template>
            <template #title>
              <span class="font-semibold">{{ $t('email_settings.outlook_settings') }}</span>
            </template>
          </t-list-item-accordion>
          <t-list-item-accordion v-if="isForwardingChannel" @click="activeTabValue = 'forwarding_settings'">
            <template #icon>
              <span class="mobile_settings_icon bg-galaxy-100 text-xl">
                <i class="fa fa-at m-auto" />
              </span>
            </template>
            <template #title>
              <span class="font-semibold">{{ $t('email_settings.domain_settings') }}</span>
            </template>
          </t-list-item-accordion>
        </div>

        <div class="mb-8 mt-8 rounded-none bg-white">
          <t-list-item-accordion :right-chevron="false" @click="removeChannel">
            <template #icon>
              <span class="mobile_settings_icon bg-error-100">
                <i class="fas fa-trash text-error-700" />
              </span>
            </template>
            <template #title>
              <span class="font-semibold text-error-700">{{ $t('email_settings.delete_channel') }}</span>
            </template>
          </t-list-item-accordion>
        </div>
      </div>
      <settings-layout>
        <template
          v-if="
            (isLgOrXl && (activeTabValue === 'channel_settings' || activeTabValue === null)) ||
            (!isLgOrXl && activeTabValue === 'channel_settings')
          "
        >
          <channel-settings
            :action="action"
            :record="record"
            :business-hours="businessHours"
            :teams="teams"
            :has-notification-sound="hasNotificationSound"
            :has-reopen-closed-tickets="hasReopenClosedTickets"
            :has-business-hours="hasBusinessHours"
            @input="(value) => (record = value)"
            @init-warning="initWarning"
            @delete-channel="removeChannel"
          />
        </template>
        <email-settings
          v-if="activeTabValue === ('email_settings' || null)"
          :action="action"
          :record="record"
          :is-external-provider="isExternalProvider"
          @switch="handleToggleSwitch"
          @input="(value) => (record = value)"
          @init-warning="initWarning"
        />
        <advanced-email-settings
          v-if="activeTabValue === 'advanced_settings'"
          :action="action"
          :record="record"
          :has-reopen-closed-tickets="hasReopenClosedTickets"
          @switch="handleToggleSwitch"
          @input="(value) => (record = value)"
          @init-warning="initWarning"
        />
        <gmail-settings
          v-if="activeTabValue === 'gmail_settings'"
          :action="action"
          :record="record"
          :external-provider-settings="externalProviderSettings"
          @switch="handleToggleSwitch"
          @reconnect-channel="reconnectChannel"
          @input="(value) => (record = value)"
          @init-warning="initWarning"
        />
        <outlook-settings
          v-if="activeTabValue === 'outlook_settings'"
          :action="action"
          :record="record"
          :external-provider-settings="externalProviderSettings"
          @switch="handleToggleSwitch"
          @reconnect-channel="reconnectChannel"
          @input="(value) => (record = value)"
          @init-warning="initWarning"
        />
        <nylas-settings
          v-if="activeTabValue === 'nylas_settings'"
          :action="action"
          :record="record"
          :external-provider-settings="externalProviderSettings"
          @input="(value) => (record = value)"
          @init-warning="initWarning"
        />
        <domain-settings
          v-if="activeTabValue === 'forwarding_settings'"
          ref="domainSettings"
          :action="action"
          :record="record"
          :is-external-provider="isExternalProvider"
          @input="(value) => (record = value)"
          @init-warning="initWarning"
          @smtp-tab-opened="updateSmtpTabStatus"
        />
      </settings-layout>

      <transition name="slide-fade-down">
        <div v-show="recordIsDirty" class="container hidden lg:block">
          <div class="xl:w-2/3">
            <t-action-popup
              :saving="saving"
              :text="$t('general.action_popup_you_have_unsaved_changes')"
              :discard-text="$t('general.action_popup_discard')"
              :save-text="$t('general.action_popup_save_changes')"
              @save="saveAction"
              @reset="revertChanges"
            />
          </div>
        </div>
      </transition>
    </template>
  </div>
  <delete-channel-modal
    :channel-id="$route.params.id"
    :channel-type="$route.params.channel"
    :confirmation-text="`${$route.params.channel}-${$route.params.id}`"
  />
</template>

<script lang="ts">
import { RotateCcwLinear, InboxLinear } from '@trengo/trengo-icons';

import { loadChannelSettings } from '@/api/modules/email';
import BaseChannel from '@/components/Channels/BaseChannel.vue';
import DeleteChannelModal from '@/components/Channels/DeleteChannelModal/DeleteChannelModal.vue';
import DomainSettings from '@/components/Channels/EmailSettings/Pages/ChannelSettings/DomainSettings.vue';
import { useDeleteChannelModalStore } from '@/components/Channels/stores/deleteChannelModalStore';
import { CHANNEL_TYPE } from '@/Configs/Constants';
import {
  GOOGLE,
  OUTLOOK,
  NYLAS,
  GMAIL,
  TRENGO,
  NYLAS_API_V3,
  NYLAS_V3,
} from '@/Configs/Constants/ChannelLandingPageTypes';
import { NYLAS_CHANNEL_RECONNECTION_MESSAGES } from '@/Configs/Constants/emailChannels';
import eventBus from '@/eventBus';
import breakpoints from '@/util/breakpoints';

import ChannelSettings from '../ChannelSettings';
import AdvancedEmailSettings from '../ChannelSettings/AdvancedEmailSettings.vue';
import EmailSettings from '../ChannelSettings/EmailSettings.vue';
import GmailSettings from '../ChannelSettings/GmailSettings.vue';
import NylasSettings from '../ChannelSettings/NylasSettings.vue';
import OutlookSettings from '../ChannelSettings/OutlookSettings.vue';
import SettingsLayout from '../ChannelSettings/SettingsLayout.vue';

export default {
  name: 'ChannelEdit',
  components: {
    RotateCcwLinear,
    DomainSettings,
    AdvancedEmailSettings,
    OutlookSettings,
    GmailSettings,
    EmailSettings,
    ChannelSettings,
    SettingsLayout,
    InboxLinear,
    NylasSettings,
    DeleteChannelModal,
  },
  // FIXME: remove BaseChannel dependency
  extends: BaseChannel,
  props: {
    action: {
      type: String,
      default: 'create',
    },
  },
  setup() {
    const deleteChannelModalStore = useDeleteChannelModalStore();
    return { deleteChannelModalStore };
  },

  data() {
    return {
      breakpoints,
      activeTabValue: null,
      activeTabKey: 0,
      record: {
        type: CHANNEL_TYPE.EMAIL,
        emailChannel: {
          auto_reply_enabled: 1,
          split_by_subject: 1,
          split_by_sender: 0,
          embed_attachments: 1,
          add_conversation_to_reply: 1,
          spam_setting: 1,
          sender_email_id: null,
          email_theme_id: null,
          auto_reply_body: '',
          signature: '',
          branding: true,
          branding_service_name: window.AGENCY.name,
          prepend_ticket_number_to_subject: false,
          type: this.$route.query.type || '',
          email_address: null,
          meta: {
            reply_all: false,
            selectedFolders: [],
            syncStatus: null,
            syncFolders: false,
            zapier_consent: null,
            email_addresses: [],
            folderIds: [],
          },
        },
      },

      externalProviderSettings: {
        syncStatus: null,
        syncFolders: null,
        availableFolders: [],
        selectedFolders: [],

        loadedAccountInfo: false,
        accountInfo: null,
        accountError: false,
      },
      isSmtpTabOpen: false,
    };
  },
  computed: {
    isLgOrXl() {
      const { comparisons } = this.breakpoints;
      return comparisons.eq_lg || comparisons.gt_lg;
    },
    isGmailChannel() {
      return this.$route.query.type === GOOGLE || this.record.emailChannel.type === GOOGLE;
    },
    isOutlookChannel() {
      return this.$route.query.type === OUTLOOK || this.record.emailChannel.type === OUTLOOK;
    },
    isForwardingChannel() {
      return this.$route.query.type === TRENGO || this.record.emailChannel.type === TRENGO;
    },
    isNylasChannel() {
      return (
        this.$route.query.type === NYLAS ||
        this.record.emailChannel.type === NYLAS ||
        this.$route.query.type === NYLAS_V3 ||
        this.record.emailChannel.type === NYLAS_V3
      );
    },
    isNylasV3Channel() {
      return this.$route.query.type === NYLAS_V3 || this.record.emailChannel.type === NYLAS_V3;
    },
    isExternalProvider() {
      return this.isGmailChannel || this.isOutlookChannel || this.isNylasChannel;
    },
    computedTabs() {
      return [
        { value: 'channel_settings', title: this.$t('email_settings.channel_settings') },
        { value: 'email_settings', title: this.$t('email_settings.email_settings') },
        { value: 'advanced_settings', title: this.$t('email_settings.advanced_settings') },
        { value: 'gmail_settings', title: this.$t('email_settings.gmail_settings'), show: this.isGmailChannel },
        { value: 'outlook_settings', title: this.$t('email_settings.outlook_settings'), show: this.isOutlookChannel },
        { value: 'nylas_settings', title: this.$t('email_settings.additional_settings'), show: this.isNylasV3Channel },
        {
          value: 'forwarding_settings',
          title: this.$t('email_settings.domain_settings'),
          show: this.isForwardingChannel,
        },
      ].filter((tab) => tab.show || !Object.prototype.hasOwnProperty.call(tab, 'show'));
    },
    strippedReconnectString() {
      return this.$t('email_settings.reconnect').slice(0, -1);
    },
    shouldDisplayReconnectButton() {
      return this.isOutlookChannel || this.isGmailChannel || this.isNylasChannel;
    },
  },

  watch: {
    isLgOrXl(val) {
      this.showToastFlag = val;
    },
    'externalProviderSettings.selectedFolders'() {
      this.record.emailChannel.meta.selectedFolders = this.externalProviderSettings.selectedFolders;
    },
    'externalProviderSettings.syncStatus'() {
      this.record.emailChannel.meta.syncStatus = this.externalProviderSettings.syncStatus;
    },
    'externalProviderSettings.syncFolders'() {
      this.record.emailChannel.meta.syncFolders = this.externalProviderSettings.syncFolders;
    },
  },
  mounted() {
    this.showToastFlag = this.isLgOrXl;

    const { status: nylasReconnectionStatus, message: nylasReconnectionMessage } = this.$route.query;
    if (nylasReconnectionStatus && nylasReconnectionMessage) {
      this.handleNylasChannelReconnection(nylasReconnectionMessage);
    }
  },

  methods: {
    removeChannel() {
      this.deleteChannelModalStore.openModal(this.$t('channels.delete_confirm_will_remove_all_conversations'));
    },
    handleManipulateValue(mainObject) {
      return typeof mainObject === 'number' ? (mainObject === 0 ? 1 : 0) : !mainObject;
    },
    handleMapRecords(keys, recordValue) {
      let records = this.record;
      keys.map((item) => {
        records = { ...records, [item]: this.handleManipulateValue(recordValue) };
      });
      return records;
    },
    handleToggleSwitch(key) {
      const keys = key.split('.');
      let recordValue = this.record;
      for (const value of keys) {
        recordValue = recordValue[value];
      }

      if (keys.length > 2) {
        this.record[keys[0]][keys[1]][keys[2]] = this.handleManipulateValue(recordValue);
      } else if (keys.length > 1) {
        this.record[keys[0]][keys[1]] = this.handleManipulateValue(recordValue);
      } else {
        this.record[keys[0]] = this.handleManipulateValue(recordValue);
      }
    },
    async fetchedChannel() {
      try {
        await this.loadExternalProvider();
        if (this.record.emailChannel.type === 'TRENGO') {
          this.setReplyToAllRecipients();
        }
      } catch (error) {
        console.error('Error fetching channel:', error);
      } finally {
        this.initWarning();
      }
    },
    openInboxWithChannelFilter() {
      router.push('/tickets');
      this.$nextTick(() => {
        eventBus.$emit('tickets.filters.apply', ...['channels', this.record.id]);
      });
    },
    setReplyToAllRecipients() {
      if (!this.record.emailChannel?.meta?.reply_all) {
        this.record.emailChannel.meta = {
          ...this.record.emailChannel.meta,
          reply_all: false,
        };
      }
      if (this.record.emailChannel?.meta?.email_addresses !== null) {
        return;
      }
      this.record.emailChannel.meta = {};
    },
    async loadExternalProvider() {
      if (!this.isExternalProvider) {
        return;
      }
      try {
        if (this.isGmailChannel) {
          await this.loadGmailSettings();
        } else if (this.isOutlookChannel) {
          await this.loadOutlookSettings();
        } else if (this.isNylasV3Channel) {
          await this.loadNylasSettings();
        }
      } catch (error) {
        console.error('Error loading external provider settings:', error);
      }
    },
    async loadGmailSettings() {
      this.externalProviderSettings.syncStatus = this.record.emailChannel.meta.syncStatus
        ? this.record.emailChannel.meta.syncStatus
        : null;
      this.externalProviderSettings.syncFolders = this.record.emailChannel.meta.syncFolders
        ? this.record.emailChannel.meta.syncFolders
        : null;
      this.externalProviderSettings.selectedFolders = this.record.emailChannel.meta.selectedFolders
        ? this.record.emailChannel.meta.selectedFolders
        : [];
      if (this.$route.query.action === 'reconnect') {
        const params = {
          access_token: this.$route.query.access_token,
          refresh_token: this.$route.query.refresh_token,
          created: this.$route.query.created,
          expires_in: this.$route.query.expires_in,
        };
        this.record.emailChannel.meta = params;
        try {
          const response = await loadChannelSettings('gmail', { ...params });
          const { labels = [] } = response.data;
          this.externalProviderSettings.syncFolders = this.record.emailChannel?.meta?.syncFolders;
          this.externalProviderSettings.availableFolders = labels;
          this.updateChannel();
        } catch (error) {
          console.error(error);
        }
      } else {
        try {
          const response = await loadChannelSettings('gmail', {}, this.record.id);
          const { user = {}, labels = [] } = response.data;
          this.externalProviderSettings.loadedAccountInfo = true;
          this.externalProviderSettings.accountInfo = user?.emailAddress;
          this.externalProviderSettings.availableFolders = labels;
        } catch (error) {
          this.externalProviderSettings.loadedAccountInfo = true;
          this.externalProviderSettings.accountError = true;
          if (error.response && error.response.data && error.response.data.message) {
            this.externalProviderSettings.accountInfo = error.response.data.message;
          }
        }
      }
    },
    async loadNylasSettings() {
      try {
        const response = await loadChannelSettings('nylas', {}, this.record.id);
        const { mail_folders = [] } = response.data;
        this.externalProviderSettings.loadedAccountInfo = true;
        this.externalProviderSettings.availableFolders = mail_folders;
        this.record.emailChannel.meta.folderIds = mail_folders;
      } catch (error) {
        this.externalProviderSettings.loadedAccountInfo = true;
        this.externalProviderSettings.accountError = true;
        if (error.response && error.response.data && error.response.data.message) {
          this.externalProviderSettings.accountInfo = error.response.data.message;
        }
      }
    },
    async loadOutlookSettings() {
      if (this.$route.query.action === 'reconnect') {
        try {
          const response = await loadChannelSettings('outlook', { outlook_id: this.$route.query.outlook_id });
          const { meta = {}, mail_folders = [] } = response.data;
          this.record.emailChannel.meta.access_token = meta?.access_token;
          this.record.emailChannel.meta.expires = meta?.expires;
          this.record.emailChannel.meta.ext_expires_in = meta?.ext_expires_in;
          this.record.emailChannel.meta.id_token = meta?.id_token;
          this.record.emailChannel.meta.refresh_token = meta?.refresh_token;
          this.record.emailChannel.meta.scope = meta?.scope;
          this.record.emailChannel.meta.token_type = meta?.token_type;
          this.externalProviderSettings.syncFolders = this.record.emailChannel.meta.syncFolders;
          this.externalProviderSettings.availableFolders = mail_folders;
          this.updateChannel();
        } catch (error) {
          console.error(error);
        }
      } else {
        try {
          const response = await loadChannelSettings('outlook', {}, this.record.id);
          const { user = {}, mail_folders = [] } = response.data;
          this.externalProviderSettings.loadedAccountInfo = true;
          this.externalProviderSettings.accountInfo = user?.userPrincipalName;
          this.externalProviderSettings.availableFolders = mail_folders;
        } catch (error) {
          this.externalProviderSettings.loadedAccountInfo = true;
          this.externalProviderSettings.accountError = true;
          if (error.response && error.response.data && error.response.data.message) {
            this.externalProviderSettings.accountInfo = error.response.data.message;
          }
        }
      }
    },

    handleNylasChannelReconnection(nylasReconnectionMessage) {
      if (nylasReconnectionMessage === NYLAS_CHANNEL_RECONNECTION_MESSAGES.UPDATE_SUCCESSFUL) {
        this.flashSuccess(this.$t('channels.update_successful'));
      } else if (nylasReconnectionMessage === NYLAS_CHANNEL_RECONNECTION_MESSAGES.UPDATE_FAILED_WRONG_EMAIL) {
        this.flashError(this.$t('channels.update_failed_wrong_email'));
      } else if (nylasReconnectionMessage === NYLAS_CHANNEL_RECONNECTION_MESSAGES.UPDATE_FAILED_DIFFERENT_CHANNEL) {
        this.flashError(this.$t('channels.update_failed_different_channel'));
      }
    },
    toggleActiveTab(activeTabKey) {
      document.querySelector('.settings-tab-content-scrollable-container').scrollTop = 0;
      this.activeTabKey = activeTabKey;
      this.activeTabValue = this.computedTabs[activeTabKey].value;
    },
    saveAction() {
      if (this.$refs.domainSettings) {
        if (this.isSmtpTabOpen) {
          this.$refs.domainSettings.handleFieldsValidation('validate');
          const isFormValid = this.$refs.domainSettings.isFormValid;
          if (!isFormValid) return;
        } else {
          this.$refs.domainSettings.handleFieldsValidation('reset');
        }
      }
      this.save();
    },
    revertChanges() {
      this.$refs.domainSettings?.handleFieldsValidation('reset');
      this.revertDirtyChanges();
    },
    updateSmtpTabStatus(value) {
      this.isSmtpTabOpen = value;
    },
    reconnectChannel(channel) {
      axios
        .post(`/api/v2/${channel.toLowerCase()}/authenticate?cid=` + this.record.id)
        .then((res) => {
          window.location.href = res.data.url;
        })
        .catch(() => {
          this.$router.push('/admin/channels2/email');
        });
    },
    getChannelType() {
      return this.record.emailChannel.type;
    },
    reconnect() {
      let channelType = this.getChannelType();
      if (channelType === GOOGLE) {
        channelType = GMAIL;
      }
      if (channelType === NYLAS_V3) {
        channelType = NYLAS_API_V3;
      }
      this.reconnectChannel(channelType);
    },
  },
};
</script>

<style scoped lang="scss" src="./Edit.scss" />
