<template>
  <danger-zone-verification :enable-scroll="false">
    <create-broadcast-view :action="action" />
  </danger-zone-verification>
</template>

<script lang="ts" setup>
import DangerZoneVerification from '@/components/common/DangerZoneVerification';

import CreateBroadcastView from './CreateBroadcastView.vue';

// The route definition passes an `action` prop to this route, but when implementing the Danger Zone
// we forgot to reintroduce the same prop and pass it further along to the actual view component
defineProps<{ action: 'create' | 'edit' }>();
</script>
