import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-30575ff8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title-bar" }
const _hoisted_2 = { class: "flex justify-between self-center" }
const _hoisted_3 = { "data-test": "installed-item-title" }
const _hoisted_4 = { class: "flex flex-row" }
const _hoisted_5 = { class: "table-wrapper" }
const _hoisted_6 = { class: "w-full table-auto border-collapse" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_install_modal = _resolveComponent("install-modal")!
  const _component_t_badge = _resolveComponent("t-badge")!
  const _component_more_horiz_fill = _resolveComponent("more-horiz-fill")!
  const _component_t_icon_button = _resolveComponent("t-icon-button")!
  const _component_edit2_linear = _resolveComponent("edit2-linear")!
  const _component_t_list_item = _resolveComponent("t-list-item")!
  const _component_edit_linear = _resolveComponent("edit-linear")!
  const _component_rotate_ccw_linear = _resolveComponent("rotate-ccw-linear")!
  const _component_trash_linear = _resolveComponent("trash-linear")!
  const _component_t_action_menu = _resolveComponent("t-action-menu")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.isReauthModalOpen)
      ? (_openBlock(), _createBlock(_component_install_modal, {
          key: 0,
          "is-open": _ctx.isReauthModalOpen,
          "installation-id": _ctx.selectedInstallationID,
          onClose: _ctx.toggleReauthModal
        }, null, 8 /* PROPS */, ["is-open", "installation-id", "onClose"]))
      : _createCommentVNode("v-if", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.installations, (installation, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: installation.id,
        class: "mb-8 rounded-md border border-grey-300"
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("span", _hoisted_3, _toDisplayString(installation.name), 1 /* TEXT */)
          ]),
          _createElementVNode("div", _hoisted_4, [
            (_ctx.getStatusText(installation.status) !== '')
              ? (_openBlock(), _createBlock(_component_t_badge, {
                  key: 0,
                  class: "mr-2 self-center",
                  variant: _ctx.getVariant(installation.status),
                  text: _ctx.$t(`integration_hub.${_ctx.getStatusText(installation.status)}`)
                }, null, 8 /* PROPS */, ["variant", "text"]))
              : _createCommentVNode("v-if", true),
            (_openBlock(), _createBlock(_component_t_action_menu, {
              key: index,
              modelValue: installation.isOpen,
              "onUpdate:modelValue": ($event: any) => ((installation.isOpen) = $event),
              width: "max-content",
              position: "left"
            }, {
              trigger: _withCtx(() => [
                _createVNode(_component_t_icon_button, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_more_horiz_fill)
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              content: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createVNode(_component_t_list_item, {
                    label: _ctx.$tc('integration_hub.rename'),
                    "data-test": "rename-installed-integration",
                    onClick: ($event: any) => (_ctx.renameInstallation(installation))
                  }, {
                    prefix: _withCtx(() => [
                      _createVNode(_component_edit2_linear, { size: "1.5rem" })
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label", "onClick"]),
                  _createVNode(_component_t_list_item, {
                    label: _ctx.$tc('integration_hub.edit'),
                    "data-test": "edit-installed-integration",
                    onClick: ($event: any) => (_ctx.editInstallation(installation))
                  }, {
                    prefix: _withCtx(() => [
                      _createVNode(_component_edit_linear, { size: "1.5rem" })
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label", "onClick"]),
                  (
                    _ctx.integration.auth_type === 'oauth2' &&
                    (_ctx.integration.oauth_grant_type === 'authorization_code' || _ctx.integration.oauth_grant_type === null)
                  )
                    ? (_openBlock(), _createBlock(_component_t_list_item, {
                        key: 0,
                        label: _ctx.$tc('integration_hub.reauthenticate'),
                        "data-test": "reauthenticate-installed-integration",
                        onClick: ($event: any) => (_ctx.reauthenticateInstallation(installation))
                      }, {
                        prefix: _withCtx(() => [
                          _createVNode(_component_rotate_ccw_linear, { size: "1.5rem" })
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label", "onClick"]))
                    : _createCommentVNode("v-if", true),
                  _createVNode(_component_t_list_item, {
                    label: _ctx.$tc('integration_hub.uninstall'),
                    "is-delete": true,
                    "data-test": "delete-installed-integration",
                    onClick: ($event: any) => (_ctx.uninstall(installation))
                  }, {
                    prefix: _withCtx(() => [
                      _createVNode(_component_trash_linear, { size: "1.5rem" })
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label", "onClick"])
                ])
              ]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onUpdate:modelValue"]))
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("table", _hoisted_6, [
            _createElementVNode("tbody", null, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.dataTemplateComponent), { installation: installation }, null, 8 /* PROPS */, ["installation"]))
            ])
          ])
        ])
      ]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}