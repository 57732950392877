import { PERMISSION } from '@/Configs/Constants';

import type { RouteRecord } from '../types';

const channelsRoutes: RouteRecord[] = [
  {
    path: '/admin/channels2/:channel',
    component: () => import(/* webpackChunkName: "Channels" */ '../../components/Channels/Index.vue'),
    meta: { permissionRequired: PERMISSION.SETTINGS__CHANNELS__MANAGE },
    children: [
      {
        path: '/admin/channels2/:channel/create',
        component: () => import(/* webpackChunkName: "Channels" */ '../../components/Channels/Edit.vue'),
        props: { action: 'create' },
        meta: { permissionRequired: PERMISSION.SETTINGS__CHANNELS__MANAGE },
      },
      {
        path: '/admin/channels2/:channel/create360dialog',
        component: () => import(/* webpackChunkName: "Channels" */ '../../components/Channels/Create360Dialog.vue'),
        meta: { permissionRequired: PERMISSION.SETTINGS__CHANNELS__MANAGE },
      },
      // When deprecating Channels?edit.vue
      // {
      //     path: "/admin/channels/email/:id",
      //     component: () => import(/* webpackChunkName: "Channels" */ "../../components/Channels/EmailSettings/index.vue"),
      //     props: { action: "edit", channelType: 'email' },
      // },
      {
        path: '/admin/channels2/:channel/:id',
        component: () => import(/* webpackChunkName: "Channels" */ '../../components/Channels/Edit.vue'),
        props: { action: 'edit' },
        meta: { permissionRequired: PERMISSION.SETTINGS__CHANNELS__MANAGE },
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "Channels" */ '../../components/AdminIndexBox.vue'),
        props: { boxName: 'channel' },
        meta: { permissionRequired: PERMISSION.SETTINGS__CHANNELS__MANAGE },
      },
    ],
  },
];

export default channelsRoutes;
