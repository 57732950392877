<template>
  <div data-test="template-header-file-input">
    <label for="fileInput" class="mb-2 text-base font-semibold text-grey-800">
      {{ headerLabel }}
    </label>
    <t-file-input
      :value="selectedFile"
      class="mb-2"
      :max-file-size="{
        size: maxFileSize,
        text: $t('whatsapp.max_file_size', { fileSize: convertBytesToMB(maxFileSize) }),
      }"
      :variant="selectedHeaderType"
      @update:value="handleFileUpdate($event)"
      @reset="handleFileReset"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { FILE_SIZE_LIMIT_IN_DECIMAL_BYTES, TEMPLATE_HEADER_TYPE } from '@/Configs/Constants/whatsapp';
import { convertBytesToMB } from '@/util/fileHelper';

import type { TemplateHeaderType } from '../types';

const { t } = useI18n();
const selectedFile = ref<File[]>([]);

const props = defineProps<{
  selectedHeaderType: TemplateHeaderType;
}>();

const emit = defineEmits<{
  updateFile: [files: File[]];
}>();

const handleFileUpdate = (files: File[]) => {
  selectedFile.value = files;
  emit('updateFile', selectedFile.value);
};

const handleFileReset = () => {
  selectedFile.value = [];
  emit('updateFile', selectedFile.value);
};

const headerLabel = computed(() => {
  return props.selectedHeaderType === TEMPLATE_HEADER_TYPE.VIDEO
    ? t('whatsapp.template_header_video_label')
    : t('whatsapp.template_header_img_label');
});

const maxFileSize = computed(() => {
  if (props.selectedHeaderType === TEMPLATE_HEADER_TYPE.VIDEO) {
    return FILE_SIZE_LIMIT_IN_DECIMAL_BYTES.GENERAL;
  }
  return FILE_SIZE_LIMIT_IN_DECIMAL_BYTES.IMAGE;
});

watch(
  () => props.selectedHeaderType,
  () => {
    selectedFile.value = [];
  },
);
</script>
