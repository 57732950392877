
<template>
  <div id="CreateTicketModal" ref="modal" class="modal animated fadeIn" role="dialog" style="animation-duration: 0.3s">
    <div
      class="modal-centered modal-dialog"
      style="height: 90vh; overflow-y: auto; transform: translate(-50%, -50%); margin: 0"
    >
      <composer
        v-if="open"
        :new-ticket="true"
        :default-channel="defaultChannel"
        composer-instance-id="create-ticket-composer"
        draft-key="new"
        :default-contact="defaultContact"
        path="/tickets/create"
        @popout="closeModal"
        @sent="onSent"
      ></composer>
    </div>
  </div>
</template>

<script lang="ts">
import eventBus from '@/eventBus';

import Composer from './ReplyForm/Composer.vue';
import { defineComponent } from 'vue';
import { useOutboundTranslationStore } from './ReplyForm/TranslationOption/useOutboundTranslationStore';
import { mapStores } from 'pinia';

export default defineComponent({
  name: 'CreateTicketModal',
  components: {
    Composer,
  },
  data: function () {
    return {
      open: false,
      defaultChannel: null,
      defaultContact: null,
    };
  },
  computed: {
    ...mapStores(useOutboundTranslationStore),
  },

  mounted: function () {
    eventBus.$on('modals.create-ticket.open', (data) => {
      $(this.$refs.modal).modal({});

      if (data) {
        if (data.contact) {
          this.defaultContact = {
            id: data.contact.id,
            text: data.contact.full_name,
            identifier: data.contact.identifier,
          };
        }

        if (data.channel) {
          this.defaultChannel = data.channel;

          this.outboundTranslationStore.setChannelSupportStatus(data.channel.id);
        }

        if (data.channelType) {
          this.defaultChannel = this.$root.channels.filter((c) => c.type === data.channelType)[0];
        }
      }
      this.open = true;
    });

    $(this.$refs.modal).on('hidden.bs.modal', () => {
      this.open = false;
    });
  },

  unmounted() {
    eventBus.$off('modals.create-ticket.open');
  },

  methods: {
    onSent(ticket_id) {
      router.push('/tickets/' + ticket_id);
      this.closeModal();
    },

    closeModal() {
      $(this.$refs.modal).modal('hide');
    },
  },
});
</script>
