<template>
  <span class="my-auto flex flex-shrink-0 flex-row flex-nowrap overflow-visible px-px" data-test="delivery-status">
    <t-tooltip
      v-if="status === WHATSAPP_DELIVERY_STATUS.FAILED && errorTooltipText && !isMobile"
      :text="errorTooltipText"
      :position="tooltipPosition"
      data-test="Delivery error tooltip"
    >
      <p class="pointer m-0 ml-1 text-red-dark" @click="navigateToExternalResource">
        {{ $tc('tickets.failed_to_deliver') }}
      </p>
    </t-tooltip>
    <p
      v-else-if="status === WHATSAPP_DELIVERY_STATUS.FAILED && errorTooltipText"
      class="pointer m-0 ml-1 text-red-dark"
    >
      {{ $tc('tickets.failed_to_deliver') }}
    </p>
    <check-linear v-else-if="status === WHATSAPP_DELIVERY_STATUS.SENT" class="ml-1 text-black" />
    <double-check-linear v-else size="1.25rem" :class="{ 'text-leaf-500': status === WHATSAPP_DELIVERY_STATUS.READ }" />
  </span>
</template>

<script setup lang="ts">
import { CheckLinear, DoubleCheckLinear } from '@trengo/trengo-icons';
import { computed, inject, ref } from 'vue';

import { ERROR_CODES_URL, WHATSAPP_DELIVERY_STATUS } from '@/Configs/Constants/whatsapp';
import { cleanString } from '@/util/formatHelper';

import type { DeliveryInfo, DeliveryStatus } from '@/types/message';

const props = defineProps<{
  deliveryInformation: DeliveryInfo | null;
}>();

const isMobile = ref(inject<boolean>('isMobile', false));
const ticketContainerWidth = ref(inject<number>('ticketContainerWidth', 0));

const tooltipWidthThreshold = 682;
const tooltipPosition = computed(() => (ticketContainerWidth.value > tooltipWidthThreshold ? 'left' : 'top'));

const status = computed<DeliveryStatus>(() => props.deliveryInformation?.status);

const deliveryDetails = computed(() => {
  const { details } = props.deliveryInformation;
  const errorCode = details?.[0]?.code || '';
  const externalResource = details?.[0]?.href || ERROR_CODES_URL;

  return { errorCode, externalResource };
});

const errorTooltipText = computed<null | string>(() => {
  if (status.value !== WHATSAPP_DELIVERY_STATUS.FAILED) return null;
  if (!props.deliveryInformation?.description) return 'No additional information available';

  const errorCode = deliveryDetails?.value?.errorCode;
  return cleanString(`Error${errorCode ? ` ${errorCode}` : ''}: ${props.deliveryInformation?.description}`);
});

const navigateToExternalResource = function () {
  window.open(deliveryDetails?.value?.externalResource, '_blank');
};
</script>
