<template>
  <t-button
    v-if="!suggestedReplyStore.isHidden"
    id="suggest-reply-cta"
    :disabled="suggestedReplyStore.isLoading"
    type="button"
    btn-style="secondary"
    class="flex items-center"
    data-test="suggest-reply-cta"
    size="sm"
    @click="handleClick"
  >
    <t-spinner v-if="suggestedReplyStore.isLoading" class="me-1" size="1rem" />
    <TaskAutomationFill v-else class="me-1 size-5" />
    <span class="text-nowrap">
      {{ suggestedReplyStore.isLoading ? $t('tickets.suggest_reply_loading') : $t('tickets.suggest_reply_cta') }}
    </span>
  </t-button>

  <div v-if="suggestedReplyStore.isError" class="flex flex-nowrap items-center gap-1 text-red-dark">
    <AlertTriangleFill class="me-1 size-5" />
    <span>{{ $t('tickets.suggest_reply_error') }}</span>
  </div>

  <SuggestedReplyModal />
</template>

<script setup lang="ts">
import { AlertTriangleFill, TaskAutomationFill } from '@trengo/trengo-icons';

import SuggestedReplyModal from './SuggestedReplyModal.vue';
import { useSuggestedReplyStore } from './useSuggestedReplyStore';

const suggestedReplyStore = useSuggestedReplyStore();

const props = defineProps<{
  ticketId: number;
}>();

suggestedReplyStore.activeTicketId = props.ticketId;

const handleClick = () => {
  if (suggestedReplyStore.isFeatureEnabled && !suggestedReplyStore.isActive) {
    suggestedReplyStore.modal.toggleModal();
    return;
  }
  suggestedReplyStore.updateReply(props.ticketId);
};
</script>
