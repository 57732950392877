<template>
  <div
    id="voip-panel"
    class="flex flex-col absolute w-92 h-124 left-[4.75rem] bottom-4 bg-black text-white rounded-2xl"
    @click.stop
  >
    <div class="p-2 flex items-center">
      <div
        class="mx-auto bg-white gap-x-1.5 rounded-lg text-center text-black flex items-center justify-between p-1.5 w-fit"
      >
        <button
          :class="{ 'text-black': activeTab === 'dialpad' }"
          class="text-grey-400 flex items-center transition-colors hover:text-grey-600"
          @click.prevent="activeTab = 'dialpad'"
        >
          <i class="material-icons">dialpad</i>
        </button>

        <button
          :class="{ 'text-black': activeTab === 'call' }"
          class="text-grey-400 flex items-center transition-colors hover:text-grey-600"
          @click.prevent="activeTab = 'call'"
        >
          <i class="material-icons">call</i>
        </button>

        <button
          :class="{ 'text-black': activeTab === 'history' }"
          class="text-grey-400 flex items-center transition-colors hover:text-grey-600"
          @click.prevent="activeTab = 'history'"
        >
          <i class="material-icons">history</i>
        </button>

        <button
          :class="{ 'text-black': activeTab === 'settings' }"
          class="text-grey-400 flex items-center transition-colors hover:text-grey-600"
          @click.prevent="activeTab = 'settings'"
        >
          <i class="material-icons">settings</i>
        </button>
      </div>
    </div>

    <div v-show="activeTab === 'dialpad'" class="flex flex-col h-full">
      <channel-selector class="flex-none" />
      <outbound-number-input v-model:number="outboundNumber" v-model:country="selectedCountry" />

      <dial-pad v-model:number="outboundNumber" />
    </div>

    <call-history v-show="activeTab === 'history'" />

    <!-- Making this v-if on purpose to prevent perm check from causing false errors -->
    <voip-settings v-if="activeTab === 'settings'" />

    <call-tab v-show="activeTab === 'call'" :is-tab-active="activeTab === 'call'" />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

import CallHistory from '@/components/Voip/components/Panel/CallHistory.vue';
import CallTab from '@/components/Voip/components/Panel/CallTab.vue';
import DialPad from '@/components/Voip/components/Panel/DialPad.vue';
import OutboundNumberInput from '@/components/Voip/components/Panel/OutboundNumberInput.vue';
import VoipSettings from '@/components/Voip/components/Panel/VoipSettings.vue';
import ChannelSelector from '@/components/Voip/Panel/ChannelSelector.vue';
import { type Country } from '@/Configs/Constants/countryCodes';

const activeTab = ref<'call' | 'dialpad' | 'history' | 'settings'>('dialpad');

const outboundNumber = ref('');
const selectedCountry = ref<Country>({
  name: 'Netherlands',
  code: 'NL',
  callingCode: '31',
  flag: '🇳🇱',
});
</script>
