const MAIN_LOCALE_CODE = {
  NL_NL: 'nl-NL', // Dutch
  EN_GB: 'en-GB', // English (UK)
  FR_FR: 'fr-FR', // French (France)
  DE_DE: 'de-DE', // German
  ES_ES: 'es-ES', // Spanish (Spain)
  IT_IT: 'it-IT', // Italian
};

export const LOCALE_CODE = {
  ...{ ...MAIN_LOCALE_CODE },
  AF_ZA: 'af-ZA', // Afrikaans
  AM_ET: 'am-ET', // Amharic
  AR_SA: 'ar-SA', // Arabic
  AS_IN: 'as-IN', // Assamese
  AZ_AZ: 'az-AZ', // Azerbaijani
  BE_BY: 'be-BY', // Belarusian
  BG_BG: 'bg-BG', // Bulgarian
  BN_BD: 'bn-BD', // Bengali
  BS_BA: 'bs-BA', // Bosnian
  CA_ES: 'ca-ES', // Catalan
  CKB_IQ: 'ckb-IQ', // Kurdish (Sorani)
  CS_CZ: 'cs-CZ', // Czech
  CY_GB: 'cy-GB', // Welsh
  DA_DK: 'da-DK', // Danish
  EL_GR: 'el-GR', // Greek
  EN_US: 'en-US', // English (US)
  ES_MX: 'es-MX', // Spanish (Mexico)
  ET_EE: 'et-EE', // Estonian
  EU_ES: 'eu-ES', // Basque
  FA_IR: 'fa-IR', // Persian
  FI_FI: 'fi-FI', // Finnish
  FIL_PH: 'fil-PH', // Filipino
  FR_CA: 'fr-CA', // French (Canada)
  GA_IE: 'ga-IE', // Irish
  GD_GB: 'gd-GB', // Scots Gaelic
  GL_ES: 'gl-ES', // Galician
  GU_IN: 'gu-IN', // Gujarati
  HA_NG: 'ha-NG', // Hausa
  HE_IL: 'he-IL', // Hebrew
  HI_IN: 'hi-IN', // Hindi
  HR_HR: 'hr-HR', // Croatian
  HT_HT: 'ht-HT', // Haitian Creole
  HU_HU: 'hu-HU', // Hungarian
  HY_AM: 'hy-AM', // Armenian
  ID_ID: 'id-ID', // Indonesian
  IG_NG: 'ig-NG', // Igbo
  IS_IS: 'is-IS', // Icelandic
  JA_JP: 'ja-JP', // Japanese
  JV_ID: 'jv-ID', // Javanese
  KA_GE: 'ka-GE', // Georgian
  KK_KZ: 'kk-KZ', // Kazakh
  KM_KH: 'km-KH', // Khmer
  KN_IN: 'kn-IN', // Kannada
  KO_KR: 'ko-KR', // Korean
  KU_TR: 'ku-TR', // Kurdish (Kurmanji)
  KY_KG: 'ky-KG', // Kyrgyz
  LB_LU: 'lb-LU', // Luxembourgish
  LO_LA: 'lo-LA', // Lao
  LT_LT: 'lt-LT', // Lithuanian
  LV_LV: 'lv-LV', // Latvian
  MG_MG: 'mg-MG', // Malagasy
  MI_NZ: 'mi-NZ', // Maori
  MK_MK: 'mk-MK', // Macedonian
  ML_IN: 'ml-IN', // Malayalam
  MN_MN: 'mn-MN', // Mongolian
  MR_IN: 'mr-IN', // Marathi
  MS_MY: 'ms-MY', // Malay
  MT_MT: 'mt-MT', // Maltese
  MY_MM: 'my-MM', // Burmese
  NE_NP: 'ne-NP', // Nepali
  NO_NO: 'no-NO', // Norwegian
  OR_IN: 'or-IN', // Odia (Oriya)
  PA_IN: 'pa-IN', // Punjabi
  PL_PL: 'pl-PL', // Polish
  PS_AF: 'ps-AF', // Pashto
  PT_BR: 'pt-BR', // Portuguese (Brazil)
  PT_PT: 'pt-PT', // Portuguese (Portugal)
  RW_RW: 'rw-RW', // Kinyarwanda
  SD_PK: 'sd-PK', // Sindhi
  SI_LK: 'si-LK', // Sinhala
  SK_SK: 'sk-SK', // Slovak
  SL_SI: 'sl-SI', // Slovenian
  SM_WS: 'sm-WS', // Samoan
  SN_ZW: 'sn-ZW', // Shona
  SO_SO: 'so-SO', // Somali
  SQ_AL: 'sq-AL', // Albanian
  SR_RS: 'sr-RS', // Serbian
  ST_ZA: 'st-ZA', // Sesotho
  SU_ID: 'su-ID', // Sundanese
  SV_SE: 'sv-SE', // Swedish
  SW_KE: 'sw-KE', // Swahili
  TA_IN: 'ta-IN', // Tamil
  TE_IN: 'te-IN', // Telugu
  TG_TJ: 'tg-TJ', // Tajik
  TH_TH: 'th-TH', // Thai
  TK_TM: 'tk-TM', // Turkmen
  TR_TR: 'tr-TR', // Turkish
  TT_RU: 'tt-RU', // Tatar
  UK_UA: 'uk-UA', // Ukrainian
  UR_PK: 'ur-PK', // Urdu
  UZ_UZ: 'uz-UZ', // Uzbek
  VI_VN: 'vi-VN', // Vietnamese
  XH_ZA: 'xh-ZA', // Xhosa
  YI_US: 'yi-US', // Yiddish
  YO_NG: 'yo-NG', // Yoruba
  ZH_CN: 'zh-CN', // Chinese (Simplified)
  ZH_TW: 'zh-TW', // Chinese (Traditional)
  ZU_ZA: 'zu-ZA', // Zulu
} as const;

const LOCALE_LABEL = {
  [LOCALE_CODE.NL_NL]: 'Nederlands',
  [LOCALE_CODE.EN_GB]: 'English (UK)',
  [LOCALE_CODE.FR_FR]: 'Français',
  [LOCALE_CODE.DE_DE]: 'Deutsch',
  [LOCALE_CODE.ES_ES]: 'Español (España)',
  [LOCALE_CODE.IT_IT]: 'Italiano',
  [LOCALE_CODE.AF_ZA]: 'Afrikaans',
  [LOCALE_CODE.AM_ET]: 'አማርኛ',
  [LOCALE_CODE.AR_SA]: 'العربية',
  [LOCALE_CODE.AS_IN]: 'অসমীয়া',
  [LOCALE_CODE.AZ_AZ]: 'Azərbaycanca',
  [LOCALE_CODE.BE_BY]: 'Беларуская',
  [LOCALE_CODE.BG_BG]: 'Български',
  [LOCALE_CODE.BN_BD]: 'বাংলা',
  [LOCALE_CODE.BS_BA]: 'Bosanski',
  [LOCALE_CODE.CA_ES]: 'Català',
  [LOCALE_CODE.CKB_IQ]: 'کوردی (سۆرانی)',
  [LOCALE_CODE.CS_CZ]: 'Čeština',
  [LOCALE_CODE.CY_GB]: 'Cymraeg',
  [LOCALE_CODE.DA_DK]: 'Dansk',
  [LOCALE_CODE.EL_GR]: 'Ελληνικά',
  [LOCALE_CODE.EN_US]: 'English (US)',
  [LOCALE_CODE.ES_MX]: 'Español (México)',
  [LOCALE_CODE.ET_EE]: 'Eesti',
  [LOCALE_CODE.EU_ES]: 'Euskara',
  [LOCALE_CODE.FA_IR]: 'فارسی',
  [LOCALE_CODE.FI_FI]: 'Suomi',
  [LOCALE_CODE.FIL_PH]: 'Filipino',
  [LOCALE_CODE.FR_CA]: 'Français (Canada)',
  [LOCALE_CODE.GA_IE]: 'Gaeilge',
  [LOCALE_CODE.GD_GB]: 'Gàidhlig',
  [LOCALE_CODE.GL_ES]: 'Galego',
  [LOCALE_CODE.GU_IN]: 'ગુજરાતી',
  [LOCALE_CODE.HA_NG]: 'Hausa',
  [LOCALE_CODE.HE_IL]: 'עברית',
  [LOCALE_CODE.HI_IN]: 'हिन्दी',
  [LOCALE_CODE.HR_HR]: 'Hrvatski',
  [LOCALE_CODE.HT_HT]: 'Kreyòl ayisyen',
  [LOCALE_CODE.HU_HU]: 'Magyar',
  [LOCALE_CODE.HY_AM]: 'Հայերեն',
  [LOCALE_CODE.ID_ID]: 'Bahasa Indonesia',
  [LOCALE_CODE.IG_NG]: 'Igbo',
  [LOCALE_CODE.IS_IS]: 'Íslenska',
  [LOCALE_CODE.JA_JP]: '日本語',
  [LOCALE_CODE.JV_ID]: 'Basa Jawa',
  [LOCALE_CODE.KA_GE]: 'ქართული',
  [LOCALE_CODE.KK_KZ]: 'Қазақ тілі',
  [LOCALE_CODE.KM_KH]: 'ភាសាខ្មែរ',
  [LOCALE_CODE.KN_IN]: 'ಕನ್ನಡ',
  [LOCALE_CODE.KO_KR]: '한국어',
  [LOCALE_CODE.KU_TR]: 'Kurdî (Kurmanji)',
  [LOCALE_CODE.KY_KG]: 'Кыргызча',
  [LOCALE_CODE.LB_LU]: 'Lëtzebuergesch',
  [LOCALE_CODE.LO_LA]: 'ລາວ',
  [LOCALE_CODE.LT_LT]: 'Lietuvių',
  [LOCALE_CODE.LV_LV]: 'Latviešu',
  [LOCALE_CODE.MG_MG]: 'Malagasy',
  [LOCALE_CODE.MI_NZ]: 'Te Reo Māori',
  [LOCALE_CODE.MK_MK]: 'Македонски',
  [LOCALE_CODE.ML_IN]: 'മലയാളം',
  [LOCALE_CODE.MN_MN]: 'Монгол',
  [LOCALE_CODE.MR_IN]: 'मराठी',
  [LOCALE_CODE.MS_MY]: 'Bahasa Melayu',
  [LOCALE_CODE.MT_MT]: 'Malti',
  [LOCALE_CODE.MY_MM]: 'မြန်မာဘာသာ',
  [LOCALE_CODE.NE_NP]: 'नेपाली',
  [LOCALE_CODE.NO_NO]: 'Norsk',
  [LOCALE_CODE.OR_IN]: 'ଓଡ଼ିଆ',
  [LOCALE_CODE.PA_IN]: 'ਪੰਜਾਬੀ',
  [LOCALE_CODE.PL_PL]: 'Polski',
  [LOCALE_CODE.PS_AF]: 'پښتو',
  [LOCALE_CODE.PT_BR]: 'Português (Brasil)',
  [LOCALE_CODE.PT_PT]: 'Português (Portugal)',
  [LOCALE_CODE.RW_RW]: 'Ikinyarwanda',
  [LOCALE_CODE.SD_PK]: 'سنڌي',
  [LOCALE_CODE.SI_LK]: 'සිංහල',
  [LOCALE_CODE.SK_SK]: 'Slovenčina',
  [LOCALE_CODE.SL_SI]: 'Slovenščina',
  [LOCALE_CODE.SM_WS]: 'Gagana Samoa',
  [LOCALE_CODE.SN_ZW]: 'ChiShona',
  [LOCALE_CODE.SO_SO]: 'Soomaali',
  [LOCALE_CODE.SQ_AL]: 'Shqip',
  [LOCALE_CODE.SR_RS]: 'Српски',
  [LOCALE_CODE.ST_ZA]: 'Sesotho',
  [LOCALE_CODE.SU_ID]: 'Basa Sunda',
  [LOCALE_CODE.SV_SE]: 'Svenska',
  [LOCALE_CODE.SW_KE]: 'Kiswahili',
  [LOCALE_CODE.TA_IN]: 'தமிழ்',
  [LOCALE_CODE.TE_IN]: 'తెలుగు',
  [LOCALE_CODE.TG_TJ]: 'Тоҷикӣ',
  [LOCALE_CODE.TH_TH]: 'ไทย',
  [LOCALE_CODE.TK_TM]: 'Türkmen',
  [LOCALE_CODE.TR_TR]: 'Türkçe',
  [LOCALE_CODE.TT_RU]: 'Татарча',
  [LOCALE_CODE.UK_UA]: 'Українська',
  [LOCALE_CODE.UR_PK]: 'اردو',
  [LOCALE_CODE.UZ_UZ]: 'Oʻzbekcha',
  [LOCALE_CODE.VI_VN]: 'Tiếng Việt',
  [LOCALE_CODE.XH_ZA]: 'isiXhosa',
  [LOCALE_CODE.YI_US]: 'ייִדיש',
  [LOCALE_CODE.YO_NG]: 'Yorùbá',
  [LOCALE_CODE.ZH_CN]: '简体中文',
  [LOCALE_CODE.ZH_TW]: '繁體中文',
  [LOCALE_CODE.ZU_ZA]: 'isiZulu',
} as const;

export const LOCALE_LABEL_ARRAY = Object.entries(LOCALE_LABEL);
