import { Device, type Call } from '@twilio/voice-sdk';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { defineStore } from 'pinia';
import { type Channel } from 'pusher-js';
import { ref, toRaw } from 'vue';

import { fetchVoipToken } from '@/api/modules/voip';
import {
  type AcceptedExternalPhoneData,
  type ClientAcceptedCallData,
  type CallEndedData,
  type CallTransferredData,
  type CallStartedData,
  type ConferenceStartedData,
  type ConferenceThirdPartyData,
  type InboundCallData,
  type InternalCallData,
  type OutboundMobileCallData,
  type TeamInternalCallData,
  PUSHER_VOIP_EVENTS,
  TWILIO_DEVICE_EVENTS,
} from '@/components/Voip/constants/events';
import {
  onAcceptedExternalPhone,
  onCallEnded,
  onCallTransferred,
  onClientAcceptedCall,
  onConferenceStarted,
  onConferenceThirdPartyJoined,
  onConferenceThirdPartyLeft,
  onInboundCall,
  onInternalCall,
  onInternalCallCancelled,
  onInternalCallRejected,
  onOutboundMobileCallStarted,
  onTeamInternalCall,
} from '@/components/Voip/VoipActions';
import { getAudioDevices } from '@/util/voip';

/**
 * For a clearer idea of how Twilio handles calls, you can read the following documentation:
 * https://www.twilio.com/docs/voice/twiml#twilios-request-to-your-application
 *
 * For reference on the below CallParams, please checkout the Backend Monolith's ConnectController:
 * https://github.com/weerdm/trengo/blob/e0faaa6f8384cc1846a7873143fe5b8c2b745088/app/Http/Api/Voip/Controllers/ConnectController.php
 *
 * The basics of how this works is that via the Device.connect method we use Twilio's custom markup
 * language format (TwiML) to deliver to *our* backend the params it needs in order to properly
 * establish a VOIP call. So the request goes from our frontend, to one of the setup Twilio devices,
 * to Twilio's servers which will then make a callback to our backend with the provided params.
 *
 *
 */
type CallParams = {
  PhoneNumber: string;
  ChannelPrefix: string;
  ChannelId: string;
  UserId: string;
  type: 'OUTBOUND' | 'INBOUND' | 'INTERN' | 'WIDGET';
  token: string;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type CallChannel = {
  id: string;
  prefix: string;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type MobileConnectionParams = {
  token: string;
  phone_number: string;
  channel_id: string;
};

const useVoipStore = defineStore('voip', () => {
  const device = ref<Device | null>(null);
  const call = ref<Call | null>(null);
  const pusherChannel = ref<Channel | null>(null);

  /**
   * The way that the call log array works in the old component (CallLog.vue) is that the component
   * listened for the `eventBus` `voip.call_started` event to fire. The component would then push the
   * payload (which would be either `status === 'QUEUED' | 'IN_PROGRESS'`) into the `callLog` `data()`
   * object.
   *
   * We'll be recreating this functionality in the events below and populating this array.
   */
  const callLog = ref<CallStartedData[]>([]);

  /**
   * Device token needs to be saved in order to forward calls as the forwardee device has to have
   * the same token as the original token that first receives the call. Might potentially mean that
   * we have to keep track of multiple device instances? Unclear for now if this is the case.
   */
  const deviceToken = ref<string | null>(null);

  const outputAudioDevices = ref<MediaDeviceInfo[]>([]);
  const inputAudioDevices = ref<MediaDeviceInfo[]>([]);

  const isVoipReady = ref(false);

  async function initializeVoipStore(channelPrefix: string, userId: number) {
    initializePusherEvents(channelPrefix, userId);
    initializeDevice();

    isVoipReady.value = true;
  }

  async function setVoipAudioDevices() {
    const { inputDevices, outputDevices } = await getAudioDevices();

    inputAudioDevices.value = inputDevices;
    outputAudioDevices.value = outputDevices;
  }

  async function getDeviceToken(): Promise<string> {
    try {
      const tokenResponse = await fetchVoipToken();

      if (!tokenResponse?.data?.token) {
        throw new Error('Invalid token response');
      }

      deviceToken.value = tokenResponse.data.token;
      return tokenResponse.data.token;
    } catch (error) {
      console.error('Failed when fetching device token:', error);
      throw error;
    }
  }

  async function initializeDevice() {
    const token = await getDeviceToken();

    // https://twilio.com/docs/voice/sdks/javascript/twiliodevice#deviceoptions
    device.value = new Device(token, {
      logLevel: window.APP_ENV === 'production' ? 5 : 1, // Silent on prod, debug otherwise
      closeProtection: true,
      appName: window.APP_ENV === 'production' ? 'prod-frontend' : 'stg-frontend',
      appVersion: __BUILD_INFORMATION__?.hash ?? '',
      edge: ['frankfurt', 'dublin', 'roaming'],
      enableImprovedSignalingErrorPrecision: true,
    });

    initializeTwilioDeviceEvents();
    await device.value.register();
  }

  // https://www.twilio.com/docs/voice/sdks/javascript/twiliodevice#events
  function initializeTwilioDeviceEvents() {
    if (!device.value) {
      throw new Error('Device was not initialized while setting up Twilio Device events.');
    }

    device.value.on(TWILIO_DEVICE_EVENTS.DESTROYED, () => {});

    device.value.on(TWILIO_DEVICE_EVENTS.ERROR, () => {});

    device.value.on(TWILIO_DEVICE_EVENTS.INCOMING, () => {});

    device.value.on(TWILIO_DEVICE_EVENTS.REGISTERED, () => {});

    device.value.on(TWILIO_DEVICE_EVENTS.REGISTERING, () => {});

    device.value.on(TWILIO_DEVICE_EVENTS.TOKEN_WILL_EXPIRE, async () => {
      if (device.value) {
        const token = await getDeviceToken();
        device.value.updateToken(token);
      }
    });

    device.value.on(TWILIO_DEVICE_EVENTS.UNREGISTERED, () => {});
  }

  async function startCall(
    channelId: string,
    channelPrefix: string,
    userId: string | number,
    phoneNumber: string,
    countryCode: string,
  ) {
    const phoneUtil = PhoneNumberUtil.getInstance();
    const parsedNumber = phoneUtil.parse(phoneNumber, countryCode);
    const formattedNumber = phoneUtil.format(parsedNumber, PhoneNumberFormat.INTERNATIONAL);

    if (device.value) {
      const callParams = {
        ChannelId: channelId,
        ChannelPrefix: channelPrefix,
        PhoneNumber: formattedNumber,
        UserId: userId.toString(),
        token: crypto.randomUUID(),
        type: 'OUTBOUND',
      } satisfies CallParams;

      /**
       * https://github.com/twilio/twilio-voice.js/issues/278
       * For some reason, the `device` being a ref breaks the Twilio SDK, thus we unwrap the Proxy
       */
      call.value = await toRaw(device.value).connect({
        params: callParams,
        rtcConstraints: { audio: true, video: false },
      });
    }
  }

  function initializePusherEvents(channelPrefix: string, userId: number) {
    if (!pusherChannel.value) {
      if (!window.PusherInstance) {
        throw new Error("Pusher Instance wasn't available when setting up VOIP store");
      } else {
        pusherChannel.value = window.PusherInstance.subscribe(`private-voip-${channelPrefix}`);
      }
    }

    pusherChannel.value!.bind(PUSHER_VOIP_EVENTS.CALL_ACCEPTED_EXTERNAL_PHONE, (data: AcceptedExternalPhoneData) => {
      onAcceptedExternalPhone(data);
    });

    pusherChannel.value!.bind(PUSHER_VOIP_EVENTS.CALL_ENDED, (data: CallEndedData) => {
      onCallEnded(data);
    });

    pusherChannel.value!.bind(PUSHER_VOIP_EVENTS.CALL_TRANSFERRED, (data: CallTransferredData) => {
      onCallTransferred(data);
    });

    pusherChannel.value!.bind(PUSHER_VOIP_EVENTS.CLIENT_ACCEPTED_CALL, (data: ClientAcceptedCallData) => {
      onClientAcceptedCall(data);
    });

    pusherChannel.value!.bind(PUSHER_VOIP_EVENTS.CONFERENCE_STARTED, (data: ConferenceStartedData) => {
      onConferenceStarted(data);
    });

    pusherChannel.value!.bind(PUSHER_VOIP_EVENTS.CONFERENCE_THIRD_PARTY_JOINED, (data: ConferenceThirdPartyData) => {
      onConferenceThirdPartyJoined(data);
    });

    pusherChannel.value!.bind(PUSHER_VOIP_EVENTS.CONFERENCE_THIRD_PARTY_LEFT, (data: ConferenceThirdPartyData) => {
      onConferenceThirdPartyLeft(data);
    });

    pusherChannel.value!.bind(PUSHER_VOIP_EVENTS.INBOUND_CALL, (data: InboundCallData) => {
      onInboundCall(data);
    });

    pusherChannel.value!.bind(PUSHER_VOIP_EVENTS.INTERNAL_CALL(userId), (data: InternalCallData) => {
      onInternalCall(data);
    });

    pusherChannel.value!.bind(PUSHER_VOIP_EVENTS.INTERNAL_CALL_CANCELLED(userId), (data: InternalCallData) => {
      onInternalCallCancelled(data);
    });

    pusherChannel.value!.bind(PUSHER_VOIP_EVENTS.INTERNAL_CALL_REJECTED(userId), (data: InternalCallData) => {
      onInternalCallRejected(data);
    });

    pusherChannel.value!.bind(PUSHER_VOIP_EVENTS.OUTBOUND_MOBILE_CALL_STARTED, (data: OutboundMobileCallData) => {
      onOutboundMobileCallStarted(data);
    });

    pusherChannel.value!.bind(PUSHER_VOIP_EVENTS.TEAM_INTERNAL_CALL, (data: TeamInternalCallData) => {
      onTeamInternalCall(data);
    });
  }

  return {
    device,
    call,
    isVoipReady,
    callLog,
    outputAudioDevices,
    inputAudioDevices,

    initializeVoipStore,
    setVoipAudioDevices,
    startCall,
  };
});

export default useVoipStore;
