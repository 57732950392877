import { PERMISSION } from '@/Configs/Constants';

import type { RouteRecord } from '../types';

const ivrRoutes: RouteRecord[] = [
  {
    path: '/admin/ivr',
    component: () => import(/* webpackChunkName: "Ivr" */ '../../components/Ivr/Index.vue'),
    meta: { permissionRequired: PERMISSION.SETTINGS__CHANNELS__MANAGE },
    children: [
      {
        path: '/admin/ivr/create',
        component: () => import(/* webpackChunkName: "Ivr" */ '../../components/Ivr/Edit.vue'),
        props: { action: 'create' },
        meta: { permissionRequired: PERMISSION.SETTINGS__CHANNELS__MANAGE },
      },
      {
        path: '/admin/ivr/:id',
        component: () => import(/* webpackChunkName: "Ivr" */ '../../components/Ivr/Edit.vue'),
        props: { action: 'edit' },
        meta: { permissionRequired: PERMISSION.SETTINGS__CHANNELS__MANAGE },
      },
    ],
  },
];

export default ivrRoutes;
