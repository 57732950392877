import { lowerCase } from 'lodash';

import { CUSTOM_FIELD, PERMISSION } from '@/Configs/Constants';

import type { RouteRecord } from '../types';

const { CONTACT, TICKET, PROFILE, USER } = CUSTOM_FIELD;

const customFieldsRoutes: RouteRecord[] = [
  {
    path: '/admin/custom_fields',
    component: () => import(/* webpackChunkName: "CustomFields" */ '../../components/CustomFields'),
    meta: { permissionRequired: PERMISSION.SETTINGS__CUSTOM_FIELDS__MANAGE },
    children: [
      {
        path: '/admin/custom_fields',
        component: () => import(/* webpackChunkName: "CustomFields" */ '@/components/CustomFields/CustomFieldsTable'),
        meta: { activePage: lowerCase(TICKET), permissionRequired: PERMISSION.SETTINGS__CUSTOM_FIELDS__MANAGE },
      },
      {
        path: lowerCase(CONTACT) + 's',
        component: () => import(/* webpackChunkName: "CustomFields" */ '@/components/CustomFields/CustomFieldsTable'),
        meta: { activePage: lowerCase(CONTACT), permissionRequired: PERMISSION.SETTINGS__CUSTOM_FIELDS__MANAGE },
      },
      {
        path: lowerCase(PROFILE) + 's',
        component: () => import(/* webpackChunkName: "CustomFields" */ '@/components/CustomFields/CustomFieldsTable'),
        meta: { activePage: lowerCase(PROFILE), permissionRequired: PERMISSION.SETTINGS__CUSTOM_FIELDS__MANAGE },
      },
      {
        path: lowerCase(USER) + 's',
        component: () => import(/* webpackChunkName: "CustomFields" */ '@/components/CustomFields/CustomFieldsTable'),
        meta: { activePage: lowerCase(USER), permissionRequired: PERMISSION.SETTINGS__CUSTOM_FIELDS__MANAGE },
      },
      {
        path: '/admin/custom_fields/create/:type',
        name: 'CustomFieldsCreate',
        component: () => import(/* webpackChunkName: "CustomFields" */ '@/components/CustomFields/CustomFieldsEdit'),
        props: (route) => ({ action: 'create', type: route.params.type }),
        meta: { permissionRequired: PERMISSION.SETTINGS__CUSTOM_FIELDS__MANAGE },
      },
      {
        path: '/admin/custom_fields/:id',
        component: () => import(/* webpackChunkName: "CustomFields" */ '@/components/CustomFields/CustomFieldsEdit'),
        props: { action: 'edit' },
        meta: { permissionRequired: PERMISSION.SETTINGS__CUSTOM_FIELDS__MANAGE },
      },
    ],
  },
];

export default customFieldsRoutes;
