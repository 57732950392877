<template>
  <div class="UI_DatePicker flex text-base" :class="{ fullWidth }">
    <div v-if="ranges" class="border-r border-grey-300">
      <div class="cursor-pointer">
        <atomic-dropdown
          max-height="500px"
          :options="getQuickSelectOptions()"
          :search="false"
          @selected="onQuickOptionSelect"
        >
          <template #toggle>
            <div class="flex items-center" style="padding: 6px 12px">
              {{ getSelectedQuickOption() }}
              <i class="far fa-chevron-down pl-2 text-sm text-grey-600"></i>
            </div>
          </template>
          <template #option="prop">
            <div class="flex items-center">
              {{ prop.option.text }}
              <span v-if="prop.option.range" class="ml-auto text-sm text-grey-600">
                {{ displayRange(prop.option.range[0], prop.option.range[1]) }}
              </span>
            </div>
          </template>
        </atomic-dropdown>
      </div>
    </div>
    <div class="picker-main cursor-pointer" :class="{ fullWidth }">
      <date-range-picker
        ref="picker"
        v-model="date"
        :date-range="date"
        :single-date-picker="mode"
        :show-dropdowns="false"
        :ranges="false"
        :auto-apply="true"
        :control-container-class="''"
        :min-date="minDate"
        :locale-data="{
          daysOfWeek: getDaysOfWeek(),
          monthNames: getMonthNames(),
        }"
        :opens="opens"
        @select="emitValue"
        @toggle="onDatePickerToggle"
      >
        <template #input>
          <div style="padding: 6px 12px" class="picker" :class="{ fullWidth }">
            <i v-if="!$slots.prependIcon" ref="open" class="far fa-calendar-alt mr-1"></i>
            <slot name="prependIcon"></slot>
            <span :class="{ placeholder: placeholder && !date.startDate }">
              {{ placeholder && !date.startDate ? placeholder : getDisplayDate() }}
            </span>
          </div>
        </template>
      </date-range-picker>
    </div>
  </div>
</template>
<script lang="ts">
import DateRangePicker from 'vue3-daterange-picker';
import 'vue3-daterange-picker/src/assets/daterangepicker.scss';
export default {
  name: 'DatePicker',
  components: {
    DateRangePicker,
  },
  props: {
    mode: {
      type: String,
      default: 'single',
    },
    modelValue: {
      type: [Object, Number, String],
      default: () => {
        return {
          start: null,
          end: null,
        };
      },
    },
    format: {
      type: String,
      default: 'YYYY-MM-DD',
    },
    asUnix: {
      type: Boolean,
      default: true,
    },
    ranges: {
      type: Boolean,
      default: true,
    },
    formatDisplay: {
      type: String,
      default: 'MMM DD',
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    minDate: {
      type: String,
      default: '',
    },
    // which way the date picker opens, left, right, center or inline
    opens: {
      type: String,
      default: 'left',
    },
  },
  emits: ['update:modelValue', 'focus', 'blur'],
  data() {
    return {
      selection: [],
      open: false,
      top: 0,
      left: 0,
      query: '',
      active: null,
      selectedIndex: 0,
      date: {
        startDate: Date.now(),
        endDate: Date.now(),
      },
      isOpen: false,
    };
  },
  watch: {
    modelValue() {
      this.setValue();
    },
  },
  mounted() {
    const localeCode = this.$root.user.locale_code;
    moment().locale(localeCode);
    this.setValue();
  },
  methods: {
    getQuickSelectOptions() {
      return [
        {
          id: 0,
          text: this.$t('reports.daterange_option_today'),
          range: [moment(), moment()],
        },
        {
          id: 1,
          text: this.$t('reports.daterange_option_yesterday'),
          range: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        },
        {
          id: 2,
          text: this.$t('reports.daterange_option_last_7_days'),
          range: [moment().subtract(6, 'days'), moment()],
        },
        {
          id: 3,
          text: this.$t('reports.daterange_option_last_14_days'),
          range: [moment().subtract(13, 'days'), moment()],
        },
        {
          id: 4,
          text: this.$t('reports.daterange_option_last_30_days'),
          range: [moment().subtract(29, 'days'), moment()],
        },
        {
          id: 5,
          text: this.$t('reports.daterange_option_this_month'),
          range: [moment().startOf('month'), moment().endOf('month')],
        },
        {
          id: 6,
          text: this.$t('reports.daterange_option_last_month'),
          range: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
        },
        {
          id: 7,
          text: this.$t('reports.daterange_option_this_year'),
          range: [moment().startOf('year'), moment().endOf('year')],
        },
        {
          id: 8,
          text: this.$t('reports.daterange_option_last_year'),
          range: [moment().subtract(1, 'year'), moment()],
        },
        {
          id: 9,
          text: this.$t('reports.daterange_option_last_3_years'),
          range: [moment().startOf('year').subtract(3, 'year'), moment().endOf('year')],
        },
        {
          id: 10,
          text: this.$t('reports.daterange_option_custom'),
          is_custom: true,
        },
      ];
    },
    setValue() {
      if (this.mode !== 'single') {
        this.date.startDate = this.asUnix
          ? moment.unix(this.modelValue.start).tz(this.$root.user.timezone).startOf('day').toDate()
          : this.modelValue.start;
        this.date.endDate = this.asUnix
          ? moment.unix(this.modelValue.end).tz(this.$root.user.timezone).endOf('day').toDate()
          : this.modelValue.end;
      } else {
        this.date.startDate = this.asUnix
          ? moment.unix(this.modelValue).tz(this.$root.user.timezone).startOf('day').toDate()
          : this.modelValue;
        this.date.endDate = this.asUnix
          ? moment.unix(this.modelValue).tz(this.$root.user.timezone).endOf('day').toDate()
          : this.modelValue;
      }
    },
    emitValue(selection) {
      this.date = selection;
      if (this.mode !== 'single') {
        this.$emit('update:modelValue', {
          start: this.asUnix ? moment(selection.startDate).startOf('day').unix() : this.formatDate(selection.startDate),
          end: this.asUnix ? moment(selection.endDate).endOf('day').unix() : this.formatDate(selection.endDate),
        });
      } else {
        this.$emit(
          'update:modelValue',
          this.asUnix ? moment(selection.startDate).startOf('day').unix() : this.formatDate(selection.startDate),
        );
      }
    },
    formatDate(date) {
      return moment(date).format(this.format);
    },
    getDisplayDate() {
      if (this.mode === 'single') {
        return moment(this.date.startDate).format(this.formatDisplay);
      } else {
        return this.displayRange(moment(this.date.startDate), moment(this.date.endDate));
      }
    },
    displayRange(start, end) {
      if (start.isSame(end, 'day')) {
        return moment(start).format(this.getFormat(start));
      }
      return start.format(this.getFormat(start)) + ' - ' + end.format(this.getFormat(end));
    },
    getFormat(date) {
      return date.isSame(new Date(), 'year') ? 'MMM D' : "MMM D 'YY";
    },
    onQuickOptionSelect(option) {
      if (option.is_custom) {
        setTimeout(() => this.$refs.picker.togglePicker(), 125); // timeout is needed somehow.. nextTick did not work
        return;
      }
      this.date.startDate = moment(option.range[0]).startOf('day').toDate();
      this.date.endDate = moment(option.range[1]).endOf('day').toDate();
      this.emitValue(this.date);
    },
    getSelectedQuickOption() {
      for (let i = 0; i < this.getQuickSelectOptions().length; i++) {
        const compare = this.getQuickSelectOptions()[i];
        if (
          compare.range &&
          moment(compare.range[0]).startOf('day').unix() === moment(this.date.startDate).unix() &&
          moment(compare.range[1]).endOf('day').unix() === moment(this.date.endDate).unix()
        ) {
          return compare.text;
        }
      }
      return 'Custom';
    },
    onDatePickerToggle(open) {
      this.isOpen = open;
      if (open) {
        this.$emit('focus');
      } else {
        this.$emit('blur');
      }
    },
    getDaysOfWeek() {
      return moment.weekdays().map((day) => day[0].toUpperCase() + day.slice(1, 3));
    },
    getMonthNames() {
      return moment.monthsShort('-MMM-').map((month) => month[0].toUpperCase() + month.slice(1));
    },
  },
};
</script>

<style lang="scss" scoped>
/**
  Overwrites the default CSS from component
*/
.UI_DatePicker.fullWidth {
  min-width: 100%;
}

.UI_DatePicker {
  &:deep(.picker-main.fullWidth) {
    min-width: 100%;
  }

  &:deep(.vue-daterange-picker) {
    min-width: 100%;
  }

  &:deep(.picker.fullWidth) {
    display: flex;
    align-items: center;
    height: 48px;
  }

  &:deep(.daterangepicker) {
    border: 1px solid #eaeaea;
    box-shadow:
      0px 2px 3px rgba(0, 0, 0, 0.02),
      0px 13px 28px rgba(0, 0, 0, 0.04);
    background: #f7f9fb;
    padding: 20px;
    border-radius: 12px;
    width: 384px;
    cursor: default;
    user-select: none;
    margin-top: 14px;
    font-size: 16px;
    animation: 0.25s cubic-bezier(0.18, 1.25, 0.4, 1) 0s 1 fadeIn;

    @media (max-width: 768px) {
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 999999;
      position: fixed;
      margin-left: 0;
      transform: none;
      width: 100%;
      padding: 5px;
      padding-bottom: 10px;
      border-bottom-right-radius: 0 !important;
      border-bottom-left-radius: 0 !important;

      .calendar-table td {
        padding: 10px;
      }
    }
  }

  &:deep(.daterangepicker:before, .daterangepicker:after) {
    display: none !important;
  }

  &:deep(.daterangepicker::after) {
    display: none !important;
  }

  &:deep(.daterangepicker > .calendars-container) {
    align-items: center;
    justify-content: center;
  }

  &:deep(.daterangepicker > .drp-calendar.left) {
    max-width: none;
    width: auto;
    padding: 0;
  }

  & :deep(.daterangepicker > thead th) {
    border: 1px solid #f7f9fb;
    cursor: default;
    &.month {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
    }

    &.prev,
    &.next {
      cursor: pointer;
      background: #f7f9fb !important;

      span {
        border-color: #56667d;
        padding: 3px;
      }
    }
  }

  &:deep(.daterangepicker > tbody th) {
    border: 1px solid #f7f9fb;
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 15px;
    padding-top: 15px;
    cursor: default;
  }

  &:deep(.calendar-table) {
    padding-right: 0 !important;
  }

  &:deep(.calendar-table td),
  &:deep(.calendar-table th) {
    background: #f7f9fb;
    font-weight: 500;
    border-radius: 0 !important;
  }

  &:deep(.calendar-table th) {
    font-size: 14px;
    color: #3f3f41;
    line-height: 24px;
  }

  &:deep(.calendar-table td) {
    border: 1px solid #eaeaea;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding: 12px 14px;
    color: #070707;
  }

  &:deep(.calendar-table td.in-range) {
    background: #49b2a1;
    border: 1px solid #49b2a1 !important;
    color: white;
  }

  &:deep(.calendar-table td.start-date),
  &:deep(.calendar-table td.end-date) {
    background: #249888;
  }

  &:deep(.placeholder) {
    color: var(--color-grey-500);
  }
}
</style>
