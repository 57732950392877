/**
 * This file contains utility functions for string comparison,
 * similarity measurement, and data filtering operations. The primary
 * focus is on utilizing the **Levenshtein distance** algorithm
 * to calculate the **edit distance** between strings and determining
 * their similarity, which is then integrated into a flexible filtering
 * mechanism for datasets.
 *
 * What is Levenshtein Distance?
 * The **Levenshtein distance**, also known as **edit distance**,
 * is a metric for quantifying the difference between two strings.
 * It measures the minimum number of operations required to transform
 * one string into another.
 */

/**
 * Calculates the minimum number of operations (insertions, deletions,
 * substitutions) required to transform one string into another, using
 * case-insensitive comparison.
 */
export const calculateEditDistance = (s1: string, s2: string) => {
  s1 = s1.toLowerCase();
  s2 = s2.toLowerCase();

  const costs: number[] = [];

  for (let i = 0; i <= s1.length; i++) {
    let lastValue = i;

    for (let j = 0; j <= s2.length; j++) {
      if (!i) costs[j] = j;
      else if (j) {
        let newValue = costs[j - 1];

        if (s1.charAt(i - 1) !== s2.charAt(j - 1)) {
          newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
        }

        costs[j - 1] = lastValue;
        lastValue = newValue;
      }
    }
    if (i) costs[s2.length] = lastValue;
  }
  return costs[s2.length];
};

/**
 * Computes the similarity percentage between two strings based on
 * their Levenshtein distance, returning a value between 0 and 1.
 */
export const matchPercentage = (s1: string, s2: string) => {
  let longer = s1;
  let shorter = s2;

  if (s1.length < s2.length) {
    longer = s2;
    shorter = s1;
  }

  const longerLength = longer.length;
  if (!longerLength) return 1.0;

  return (longerLength - calculateEditDistance(longer, shorter)) / longerLength;
};

/**
 * Filters and sorts an array of objects by matching string properties
 * to a given search term, with optional exact match support.
 * @param data
 * @param keys
 * @param filterBy
 * @param isExactMatch
 */
export const filterData = <T>(
  data: T[],
  keys: (keyof { [K in keyof T]: T[K] extends string ? T[K] : never })[],
  filterBy: string,
  isExactMatch?: boolean,
): T[] => {
  if (data?.length === 0) {
    return []; // Return an empty array if no data
  }

  return data
    .filter((item) => {
      const filterValue = filterBy.toLowerCase();
      return keys.some((key) => {
        const itemValue = String(item[key]).toLowerCase();
        const match = matchPercentage(itemValue, filterValue);

        if (isExactMatch) return match === 1;
        return itemValue.includes(filterValue) || match > 0.4;
      });
    })
    .sort((prev, curr) => {
      const newDataName = filterBy.toLowerCase();
      // Compare items by the best match score across all keys
      const getMaxMatch = (item: T) =>
        Math.max(...keys.map((key) => matchPercentage(String(item[key]).toLowerCase(), newDataName)));

      return getMaxMatch(curr) - getMaxMatch(prev);
    });
};
