import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  class: "schedule-send-date",
  "data-test": "schedule-send-date"
}
const _hoisted_2 = { class: "t-text-md-emphasize" }
const _hoisted_3 = { class: "ml-3 mt-3" }
const _hoisted_4 = {
  class: "pointer my-1 flex items-center",
  for: "now-radio"
}
const _hoisted_5 = { id: "now-radio-label" }
const _hoisted_6 = { class: "ml-3 flex h-48px items-center" }
const _hoisted_7 = {
  class: "pointer flex items-center",
  for: "later-radio"
}
const _hoisted_8 = { id: "later-radio-label" }
const _hoisted_9 = ["aria-invalid"]
const _hoisted_10 = {
  ref: "datepicker",
  class: "t-text-desktop-paragraph-md min-w-full"
}
const _hoisted_11 = { class: "ml-4 w-1/3" }
const _hoisted_12 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_t_input_radio = _resolveComponent("t-input-radio")!
  const _component_calendar_dates_linear = _resolveComponent("calendar-dates-linear")!
  const _component_atomic_datepicker = _resolveComponent("atomic-datepicker")!
  const _component_search_bar = _resolveComponent("search-bar")!
  const _component_t_error_item = _resolveComponent("t-error-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('broadcast.schedule_send_date')), 1 /* TEXT */),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("label", _hoisted_4, [
        _createVNode(_component_t_input_radio, {
          id: "now-radio",
          modelValue: $options.selectedSchedule,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($options.selectedSchedule) = $event)),
          name: "date",
          value: "now",
          "data-test": "now-radio",
          class: "mt-1"
        }, null, 8 /* PROPS */, ["modelValue"]),
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('broadcast.scheduled_date_now')), 1 /* TEXT */)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("label", _hoisted_7, [
        _createVNode(_component_t_input_radio, {
          id: "later-radio",
          modelValue: $options.selectedSchedule,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($options.selectedSchedule) = $event)),
          name: "date",
          value: "later",
          "data-test": "later-radio",
          class: "mt-1"
        }, null, 8 /* PROPS */, ["modelValue"]),
        _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('broadcast.scheduled_date_later')), 1 /* TEXT */)
      ]),
      ($options.isScheduledForLater)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["input-container ml-6 flex w-2/3 items-center rounded-xl border bg-white", { error: !$props.validation.date }]),
            "aria-live": "polite",
            "aria-atomic": "true",
            "aria-invalid": !$props.validation.date,
            "aria-label": "Schedule date input"
          }, [
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_atomic_datepicker, {
                id: "datepicker",
                modelValue: $options.selectedDate,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($options.selectedDate) = $event)),
                "aria-label": "Datepicker to select a schedule date",
                ranges: false,
                "format-display": "DD/MM/YYYY",
                "full-width": "",
                "as-unix": false,
                tabindex: "0",
                placeholder: $options.today,
                "data-test": "datepicker",
                "min-date": $options.yesterday,
                opens: "center"
              }, {
                prependIcon: _withCtx(() => [
                  _createVNode(_component_calendar_dates_linear, {
                    size: "18px",
                    class: "ml-1 mr-2.5",
                    "data-test": "datepicker-icon",
                    "aria-hidden": "true"
                  })
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["modelValue", "placeholder", "min-date"])
            ], 512 /* NEED_PATCH */)
          ], 10 /* CLASS, PROPS */, _hoisted_9))
        : _createCommentVNode("v-if", true),
      _createElementVNode("div", _hoisted_11, [
        ($options.isScheduledForLater)
          ? (_openBlock(), _createBlock(_component_search_bar, {
              key: 0,
              id: "time-searchbar",
              "aria-label": "Search bar to select time",
              "model-value": $options.selectedTime,
              options: $options.availableTimes,
              size: "lg",
              placeholder: "12:00",
              class: "b-1",
              error: !$props.validation.time || !$props.validation.timeFormat,
              "prepend-icon": "wab-broadcasting/time",
              "prepend-icon-size": 18,
              "data-test": "time-searchbar",
              height: 405
            }, {
              item: _withCtx((slotProps) => [
                _createElementVNode("div", {
                  class: "time-item pointer t-text-desktop-paragraph-sm mx-3 my-1 rounded-xl px-2 py-1 text-grey-800 hover:bg-grey-300",
                  "aria-label": "Available time option",
                  role: "menuitem",
                  onClick: ($event: any) => ($options.selectedTime = slotProps.item)
                }, _toDisplayString(slotProps.item), 9 /* TEXT, PROPS */, _hoisted_12)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["model-value", "options", "error"]))
          : _createCommentVNode("v-if", true)
      ])
    ]),
    ($options.errorMessages)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList($options.errorMessages, (error, index) => {
          return (_openBlock(), _createBlock(_component_t_error_item, {
            key: index,
            text: error,
            "data-test": "error-message",
            role: "alert",
            "aria-atomic": "true"
          }, null, 8 /* PROPS */, ["text"]))
        }), 128 /* KEYED_FRAGMENT */))
      : _createCommentVNode("v-if", true)
  ]))
}