import { PERMISSION } from '@/Configs/Constants';
import { SETTINGS_URL } from '@/Configs/Constants/navigation/settingsUrl';

import type { RouteRecord } from '../types';

const locationsRoutes: RouteRecord[] = [
  {
    path: '/admin/company_profile/locations',
    redirect: SETTINGS_URL.ADMIN__LOCATIONS,
  },
  {
    path: '/admin/company_profile/locations/*',
    redirect: SETTINGS_URL.ADMIN__LOCATIONS,
  },
  {
    path: SETTINGS_URL.ADMIN__LOCATIONS,
    component: () => import(/* webpackChunkName: "Locations" */ '../../components/Location/Index.vue'),
    meta: { permissionRequired: PERMISSION.SETTINGS__LOCATIONS__MANAGE },
    children: [
      {
        path: SETTINGS_URL.ADMIN__LOCATIONS__CREATE,
        component: () => import(/* webpackChunkName: "Locations" */ '../../components/Location/Edit.vue'),
        props: { action: 'create' },
        meta: { permissionRequired: PERMISSION.SETTINGS__LOCATIONS__MANAGE },
      },
      {
        path: `${SETTINGS_URL.ADMIN__LOCATIONS}/:id`,
        component: () => import(/* webpackChunkName: "Locations" */ '../../components/Location/Edit.vue'),
        props: { action: 'edit' },
        meta: { permissionRequired: PERMISSION.SETTINGS__LOCATIONS__MANAGE },
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "Locations" */ '../../components/AdminIndexBox.vue'),
        props: { boxName: 'locations' },
        meta: { permissionRequired: PERMISSION.SETTINGS__LOCATIONS__MANAGE },
      },
    ],
  },
];

export default locationsRoutes;
