export const ROUTE_URL = {
  HOME: '/',
  TICKETS: '/tickets',
};

const BASE_TRENGO_URL = 'https://trengo.com';

export const TRENGO_WEB_URL = {
  GET_DEMO: `${BASE_TRENGO_URL}/getdemo`,
};
