<template>
  <div class="create-broadcast flex h-full w-full flex-col justify-center bg-grey-200">
    <div class="flex h-full w-full items-center justify-center gap-3 px-4 py-4">
      <div
        class="my-4 flex h-full w-full min-w-[480px] max-w-[800px] flex-col items-start justify-center self-center rounded-xl border border-solid border-grey-300 bg-white"
      >
        <create-broadcast-header :title="titleLabel" />
        <div class="flex w-full flex-1 flex-col gap-6 overflow-y-auto p-8">
          <create-broadcast-form
            v-model:name="name"
            v-model:sender="sender"
            v-model:search-value="searchValue"
            v-model:template-id="templateId"
            v-model:tags="tags"
            v-model:message="message"
            :sender-object="senderObject"
            :computed-filtered-senders="computedFilteredSenders"
            :senders="senders"
            :requires-whatsapp-upgrade="requiresWhatsappUpgrade"
            :is-sms-enabled="isSmsEnabled"
            :is-editing-broadcast="isEditingBroadcast"
            :validation="validation"
            :wa-templates="waTemplates"
            :template="template"
            :tags-added="tagsAdded"
            :action="action"
            @on-update-tags="onUpdateTags"
            @on-update-template-media="onUpdateTemplateMedia"
          />

          <div>
            <wab-recipients
              v-model="recipientsLocal"
              :error="!validation.recipients"
              :show-limit="senderObject?.type === CHANNEL_TYPE.WA_BUSINESS"
              :is-editing="isEditingBroadcast"
            />
            <t-error-item
              v-if="!validation.recipients"
              data-test="recipients-error"
              class="mb-1 mt-1"
              :text="
                $t('broadcast.recipients_error', {
                  date: dateRadio === 'now' ? $t('general.send').toLowerCase() : $t('general.schedule').toLowerCase(),
                })
              "
            />
            <recipients-warning-banner v-model="showRecipientsWarning" />
          </div>

          <schedule-send-date
            v-model:date="date"
            v-model:time="time"
            v-model:schedule="dateRadio"
            :validation="validation"
          />
          <t-inline-banner
            v-if="insufficientBalance"
            type="error"
            class="tags-warning mt-12"
            data-test="insufficient-balance-warning"
          >
            <template #icon>
              <cross-octagon-linear />
            </template>
            <div v-if="userStore.hasPermission(PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE)">
              {{ $t('broadcast.you_dont_have_enough_balance_to_send_this_broadcast') }}
            </div>
            <div v-if="!userStore.hasPermission(PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE)">
              {{ $t('broadcast.broadcast_not_enough_balance_contact_admin_error') }}
            </div>
            <template #action>
              <t-button
                class="flex items-center"
                size="sm"
                btn-style="secondary"
                data-test="topup-button"
                @click="topUp"
              >
                <span class="whitespace-nowrap">{{ $t('broadcast.top_up') }}</span>
                <external-link2-linear size="14px" class="info-icon ml-2 text-grey-900" />
              </t-button>
            </template>
          </t-inline-banner>
        </div>
        <create-broadcast-footer
          :formatted-amount="formattedAmount"
          :cost-label="costLabel"
          :insufficient-balance="insufficientBalance"
          :submit-label="submitLabel"
          @cancel="handleGoBack"
          @submit="handleSubmit"
        />
      </div>
      <transition name="template-preview" mode="out-in">
        <div v-if="template?.id" class="flex h-full w-full min-w-[320px] max-w-[464px] items-center justify-center">
          <template-preview
            :is-create-mode="true"
            :selected-header-type="templateHeader?.sub_type?.toLowerCase()"
            :template-header="previewHeader"
            :template-body="templateBody"
            :template-footer="templateFooter"
            :buttons="templateButtons"
          />
        </div>
      </transition>
    </div>
    <whatsapp-disclaimer-modal v-model="whatsappDisclaimerModalIsOpen" @submit="disclaimerAccepted" />
    <confirm-broadcast-modal
      v-model="confirmBroadcastModalIsOpen"
      :sender-type="senderObject && senderObject.type"
      :message-length="stripHtml(message).length"
      :scheduled="dateRadio === 'later'"
      :tags-added="tagsAdded"
      :send-date="sendDateModalLabel"
      :total-amount="amount"
      :balance="balance"
      :recipients-quantity="recipients.contacts.length + recipients.numbers.length"
      :is-confirm-btn-disabled="isConfirmBtnDisabled"
      @submit="confirmBroadcast"
    />
  </div>
</template>

<script lang="ts">
import { CrossOctagonLinear, ExternalLink2Linear } from '@trengo/trengo-icons';
import { sanitize } from 'dompurify';
import { debounce, forOwn, includes, isEmpty, map } from 'lodash';
import moment from 'moment';
import { mapStores } from 'pinia';
import { defineComponent } from 'vue';

import { fetchWaTemplates } from '@/api/modules/waTemplates';
import {
  createBroadcast,
  getBroadcast,
  getPriceEstimation,
  updateBroadcast,
  uploadFile,
} from '@/components/WabBroadcasting/api';
import ConfirmBroadcastModal from '@/components/WabBroadcasting/components/ConfirmBroadcastModal';
import CreateBroadcastFooter from '@/components/WabBroadcasting/components/CreateBroadcastFooter/CreateBroadcastFooter.vue';
import CreateBroadcastForm from '@/components/WabBroadcasting/components/CreateBroadcastForm/CreateBroadcastForm.vue';
import CreateBroadcastHeader from '@/components/WabBroadcasting/components/CreateBroadcastHeader/CreateBroadcastHeader.vue';
import RecipientsWarningBanner from '@/components/WabBroadcasting/components/RecipientsWarningBanner/RecipientsWarningBanner.vue';
import ScheduleSendDate from '@/components/WabBroadcasting/components/ScheduleSendDate';
import WabRecipients from '@/components/WabBroadcasting/components/WabRecipients';
import WhatsappDisclaimerModal from '@/components/WabBroadcasting/components/WhatsappDisclaimerModal';
import { currency } from '@/components/WabBroadcasting/utils/Utils.js';
import TemplatePreview from '@/components/WaTemplates/TemplatePreview/TemplatePreview.vue';
import { useBalance } from '@/composables/useBalance';
import { CHANNEL_TYPE, FEATURE, ENTITLEMENT_STATUS, PERMISSION } from '@/Configs/Constants';
import { SETTINGS_URL } from '@/Configs/Constants/navigation/settingsUrl';
import { STATUS_CODE } from '@/Configs/Constants/StatusCodes';
import { TEMPLATE_STATUS, TEMPLATE_TAG_TYPE, WA_TEMPLATE_COMPONENT_TYPE } from '@/Configs/Constants/whatsapp';
import { useCompanyProfileStore, useEntitlementsStore, useUserStore, useChannelsStore } from '@/store/pinia';

import type { Recipient, PriceEstimationParams } from '@/components/WabBroadcasting/types/broadcasting';
import type {
  TemplateTag,
  TemplateHeaderMedia,
  TemplateComponent,
  TemplateButtonItem,
} from '@/components/WaTemplates/types';
import type { Channel } from '@/store/pinia/channels/types';
import type { Template } from '@/types/waTemplates';

export default defineComponent({
  name: 'CreateBroadcastView',
  components: {
    WabRecipients,
    CrossOctagonLinear,
    ExternalLink2Linear,
    ScheduleSendDate,
    WhatsappDisclaimerModal,
    ConfirmBroadcastModal,
    TemplatePreview,
    CreateBroadcastHeader,
    CreateBroadcastFooter,
    RecipientsWarningBanner,
    CreateBroadcastForm,
  },
  props: {
    action: {
      type: String,
      default: 'create',
    },
  },

  setup() {
    const { rawBalance } = useBalance();
    return { balance: rawBalance };
  },

  data() {
    return {
      name: '',
      sender: null as number | null,
      searchValue: '',
      message: '',
      recipientsLocal: {
        contacts: {},
        manual: [],
        groups: {},
      },
      templateId: null as number | null,
      waTemplates: [] as Template[],
      tags: [] as TemplateTag[],
      validation: {
        sender: true,
        date: true,
        time: true,
        timeFormat: true,
        timeAfterNow: true,
        template: true,
        recipients: true,
        message: true,
        tags: true,
      },
      isSubmitButtonValid: true,
      date: '',
      recentLaterDate: '',
      time: '',
      dateRadio: 'now',
      send_at: null,
      whatsappDisclaimerModalIsOpen: false,
      confirmBroadcastModalIsOpen: false,
      whatsappDisclaimerAccepted: false,
      amount: '0,00',
      record: {},
      validationTriggered: false,
      showRecipientsWarning: false,
      recipientsNumbers: [],
      FEATURE,
      ENTITLEMENT_STATUS,
      isConfirmBtnDisabled: false,
      PERMISSION,
      templateMedia: null as TemplateHeaderMedia | null,
      CHANNEL_TYPE,
    };
  },
  computed: {
    ...mapStores(useCompanyProfileStore, useEntitlementsStore, useUserStore, useChannelsStore),
    requiresWhatsappUpgrade() {
      return (
        this.entitlementsStore?.getEntitlementStatusFor(FEATURE.CUSTOMER_SERVICE__BROADCASTING__SMS) ===
          ENTITLEMENT_STATUS.ENTITLED &&
        this.entitlementsStore?.getEntitlementStatusFor(FEATURE.CUSTOMER_SERVICE__BROADCASTING__WHATSAPP) ===
          ENTITLEMENT_STATUS.REQUIRES_PLAN_UPGRADE
      );
    },
    isEntitledToSmsAndWhatsapp() {
      return (
        this.entitlementsStore?.getEntitlementStatusFor(FEATURE.CUSTOMER_SERVICE__BROADCASTING__SMS) ===
          ENTITLEMENT_STATUS.ENTITLED &&
        this.entitlementsStore?.getEntitlementStatusFor(FEATURE.CUSTOMER_SERVICE__BROADCASTING__WHATSAPP) ===
          ENTITLEMENT_STATUS.ENTITLED
      );
    },
    senders() {
      const channels: string[] = [];
      if (this.isSmsEnabled) {
        channels.push(CHANNEL_TYPE.SMS);
      }
      if (this.isEntitledToSmsAndWhatsapp) {
        channels.push(CHANNEL_TYPE.WA_BUSINESS);
      }
      return this.channelsStore.channels
        .filter((channel: Channel) => {
          return channels.includes(channel.type);
        })
        .map((channel: Channel) => ({
          icon: `${this.$root.assetsURL}img/${this.getIcon(channel.type)}.svg`,
          ...channel,
        }));
    },
    isSmsEnabled() {
      return typeof window.AGENCY.enable_bulk_sms == 'undefined' || window.AGENCY.enable_bulk_sms == 1;
    },
    computedFilteredSenders() {
      return this.mapItemsBySearch(this.searchValue);
    },
    senderObject() {
      return this.senders.filter((channel: Channel) => channel.id === this.sender)[0] || null;
    },
    tagsAdded() {
      return this.message?.includes('class="sms-tag"');
    },
    template(): Template | undefined {
      return (
        this.waTemplates.filter((currentTemplate: Template) => {
          return currentTemplate.id === this.templateId;
        })[0] || []
      );
    },
    templateBody(): string {
      let parsedSanitizedMessage = this.template?.message;

      this.tags?.forEach((tag: TemplateTag) => {
        parsedSanitizedMessage = parsedSanitizedMessage.replace(
          tag.key,
          '<span class="text-leaf-500 bg-leaf-100 rounded-[4px] px-[1px]">' +
            (tag.value ? tag.value : tag.key) +
            '</span>',
        );
      });

      return sanitize(parsedSanitizedMessage);
    },
    templateHeader(): TemplateComponent | undefined {
      // Todo: move this to util file to be reused in template creation and in the composer
      const header = this.template?.components?.find(
        (item: TemplateComponent) => item?.type === WA_TEMPLATE_COMPONENT_TYPE.HEADER,
      );
      return header;
    },
    templateFooter(): string | undefined {
      // Todo: move this to util file to be reused in template creation and in the composer
      const footer = this.template?.components?.find(
        (item: TemplateComponent) => item?.type === WA_TEMPLATE_COMPONENT_TYPE.FOOTER,
      );
      return footer?.value;
    },
    templateButtons(): TemplateButtonItem[] {
      // Todo: move this to util file to be reused in template creation and in the composer
      const buttons = this.template?.components
        ?.filter((item: TemplateComponent) => item?.type === WA_TEMPLATE_COMPONENT_TYPE.BUTTONS)
        ?.map((item: TemplateComponent) => ({
          details: item?.details,
          id: item?.id,
          isValid: true,
          subType: item?.sub_type,
          value: item?.value,
        }));
      return buttons;
    },
    previewHeader(): string {
      return this.templateMedia ? this.templateMedia.url : this.templateHeader?.value;
    },
    submitLabel() {
      if (this.isEditingBroadcast) {
        if (this.dateRadio === 'now') {
          return this.$t('broadcast.broadcast_save_and_send_button');
        }
        return this.$t('broadcast.broadcast_save_and_schedule_button');
      } else if (this.dateRadio === 'now') {
        return this.$t('general.send');
      }
      return this.$t('general.schedule');
    },
    sendDateModalLabel() {
      if (this.dateRadio === 'now') {
        return moment().tz(this.timezone).format('DD/MM/YYYY HH:mm');
      } else
        return moment(this.date + ' ' + this.time)
          .tz(this.timezone)
          .format('DD/MM/YYYY HH:mm');
    },
    costLabel() {
      if (this.senderObject && this.senderObject.type === CHANNEL_TYPE.SMS && this.tagsAdded) {
        return this.$t('broadcast.estimated_cost');
      } else return this.$t('broadcast.total_cost');
    },
    insufficientBalance() {
      if (this.companyProfileStore.isUsageBasedPricingModel) {
        return;
      }
      return this.balance - this.amount < 0;
    },
    recipients() {
      const contacts = Object.keys(this.recipientsLocal.contacts).map((key) => parseInt(key, 10));
      const numbers = Object.values(this.recipientsLocal.manual).map((number) => number.value);

      return {
        contacts,
        numbers,
      };
    },
    isEditingBroadcast() {
      return this.action === 'edit';
    },
    titleLabel() {
      if (this.isEditingBroadcast) {
        return this.$t('broadcast.editing_broadcast_title');
      } else return this.$t('broadcast.new_broadcast');
    },
    formattedAmount() {
      return currency(this.amount);
    },
    timezone() {
      return this.userStore.user?.timezone;
    },
    customFieldTags() {
      return this.tags.filter((tag: TemplateTag) => tag.type === TEMPLATE_TAG_TYPE.CUSTOM_FIELD);
    },
    shouldUploadTemplateMedia() {
      return this.templateMedia?.file;
    },
  },
  watch: {
    date(value) {
      this.validation.date = value?.length > 0;
      this.validation.timeAfterNow = moment(this.date + ' ' + this.time)
        .tz(this.timezone)
        .isAfter(moment().tz(this.timezone));
      this.handleSubmitButtonValidation();
    },
    time(value) {
      this.validation.time = value?.length > 0;
      this.validation.timeFormat = value?.length === 0 || /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(this.time);
      this.validation.timeAfterNow = moment(this.date + ' ' + this.time)
        .tz(this.timezone)
        .isAfter(moment().tz(this.timezone));
      this.handleSubmitButtonValidation();
    },
    dateRadio(value: 'now' | 'later') {
      if (value === 'now' || !this.isEditingBroadcast) {
        this.recentLaterDate = this.date;
        this.dateRadio = value;
        this.date = moment().tz(this.timezone).format('YYYY-MM-DD');
      } else {
        this.date = this.recentLaterDate;
      }
    },
    async sender(channelId: number) {
      try {
        this.validation.sender = !!channelId;
        this.handleSubmitButtonValidation();

        if (!this.isEditingBroadcast) {
          this.templateId = null;
        }

        if (this.senderObject?.type === CHANNEL_TYPE.WA_BUSINESS) {
          await this.fetchWaTemplates();
        }
      } catch (error) {
        console.error(error);
      }
    },
    template(value) {
      this.validation.template = !!value;
      this.handleSubmitButtonValidation();
    },
    message(value: string) {
      this.validation.message = window.stripHtml(value).length > 0;
      this.handleSubmitButtonValidation();
      this.convertAndGetEstimation();
    },
    recipients: {
      deep: true,
      handler(value) {
        this.validation.recipients = !!(value?.contacts?.length || value?.numbers?.length);
        this.convertAndGetEstimation();
      },
    },
    customFieldTags: {
      deep: true,
      handler(value) {
        if (this.validationTriggered) {
          this.validation.tags = !includes(map(value, 'value'), '');
          this.handleSubmitButtonValidation();
        }
      },
    },
    templateId(value) {
      if (!value) return;
      this.convertAndGetEstimation();
    },
  },
  mounted() {
    if (this.isEditingBroadcast) {
      getBroadcast(this.$route.params.id).then((res) => {
        this.name = res.data.name;
        this.sender = res.data.channel_id;
        this.templateId = res.data.template?.id;
        this.tags = [];
        res.data.tags.forEach((tag: TemplateTag) => {
          this.tags.push({ key: tag.key, value: tag.value, type: tag.type });
        });
        this.message = res.data.message;
        this.recipientsLocal.contacts = {};
        res.data.recipients.forEach((recipient: Recipient) => {
          this.recipientsLocal.contacts[recipient.id] = {
            isSelected: true,
            id: recipient.id,
            name: recipient.name,
            phone: recipient.phone,
          };
        });
        if (res.data.send_at) {
          this.dateRadio = 'later';
          this.date = moment(res.data.send_at).tz(this.timezone).format('YYYY-MM-DD');
          this.time = moment(res.data.send_at).tz(this.timezone).format('HH:mm');
        }
      });
    }
  },
  async created() {
    this.WA_BUSINESS = CHANNEL_TYPE.WA_BUSINESS;
    this.SMS = CHANNEL_TYPE.SMS;
    this.loadRecord();
    this.whatsappDisclaimerAccepted = await this.$tStorage.getItem(
      this.userStore.user?.id + '_whatsapp_disclaimer_accepted',
    );
    this.debouncedEstimation = debounce(this.handleEstimation, 200);
  },
  methods: {
    getIcon(type: valueof<typeof CHANNEL_TYPE>) {
      if (type === CHANNEL_TYPE.SMS) {
        return 'wab-broadcasting/sms';
      } else return 'wab-broadcasting/whatsapp';
    },
    mapItemsBySearch(search: string) {
      return this.senders.filter((item: Channel) => item.title.toLowerCase().includes(search.toLowerCase()));
    },
    updateMessage(incomingMessage: string) {
      this.message = incomingMessage;
    },
    onUpdateTags(incomingTags: TemplateTag[]) {
      this.tags = incomingTags;
    },
    handleGoBack() {
      this.$router.push('/broadcasting');
    },
    handleSubmitButtonValidation(): void {
      this.isSubmitButtonValid = !Object.values(this.validation).includes(false);
    },
    validate(): boolean {
      this.validation = {
        sender: !!this.sender,
        date: this.dateRadio === 'now' || this.date?.length > 0,
        time: this.dateRadio === 'now' || this.time?.length > 0,
        timeFormat:
          this.dateRadio === 'now' || this.time?.length === 0 || /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(this.time),
        recipients: !!(this.recipients?.contacts.length || this.recipients?.numbers.length),
        template: this.senderObject?.type === CHANNEL_TYPE.SMS || this.template?.id,
        message: this.senderObject?.type === CHANNEL_TYPE.WA_BUSINESS || window.stripHtml(this.message).length > 0,
        tags: !this.template || !includes(map(this.customFieldTags, 'value'), ''),
        timeAfterNow:
          this.dateRadio === 'now' ||
          moment(this.date + ' ' + this.time)
            .tz(this.timezone)
            .isAfter(moment().tz(this.timezone)),
      };
      this.handleSubmitButtonValidation();
      return this.isSubmitButtonValid;
    },
    handleSubmit(): boolean | void {
      this.validationTriggered = true;
      if (!this.validate()) {
        return false;
      }
      if (this.whatsappDisclaimerAccepted || this.senderObject?.type === CHANNEL_TYPE.SMS) {
        this.confirmBroadcastModalIsOpen = true;
      } else {
        this.whatsappDisclaimerModalIsOpen = true;
      }
    },
    disclaimerAccepted(): void {
      this.whatsappDisclaimerModalIsOpen = false;
      this.$tStorage.setItem(this.userStore.user?.id + '_whatsapp_disclaimer_accepted', true);
      this.whatsappDisclaimerAccepted = true;
      this.confirmBroadcastModalIsOpen = true;
    },
    confirmBroadcast(): void {
      this.isConfirmBtnDisabled = true;
      this.formatBroadcastSendAt();

      const now = moment().tz(this.timezone);

      if (this.senderObject?.type === CHANNEL_TYPE.WA_BUSINESS) {
        this.handleWhatsAppBroadcast(now);
      } else {
        this.handleSmsBroadcast(now);
      }
    },
    formatBroadcastSendAt(): void {
      if (this.dateRadio === 'now') {
        this.send_at = null;
      } else if (this.dateRadio === 'later') {
        this.send_at = moment.tz(this.date + ' ' + this.time, '', this.timezone).format();
      }
    },
    async handleWhatsAppBroadcast(now: moment.Moment) {
      if (this.shouldUploadTemplateMedia) {
        await this.updateTemplateMediaTag();
      }

      const apiParams = {
        name: this.name ? this.name : this.$t('broadcast.whatsapp_broadcast_title') + now.format('DD/MM/YYYY'),
        channel_id: this.sender,
        template_id: this.templateId,
        send_at: this.send_at,
        tags: this.tags,
        recipients: {
          contacts: this.recipients.contacts,
          numbers: this.recipients.numbers,
        },
      };

      this.dispatchBroadcastRequest(apiParams);
    },
    handleSmsBroadcast(now: moment.Moment): void {
      const apiParams = {
        name: this.name ? this.name : this.$t('broadcast.sms_broadcast_title') + now.format('DD/MM/YYYY'),
        channel_id: this.sender,
        send_at: this.send_at,
        recipients: {
          contacts: this.recipients.contacts,
          numbers: this.recipients.numbers,
        },
        message: this.message,
      };

      this.dispatchBroadcastRequest(apiParams);
    },

    dispatchBroadcastRequest(apiParams: BroadcastParams): void {
      const apiFunc = this.isEditingBroadcast ? updateBroadcast : createBroadcast;

      apiFunc(this.isEditingBroadcast ? this.$route.params.id : apiParams, apiParams)
        .then(() => {
          this.confirmBroadcastModalIsOpen = false;
          this.$nextTick(() => {
            const successMessage =
              this.dateRadio === 'now'
                ? this.$t('broadcast.your_broadcast_will_begin_sending_shortly')
                : this.$t('broadcast.broadcast_scheduled_successfully');
            this.flashSuccess(successMessage);
            this.$router.push('/broadcasting');
          });
        })
        .catch((e) => {
          this.isConfirmBtnDisabled = false;
          console.error(e);
          if (e.response?.data?.errors) {
            this.flashError(
              Object.values(e.response.data.errors)
                .map((errorArray) => errorArray[0])
                .join('<br />'),
            );
          }
        });
    },

    topUp() {
      this.$tStorage.setItem(this.userStore.user?.id + '_broadcast_record', {
        name: this.name,
        sender: this.sender,
        templateId: this.templateId,
        message: this.message,
        recipientsLocal: this.recipientsLocal,
        tags: this.tags,
        dateRadio: this.dateRadio,
        date: this.date,
        time: this.time,
      });
      this.$router.push(SETTINGS_URL.ADMIN__BALANCE_SETTINGS);
    },
    async loadRecord() {
      this.record = await this.$tStorage.getItem(this.userStore.user?.id + '_broadcast_record');
      if (!isEmpty(this.record)) {
        this.$tStorage.setItem(this.userStore.user?.id + '_broadcast_record', {});
        this.name = this.record.name;
        this.sender = this.record.sender;
        this.templateId = this.record.templateId;
        this.message = this.record.message;
        this.recipientsLocal = this.record.recipientsLocal;
        this.tags = this.record.tags;
        this.dateRadio = this.record.dateRadio;
        this.date = this.record.date;
        this.time = this.record.time;
      }
    },
    convertAndGetEstimation() {
      if (this.sender && (this.senderObject?.type === CHANNEL_TYPE.WA_BUSINESS || stripHtml(this.message).length)) {
        let estimationParams;
        if (this.senderObject?.type === CHANNEL_TYPE.WA_BUSINESS) {
          estimationParams = {
            channel_id: this.sender,
            recipients: {
              contacts: this.recipients.contacts,
              numbers: this.recipients.numbers,
            },
            ...(this.templateId && { hsm_id: this.templateId }),
          };
        } else {
          estimationParams = {
            channel_id: this.sender,
            message: stripHtml(this.message),
            recipients: {
              contacts: this.recipients.contacts,
              numbers: this.recipients.numbers,
            },
          };
        }
        this.debouncedEstimation(estimationParams);
      }
    },
    onUpdateTemplateMedia(templateMedia: TemplateHeaderMedia): void {
      this.templateMedia = templateMedia;
    },
    async handleDynamicTemplateMediaUpload(): Promise<TemplateHeaderMedia | null> {
      try {
        const formData = new FormData();
        formData.append('file', this.templateMedia?.file);
        const { data }: { data: TemplateHeaderMedia } = await uploadFile(formData);
        return data;
      } catch (error) {
        console.error(error);
        throw error; // Throw error to stop broadcast creation
      }
    },
    async updateTemplateMediaTag(): Promise<void> {
      const uploadedMedia = await this.handleDynamicTemplateMediaUpload();
      if (!uploadedMedia) return this.tags;

      this.tags = this.tags.map((tag: TemplateTag) =>
        tag.type === TEMPLATE_TAG_TYPE.TEMPLATE_COMPONENT ? { ...tag, value: uploadedMedia.id } : tag,
      );
    },
    async fetchWaTemplates() {
      const { data } = await fetchWaTemplates({
        status: TEMPLATE_STATUS.ACCEPTED,
        channel_id: this.sender,
      });
      this.waTemplates = data?.data || [];
    },
    handleEstimation(data: PriceEstimationParams) {
      getPriceEstimation(data)
        .then((res) => {
          this.amount = res.data.amount;
        })
        .catch((e) => {
          if (e.response.status === STATUS_CODE.UNPROCESSABLE_ENTITY) {
            this.showRecipientsWarning = false;
            this.recipientsNumbers = [...this.recipients.numbers];
            forOwn(e.response.data.errors, (error, key) => {
              if (key.startsWith('recipients.numbers')) {
                this.showRecipientsWarning = true;
                this.recipientsNumbers = this.recipientsNumbers.filter(
                  (number: string) => number != this.recipients.numbers[key.split('.')[2]],
                );
              }
            });
            if (this.showRecipientsWarning) {
              this.recipients = Object.assign({}, this.recipients, {
                numbers: [...this.recipientsNumbers],
              });
            }
          }
        });
    },
  },
});
</script>

<style lang="scss" scoped>
.create-broadcast {
  /* Enter transitions */
  .template-preview-enter-active {
    animation: slideInRight 0.3s ease-in-out;
  }

  /* Leave transitions */
  .template-preview-leave-active {
    animation: slideOutRight 0.3s ease-in-out;
  }

  @keyframes slideInRight {
    from {
      opacity: 0;
      transform: translateX(100px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes slideOutRight {
    from {
      opacity: 1;
      transform: translateX(0);
    }
    to {
      opacity: 0;
      transform: translateX(100px);
    }
  }
}
</style>
