<template>
  <t-multi-choice
    v-model="headerType"
    :disabled="disabled"
    stretch
    class="my-5 flex w-full justify-between gap-x-4"
    child-class="w-1/2"
    data-test="header-type-choice"
  >
    <template v-for="choice in headerCardsChoices" :key="choice.type" #[choice.type]="{ selected }">
      <t-multi-choice-button
        class="h-full"
        vertical
        :title="choice.title"
        :desc="choice.description"
        :selected="selected"
        :show-check-icon="selected"
        :disabled="disabled"
        :data-test="`header-type-${choice.type}-button`"
      >
        <template #icon>
          <component :is="choice.icon" size="1.5rem" />
        </template>
      </t-multi-choice-button>
    </template>
  </t-multi-choice>
</template>

<script setup lang="ts">
import { Image3Linear, TextLinear, VideoCameraLinear } from '@trengo/trengo-icons';
import { computed, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';

import { TEMPLATE_HEADER_TYPE } from '@/Configs/Constants/whatsapp';

import type { MultiChoiceCard, TemplateHeaderType } from '@/components/WaTemplates/types';
import type { PropType } from 'vue';

const { t } = useI18n();

const headerCardsChoices = computed(
  () =>
    [
      {
        title: t('whatsapp.template_header_cards_choices_text_title'),
        description: t('whatsapp.template_header_cards_choices_text_description'),
        type: TEMPLATE_HEADER_TYPE.TEXT,
        icon: TextLinear,
      },
      {
        title: t('whatsapp.template_header_cards_choices_image_title'),
        description: t('whatsapp.template_header_cards_choices_image_description'),
        type: TEMPLATE_HEADER_TYPE.IMAGE,
        icon: Image3Linear,
      },
      {
        title: t('whatsapp.template_header_cards_choices_video_title'),
        description: t('whatsapp.template_header_cards_choices_video_description'),
        type: TEMPLATE_HEADER_TYPE.VIDEO,
        icon: VideoCameraLinear,
      },
    ] satisfies MultiChoiceCard[],
);

const props = defineProps({
  selectedHeaderType: {
    type: String as PropType<TemplateHeaderType>,
    default: TEMPLATE_HEADER_TYPE.TEXT,
    validator: (value: TemplateHeaderType) => Object.values(TEMPLATE_HEADER_TYPE).includes(value),
  },
  disabled: { type: Boolean, default: true },
});

const emit = defineEmits<{
  (e: 'handle-selected-header', value: TemplateHeaderType): void;
}>();

const { selectedHeaderType } = toRefs(props);

const headerType = computed({
  get: () => selectedHeaderType.value,
  set: (value) => emit('handle-selected-header', value),
});
</script>
