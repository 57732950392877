<template>
  <div class="" style="height: 100%; margin-top: -2px" :class="{ 'composer-dragenter': dragenter }">
    <div
      v-if="newTicket || userStore.hasPermission(PERMISSION.INBOX__CONVERSATIONS__REPLY)"
      class="composer-container pos-relative flex h-full min-w-0 flex-col bg-white lg:rounded-xl"
      :class="{ 'composer__grey-border': composerGreyBorderClass }"
    >
      <div v-if="newTicket && !popout" class="modal-header">
        <h5
          class="modal-title text-center leading-none"
          style="font-size: 18px"
          v-text="forwarding ? $t('tickets.composer_title_forward') : $t('tickets.composer_title_new')"
        ></h5>
        <button type="button" class="modal-close-btn leading-none" data-dismiss="modal" aria-label="Close">
          <i class="material-icons text-grey-500" style="margin-top: -3px">close</i>
        </button>
      </div>
      <form
        class="flex min-h-0 w-full min-w-0 flex-1 flex-col"
        style="word-wrap: break-word"
        @submit.prevent="onFormSubmitted"
      >
        <template v-if="publicProvider === 'FACEBOOK' && replyingTo">
          <span class="flex items-center border-b-2 border-grey-200 px-4 py-3">
            <i class="fa fa-facebook mr-2" style="color: rgb(97, 110, 255)"></i>
            <template v-if="ticket.channelMeta.type && ticket.channelMeta.type === 'FACEBOOK_FEED_MESSAGES'">
              <div
                class="pr-1 text-grey-500"
                v-html="$t('tickets.replying_to', { type: replyingTo.type, name: replyingTo.full_name })"
              ></div>
              <div class="text-grey-600">{{ $t('tickets.replying_to_facebook_page') }} {{ ticket.channel.name }}</div>
            </template>
            <template v-else>
              <div
                class="pr-1 text-grey-500"
                v-html="$t('tickets.replying_to', { type: 'mention', name: replyingTo.full_name })"
              ></div>
              <div class="text-grey-600">{{ $t('tickets.replying_to_facebook_post') }} {{ ticket.contact.name }}</div>
            </template>
          </span>
        </template>
        <template v-else-if="publicProvider === 'INSTAGRAM' && replyingTo">
          <span class="flex items-center border-b-2 border-grey-200 px-4 py-3">
            <i class="fa fa-instagram mr-2" style="color: rgb(131, 58, 180)"></i>
            <div
              class="pr-1 text-grey-500"
              v-html="$t('tickets.replying_to_instagram', { type: replyingTo.type, name: replyingTo.full_name })"
            ></div>
            <div class="text-grey-600">{{ $t('tickets.replying_to_instagram_profile') }} {{ ticket.channel.name }}</div>
          </span>
        </template>
        <div
          v-show="ticket == null || isEmail"
          class="relative flex min-w-0 border-b-2 border-grey-200"
          :class="{ 'pb-1 lg:pb-0': showCc || showBcc }"
        >
          <div class="flex-1 text-ellipsis xl3:flex">
            <div
              style="/*pointer-events: none*/"
              class="flex min-w-0 items-center"
              :class="{ 'cursor-pointer': !newTicket && !popout }"
              v-on="!newTicket && !popout ? { click: openSendAsModal } : {}"
            >
              <label class="m-0 px-3 py-2 pr-2 text-grey-500" :class="{ 'cursor-pointer': !newTicket && !popout }">
                {{ $t('tickets.composer_from') }}:
              </label>
              <dropdown
                v-if="newTicket"
                v-model="channel"
                :options="channels"
                :visible-items="5"
                :caret="true"
                data-test-dropdown-toggle="composer-from-channel-dropdown-toggle"
                data-test-dropdown-list="composer-from-channel-dropdown-list"
                data-test-search-dropdown-input="composer-select-channel-search-input"
                data-test-dropdown-list-item="composer-from-channel-dropdown-list-item"
              >
                <template #heading>
                  <div class="_500 select-none border-grey-200 pt-4 text-center text-base leading-none text-black">
                    {{ $t('tickets.select_sender') }}
                  </div>
                </template>
                <template #toggle="prop">
                  <div
                    v-if="prop.label"
                    class="flex min-w-0 select-none flex-nowrap items-center text-ellipsis rounded-lg bg-grey-200 leading-none text-grey-600 hover:text-grey-800"
                    style="display: inline-flex; vertical-align: middle; padding: 5px 10px"
                  >
                    <div class="flex items-center">
                      <i v-if="prop.selected?.is_private" class="fa fa-lock mr-2 text-grey-500"></i>
                      <span class="ticket-from-channel text-ellipsis">{{ prop.label }}</span>
                    </div>
                  </div>
                </template>
                <template #option="prop">
                  <div class="flex items-center">
                    <i v-if="prop.option?.is_private" class="fa fa-lock mr-2 text-grey-500"></i>
                    <span class="text-ellipsis">{{ prop.option?.display_name }}</span>
                  </div>
                </template>
              </dropdown>
              <span
                v-if="!newTicket"
                style="margin: 5px; padding: 5px 10px"
                class="mr-1 cursor-pointer text-ellipsis rounded-lg bg-grey-200 leading-none text-grey-600"
              >
                {{ channel.text }}
              </span>
            </div>
            <div
              class="align-items flex min-w-0 flex-1 flex-shrink-0 items-center border-t-2 border-grey-200 xl3:border-t-0"
              style="min-height: 35px"
            >
              <label class="m-0 px-3 py-0 pr-2 text-grey-500">{{ $t('tickets.composer_to') }}:</label>
              <div class="w-full text-ellipsis pr-2">
                <composer-dropdown
                  v-if="isNewToCcBccUxEnabled"
                  :allow-multiple="isEmail"
                  :item-label-index="'display_name'"
                  :selected-label-index="'identifier'"
                  :items="contacts"
                  :loading="isContactSearchLoading"
                  :initial-selected-items="contact"
                  data-test-remote-search-input="composer-to-recipient-remote-search-input"
                  data-test-dropdown-toggle="composer-to-recipient-channel-dropdown-toggle"
                  data-test-dropdown-list="composer-to-recipient-dropdown-list"
                  data-test-dropdown-list-item="composer-to-recipient-dropdown-list-item"
                  @on-input-change="handleComposerInputChange"
                  @on-select="
                    (_contacts) => {
                      contact = _contacts;
                      processDraft();
                    }
                  "
                />
                <dropdown
                  v-else
                  v-model="contact"
                  label="display_name"
                  :multiple="isEmail"
                  :options="contacts"
                  :remote="true"
                  selected-label-field="identifier"
                  data-test-remote-search-input="composer-to-recipient-remote-search-input"
                  data-test-dropdown-toggle="composer-to-recipient-channel-dropdown-toggle"
                  data-test-dropdown-list="composer-to-recipient-dropdown-list"
                  data-test-dropdown-list-item="composer-to-recipient-dropdown-list-item"
                  @update:model-value="processDraft"
                  @search="onContactSearch"
                />
              </div>
            </div>
          </div>
          <div
            v-show="isEmail"
            class="align-items ml-auto flex items-start border-b-2 border-grey-200 pl-3 xl3:border-b-0"
            style="height: 39px; padding-top: 7px"
          >
            <span
              class="composer-btn cursor-pointer"
              :class="{ 'bg-grey-200 text-grey-600': !showCc, 'bg-grey-600 text-white': showCc }"
              style="margin-right: 5px"
              @click="showCc = !showCc"
            >
              {{ $t('tickets.composer_cc_uppercase') }}
            </span>
            <span
              class="composer-btn cursor-pointer"
              :class="{ 'bg-grey-200 text-grey-600': !showBcc, 'bg-grey-600 text-white': showBcc }"
              style="margin-right: 10px"
              @click="showBcc = !showBcc"
            >
              {{ $t('tickets.composer_bcc_uppercase') }}
            </span>
            <a
              v-if="canPopout"
              class="composer-btn bg-grey-200"
              style="margin-left: -5px; margin-right: 10px"
              @click="openInExternalWindow()"
            >
              <i class="material-icons md-12 text-grey-600">open_in_new</i>
            </a>
          </div>
        </div>
        <div
          v-show="showCc"
          class="align-items flex flex-shrink-0 items-center border-b-2 border-grey-200"
          style="min-height: 35px"
        >
          <label class="m-0 px-3 py-2 pr-2 text-grey-500">{{ $t('tickets.composer_cc') }}</label>
          <span class="flex-1 text-ellipsis">
            <composer-dropdown
              v-if="isNewToCcBccUxEnabled"
              :allow-multiple="true"
              :item-label-index="'display_name'"
              :selected-label-index="'identifier'"
              :items="contacts"
              :loading="isContactSearchLoading"
              :initial-selected-items="cc"
              @on-input-change="handleComposerInputChange"
              @on-select="
                (_contacts) => {
                  cc = _contacts;
                  processDraft();
                }
              "
            />
            <dropdown
              v-else
              v-model="cc"
              label="display_name"
              :multiple="true"
              :options="contacts"
              :remote="true"
              selected-label-field="identifier"
              @update:model-value="processDraft"
              @search="onContactSearch"
            ></dropdown>
          </span>
        </div>
        <div
          v-show="showBcc"
          class="align-items flex flex-shrink-0 items-center border-b-2 border-grey-200"
          style="min-height: 35px"
        >
          <label class="m-0 px-3 py-2 pr-2 text-grey-500" style="font-size: 14px">
            {{ $t('tickets.composer_bcc') }}
          </label>
          <span class="flex-1 text-ellipsis">
            <composer-dropdown
              v-if="isNewToCcBccUxEnabled"
              :allow-multiple="true"
              :item-label-index="'display_name'"
              :selected-label-index="'identifier'"
              :items="contacts"
              :loading="isContactSearchLoading"
              :initial-selected-items="bcc"
              @on-input-change="handleComposerInputChange"
              @on-select="
                (_contacts) => {
                  bcc = _contacts;
                  processDraft();
                }
              "
            />
            <dropdown
              v-else
              v-model="bcc"
              label="display_name"
              :multiple="true"
              :options="contacts"
              :remote="true"
              selected-label-field="identifier"
              @update:model-value="processDraft"
              @search="onContactSearch"
            ></dropdown>
          </span>
        </div>
        <div v-show="isEmail" class="align-items flex flex-shrink-0 items-center border-b-2 border-grey-200">
          <label for="InputEmailSubject" class="m-0 px-3 py-2 pr-2 text-grey-500" style="font-size: 14px">
            {{ $t('tickets.composer_subject') }}:
          </label>
          <input
            id="InputEmailSubject"
            v-model="subject"
            type="text"
            data-hj-suppress
            autocomplete="off"
            class="w-full"
            style="border: none; background: none"
            @change="processDraft(true)"
          />
        </div>
        <div class="flex flex-1 flex-col">
          <div>
            <div v-if="isWhatsappBusiness && supportsWhatsappTemplateMessages" class="px-3 pb-2 pt-3">
              <div class="flex">
                <div class="flex-1">
                  <label class="block">
                    <input
                      v-model="wa_business_message_type"
                      type="radio"
                      :value="WHATSAPP_BUSINESS_MESSAGE_TYPE.SESSION_MESSAGE"
                      data-test="composer-default-message-radio-btn"
                    />
                    {{ sessionMessageTitle }}
                    <br />
                  </label>
                  <label class="block">
                    <input
                      v-model="wa_business_message_type"
                      type="radio"
                      :value="WHATSAPP_BUSINESS_MESSAGE_TYPE.TEMPLATE_MESSAGE"
                      data-test="composer-template-message-radio-btn"
                    />
                    {{ $t('tickets.wa_message_type_template_title') }}
                    <br />
                  </label>
                </div>
              </div>
            </div>
            <template v-if="emailChatDeliveryMode">
              <span class="flex items-center border-b-2 border-grey-200 px-3 py-3 text-xs text-grey-600">
                <div class="flex items-center">
                  <i class="material-icons text-md mr-2">email</i>
                  {{ $t('tickets.delivery_via_email') }} {{ ticket.contact.email }}
                </div>
              </span>
            </template>
          </div>
          <froala
            v-if="isEmail"
            ref="emailEditor"
            v-model="message"
            has-padding-x
            has-padding-y
            data-hj-suppress
            :image="true"
            :border="false"
            image-prefix="inline_image"
            cols="30"
            :focus="true"
            rows="10"
            :dir="readingDirection"
            @file-paste="onFilePaste"
            @change="processDraft(true)"
            @drop="onImageDrop"
            @blur="onFroalaBlur"
            @focus="shouldInsertQuickReplyAtStart = false"
          >
            <translation-revert-button
              v-if="!newTicket"
              class="top-[calc(100%-86px)]"
              :message="message"
              @revert="setTranslation"
            />
          </froala>
          <div v-if="shouldSendWaMessage" class="relative">
            <content-editable
              id="publicComposer"
              ref="messageEditor"
              v-model="message"
              :file-paste="true"
              :shift-enter="!newTicket ? true : undefined"
              :multiline="newTicket || chatMailMode"
              :style="contentEditableStyle"
              max-height="50vh"
              class="message-editor my-3 flex-1 overflow-auto px-3"
              :placeholder="$t('tickets.msg_placeholder')"
              :allow-formatting="true"
              :allow-link-formatting="isWebChat"
              :is-loading="isDraftLoading"
              @change="processDraft(true)"
              @file-paste="onFilePaste"
              @enter="onEnter"
              @input="contentEditableReplaceQRShortcuts"
              @blur="onContentEditableBlur"
            />
            <translation-revert-button
              v-if="!newTicket"
              class="left-4 top-[calc(100%-1rem)]"
              :message="message"
              @revert="setTranslation"
            />
          </div>
          <empty-msg
            v-if="shouldShowEmptyFallbackMsg"
            :show-button="isAdmin"
            @handle-click-action="redirectToWaChannelSetting"
          >
            <template #title>
              {{ $t('tickets.no_fallback_sms_set') }}
            </template>
            <template v-if="isAdmin" #message>
              {{ $t('tickets.select_fallback_sms') }}
            </template>
            <template v-else #message>
              {{ $t('tickets.fallback_get_in_contact_with_admin') }}
            </template>
            <template v-if="isAdmin" #action>
              {{ $t('tickets.set_falllback_sms') }}
            </template>
          </empty-msg>
          <div v-if="isComposingWhatsappTemplateMessage" class="px-3 pb-3">
            <straight v-if="isWaTemplatesLoading" :width="157" :height="12" />
            <empty-msg
              v-else-if="showEmptyTemplateMsg"
              :show-button="isAdmin"
              @handle-click-action="redirectToCreateWaTemplates"
            >
              <template #title>
                {{ $t('tickets.no_template_yet') }}
              </template>
              <template v-if="isAdmin" #message>
                {{ $t('tickets.create_message_template') }}
              </template>
              <template v-else #message>
                {{ $t('tickets.template_get_in_contact_with_admin') }}
              </template>
              <template v-if="isAdmin" #action>
                {{ $t('tickets.create_new_template') }}
              </template>
            </empty-msg>
            <div v-else>
              <wa-template-dropdown
                :wa-template="wa_template"
                :wa-template-messages="wa_template_messages"
                @set-whatsapp-template="setWaTemplate"
                @re-fetch-whatsapp-templates="getWaTemplates(false)"
              />
              <div class="mt-4">
                <wa-template-message
                  :body="wa_parsed_message"
                  :header="waTemplateMessageComponents.header"
                  :footer="waTemplateMessageComponents.footer"
                  :selected-file="selectedWaTemplateHeaderFile"
                  :force-file-validation="shouldValidateWaTemplateHeaderFile"
                  :composer-instance-id="composerInstanceId"
                  @handle-file-change="updateWaTemplateFile"
                  @reset="resetWaTemplateFile"
                />
                <div class="row">
                  <div v-for="(param, i) in waTemplateBodyParams" :key="i" class="col-md-3">
                    <message-input
                      :placeholder="param.key"
                      :input-val="param.value"
                      :is-error="isEmptyWaTemplateVariable(param)"
                      :show-error-msg="isEmptyWaTemplateVariable(param)"
                      input-size="sm"
                      input-class="mt-2"
                      error-item-class="mt-2 mb-1"
                      error-msg="Please fill this field"
                      @use-input-value="updateWaTemplateVariables($event, i)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="composer-footer mt-auto flex min-h-max flex-wrap p-4">
          <div
            v-if="!isComposingWhatsappTemplateMessage"
            class="composer-footer-attachments-container flex flex-nowrap gap-1"
          >
            <transition name="fade" mode="out-in">
              <template v-if="!suggestedReplyStore.isFeedbackActive">
                <div
                  v-if="suggestedReplyStore.isFeatureEnabled && !newTicket && !shouldShowEmptyFallbackMsg"
                  class="flex items-center gap-x-2"
                >
                  <suggested-reply-c-t-a :ticket-id="ticket?.id" />
                </div>
              </template>
              <feature-feedback v-else :ticket-id="ticket?.id" />
            </transition>
            <div
              :class="{ 'mt-1': suggestedReplyStore.isFeatureEnabled && suggestedReplyStore.isActive }"
              class="selected-attachments composer-footer-attachments"
            >
              <div
                v-for="(attachment, index) in attachments"
                :key="attachment.full_url"
                class="email-form-attachment m-b-xs"
              >
                <span class="label success">{{ attachment.extension }}</span>
                <span class="attachment-name whitespace-nowrap">{{ attachment.client_name }}</span>
                <small class="text-muted mx-2 whitespace-nowrap">{{ attachment.size }}</small>
                <span class="text-muted ml-auto whitespace-nowrap">
                  <i v-show="attachment.uploading" class="fa fa-spin fa-spinner mr-1"></i>
                  <a v-show="!attachment.uploading" :href="safeUrl(attachment.full_url)" target="_blank" rel="noopener">
                    <i class="fa fa-download"></i>
                  </a>
                  <i
                    v-show="!attachment.uploading"
                    class="fa fa-remove ml-1"
                    style="cursor: pointer"
                    @click="removeAttachment(attachment, index)"
                  ></i>
                </span>
              </div>
            </div>
            <translation-loader />
          </div>
          <div class="ml-auto flex min-w-max flex-wrap items-center gap-2">
            <transition name="fade">
              <translation-option
                v-if="!isComposingWhatsappTemplateMessage"
                :message="message"
                :ticket-id="ticket?.id"
                @new-translation="setTranslation"
              />
            </transition>
            <t-icon-button v-if="showDeleteAndQuickReplies" size="lg">
              <dropdown
                ref="dropdown_quick_replies"
                :options="quickReplies"
                label="title"
                placement="top"
                :active-state="false"
                width="350px"
                @selected="insertQuickReply"
                @open="handleQuickRepliesDropdownOpen"
                @close="handleQuickRepliesDropdownClose"
              >
                <template #heading>
                  <div class="_500 pt-4 text-center text-base leading-none text-black">
                    {{ $t('tickets.insert_quick_reply') }}
                  </div>
                </template>
                <template #toggle>
                  <i
                    ref="quick_replies_icon"
                    v-tooltip="{
                      placement: 'top',
                      content: $t('tickets.insert_quick_reply'),
                      delay: { show: 500, hide: 0 },
                    }"
                    role="button"
                    class="material-icons selector-conversation-quick-reply cursor-pointer text-grey-600"
                    @click="triggerQuickRepliesDropdown"
                  >
                    format_quote
                  </i>
                </template>
                <template #option="prop">
                  <span class="font-bold">{{ prop.option?.title }}</span>
                  <br />
                  <span>{{ stripHtml(prop.option?.message) }}</span>
                </template>
              </dropdown>
            </t-icon-button>
            <t-icon-button v-show="isWebChat" size="lg" class="mt-1.5">
              <i
                v-tooltip="{
                  placement: 'top',
                  content: $t('tickets.insert_gif'),
                  delay: { show: 500, hide: 0 },
                }"
                class="material-icons cursor-pointer text-grey-600"
                @click="toggleGifPicker($event)"
              >
                gif
              </i>
              <div v-if="showGifPicker" ref="gifContainer" v-click-away="showGifPicker = false" class="gif-container">
                <gif-picker @insert-gif="sendGif" @close="showGifPicker = false"></gif-picker>
              </div>
            </t-icon-button>
            <t-icon-button v-show="showEmojiSelector" ref="emojiSelector" size="lg" class="hidden-md-down mt-1.5">
              <i
                v-tooltip="{
                  placement: 'top',
                  content: $t('tickets.insert_emoji'),
                  delay: { show: 500, hide: 0 },
                }"
                class="material-icons selector-conversation-gif cursor-pointer text-grey-600 hover:text-grey-600"
                role="button"
                @click="toggleEmojiPicker($event)"
              >
                insert_emoticon
              </i>
              <div
                v-if="showEmojiPicker"
                ref="emojiContainer"
                v-click-away="toggleEmojiPicker"
                class="absolute bottom-12"
              >
                <emoji-picker @insert-emoji="insertEmoji" @hide-emoji-picker="toggleEmojiPicker"></emoji-picker>
              </div>
            </t-icon-button>
            <t-icon-button v-show="attachmentsEnabled" size="lg">
              <attachment-selector @attachment="addAttachment" />
            </t-icon-button>
            <t-icon-button v-if="showDeleteAndQuickReplies" size="lg" class="flex justify-center">
              <i
                v-show="draft.exists"
                v-tooltip="{
                  placement: 'top',
                  content: $t('tickets.delete_draft'),
                  delay: { show: 500, hide: 0 },
                }"
                class="material-icons cursor-pointer text-grey-600"
                @click="deleteDraft(true)"
              >
                delete_forever
              </i>
              <i v-show="!draft.exists" disabled="disabled" class="material-icons text-grey-500">delete_forever</i>
            </t-icon-button>

            <ticket-result-modal
              :is-open="isResultModalOpen"
              @select-result="selectTicketResult"
              @close="resetMessageSendingState"
            />

            <div
              class="composer-send-button btn-group flex min-w-max cursor-pointer whitespace-nowrap shadow"
              style="margin-left: 5px; border-radius: 9999px"
            >
              <button
                v-if="!defaultClose"
                :disabled="!isValid"
                type="button"
                class="btn btn-md composer-send-button-left cursor-pointer bg-grey-900 text-white hover:bg-grey-800 active:bg-grey-700"
                :class="{ loader: sending }"
                data-test="composer-close-button"
                @click="sendMessage(false)"
              >
                <i class="material-icons">send</i>
                {{ computedSendText }}
              </button>
              <button
                v-if="defaultClose"
                :disabled="!isValid"
                type="button"
                class="btn btn-md composer-send-button-left cursor-pointer bg-grey-900 text-white hover:bg-grey-800 active:bg-grey-700"
                :class="{ loader: sending }"
                data-test="composer-default-close-button"
                @click="sendMessage(true)"
              >
                <i class="material-icons">send</i>
                {{ $t('tickets.action_send_and_close') }}
              </button>
              <button
                type="button"
                :disabled="!isValid"
                class="btn btn-md composer-send-button-right dropdown-toggle-split cursor-pointer bg-grey-900 text-white hover:bg-grey-800 active:bg-grey-700"
                data-toggle="dropdown"
                data-test="composer-toggle-send-options-dropdown-button"
              >
                <i class="material-icons" style="font-size: 1.2rem; margin-left: -5px">keyboard_arrow_down</i>
                <span class="sr-only">Toggle Dropdown</span>
              </button>
              <div
                class="dropdown-menu dropdown-menu-right cursor-pointer"
                style="transform-origin: bottom left; top: auto; bottom: 100%; margin-bottom: 2px; z-index: 9999"
              >
                <a class="dropdown-item" data-test="composer-send-dropdown-option" @click="sendMessage(false)">
                  {{ $t('tickets.action_send') }}
                </a>
                <a class="dropdown-item" data-test="composer-send-and-close-dropdown-option" @click="sendMessage(true)">
                  {{ $t('tickets.action_send_and_close') }}
                </a>
                <a
                  v-show="canSendLater"
                  class="dropdown-item"
                  data-test="composer-send-later-dropdown-option"
                  @click="sendMessage(false, true)"
                >
                  {{ $t('tickets.action_send_later') }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import { debounce, isEmpty, map, throttle, toArray } from 'lodash';
import moment from 'moment';
import { mapState, mapStores } from 'pinia';
import { defineComponent } from 'vue';
import { mixin as VueClickAway } from 'vue3-click-away';

import { ENDPOINT } from '@/api/constants';
import { sendConsolidatedMessage } from '@/api/modules/messages';
import { fetchWaTemplates } from '@/api/modules/waTemplates';
import Straight from '@/components/Channels/CreateChannelModals/WhatsApp/SkeletonLoader/Straight.vue';
import ComposerDropdown from '@/components/ReplyForm/ComposerDropdown.vue';
import FeatureFeedback from '@/components/ReplyForm/FeatureFeedback/FeatureFeedback.vue';
import TicketResultModal from '@/components/ReplyForm/TicketResultModal.vue';
import { CHANNEL_TYPE, FEATURE_FLAG_INBOX, FEATURE_FLAG_VOLUME, MESSAGE_TYPE, PERMISSION } from '@/Configs/Constants';
import { STATUS_CODE } from '@/Configs/Constants/StatusCodes';
import {
  TEMPLATE_HEADER_TYPE,
  TEMPLATE_PARAM_TYPE,
  WA_TEMPLATE_COMPONENT_TYPE,
  WHATSAPP_BUSINESS_MESSAGE_TYPE,
} from '@/Configs/Constants/whatsapp';
import eventBus from '@/eventBus';
import { waitForEvent } from '@/start/util';
import { useFeatureFlagStore, useUserStore } from '@/store/pinia';
import { animateMessageUpdate } from '@/util/animateMessageUpdate';
import { flashError } from '@/util/flashNotification';
import { getDirectionByLocale } from '@/util/languageDirection';
import { retryRequestIfResponseMatchesStatusCode } from '@/util/request';
import { getQrRegex } from '@/util/stringHelpers';

import { sendWaTemplateMessage } from './api';
import Dropdown from './Dropdown.vue';
import EmptyMsg from './EmptyMsg';
import Froala from './Froala.vue';
import GifPicker from './GifPicker.vue';
import MessageInput from './MessageInput.vue';
import attachmentSelector from './MultipleAttachmentSelector.vue';
import SuggestedReplyCTA from './SuggestedReply/SuggestedReplyCTA.vue';
import { useSuggestedReplyStore } from './SuggestedReply/useSuggestedReplyStore';
import TranslationLoader from './TranslationOption/TranslationLoader.vue';
import TranslationOption from './TranslationOption/TranslationOption.vue';
import TranslationRevertButton from './TranslationOption/TranslationRevertButton.vue';
import { useOutboundTranslationStore } from './TranslationOption/useOutboundTranslationStore';
import { convertHTMLToText, saveFile, stripHtmlToString } from './utils';
import WaTemplateMessage from './WaTemplateMessage';
import contactRepository from '../../repositories/Contact';
import ticketRepository from '../../repositories/Ticket';
import ContentEditable from '../ContentEditable.vue';
import EmojiPicker from '../Elements/EmojiPicker.vue';
import WaTemplateDropdown from './WaTemplateDropdown/WaTemplateDropdown.vue';

import type { ConsolidatedMessageReturnPayload } from '@/api/modules/messages';
import type {
  BasePayload,
  ConsolidatedComposerSendPayload,
  FilteredWhatsappTemplate,
  Recipient,
} from '@/components/ReplyForm/types/composer';
import type { TemplateComponent } from '@/components/WaTemplates/types';
import type { Channel } from '@/store/pinia/channels/types';
import type { File } from '@/types';
import type { Contact } from '@/types/contact';
import type { Template } from '@/types/waTemplates';

const DEFAULT_WHATSAPP_BUSINESS_MESSAGE_TYPE = WHATSAPP_BUSINESS_MESSAGE_TYPE.SESSION_MESSAGE;

export default defineComponent({
  name: 'Composer',

  components: {
    ContentEditable,
    ComposerDropdown,
    Straight,
    TicketResultModal,
    attachmentSelector,
    Dropdown,
    Froala,
    EmojiPicker,
    GifPicker,
    MessageInput,
    EmptyMsg,
    WaTemplateMessage,
    SuggestedReplyCTA,
    FeatureFeedback,
    TranslationOption,
    TranslationLoader,
    TranslationRevertButton,
    WaTemplateDropdown,
  },

  mixins: [VueClickAway],
  props: {
    ticket: {
      type: Object as () => Record<string, unknown> | null,
      default: null,
    },
    newTicket: {
      type: Boolean,
      default: false,
    },
    popout: {
      type: Boolean,
      default: false,
    },
    path: {
      type: String,
      default: '',
    },
    defaultMessage: {
      type: String,
      default: '',
    },
    defaultSubject: {
      type: String,
      default: '',
    },
    defaultContact: {
      type: Object as () => Record<string, unknown> | null,
      default: null,
    },
    emailOnly: {
      type: Boolean,
      default: false,
    },
    forwarding: {
      type: Boolean,
      default: false,
    },
    defaultChannel: {
      type: Object as () => Record<string, unknown> | null,
      default: null,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    defaultClose: {
      type: Boolean,
      default: false,
    },
    forwardTicketId: {
      type: [Number, String] as () => number | string | null,
      default: null,
    },
    forwardMessageId: {
      type: [Number, String] as () => number | string | null,
      default: null,
    },
    draftKey: {
      type: String,
      default: null,
    },
    publicProvider: {
      type: String,
      default: null,
    },
    replyTo: {
      type: Object as () => Record<string, unknown> | null,
      default: null,
    },
    messages: {
      type: Array as () => Array<Record<string, unknown>>,
      default: () => [],
    },
    contactIsOnline: {
      type: Boolean,
      default: false,
    },
    hasAccessToChannel: {
      type: Boolean,
      default: false,
    },
    isLatestInbound: {
      type: Boolean,
      default: false,
    },
    composerInstanceId: {
      // This is used to identify the instance of the composer
      type: String,
      default: '',
    },
  },
  emits: [
    'popout',
    'draftLoaded',
    'focusEditor',
    'sent',
    'appendMessage',
    'handle-consolidated-message-endpoint-closed-ticket',
    'update-send-and-close-ticket-status',
  ],

  data() {
    return {
      isContactSearchLoading: false,
      waTemplateSelectedIndex: -1,
      isWaTemplatesLoading: false,
      openTemplateDropdown: false,
      showBcc: false,
      showCc: false,
      channel: {},
      channelInfo: {},
      subject: '',
      attachments: [],
      showEmojiPicker: false,
      showGifPicker: false,
      contacts: [],
      prevChannel: [],
      quickReplies: [],

      contact: {},
      cc: [] as Contact[],
      bcc: [] as Contact[],

      dragenter: false,
      sending: false,
      signature: '',
      message: '',
      typingTimer: null,
      typing: false,
      draftTimer: null,
      readingDirection: 'ltr',

      ready: false,
      draft: {},
      dirty: false,

      pendingUploads: 0,
      externalWindowInstance: null,

      selection: null,

      wa_business_message_type: DEFAULT_WHATSAPP_BUSINESS_MESSAGE_TYPE,
      wa_template_messages: [] as Template[],
      wa_template: {} as Template,
      waTemplateBodyParams: [],
      waTemplateHeaderParam: null,
      selectedWaTemplateHeaderFile: [],
      shouldValidateWaTemplateHeaderFile: false,
      isTemplateWithHeaderMedia: false,
      isTemplateWithHeaderImage: false,
      isTemplateWithHeaderVideo: false,
      wa_template_search: '',

      contentEditableSelRange: null,
      quickReplyOpenDropdown: false,
      shouldInsertQuickReplyAtStart: true,
      isValidTemplateVariable: true,
      ticketResultId: -1,
      shouldCloseAfterSend: false,
      isResultModalOpen: false,
      // Track whether the message should be sent later or not in the new message flow
      shouldSendLater: false,

      CHANNEL_TYPE,
      PERMISSION,
      WHATSAPP_BUSINESS_MESSAGE_TYPE,
      isDraftLoading: false,
    };
  },

  computed: {
    ...mapStores(useSuggestedReplyStore, useUserStore, useOutboundTranslationStore, useFeatureFlagStore),
    ...mapState(useSuggestedReplyStore, ['suggestedReply']),
    ...mapState(useOutboundTranslationStore, ['translation', 'status']),

    composerGreyBorderClass() {
      return !this.newTicket && !this.isInternalCommentRedesignEnabled ? 'composer__grey-border' : '';
    },

    emailChatDeliveryMode() {
      return this.isWebChat && this.chatMailMode;
    },

    isInternalCommentRedesignEnabled() {
      return this.featureFlagStore.isEnabled(FEATURE_FLAG_INBOX.INTERNAL_COMMENT_TABS);
    },

    isConsolidatedMessageEndpointEnabled() {
      return this.featureFlagStore.isEnabled(FEATURE_FLAG_INBOX.CONSOLIDATED_MESSAGE_ENDPOINT_ENABLED);
    },

    isNewToCcBccUxEnabled() {
      return this.featureFlagStore.isEnabled(FEATURE_FLAG_VOLUME.TVE_NEW_TO_CC_BCC_UX);
    },

    waMessageOutsideWindow() {
      const isNotWaBussiness = this.channelInfo.type !== CHANNEL_TYPE.WA_BUSINESS;

      if (this.newTicket || !this.channelInfo || isNotWaBussiness) {
        return false;
      }

      const now = moment().tz(window.APP_TIMEZONE);
      return (
        this.messages.filter((m) => {
          const createdAt = moment.tz(m.created_at, window.APP_TIMEZONE);
          const diffInHours = now.diff(createdAt, 'hours');
          return m.type === MESSAGE_TYPE.INBOUND && diffInHours < 24;
        }).length === 0
      );
    },

    isComposingWhatsappTemplateMessage() {
      return (
        this.channelInfo?.type === CHANNEL_TYPE.WA_BUSINESS &&
        this.wa_business_message_type === WHATSAPP_BUSINESS_MESSAGE_TYPE.TEMPLATE_MESSAGE
      );
    },

    supportsWhatsappTemplateMessages() {
      const isTwilio = this.channelInfo?.whatsappChannel?.provider === 'twilio';

      return this.channelInfo || this.channelInfo.type === CHANNEL_TYPE.WA_BUSINESS || isTwilio;
    },

    canPopout() {
      return (
        this.isEmail && !this.popout && !window.is_mobile_device() && !window.isLoadedFromApp && !window.isElectron
      );
    },

    localStorageChannelKey() {
      if (this.forwarding) {
        return 'ticket_channel.forwarding';
      }

      return 'ticket_channel';
    },

    channels() {
      let supported = [];

      if (this.newTicket) {
        supported = [
          CHANNEL_TYPE.VOIP,
          CHANNEL_TYPE.SMS,
          CHANNEL_TYPE.EMAIL,
          CHANNEL_TYPE.WHATSAPP,
          CHANNEL_TYPE.TELEGRAM,
          CHANNEL_TYPE.WA_BUSINESS,
        ];
      }

      if (this.forwarding) {
        supported = [CHANNEL_TYPE.EMAIL];
      }

      return this.$root.channels.filter((channel) => {
        return supported.indexOf(channel.type) !== -1;
      });
    },

    // Determines whether a chat message should be delivered via email
    shouldDeliverViaEmailInChat() {
      if (this.newTicket || this.ticket.channel.type !== CHANNEL_TYPE.CHAT) {
        return false;
      }

      return !!this.ticket.contact.email && !this.contactIsOnline;
    },

    chatMailMode() {
      if (this.newTicket) {
        return false;
      }

      return (
        this.ticket.channel.type === CHANNEL_TYPE.CHAT &&
        this.ticket.contact.email != null &&
        this.contactIsOnline === false
      );
    },

    attachmentsEnabled() {
      if (
        this.channel.type === CHANNEL_TYPE.SMS ||
        this.channel.type === CHANNEL_TYPE.ZIPWHIP ||
        this.publicProvider === CHANNEL_TYPE.FACEBOOK ||
        this.publicProvider === CHANNEL_TYPE.INSTAGRAM
      ) {
        return false;
      }

      if (this.isComposingWhatsappTemplateMessage) {
        return false;
      }

      if (this.shouldShowEmptyFallbackMsg) {
        return false;
      }

      return true;
    },

    replyingTo() {
      if (isEmpty(this.replyTo)) {
        return {
          type: 'post',
          full_name: this.ticket.contact.full_name,
        };
      }
      return this.replyTo;
    },

    wa_parsed_message() {
      if (!this.wa_template) {
        return;
      }
      let msg = this.wa_template.message;
      for (const p of this.waTemplateBodyParams) {
        if (p.value && p.value.length) {
          msg = msg.replace(p.key, p.value);
        }
      }
      return msg;
    },

    isTypingChannelType() {
      return [CHANNEL_TYPE.CHAT, CHANNEL_TYPE.GBM].includes(this.channel.type);
    },
    hasEmptyTemplateVariable() {
      return (
        this.isComposingWhatsappTemplateMessage &&
        this.waTemplateBodyParams.some((param: { key: string; value: string }) => !param.value.trim())
      );
    },
    showEmptyTemplateMsg() {
      return this.wa_template_messages.length === 0;
    },
    fallbackSmsChannel() {
      return this.channelInfo?.whatsappChannel?.sms_channel_id ? true : false;
    },
    isAdmin() {
      return this.userStore.hasPermission(PERMISSION.SETTINGS__CHANNELS__MANAGE);
    },
    shouldSendWaMessage() {
      const within24hrWindowConditions =
        !this.waMessageOutsideWindow && !this.isEmail && !this.isComposingWhatsappTemplateMessage;
      const outside24hrWindowConditions =
        this.waMessageOutsideWindow &&
        this.fallbackSmsChannel &&
        !this.isEmail &&
        !this.isComposingWhatsappTemplateMessage;
      return within24hrWindowConditions || outside24hrWindowConditions;
    },
    shouldShowEmptyFallbackMsg() {
      const isDefaultMessage = this.wa_business_message_type === DEFAULT_WHATSAPP_BUSINESS_MESSAGE_TYPE;
      return this.waMessageOutsideWindow && isDefaultMessage && !this.fallbackSmsChannel;
    },
    sessionMessageTitle() {
      return this.waMessageOutsideWindow
        ? this.$t('tickets.sms_fall_back')
        : this.$t('tickets.wa_message_type_session_title');
    },
    computedSendText() {
      const isWhatsApp = this.isWhatsappBusiness;
      const isSessionMessage = this.wa_business_message_type === WHATSAPP_BUSINESS_MESSAGE_TYPE.SESSION_MESSAGE;
      const isFallbackSms = isWhatsApp && this.waMessageOutsideWindow && isSessionMessage;

      if (isFallbackSms) {
        return this.$t('tickets.send_sms');
      } else if (this.isComposingWhatsappTemplateMessage) {
        return this.$t('tickets.send_template');
      } else {
        return this.$t('tickets.composer_send');
      }
    },
    showDeleteAndQuickReplies() {
      return !this.isComposingWhatsappTemplateMessage && !this.shouldShowEmptyFallbackMsg;
    },
    showEmojiSelector() {
      return !this.isEmail && !this.isComposingWhatsappTemplateMessage && !this.shouldShowEmptyFallbackMsg;
    },
    waTemplateMessageComponents() {
      const component = {
        header: null,
        footer: null,
      };

      this.wa_template.components.forEach((item) => {
        if (item.type === WA_TEMPLATE_COMPONENT_TYPE.HEADER) {
          component.header = item;
        }
        if (item.type === WA_TEMPLATE_COMPONENT_TYPE.FOOTER) {
          component.footer = item.value;
        }
      });

      return component;
    },
    contentEditableStyle() {
      if (this.newTicket) {
        return { 'min-height': '250px' };
      }
      const { WA_BUSINESS, WHATSAPP } = CHANNEL_TYPE;
      if ([WA_BUSINESS, WHATSAPP].includes(this.channel.type)) {
        return { 'min-height': '106px' };
      }
      return { 'min-height': '82px' };
    },
    hasNotSelectedHeaderFile() {
      return (
        this.isComposingWhatsappTemplateMessage &&
        this.isTemplateWithHeaderMedia &&
        !this.selectedWaTemplateHeaderFile.length
      );
    },

    isEmail() {
      return this.channel.type === CHANNEL_TYPE.EMAIL;
    },
    isCustom() {
      return this.channel.type === CHANNEL_TYPE.CUSTOM;
    },
    isWebChat() {
      return this.channel.type === CHANNEL_TYPE.CHAT;
    },
    isWhatsappBusiness() {
      return this.channel.type === CHANNEL_TYPE.WA_BUSINESS;
    },
    contactFallback() {
      const noDefaultContact = this.defaultContact && (!this.defaultContact.identifier || !this.defaultChannel);
      if (!this.channel || noDefaultContact) return [];

      const contact = this.defaultContact?.identifier ? this.defaultContact : this.ticket?.contact;

      if (!contact) return [];
      return [
        {
          id: contact.id,
          identifier: contact.identifier,
          text: contact.full_name,
          full_name: contact.full_name,
        },
      ];
    },
    draftParams() {
      return {
        ticket_id: this.newTicket ? '' : this.ticket.id,
        channel_id: this.channel.id,
        forwarding: this.forwarding ? 1 : 0,
        forward_ticket_id: this.forwardTicketId != null ? this.forwardTicketId : '',
        forward_message_id: this.forwardMessageId != null ? this.forwardMessageId : '',
        key: this.draftKey,
      };
    },
    canSendLater() {
      return (
        !this.forwarding &&
        this.newTicket &&
        this.channel != null &&
        this.channel.type !== CHANNEL_TYPE.VOIP &&
        !this.isComposingWhatsappTemplateMessage
      );
    },
    selectionMarkerElement() {
      return '<span class="fr-marker" data-id="0" data-type="false" style="display: none; line-height: 0;">&ZeroWidthSpace;</span><span class="fr-marker" data-id="0" data-type="true" style="display: none; line-height: 0;">&ZeroWidthSpace;</span>';
    },
    isValid() {
      if (this.pendingUploads > 0) {
        return;
      }

      if (this.isEmail || this.newTicket) {
        if (!this.contact[0] || (!this.contact[0].id && !this.contact[0].identifier)) {
          return false;
        }
      }

      if (this.isComposingWhatsappTemplateMessage) {
        return this.wa_template != null; // todo
      }

      if (this.shouldShowEmptyFallbackMsg) {
        return;
      }

      return this.message != '' || this.attachments.length > 0;
    },
  },

  watch: {
    'channel.id': async function (newId: string) {
      await this.suggestedReplyStore?.getIsSuggestedReplyAvailable(newId);
      await this.inboundTranslationStore?.setChannelSupportStatus(this.ticket.id);
    },

    'ticket.id': async function () {
      await this.inboundTranslationStore?.setChannelSupportStatus(this.ticket.id);
      this.message = this.useOutboundTranslationStore.prompt;
      this.suggestedReplyStore.isFeedbackActive = false; // TODO should be linked to an existing generated reply
    },

    async suggestedReply(newMessage: string) {
      await this.updateMessage(newMessage);
      this.suggestedReplyStore.isFeedbackActive = true;
    },

    showCc(v) {
      if (!v) {
        this.cc = [];
        this.processDraft(true);
      }
    },

    showBcc(v) {
      if (!v) {
        this.bcc = [];
        this.processDraft(true);
      }
    },

    wa_business_message_type(v) {
      if (v === WHATSAPP_BUSINESS_MESSAGE_TYPE.TEMPLATE_MESSAGE) {
        this.getWaTemplates();
      }
    },

    async wa_template(template) {
      this.resetWaTemplate();

      if (!template) return;

      const templateBodyParams: string[] = template?.message.match(/(\{\{[0-9]+)\}\}/g) || [];
      this.isTemplateWithHeaderImage = template?.components?.some(
        (item: TemplateComponent) => item.sub_type === TEMPLATE_HEADER_TYPE.IMAGE.toUpperCase(),
      );
      this.isTemplateWithHeaderVideo = template?.components?.some(
        (item: TemplateComponent) => item.sub_type === TEMPLATE_HEADER_TYPE.VIDEO.toUpperCase(),
      );
      this.isTemplateWithHeaderMedia = this.isTemplateWithHeaderImage || this.isTemplateWithHeaderVideo;
      await this.$nextTick();

      this.waTemplateBodyParams = templateBodyParams.map((key) => ({ key, value: '' }));

      if (this.isTemplateWithHeaderMedia) {
        const headerComponent = template.components?.find(
          (item: TemplateComponent) => item.type === WA_TEMPLATE_COMPONENT_TYPE.HEADER,
        );
        const headerType = headerComponent?.sub_type?.toLowerCase();

        this.waTemplateHeaderParam = {
          key: headerType,
          value: null,
          type: TEMPLATE_PARAM_TYPE.HEADER,
          hasError: false,
        };
      }
    },

    message() {
      if (this.isTypingChannelType) {
        if (this.message == '') {
          this.endTypingIndicator();
        } else {
          this.startTypingIndicator();
        }
      }
    },

    channel(newValue, oldValue) {
      if (oldValue.id != null) {
        this.loadQuickReplies(newValue.id);
        this.$tStorage.setItem(this.localStorageChannelKey, newValue.id);
      }

      const otherType = oldValue.id != null && oldValue.type != newValue.type;

      if (otherType) {
        this.attachments = [];
        this.subject = '';
        this.cc = this.bcc = [];
        this.contact = {};
      }

      this.restoreDraft();

      this.setWaTemplateDefaults();

      this.setChannelInfo();
    },

    defaultMessage() {
      this.message = this.defaultMessage;
      this.onMessageUpdate();
    },

    defaultChannel() {
      this.message = this.defaultMessage;
      this.onMessageUpdate();
    },

    waMessageOutsideWindow(v) {
      this.wa_business_message_type = v
        ? WHATSAPP_BUSINESS_MESSAGE_TYPE.TEMPLATE_MESSAGE
        : WHATSAPP_BUSINESS_MESSAGE_TYPE.SESSION_MESSAGE;
    },
    shouldCloseAfterSend(newValue) {
      this.$emit('update-send-and-close-ticket-status', newValue);
    },
  },

  unmounted() {
    eventBus.$off('reply_form.focus');
    eventBus.$off('reply_form.blur');
    eventBus.$off('updateTicketChannelInComposer');
    eventBus.$off('appendToComposer');
    document.removeEventListener('selectionchange', this.handleDocumentSelectionChange);
    clearTimeout(this.typingTimer);
  },

  async mounted() {
    this.handleIe11();
    eventBus.$on('reply_form.focus', this.focusOnEditor);
    eventBus.$on('reply_form.blur', this.blurEditor);

    document.addEventListener('selectionchange', this.handleDocumentSelectionChange);

    // wait for the application to login and load all initial data (channels)
    await waitForEvent('initial-data.loaded', this.$store.state.initialData.user.id);

    if (this.defaultSubject != '') {
      this.subject = this.defaultSubject;
    }

    if (this.defaultChannel != null) {
      this.channel = this.defaultChannel;
    }

    if (this.ticket) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      this.$root.channels.filter((c: { id: string }) => {
        return c.id == this.ticket.channel.id;
      })[0];

      this.channel = {
        id: this.ticket.channel.id,
        type: this.ticket.channel.type,
        text: this.ticket.channel.text,
      };

      if (this.ticket.subject != null) {
        this.subject = this.ticket.subject;
      }

      if (this.ticket.contact_cc != null && this.ticket.contact_cc != '') {
        this.cc.identifier = this.ticket.contact_cc;
        this.showCc = true;
      }
    } else {
      if (this.defaultChannel == null) {
        const localStorageChannelKey = await this.$tStorage.getItem(this.localStorageChannelKey);
        if (localStorageChannelKey) {
          const c = this.channels.filter((c) => {
            return c.id === localStorageChannelKey;
          })[0];
          this.channel = c != null ? c : this.channels[0];
        } else {
          this.channel = this.channels[0];
        }
      } else {
        // let def = (this.channels).filter(c => { return c.id == this.defaultChannel; })[0];
        // this.channel = def || this.channels[0];
      }
    }

    eventBus.$on('updateTicketChannelInComposer', this.updateTicketChannelInComposer);
    eventBus.$on('appendToComposer', this.appendToComposer);
    eventBus.$on('contact.updated', (data) => {
      for (let i = 0; i < this.contact.length; i++) {
        if (data.old.identifier === this.contact[i].identifier) {
          this.contact[i].identifier = data.new.identifier;
          this.contact[i].email = data.new.identifier;
          this.processDraft();
        }
      }
    });
    eventBus.$on('froalaKeyDown', () => {
      this.processFroalaContentChange();
    });

    this.initAttachmentDrop();
  },

  methods: {
    /* General methods */
    openInExternalWindow() {
      this.$emit('popout');
      let url = this.newTicket
        ? 'https://' + window.location.hostname + '/composer'
        : 'https://' + window.location.hostname + '/composer/' + this.ticket.id;

      if (this.forwarding) {
        url += '?forward=true&ticket_id=' + this.forwardTicketId;
        if (this.forwardMessageId) {
          url += '&message_id=' + this.forwardMessageId;
        }
      }

      this.externalWindowInstance = window.PopupCenter(url, 'Composer', 800, 600, false);
      this.externalWindowInstance.isComposerWindow = true;

      this.externalWindowInstance.onbeforeunload = () => {
        this.externalWindowInstance = null;
        setTimeout(() => {
          this.restoreDraft();
        }, 200);
        return null;
      };
    },
    setTranslation(message: string, locale: string) {
      this.message = message;
      this.attemptEditorInnerHTMLUpdate();
      this.readingDirection = getDirectionByLocale(locale);
      this.processDraft();
    },
    openSendAsModal() {
      if (!this.hasAccessToChannel) {
        return;
      }

      eventBus.$emit('modals.send-as.open', {
        ticket: this.ticket,
      });
    },
    handleIe11() {
      if (this.ticket == null || this.ticket.channel == null || !window.isIe11()) {
        return;
      }

      this.$el.querySelector('.composer-container').style.height =
        this.ticket.channel.type == CHANNEL_TYPE.EMAIL ? '400px' : '150px';
    },
    handleDocumentSelectionChange() {
      if (!this.$refs.messageEditor) {
        return;
      }

      const sel = document.getSelection();
      let selRange: Range | undefined;
      switch (true) {
        case !this.selectionIsInEditor(sel) && !this.selectionIsInPicker(sel):
          this.selection = null;
          break;
        case this.selectionIsInEditor(sel):
          selRange = sel?.getRangeAt(0);
          if (sel?.anchorNode?.nodeType === 3) {
            // text
            const addStart = this.sumPreviousSiblingsLength(sel.anchorNode);
            const addEnd = this.sumPreviousSiblingsLength(sel.focusNode);
            this.selection = {
              start: selRange?.startOffset + (addStart <= addEnd ? addStart : addEnd),
              end: selRange?.endOffset + (addStart > addEnd ? addStart : addEnd),
            };
          } else if (sel?.anchorNode?.nodeType === 1) {
            // element
            this.selection = {
              start: this.sumEditorNodesUntil(selRange?.startOffset),
              end: this.sumEditorNodesUntil(selRange?.endOffset),
            };
          }
          break;
      }
    },
    async setChannelInfo() {
      let channel = this.$root.channels?.find((c) => c.id === this.channel.id);
      if (!channel && this.channel.id) {
        try {
          const response = await fetchChannelById(this.channel.id);
          channel = response.data; // Ensure `data` matches the API response format
        } catch (error) {
          console.error('Error fetching channel info:', error);
        }
      }
      this.channelInfo = channel;
    },
    onEnter(e: Event) {
      if (e.shiftKey || this.newTicket) {
        return;
      }

      if (!this.chatMailMode) {
        this.sendMessage();
      }
    },
    buildRecipientList(recipients: { identifier: string; full_name?: string; [key: string]: unknown }[]): Recipient[] {
      return recipients.map((recipient) => {
        return {
          identifier: recipient.identifier,
          name: recipient?.full_name || recipient.identifier.substring(0, recipient.identifier.indexOf('@')),
        };
      });
    },
    onContactSearch: debounce(function (term) {
      const requiresPhone = [CHANNEL_TYPE.VOIP, CHANNEL_TYPE.SMS, CHANNEL_TYPE.WA_BUSINESS];
      contactRepository.fetchByChannel(this.channel.id, term, this.channel.type).then((contacts) => {
        this.contacts = contacts == '' ? [] : contacts;
        if (requiresPhone.indexOf(this.channel.type) !== -1) {
          this.contacts = this.contacts.filter((contact) => contact.is_phone);
        }
      });
    }, 100),
    processContact() {
      return new Promise((resolve, reject) => {
        if (this.contact[0]?.id && this.contact[0].is_private === this.channel.is_private) {
          resolve(this.contact[0].id);
          return;
        }

        retryRequestIfResponseMatchesStatusCode(
          {
            method: 'POST',
            url: `${ENDPOINT.CHANNELS}/${this.channel.id}/contacts`,
            data: {
              identifier: this.contact[0].identifier,
            },
          },
          {
            statusCodes: [STATUS_CODE.NOT_FOUND],
          },
        )
          .then((res) => {
            resolve(res.data.id);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    stripText(string: string) {
      return this.stripHtml(string).replace(/\r\n|\r|\n/g, '<br />');
    },
    stripHtml(string: string) {
      return stripHtmlToString(string);
    },

    insertCaretPositionElement() {
      if (this.isEmail) {
        if (!this.$refs.emailEditor.instance.html.get().includes('<span class="caret-position"></span>')) {
          if (this.shouldInsertQuickReplyAtStart) {
            this.$refs.emailEditor.instance.html.set(
              '<span class="caret-position"></span>' + this.$refs.emailEditor.instance.html.get(),
            );
          } else if (
            /iPhone|iPad|iPod/i.test(navigator.userAgent) ||
            (/^((?!chrome|android).)*safari/i.test(navigator.userAgent) && !window.is_mobile_device())
          ) {
            this.$refs.emailEditor.instance.html.set(
              '<span class="caret-position"></span>' + this.$refs.emailEditor.instance.html.get(),
            );
          } else {
            this.$refs.emailEditor.instance.html.insert('<span class="caret-position"></span>');
          }
        }
        this.message = this.$refs.emailEditor.instance.html.get();
      }
    },
    insertContentEditableStringAtCaret(string: string) {
      this.$refs.messageEditor.insertTextAtCursor(string, 'span');
      this.message = this.$refs.messageEditor.$refs.el.innerHTML;
    },
    triggerDropdownWithShortcode() {
      const matches = Array.from(this.$refs?.emailEditor?.instance?.html?.get()?.trim()?.matchAll(getQrRegex()) || []);
      if (matches?.length) {
        this.$refs.emailEditor.instance.html.insert('<span class="caret-position"></span>');
        this.openQuickRepliesDropdown(this.$refs.dropdown_quick_replies, document.querySelector('.caret-position'));
      }
    },
    contentEditableReplaceQRShortcuts() {
      const matches = Array.from(this.$refs?.messageEditor?.$el?.innerHTML?.trim()?.matchAll(getQrRegex()) || []);
      if (matches?.length) {
        this.$refs.messageEditor.insertTextAtCursor('<span class="caret-position"></span>', 'span');
        this.openQuickRepliesDropdown(this.$refs.dropdown_quick_replies, document.querySelector('.caret-position'));
        this.message = this.$refs.messageEditor.$refs.el.innerHTML;
      }
    },
    sumPreviousSiblingsLength(node, prev = null) {
      let currentNodeLength = 1;
      if (node.innerText) {
        currentNodeLength = node.innerText.length; // nodeType 1
      } else if (node.length) {
        currentNodeLength = node.length; // nodeType 3
      }

      if (node.previousSibling) {
        return this.sumPreviousSiblingsLength(node.previousSibling, prev === null ? 0 : currentNodeLength + prev);
      } else {
        return prev === null ? 0 : currentNodeLength + prev;
      }
    },

    startTypingIndicator() {
      clearTimeout(this.typingTimer);

      if (!this.typing) {
        axios.post('/api/v2/tickets/' + this.ticket.id + '/presence', {
          action: 'typing-started',
        });
        this.typing = true;
      }

      this.typingTimer = setTimeout(() => {
        this.endTypingIndicator();
      }, 5000);
    },
    endTypingIndicator() {
      axios
        .post('/api/v2/tickets/' + this.ticket.id + '/presence', {
          action: 'typing-ended',
        })
        .then(() => {
          this.typing = false;
        });
    },

    /* Editor & composer methods */
    focusOnEditor() {
      if (this.$root.usedShortCut) {
        this.$emit('focusEditor');
        return;
      }

      if (this.ticket && this.ticket.status !== 'ASSIGNED') {
        return;
      }

      if (!this.isEmail) {
        this.$nextTick(() => {
          setTimeout(() => {
            if (!this.$refs.messageEditor) {
              return;
            }

            window.placeCaretAtEnd(this.$refs.messageEditor.$refs.el);
          }, 100);
        });
      }
    },
    blurEditor() {
      if (this.$refs.messageEditor) {
        this.$refs.messageEditor.blur();
      }
    },
    selectionIsInEditor(sel: Selection) {
      return sel && sel.anchorNode && this.$refs.messageEditor.$refs.el.contains(sel.anchorNode);
    },
    selectionIsInPicker(sel: Selection) {
      return sel.anchorNode?.parentElement && this.$refs.emojiSelector?.$el?.contains(sel.anchorNode.parentElement);
    },
    processEditorContentPlaceholders(string: string) {
      const editorContent = this.$refs.messageEditor.$refs.el.innerHTML;
      this.message = editorContent
        .replace('<span class="caret-position"></span>', string)
        .replaceAll('<span></span>', string)
        .replaceAll(getQrRegex(), (match, group1) => group1);

      this.onMessageUpdate();
      window.placeCaretAtEnd(this.$refs.messageEditor.$refs.el);
    },
    processEditorShortcodes(string: string = '') {
      if (this.isEmail) {
        // remove open & closing <p> tag from reply message with only 1 surrounding <p> tag (to prevent caret jumping to end when setting editors html)
        if ((string.match(/<p>/g) || []).length === 1) {
          string = this.stripFroalaWrappingParagraphTags(string);
        }

        // remove /qr and reset caret position
        if (this.$refs.emailEditor.instance.html.get().includes('<span class="caret-position"></span>')) {
          this.processFroalaShortcodes(string);
          // fix for if editor is empty
        } else if (string) {
          this.$refs.emailEditor.instance.html.set(
            string + this.selectionMarkerElement + this.$refs.emailEditor.instance.html.get(),
          );
        }
        return;
      }

      // replace and update messaging editor
      if (this.$refs.messageEditor.$refs.el.innerHTML.includes('<span class="caret-position"></span>')) {
        this.processEditorContentPlaceholders(string);
      }
    },
    attemptEditorInnerHTMLUpdate() {
      if (!this.$refs.messageEditor) return;
      this.$refs.messageEditor.$refs.el.innerHTML = this.message || '';
    },
    sumEditorNodesUntil(index: number) {
      let total = 0;
      for (let i = 0; i < index && i < this.$refs.messageEditor.$refs.el.childNodes.length; i++) {
        const node = this.$refs.messageEditor.$refs.el.childNodes[i];

        let currentNodeLength = 1;
        if (node.innerText) {
          currentNodeLength = node.innerText.length; // nodeType 1
        } else if (node.length) {
          currentNodeLength = node.length; // nodeType 3
        }

        total += currentNodeLength;
      }
      return total;
    },

    appendToComposer(data) {
      if (this.message.indexOf(data.message) < 0) {
        this.message += data.message + ' ';
        this.onMessageUpdate();
        this.focusOnEditor();
      }
    },
    async handleComposerInputChange(input: string) {
      if (input.length === 0) {
        this.contacts = [];
        return;
      }
      this.isContactSearchLoading = true;
      try {
        const requiresPhone = [CHANNEL_TYPE.VOIP, CHANNEL_TYPE.SMS, CHANNEL_TYPE.WA_BUSINESS];
        const contacts = await contactRepository.fetchByChannel(this.channel.id, input, this.channel.type);
        this.contacts = contacts == '' ? [] : contacts;
        if (requiresPhone.indexOf(this.channel.type) !== -1) {
          this.contacts = this.contacts.filter((contact: Contact) => contact.is_phone);
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.isContactSearchLoading = false;
      }
    },
    onContentEditableBlur(value) {
      this.message = value;
      this.contentEditableSelRange = this.$refs.messageEditor?.saveSelection();
    },

    /* Message methods */
    processMessage(ticket_id, later) {
      // Make request to HSM endpoint and abort
      if (this.isComposingWhatsappTemplateMessage) {
        return this.sendWaHsm(ticket_id);
      }

      const formData = new FormData();

      if (this.message) {
        let msg = this.message;

        if (this.channel.type !== CHANNEL_TYPE.EMAIL) {
          msg = window.stripHtml(convertHTMLToText(this.message));
        }

        formData.append('message', msg);
      }

      if (this.subject) {
        formData.append('subject', this.subject);
      }

      if (this.bcc && this.showBcc) {
        formData.append('bcc', JSON.stringify(this.bcc));
      }

      if (this.cc) {
        formData.append('cc', JSON.stringify(this.cc));
      }

      if (this.forwarding) {
        if (this.forwardMessageId) {
          formData.append('forwarding_message_id', this.forwardMessageId);
        }
        formData.append('forwarding_ticket_id', this.forwardTicketId);
        formData.append('forwarding', 1);
      }

      if (later) {
        formData.append('send_later', 1);
      }

      formData.append('delivery_method', this.chatMailMode ? 'email' : null);

      if (this.attachments.length > 0) {
        for (let i = 0, len = this.attachments.length; i < len; i++) {
          formData.append('attachment_ids[]', this.attachments[i]['id']);
        }
      }

      const originalMessage = this.message;

      if (!this.isEmail) {
        this.message = '';
        this.onMessageUpdate();
        this.$refs.messageEditor.$refs.el.focus();
      }

      if (this.isWebChat) {
        this.endTypingIndicator();
      }

      if (this.replyTo !== null && this.replyTo.message_id) {
        formData.append('message_id', this.replyTo.message_id);
      }

      // Process multi-contact email
      formData.append('contacts', JSON.stringify(this.contact));

      return new Promise((resolve) => {
        retryRequestIfResponseMatchesStatusCode(
          {
            method: 'POST',
            url: `${ENDPOINT.TICKETS}/${ticket_id}/messages`,
            data: formData,
          },
          { statusCodes: [STATUS_CODE.NOT_FOUND] },
        )
          .then((res) => {
            resolve(res);
            eventBus.$emit('user-reply-conversation');
          })
          .catch(() => {
            this.message = originalMessage;
            this.sending = false;
            this.onMessageUpdate();
          });
      });
    },
    async updateMessage(message?: string, timing?: number) {
      this.suggestedReplyStore.isLoading = true;
      try {
        await animateMessageUpdate(this, message, timing);
      } catch {
        this.suggestedReplyStore.isLoading = false;
      } finally {
        this.suggestedReplyStore.isLoading = false;
      }

      this.attemptEditorInnerHTMLUpdate();
    },
    onMessageUpdate() {
      if (this.isEmail || !this.$refs.messageEditor) {
        return;
      }

      this.attemptEditorInnerHTMLUpdate();
    },
    handleSentMessage(message: ConsolidatedMessageReturnPayload['message'], shouldDeleteDraft: boolean = true) {
      this.$emit('sent', message.ticket_id, this.shouldCloseAfterSend);

      if (!this.newTicket) {
        this.attachments = [];
      }

      if (!this.newTicket) {
        message.is_latest = true;

        if (message?.ticket_id === this?.ticket?.id) {
          this.$emit('appendMessage', message);
        }
      }

      if (shouldDeleteDraft) {
        this.deleteDraft(false, this.isEmail);
      }

      eventBus.$emit('ticket-message-sent');
      if (this.isEmail) {
        eventBus.$emit('ticket-email-sent');
      }
    },
    onMessageSent(message: string, deleteDraft: boolean = true, send_and_close: boolean = false) {
      this.$emit('sent', message.ticket_id, send_and_close);

      this.sending = false;

      if (!this.newTicket) {
        this.attachments = [];
      }

      if (
        !this.newTicket &&
        this.publicProvider !== CHANNEL_TYPE.FACEBOOK &&
        this.publicProvider !== CHANNEL_TYPE.INSTAGRAM
      ) {
        message.is_latest = true;

        if (parseInt(message.ticket_id) === parseInt(this.ticket.id)) {
          this.$emit('appendMessage', message);
        }
      }

      if (deleteDraft) {
        this.deleteDraft(false, this.isEmail);
      }

      eventBus.$emit('ticket-message-sent');

      if (this.isEmail) {
        eventBus.$emit('ticket-email-sent');
      }
      if (this.isWhatsappBusiness) {
        this.resetWaTemplate();
      }
    },
    resetMessageSendingState() {
      this.sending = false;
      this.ticketResultId = -1;
      this.isResultModalOpen = false;
      this.shouldSendLater = false;
      this.shouldCloseAfterSend = false;
    },
    sendMessage(send_and_close: boolean = false, later: boolean = false) {
      this.validateWaTemplateVariables();
      const emptyTemplateVariable = this.hasEmptyTemplateVariable;

      if (this.sending || !this.isValid || emptyTemplateVariable || this.hasNotSelectedHeaderImage) {
        return;
      }

      if (
        this.isEmail &&
        (this.subject == '' || this.subject == null) &&
        !confirm(this.$t('tickets.composer_warning_no_subject'))
      ) {
        return;
      }

      if (this.channel.type == CHANNEL_TYPE.FACEBOOK && this.message.length > 2000) {
        flashError('The length of this message exceeds the Facebook limit of 2000 characters.');
        return;
      }

      // New messaging flow
      if (this.isConsolidatedMessageEndpointEnabled) {
        /**
         * The new flow requires that a result_id be present *before* the message gets sent, whereas
         * the old flow would first send the message, then let you choose the result, and only once
         * a result is chosen does it actually close the ticket.
         *
         * Because of the new flow, we have to keep track of the state of the request, for example
         * whether the user hit the 'send later' button or not. I don't think it's actually possible
         * to 'Send Later & Close', but in any case it simplifies the flow of logic here if we can
         * use this piece of state to determine the `send_later` param inside the request body.
         */
        this.shouldSendLater = later;
        this.shouldCloseAfterSend = send_and_close;
        this.$emit('update-send-and-close-ticket-status', send_and_close);

        if (send_and_close && this?.$root?.ticketResults?.length > 0) {
          this.isResultModalOpen = true;
          return;
        }
      }

      this.$nextTick(() => {
        this.processSendMessage(send_and_close, later);
      });
    },
    async processSendMessage(send_and_close: boolean, later: boolean) {
      try {
        this.sending = true;

        if (this.isConsolidatedMessageEndpointEnabled) {
          await this.processSendViaConsolidatedEndpoint();
        } else {
          const contact_id = await this.processContact();

          const ticket_id = await this.processTicket(contact_id);

          const processMessage = await this.processMessage(ticket_id, later);

          this.onMessageSent(processMessage.data.message, true, send_and_close);

          if (send_and_close) {
            waitForEvent(
              'ticket-loaded',
              parseInt(this.$route.params.ticketId) === ticket_id,
              (t) => parseInt(t.id) === ticket_id,
              10000,
            ).then((_ticket) => {
              eventBus.$emit('ticket.current.close', ticket_id);
            });
          }
        }
      } catch (e) {
        console.error(e);
        this.sending = false;
      }
    },
    async processSendViaConsolidatedEndpoint() {
      try {
        const ticketId = this.newTicket ? null : this.ticket.id;

        let payload: ConsolidatedComposerSendPayload;

        const basePayload: BasePayload = {
          channel_id: this.channel.id,
          ticket_id: ticketId,
          subject: this.subject,
        };

        if (this.isComposingWhatsappTemplateMessage) {
          const whatsappTemplate = await this.prepareWaHsmPayload(ticketId);

          const recipient = this.contact[0]?.id ? [this.contact[0]] : [];

          payload = {
            ...basePayload,
            type: 'whatsapp_template',
            whatsapp_template: { ...whatsappTemplate, recipient: this.buildRecipientList(recipient)[0] },
          };
        } else {
          payload = {
            ...basePayload,
            type: 'generic_message',
            messaging: {
              recipients: this.buildRecipientList(this.contact),
              cc: this.buildRecipientList(this.cc),
              bcc: this.showBcc ? this.buildRecipientList(this.bcc) : [],
              message: this.isEmail ? this.message : window.stripHtml(convertHTMLToText(this.message)),
              is_note: false,
              send_later: this.shouldSendLater,
              close_after_send: this.shouldCloseAfterSend,
              delivery_method: this.shouldDeliverViaEmailInChat ? 'email' : null,
              ticket_result_id: this.ticketResultId > -1 ? this.ticketResultId : null,
              forwarded_message_id: this?.forwardMessageId || null,
              forwarding_ticket_id: this?.forwardTicketId || null,
              // We don't have a type for ticket attachments, so for now we have to ignore its type here
              attachment_ids: this?.attachments?.map((attachment) => attachment.id) ?? [],
              // FIXME: https://linear.app/trengo/issue/TFA-23/composer-processsendviaconsolidatedendpoint Erik is still working out the kinks with this particular functionality; For now it'll remain false until that's solved
              mark_as_read: this.newTicket && this.channel.type !== 'AUDIT' && this.channel.type !== 'NOTE',
            },
          };
        }

        await sendConsolidatedMessage(payload)
          .then((response) => {
            const { ticket, message } = response.data;
            this.handleSentMessage(message, true);

            if (this.shouldCloseAfterSend) {
              eventBus.$emit('ticket.current.close', ticket.id);
              this.$emit('handle-consolidated-message-endpoint-closed-ticket', ticket);
            }
          })
          .finally(() => {
            this.resetMessageSendingState();
            if (this.isWhatsappBusiness) {
              this.resetWaTemplate();
            }
          });
      } catch (err) {
        // TODO: Proper handling; What are the possible states here that aren't handled by axios?
        console.error(err);
        this.resetMessageSendingState();
      }
    },

    /* Image, attachments, gifs, emojis and files methods */
    onImageDrop(e: JQuery.DropEvent) {
      const dataTransfer = e.originalEvent?.dataTransfer;
      if (dataTransfer && !window.isDragSourceExternalFile(dataTransfer)) {
        return false;
      }

      e.preventDefault();
      e.stopPropagation();

      const files = e.originalEvent?.dataTransfer?.files;
      this.dragenter = false;

      if (!files?.length) return;

      for (let i = 0; i < files.length; i++) {
        this.addAttachment({
          name: files[i]['name'],
          obj: files[i],
          extension: window.getExtFromFileName(files?.[i]['name']),
          size: window.formatBytes(files?.[i]['size']),
        });
      }
    },
    onFilePaste(file: File) {
      this.addAttachment({
        name: file['name'],
        obj: file,
        extension: window.getExtFromFileName(file['name']),
        size: window.formatBytes(file['size']),
      });
    },

    addAttachment(data) {
      if (!this.isEmail && !this.isCustom && this.attachments.length > 0) {
        return;
      }

      const formData = new FormData();

      if (data.quick_reply_attachment_id) {
        formData.append('quick_reply_attachment_id', data.quick_reply_attachment_id);
      } else {
        formData.append('file', data.obj);
      }

      data.uploading = true;
      data.client_name = data.name;
      data.id = null;
      this.attachments.push(data);
      this.pendingUploads++;

      axios
        .post('/api/v2/ticket_draft_attachments?channel_id=' + this.channel.id + '&key=' + this.draftKey, formData)
        .then((res) => {
          data.client_name = res.data.client_name;
          data.uploading = false;
          data.full_url = res.data.full_url;
          data.id = res.data.id;
          this.pendingUploads--;
        })
        .catch(() => {
          this.attachments.splice(this.attachments.indexOf(data), 1);
          data.uploading = false;
          this.pendingUploads--;
        });
    },
    removeAttachment(attachment) {
      this.attachments.splice(this.attachments.indexOf(attachment), 1);

      axios
        .delete(
          '/api/v2/ticket_draft_attachments/' +
            attachment.id +
            '?channel_id=' +
            this.channel.id +
            '&key=' +
            this.draftKey,
        )
        .then(() => {});
    },
    initAttachmentDrop() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const v = this;
      const droppable = $(this.$el);
      let lastenter: EventTarget;

      droppable.on('dragenter', (event) => {
        if (isDragSourceExternalFile(event.originalEvent?.dataTransfer) === false || v.allowMedia == false) {
          return false;
        }
        this.dragenter = true;
        lastenter = event.target;
      });

      droppable.on('dragleave', (event) => {
        if (lastenter === event.target) {
          this.dragenter = false;
        }
      });

      droppable.on('dragover', function (e) {
        e.preventDefault();
      });

      droppable.on('drop', (e) => {
        this.onImageDrop(e);
      });
    },

    toggleGifPicker(e: Event) {
      this.showGifPicker = !this.showGifPicker;
      if (this.showGifPicker) {
        this.$nextTick(() => {
          const el = this.$refs.gifContainer;
          if (window.matchMedia('(max-width:543px)').matches) {
            // Mobile
            el.style.left = '0px';
          } else {
            el.style.left = e.target.offsetLeft - 120 + 'px';
          }
        });
      }
    },
    sendGif(e, gif) {
      this.showGifPicker = false;
      const gifPath = gif.images.fixed_height.url;

      if (!gifPath) {
        return;
      }

      // send message
      axios
        .post('/api/v2/tickets/' + this.ticket.id + '/messages', {
          message: '',
          body_type: 'GIF',
          gif: gifPath,
        })
        .then((res) => {
          this.onMessageSent(res.data.message, false);
        })
        .catch((e) => {
          this.onMessageUpdate();
          throw e;
        });

      this.focusOnEditor();

      // complete onboarding step
      eventBus.$emit('ticket-gif-sent');
    },

    toggleEmojiPicker(e: Event) {
      this.showEmojiPicker = !this.showEmojiPicker;
      if (this.showEmojiPicker) {
        this.$nextTick(() => {
          const el = this.$refs.emojiContainer;
          el.style.left = e.target.offsetLeft - 120 + 'px';
        });
      }
    },
    insertEmoji(emoji) {
      if (this.selection) {
        const tmpMessage = this.$refs.messageEditor.$refs.el.innerText;
        const before = tmpMessage.substring(
          0,
          this.selection.start <= this.selection.end ? this.selection.start : this.selection.end,
        );
        const after = tmpMessage.substring(
          this.selection.start > this.selection.end ? this.selection.start : this.selection.end,
        );

        this.$refs.messageEditor.$refs.el.innerText = before + emoji.emoji + after;
        this.message = this.$refs.messageEditor.$refs.el.innerHTML;
      } else {
        // no selection, append emoji
        this.message = this.message + '' + emoji.emoji;
      }
      this.selection = null;

      this.onMessageUpdate();
      this.focusOnEditor();
    },

    restoreDraft() {
      this.isDraftLoading = true;
      retryRequestIfResponseMatchesStatusCode(
        {
          method: 'GET',
          url: ENDPOINT.TICKET_DRAFTS,
          params: this.draftParams,
        },
        {
          statusCodes: [STATUS_CODE.NOT_FOUND],
        },
      )
        .then((res) => {
          this.decorateDraft(res.data);
          this.$nextTick(() => {
            this.$emit('draftLoaded');
          });
          this.isDraftLoading = false;
        })
        .catch(() => {});
    },
    decorateDraft(data) {
      if (this.newTicket || data.message != '') {
        this.message = data.message || '';
        this.onMessageUpdate();
      }

      this.draft = data;
      this.attachments = data.attachments;
      this.subject = data.subject || '';
      this.cc = data.cc;
      this.showCc = data.cc.length > 0;
      this.showBcc = false; // we don't show bcc by default in the composer

      if (this.defaultContact != null) {
        data.to = [];
      }

      if (data.to.length > 0 && !this.isConsolidatedMessageEndpointEnabled) {
        this.contact = data.to;
        if (data.to[0]?.id) {
          retryRequestIfResponseMatchesStatusCode(
            {
              method: 'GET',
              url: `${ENDPOINT.CONTACTS}/${data.to[0].id}`,
            },
            {
              statusCodes: [STATUS_CODE.NOT_FOUND],
            },
          ).then((res) => {
            this.contact[0].full_name = res.data.full_name != null ? res.data.full_name : '';
          });
        }
      } else {
        this.contact = this.contactFallback;
      }
    },
    deleteDraft(should_confirm = false, force = true) {
      const messageExistsForLegacyMessagingFlow = this.message.length && !this.isConsolidatedMessageEndpointEnabled;
      if (!force && messageExistsForLegacyMessagingFlow) {
        return;
      }

      if (should_confirm) {
        if (!confirm('Are you sure?')) {
          return;
        }
      }
      if (!this.isEmail) {
        this.message = '';
        this.onMessageUpdate();
      }

      retryRequestIfResponseMatchesStatusCode(
        {
          method: 'DELETE',
          url: `${ENDPOINT.TICKET_DRAFTS}/0`,
          params: this.draftParams,
        },
        {
          statusCodes: [STATUS_CODE.NOT_FOUND],
        },
      ).then(() => {
        this.restoreDraft();
      });
    },
    processDraft: throttle(function () {
      axios
        .post(
          '/api/v2/ticket_drafts?ticket_id=' +
            (this.newTicket ? '' : this.ticket.id) +
            '&channel_id=' +
            this.channel.id +
            '&key=' +
            this.draftKey,
          {
            channel_id: this.channel.id,
            message: this.message,
            subject: this.subject,
            to: toArray(
              map(this.contact, (field) => {
                return { id: field.id, identifier: field.identifier };
              }),
            ),
            cc: toArray(
              map(this.cc, (field) => {
                return { id: field.id, identifier: field.identifier };
              }),
            ),
            bcc: toArray(
              map(this.bcc, (field) => {
                return { id: field.id, identifier: field.identifier };
              }),
            ),
          },
        )
        .then((res) => {
          this.draft = res.data;
        });
    }, 2000),

    /* Ticket methods */
    updateTicketContact(ticket_id, contact_id) {
      return retryRequestIfResponseMatchesStatusCode(
        {
          url: `${ENDPOINT.TICKETS}/${ticket_id}`,
          method: 'PUT',
          data: { contact_id },
        },
        { statusCodes: [STATUS_CODE.NOT_FOUND] },
      );
    },
    processTicket(contact_id) {
      return new Promise((resolve, reject) => {
        if (!this.newTicket) {
          if (this.isEmail) {
            // Handle situation where the contact is updated
            if (!this.contact[0].id || this.contact[0].id !== this.ticket.contact_id) {
              if (!this.contact[0].id) {
                this.processContact().then((contact_id) => {
                  this.updateTicketContact(this.ticket.id, contact_id)
                    .then(() => {
                      resolve(this.ticket.id);
                    })
                    .catch(() => {
                      reject();
                    });
                });
              } else {
                this.updateTicketContact(this.ticket.id, contact_id)
                  .then(() => {
                    resolve(this.ticket.id);
                  })
                  .catch(() => {
                    reject();
                  });
              }
            } else {
              resolve(this.ticket.id);
            }
          } else {
            resolve(this.ticket.id);
          }
          return;
        }

        retryRequestIfResponseMatchesStatusCode(
          {
            method: 'POST',
            url: ENDPOINT.TICKETS,
            data: {
              channel_id: this.channel.id,
              contact_id,
              subject: this.subject,
            },
          },
          { statusCodes: [STATUS_CODE.NOT_FOUND] },
        )
          .then((res) => {
            resolve(res.data.id);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    updateTicketChannelInComposer(channel: Channel) {
      this.channel = channel;
      this.processDraft();
    },
    selectTicketResult(resultId: number) {
      this.isResultModalOpen = false;
      this.ticketResultId = resultId;
      this.processSendMessage(true, false);
    },

    /* Froala methods */
    processFroalaShortcodes(string: string = '') {
      this.message = this.$refs.emailEditor.instance.html
        .get()
        .replaceAll(getQrRegex(), (match: string, group1: string) => group1)
        .replace('<span class="caret-position"></span>', string + this.selectionMarkerElement);
      setTimeout(() => {
        this.$refs.emailEditor.instance.html.set(this.stripFroalaWrappingParagraphTags(this.message));
      });
    },
    stripFroalaWrappingParagraphTags(string: string) {
      // remove open & closing <p> tag from string (to prevent caret jumping to end when setting editors html)
      const requiresFormating =
        string.startsWith('<p>') &&
        string.endsWith('</p>') &&
        !string.startsWith('<p><br>') &&
        !string.endsWith('<br></p>');
      return requiresFormating ? string.slice(0, -4).substring(3) : string;
    },
    onFroalaBlur() {
      // fix for inserting quick reply with no focus at start of the editors content instead of end
      setTimeout(() => {
        if (!this.quickReplyOpenDropdown) {
          this.shouldInsertQuickReplyAtStart = true;
        }
      }, 200);
    },
    processFroalaContentChange() {
      setTimeout(() => {
        this.triggerDropdownWithShortcode();
      }, 10);
    },

    /* Whatsapp methods */
    resetWaTemplate() {
      this.waTemplateBodyParams = this.waTemplateBodyParams?.map((param: { key: string; value: string }) => ({
        key: param.key,
        value: '',
      }));
      this.waTemplateHeaderParam = { ...this.waTemplateHeaderParam, value: '' };
      this.selectedWaTemplateHeaderFile = [];
      this.shouldValidateWaTemplateHeaderFile = false;
    },
    setWaTemplate(template: FilteredWhatsappTemplate) {
      this.wa_template = template.value;
    },
    sortWaTemplatesByStarred(templates: Template[]) {
      return templates.sort((a, b) => {
        if (a.is_starred !== b.is_starred) {
          return a.is_starred ? -1 : 1;
        }
        return a.title.localeCompare(b.title);
      });
    },
    updateWaTemplateFile(file: File) {
      this.selectedWaTemplateHeaderFile = file || [];
    },
    redirectToWaChannelSetting() {
      this.$router.push(`/admin/channels2/wa_business/${this.channelInfo.id}`);
    },
    redirectToCreateWaTemplates() {
      this.$router.push('/admin/wa_templates/create');
      if (this.newTicket) {
        this.$emit('popout');
      }
    },
    updateWaTemplateVariables(val: string, idx: number) {
      this.waTemplateBodyParams[idx].value = val;
    },
    validateWaTemplateVariables() {
      if (this.hasEmptyTemplateVariable) {
        this.isValidTemplateVariable = false;
      }
      if (this.hasNotSelectedHeaderFile) {
        this.shouldValidateWaTemplateHeaderFile = true;
      }
    },
    isEmptyWaTemplateVariable(variable: string) {
      return !this.isValidTemplateVariable || !variable?.value.trim();
    },
    setWaTemplateDefaults() {
      this.wa_business_message_type = WHATSAPP_BUSINESS_MESSAGE_TYPE.SESSION_MESSAGE;
      this.wa_template_messages = [];
      this.wa_template = null;
      this.waTemplateBodyParams = [];
    },

    async resetWaTemplateFile() {
      try {
        // add back the selected file
        // This enables the TFileInput component to still have the selected file
        this.selectedWaTemplateHeaderFile = [...this.selectedWaTemplateHeaderFile];

        const shouldDeleteFile = await this.$tConfirm(
          this.$t('whatsapp.are_you_sure_you_want_to_remove_the_attached_file'),
          {
            title: this.$t('whatsapp.remove_attached_file'),
            confirmText: this.$t('whatsapp.remove_file'),
          },
        );

        if (!shouldDeleteFile) {
          return;
        }
        this.selectedWaTemplateHeaderFile = [];
      } catch (error) {
        console.error('error while deleting selected template', error);
      }
    },
    async uploadWaTemplateFile() {
      try {
        const formData = new FormData();
        formData.append('file', this.selectedWaTemplateHeaderFile[0]);
        const response = await saveFile(formData);
        this.waTemplateHeaderParam.value = response?.id;
      } catch (error) {
        console.error(error);
      }
    },
    async getWaTemplates(initiateLoading: boolean = true) {
      this.isWaTemplatesLoading = initiateLoading;
      const res = await fetchWaTemplates({ status: 'ACCEPTED', channel_id: this.channel.id, show_starred: true });
      this.wa_template_messages = this.sortWaTemplatesByStarred(res.data.data || []);
      if (this.wa_template === null) {
        this.wa_template = this.wa_template_messages[0];
      }
      this.isWaTemplatesLoading = false;
    },
    async prepareWaHsmPayload(ticket_id) {
      const hasSelectedTemplateHeaderFile = this.isTemplateWithHeaderMedia && this.selectedWaTemplateHeaderFile.length;

      if (hasSelectedTemplateHeaderFile) {
        await this.uploadWaTemplateFile();
      }

      const params = this.waTemplateBodyParams?.map((param: { key: string; value: string }) => {
        return {
          key: param?.key,
          value: param?.value,
          type: TEMPLATE_PARAM_TYPE.BODY,
        };
      });

      if (this.isTemplateWithHeaderMedia) {
        params.push({
          key: this.waTemplateHeaderParam?.key,
          value: this.waTemplateHeaderParam?.value,
          type: TEMPLATE_PARAM_TYPE.HEADER,
        });
      }

      return {
        ticket_id,
        hsm_id: this.wa_template.id,
        params,
        source: 'trengo-app',
      };
    },
    async sendWaHsm(ticket_id: number) {
      try {
        const payload = await this.prepareWaHsmPayload(ticket_id);
        return sendWaTemplateMessage(payload);
      } catch (error) {
        console.error(error);
        this.sending = false;
      }
    },

    /* Quick Replies methods */
    openQuickRepliesDropdown(ref: HTMLElement, position: Element | null = null) {
      if (ref) {
        if (this.isEmail) {
          this.$refs.emailEditor.instance.el.blur();
        }
        this.quickReplyOpenDropdown = true;
        eventBus.$emit('OPEN_DROPDOWN', ref, position);
      }
    },
    triggerQuickRepliesDropdown() {
      this.insertCaretPositionElement();
      this.openQuickRepliesDropdown(this.$refs.quick_replies_icon);
    },
    handleQuickRepliesDropdownOpen() {
      if (this.quickReplies.length === 0) {
        this.loadQuickReplies(this.channel.id);
      }
    },
    handleQuickRepliesDropdownClose() {
      this.quickReplyOpenDropdown = false;
      setTimeout(() => {
        if (this.isEmail) {
          if (this.$refs.emailEditor.instance.html.get()) {
            this.processEditorShortcodes();
          } else {
            // fix for removing caret-position element in empty editor
            this.$refs.emailEditor.instance.html.set('');
          }
        } else if (this.$refs.messageEditor.$refs.el.innerHTML.includes('<span class="caret-position"></span>')) {
          this.processEditorShortcodes();
          this.onMessageUpdate();
          window.placeCaretAtEnd(this.$refs.messageEditor.$refs.el);
        }
      }, 10);
    },
    processQuickReplyAttachments(reply) {
      if (reply.attachments.length) {
        if (!this.isEmail) {
          reply.attachments = reply.attachments.splice(0, 1);
        }

        $.each(reply.attachments, (i, attachment) => {
          this.addAttachment({
            quick_reply_attachment_id: attachment.id,
            extension: attachment.client_name.substr(attachment.client_name.lastIndexOf('.') + 1),
            size: formatBytes(attachment.file_size),
            name: attachment.client_name,
          });
        });
      }
      eventBus.$emit('ticket-quick-reply-inserted');
    },
    insertQuickReply(reply) {
      this.processQuickReplyAttachments(reply);
      let replyMessage = this.replaceQuickReplyTags(reply);

      if (this.isEmail) {
        this.processEditorShortcodes(replyMessage);
      } else {
        replyMessage = this.stripText(replyMessage);
        if (!this.$refs.messageEditor.$refs.el.innerHTML.includes('<span class="caret-position"></span>')) {
          this.$refs.messageEditor.restoreSelection(this.contentEditableSelRange);
          // if messaging editor has focus
          if (document?.activeElement.classList.contains('message-editor')) {
            this.insertContentEditableStringAtCaret(replyMessage);
            // if messaging editor has no focus
          } else {
            this.message = this.$refs.messageEditor.$refs.el.innerHTML = replyMessage + this.message;
          }
        } else {
          this.processEditorShortcodes(replyMessage);
        }
        this.$refs.messageEditor.onChange();
      }
    },
    replaceQuickReplyTags(reply) {
      return reply.message
        .replaceAll(/(\[ticket_id])/, this.ticket?.id || '')
        .replaceAll(/\[name]/, this.ticket?.contact?.full_name || this.contact[0]?.full_name || '')
        .replaceAll(/\[contact_name]/, this.ticket?.contact?.full_name || this.contact[0]?.full_name || '')
        .replaceAll(/\[agent_name]/, this.$root.user?.full_name || '')
        .replaceAll(/\[profile_name]/, this.ticket?.contact?.profile[0]?.name || '');
    },
    loadQuickReplies() {
      const withoutAttachments = !!this.publicProvider;
      ticketRepository.loadQuickReplies(this.channel.type, this.channel.id, withoutAttachments).then((res) => {
        this.quickReplies = res;
      });
    },
  },
});
</script>

<style scoped lang="scss">
.composer__grey-border {
  @apply border-b-2 border-t-2 border-grey-200 lg:border-2 lg:border-b-2;
}

.composer-send-button-left,
.composer-send-button-right {
  display: inline-flex;
  align-items: center;
  height: 32px;
}

.composer-send-button-left {
  border-radius: 50px 0 0 50px !important;
}

.composer-send-button-left i.material-icons {
  font-size: 16px;
  margin-right: 5px;
  margin-top: 1px;
}

.composer-send-button-right {
  border-radius: 0 50px 50px 0 !important;
}

@media (max-width: 991px) {
  .modal .composer-container {
    border-radius: 0 !important;
  }

  .modal .composer-footer {
    background-color: white;
  }

  .modal-header {
    border-radius: 0 !important;
  }

  .modal-centered {
    border-radius: 0 !important;
    background-color: white;
  }
}

@media (min-width: 1227px) {
  .composer-footer-attachments-container {
    max-height: 100% !important;
  }
}

.composer-footer-attachments-container {
  max-height: 80%;
  margin-bottom: 4px;
  margin-top: 4px;
}

.composer-footer-attachments {
  max-height: 100%;
}

.gif-container {
  position: absolute;
  bottom: 40px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.34);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  z-index: 9999;
  color: #9b9b9b;
  font-size: 14px;
}

@media (min-width: 1900px) {
  .ticket-from-channel {
    max-width: 360px;
  }
}

:deep(.emoji-browser) {
  width: 300px;
  height: 300px;
}
</style>
