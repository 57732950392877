export const CHANNEL_TYPE = {
  CHAT: 'CHAT',
  CUSTOM: 'CUSTOM',
  EMAIL: 'EMAIL',
  FACEBOOK: 'FACEBOOK',
  GBM: 'GBM',
  HELP_CENTER: 'HELP_CENTER',
  INSTAGRAM: 'INSTAGRAM',
  SMS: 'SMS',
  TELEGRAM: 'TELEGRAM',
  TWITTER: 'TWITTER',
  VOIP: 'VOIP',
  VOYS: 'VOYS',
  WA_BUSINESS: 'WA_BUSINESS',
  WECHAT: 'WECHAT',
  VOICE: 'VOICE',
  WHATSAPP: 'WHATSAPP',
  ZIPWHIP: 'ZIPWHIP',
  AIRCALL: 'AIRCALL',
} as const;
