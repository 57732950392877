import { PERMISSION } from '@/Configs/Constants';

import type { RouteRecord } from '../types';

const developersRoutes: RouteRecord[] = [
  {
    path: '/admin/developers',
    component: () => import(/* webpackChunkName: "Developers" */ '../../components/Developers/Index.vue'),
    meta: { permissionRequired: PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE },
    children: [
      {
        path: '/admin/developers/rest',
        component: () => import(/* webpackChunkName: "Developers" */ '../../components/Developers/Rest.vue'),
        meta: { permissionRequired: PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE },
      },
      {
        path: '/admin/developers/webhooks',
        component: () => import(/* webpackChunkName: "Developers" */ '../../components/Developers/Webhooks/Index.vue'),
        meta: { permissionRequired: PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE },
        children: [
          {
            path: '/admin/developers/webhooks/create',
            component: () => import(/* webpackChunkName: "Developers" */ '../../components/Developers/Webhooks/Edit.vue'),
            props: { action: 'create' },
            meta: { permissionRequired: PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE },
          },
          {
            path: '/admin/developers/webhooks/:id',
            component: () => import(/* webpackChunkName: "Developers" */ '../../components/Developers/Webhooks/Edit.vue'),
            props: { action: 'edit' },
            meta: { permissionRequired: PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE },
          },
          {
            path: '',
            component: () => import(/* webpackChunkName: "Developers" */ '../../components/AdminIndexBox.vue'),
            props: { boxName: 'webhooks' },
            meta: { permissionRequired: PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE },
          },
        ],
      },
      {
        path: '/admin/developers/plugins',
        component: () => import(/* webpackChunkName: "Developers" */ '../../components/Developers/Plugins/Index.vue'),
        meta: { permissionRequired: PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE },
        children: [
          {
            path: '/admin/developers/plugins/create',
            component: () => import(/* webpackChunkName: "Developers" */ '../../components/Developers/Plugins/Edit.vue'),
            props: { action: 'create' },
            meta: { permissionRequired: PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE },
          },
          {
            path: '/admin/developers/plugins/create/:type',
            component: () => import(/* webpackChunkName: "Developers" */ '../../components/Developers/Plugins/Edit.vue'),
            props: { action: 'create' },
            meta: { permissionRequired: PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE },
          },
          {
            path: '/admin/developers/plugins/:id',
            component: () => import(/* webpackChunkName: "Developers" */ '../../components/Developers/Plugins/Edit.vue'),
            props: { action: 'edit' },
            meta: { permissionRequired: PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE },
          },
          {
            path: '',
            component: () => import(/* webpackChunkName: "Developers" */ '../../components/AdminIndexBox.vue'),
            props: { boxName: 'plugins' },
            meta: { permissionRequired: PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE },
          },
        ],
      },
      {
        path: '/admin/developers/zapier',
        component: () => import(/* webpackChunkName: "Developers" */ '../../components/Developers/Zapier.vue'),
        meta: { permissionRequired: PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE },
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "Developers" */ '../../components/AdminIndexBox.vue'),
        props: { boxName: 'developers' },
        meta: { permissionRequired: PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE },
      },
    ],
  },
];

export default developersRoutes;
