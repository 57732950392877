import { PERMISSION } from '@/Configs/Constants';

import type { RouteRecord } from '../types';

const ticketResultsRoutes: RouteRecord[] = [
  {
    path: '/admin/ticket_results',
    component: () => import(/* webpackChunkName: "TicketResults" */ '../../components/TicketResults/Index.vue'),
    meta: { permissionRequired: PERMISSION.SETTINGS__TICKET_RESULTS__MANAGE },
    children: [
      {
        path: '/admin/ticket_results/create',
        component: () => import(/* webpackChunkName: "TicketResults" */ '../../components/TicketResults/Edit.vue'),
        props: { action: 'create' },
        meta: { permissionRequired: PERMISSION.SETTINGS__TICKET_RESULTS__MANAGE },
      },
      {
        path: '/admin/ticket_results/:id',
        component: () => import(/* webpackChunkName: "TicketResults" */ '../../components/TicketResults/Edit.vue'),
        props: { action: 'edit' },
        meta: { permissionRequired: PERMISSION.SETTINGS__TICKET_RESULTS__MANAGE },
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "TicketResults" */ '../../components/AdminIndexBox.vue'),
        props: { boxName: 'ticket_results' },
        meta: { permissionRequired: PERMISSION.SETTINGS__TICKET_RESULTS__MANAGE },
      },
    ],
  },
];

export default ticketResultsRoutes;
