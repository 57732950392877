<template>
  <div
    ref="modalRef"
    aria-modal="true"
    data-test="modal-translation"
    class="absolute bottom-[125%] max-h-[338px] z-10 flex flex-col transform-gpu overflow-hidden rounded-xl bg-white border-1 border-grey-300 shadow-sm md:left-0 xl:left-[initial]"
    @keydown="onKeydown"
  >
    <div class="sticky p-2 border-b border-grey-300">
      <t-input-search
        ref="searchInputRef"
        :model-value="searchTerm"
        aria-label="Search for languages"
        variant="primary-alt"
        :placeholder="$t('general.search') + ` ` + $t('general.language').toLowerCase() + `...`"
        @clear="searchTerm = ''"
        @input="onSearch"
      />
    </div>
    <div
      v-if="languageOptions.length"
      class="overflow-y-auto"
      :class="{ 'py-2 pl-2 pr-0': !searchTerm.length, 'p-2': searchTerm.length }"
    >
      <div v-for="([key, locale], index) in languageOptions" :key="locale">
        <button
          type="button"
          class="block w-full pl-2 py-2 text-left rounded-xl transition-transform hover:bg-grey-200 focus:bg-grey-200"
          @click="emit('select-locale', key as Locales)"
        >
          {{ locale }}
        </button>
        <t-divider v-if="index === 5 && !searchTerm?.length" class="relative py-2" />
      </div>
    </div>
    <div v-else class="text-grey-500 p-4" data-test="no-results">{{ $t('general.no_match') }} '{{ searchTerm }}'</div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, nextTick } from 'vue';

import { LOCALE_LABEL_ARRAY } from '@/Configs/Constants';
import { filterData } from '@/util/filterHelper';

import { type Locales } from './useLocales';

const emit = defineEmits<{
  (e: 'select-locale', locale: Locales): void;
}>();

const searchTerm = ref<string>('');

const languageOptions = computed<[string, string][]>(() => {
  if (!searchTerm.value) return LOCALE_LABEL_ARRAY;

  const filteredOptions = filterData(
    LOCALE_LABEL_ARRAY.map(([key, locale]) => ({ key, locale })),
    ['key', 'locale'],
    searchTerm.value,
  );

  return filteredOptions.map(({ key, locale }) => [key, locale]);
});

/**
 * Updates the `searchTerm` value when the user types in the search bar,
 * triggering filtering of language options.
 */
function onSearch(value: string) {
  searchTerm.value = value;
}

const modalRef = ref<HTMLElement | null>(null);
/**
 * Handles keyboard navigation (Arrow Up/Down) within the modal,
 * focusing the appropriate button based on the current focus.
 */
function onKeydown(event: KeyboardEvent) {
  const allowedKeys = ['ArrowUp', 'ArrowDown'];
  if (!allowedKeys.includes(event.key)) return;

  if (modalRef.value) {
    const buttons = modalRef.value.querySelectorAll('button');
    const currentIndex = Array.from(buttons).findIndex((el) => el === document.activeElement);

    switch (event.key) {
      case 'ArrowDown':
        if (currentIndex === -1 && buttons.length) {
          buttons[0]?.focus(); // Focus the first button if none is focused
        } else {
          // Using modulo ensures the index stays within bounds by wrapping around.
          // When (currentIndex + 1) equals the list length, the remainder is 0,
          // which makes the "next" index the first item. The same logic is applied
          // for ArrowUp to wrap back to the last item.
          const nextIndex = (currentIndex + 1) % buttons.length; // Navigate to the next button.
          buttons[nextIndex]?.focus();
        }
        break;
      case 'ArrowUp':
        if (currentIndex === -1 && buttons.length) {
          buttons[buttons.length - 1]?.focus(); // Focus the last button if none is focused
        } else {
          const prevIndex = (currentIndex - 1 + buttons.length) % buttons.length; // Navigate to the previous button
          buttons[prevIndex]?.focus();
        }
        break;
    }
    event.preventDefault();
  }
}

const searchInputRef = ref<HTMLInputElement | null>(null);

onMounted(() => {
  nextTick(() => {
    if (searchInputRef.value?.focus) {
      searchInputRef.value.focus();
    } else if (searchInputRef.value) {
      const inputElement = searchInputRef.value.$el?.querySelector('input');
      inputElement?.focus();
    }
  });
});
</script>
