import { PERMISSION } from '@/Configs/Constants';

import legacy from './legacy';
import { queryParamsGuard } from './loadIndex';
import { ROUTE_NAME } from '../constants';

import type { RouteRecord } from '../types';

const reportingRoutes: RouteRecord[] = [
  {
    path: '/reports',
    component: () => {
      return import(/* webpackChunkName: "Reporting" */ '@/components/Reporting/Pages/DynamicIndex.vue');
    },
    meta: { permissionRequired: PERMISSION.REPORTING__GENERAL__MANAGE, title: 'Reporting' },
    name: ROUTE_NAME.REPORTING_BASE,
    children: [
      ...legacy,
      {
        path: '/reports',
        component: () => import(/* webpackChunkName: "Reporting" */ '@/components/Reporting/Pages/V2/Overview/Index.vue'),
        meta: { permissionRequired: PERMISSION.REPORTING__GENERAL__MANAGE },
        name: 'reportingDashboard',
      },
      {
        path: '/reports/ticket_insights',
        components: {
          default: () =>
            import(
              /* webpackChunkName: "Reporting" */ '@/components/Reporting/Pages/V2/TicketInsights/Index.vue'
            ),
        },
        meta: { permissionRequired: PERMISSION.REPORTING__GENERAL__MANAGE },
        name: 'reportingTicketInsights',
      },
      {
        path: '/reports/ticket_insights/:page',
        components: {
          default: () =>
            import(
              /* webpackChunkName: "Reporting" */ '@/components/Reporting/Pages/V2/TicketInsights/Index.vue'
            ),
        },
        meta: { permissionRequired: PERMISSION.REPORTING__GENERAL__MANAGE },
        beforeEnter: (to, from, next) => {
          queryParamsGuard(to, from, next);
        },
        name: 'reportingTicketInsightsSpecificPage',
      },
      {
        path: '/reports/workload_management',
        components: {
          default: () =>
            import(
              /* webpackChunkName: "Reporting" */ '@/components/Reporting/Pages/V2/WorkloadManagement/Index.vue'
            ),
        },
        meta: { permissionRequired: PERMISSION.REPORTING__GENERAL__MANAGE },
        name: 'reportingWorkloadManagement',
      },
      {
        path: '/reports/agents_availability',
        components: {
          default: () =>
            import(/* webpackChunkName: "Reporting" */ '@/components/Reporting/Pages/Availability.vue'),
        },
        meta: { permissionRequired: PERMISSION.REPORTING__GENERAL__MANAGE },
        name: 'reportingAgentsAvailability',
      },
      {
        path: '/reports/agents_performance',
        components: {
          default: () =>
            import(
              /* webpackChunkName: "Reporting" */ '@/components/Reporting/Pages/V2/AgentsPerformance/Index.vue'
            ),
        },
        meta: { permissionRequired: PERMISSION.REPORTING__GENERAL__MANAGE },
        name: 'reportingAgentsPerformance',
      },
      {
        path: '/reports/agents_availability',
        components: {
          default: () =>
            import(/* webpackChunkName: "Reporting" */ '@/components/Reporting/Pages/Availability.vue'),
        },
        meta: { permissionRequired: PERMISSION.REPORTING__GENERAL__MANAGE },
        name: 'reportingAgentsAvailability',
      },
      {
        path: '/reports/csat',
        components: {
          default: () =>
            import(
              /* webpackChunkName: "Reporting" */ '@/components/Reporting/Pages/V2/CustomerSatisfaction/Index.vue'
            ),
        },
        meta: { permissionRequired: PERMISSION.REPORTING__GENERAL__MANAGE },
        name: 'reportingCSAT',
      },
    ],
  },
];

export default reportingRoutes;
