<template>
  <span style="cursor: pointer" aria-readonly="false">{{ modelValue }}</span>
</template>

<script type="text/babel" lang="ts">
export default {
  name: 'ContactNameEditable',
  props: {
    contactId: {
      type: [String, Number],
      default: '',
    },
    modelValue: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: '',
    },
    mode: {
      type: String,
      default: 'inline',
    },
    placement: {
      type: String,
      default: 'top',
    },
    inputClass: {
      type: String,
      default: 'form-control',
    },
  },
  emits: ['saved'],
  watch: {
    pk() {
      this.init();
    },
    modelValue() {
      this.init();
    },
  },
  mounted() {
    this.init();
  },

  methods: {
    init() {
      $(this.$el)
        .editable({
          mode: this.mode,
          type: 'text',
          placement: this.placement,
          send: 'never',
          inputclass: this.inputClass,
        })
        .on('save', (a, b) => {
          axios.put('/api/v2/contacts/' + this.contactId, { name: b.newValue }).then(() => {
            this.$emit('saved', {
              value: b.newValue,
            });
          });
        });
    },
  },
};
</script>
