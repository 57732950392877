<template>
  <div class="filter-datepicker flex text-base">
    <div class="w-full">
      <date-range-picker
        ref="picker"
        v-model="date"
        :date-range="date"
        single-date-picker="range"
        :show-dropdowns="false"
        opens="inline"
        :ranges="false"
        :auto-apply="false"
        :control-container-class="''"
        :locale-data="{
          daysOfWeek: getDaysOfWeek(),
          monthNames: getMonthNames(),
        }"
        @select="setSelectedRange"
      >
        <template #input="picker">
          <div class="flex items-center justify-center">
            <span
              class="mb-4 ml-4 mr-4 mt-4 flex h-10 w-full items-center justify-center rounded-xl border border-grey-300"
            >
              {{ getStartDate() }}
            </span>
            <arrow-right-linear size="1.25rem" class="shrink-0" />
            <span
              class="mb-4 ml-4 mr-4 mt-4 flex h-10 w-full items-center justify-center rounded-xl border border-grey-300"
            >
              {{ getEndDate() }}
            </span>
          </div>
          <t-divider />
        </template>
        <template #footer="picker">
          <div class="flex justify-end px-2 py-5">
            <t-button size="sm" btn-style="secondary" class="mr-2" @click="clearSelection">Clear</t-button>
            <t-button size="sm" @click="applySelection">Apply</t-button>
          </div>
        </template>
      </date-range-picker>
    </div>
  </div>
</template>

<script lang="ts">
import { ArrowRightLinear } from '@trengo/trengo-icons';
import moment from 'moment';
import DateRangePicker from 'vue3-daterange-picker';

import type { Moment } from 'moment';

type DateRange = {
  startDate: Moment | Date | null;
  endDate: Moment | Date | null;
};

type Data = {
  date: DateRange;
  formatDisplay: string;
};

export default {
  name: 'DatePicker',
  emits: ['selection', 'clear'],
  data(): Data {
    return {
      date: {
        startDate: null,
        endDate: null,
      },
      formatDisplay: 'DD/MM/YYYY',
    };
  },

  components: {
    DateRangePicker,
    ArrowRightLinear,
  },
  props: {
    modelValue: {
      type: Object as () => DateRange,
      default: () => ({
        startDate: null,
        endDate: null,
      }),
    },
  },
  mounted() {
    const localeCode = this.$root.user.locale_code;
    moment().locale(localeCode);
    this.setValue();
  },
  watch: {
    modelValue() {
      this.setValue();
    },
  },
  methods: {
    setValue() {
      this.date.startDate = this.modelValue.startDate;
      this.date.endDate = this.modelValue.endDate;
    },
    setSelectedRange(selection: DateRange) {
      this.date = selection;
    },
    getStartDate() {
      return this.date.startDate
        ? moment(this.date.startDate).tz(this.$root.user.timezone).format(this.formatDisplay)
        : '-';
    },
    getEndDate() {
      return this.date.endDate
        ? moment(this.date.endDate).tz(this.$root.user.timezone).format(this.formatDisplay)
        : '-';
    },
    getDaysOfWeek() {
      return moment.weekdays().map((day) => day[0].toUpperCase() + day.slice(1, 3));
    },
    getMonthNames() {
      return moment.monthsShort('-MMM-').map((month) => month[0].toUpperCase() + month.slice(1));
    },
    applySelection() {
      this.$emit('selection', {
        startDate: moment(this.date.startDate).tz(this.$root.user.timezone),
        endDate: moment(this.date.endDate).tz(this.$root.user.timezone),
      });
    },
    clearSelection() {
      this.date.startDate = null;
      this.date.endDate = null;
      this.$emit('clear');
      this.applySelection();
    },
  },
};
</script>

<style lang="scss" src="./DatePicker.scss" />
