<template>
  <div class="relative flex">
    <straight v-if="isLoading" :width="160" :height="12" />
    <div
      v-show="!isLoading"
      :id="elementId"
      ref="el"
      :contenteditable="true"
      data-hj-suppress
      class="flex-1 break-words"
      :style="{ maxHeight: maxHeight }"
      style="word-break: break-word"
      @keyup.delete.prevent="$emit('delete')"
      @focus="onFocus"
      @blur="onBlur"
      @keydown.enter="onEnter"
      @keyup.prevent="onChange($event)"
      @paste="onChange"
      @input="onChange"
      @mouseup="handleTextSelection"
      @keyup="handleTextSelection"
    />
    <div v-if="showPlaceholder && !isLoading" class="placeholder">{{ placeholder }}</div>
    <div
      v-if="showTooltip && (allowFormatting || allowLinkFormatting)"
      ref="tooltip"
      v-on-click-outside="clickedOutside"
      class="tooltip-container absolute z-50"
      :style="tooltipStyle"
    >
      <div
        v-if="!isLinkingActive"
        class="z-50 flex flex-row items-center space-x-1 rounded-2xl border border-grey-300 bg-white p-1 shadow-lg"
      >
        <template v-if="allowFormatting">
          <t-icon-button :class="{ selected: isFormatted('bold') }" @click="format('bold')">
            <text-bold-linear class="text-grey-700" size="1.5rem" />
          </t-icon-button>
          <t-icon-button :class="{ selected: isFormatted('italic') }" @click="format('italic')">
            <text-italic-linear class="text-grey-700" size="1.5rem" />
          </t-icon-button>
          <t-icon-button :class="{ selected: isFormatted('strikethrough') }" @click="format('strikethrough')">
            <text-strikethrough-linear class="text-grey-700" size="1.5rem" />
          </t-icon-button>
          <t-icon-button :class="{ selected: isFormatted('inlineCode') }" @click="format('inlineCode')">
            <code-linear class="text-grey-700" size="1.5rem" />
          </t-icon-button>
        </template>
        <div v-if="allowLinkFormatting" class="border-l-1 border-grey-300 pl-1">
          <t-icon-button
            v-if="isFormatted('link')"
            v-tooltip="{ placement: 'top', content: 'Open link' }"
            class="mr-1"
            @click="openExternalLink"
          >
            <external-link-linear class="text-grey-700" size="1.5rem" />
          </t-icon-button>
          <t-icon-button
            v-tooltip="{ placement: 'top', content: isFormatted('link') ? 'Unlink' : 'Insert link' }"
            @click="triggerLinkForm"
          >
            <paperclip-slash-linear v-if="isFormatted('link')" class="text-grey-700" size="1.5rem" />
            <link1-linear v-else class="text-grey-700" size="1.5rem" />
          </t-icon-button>
        </div>
      </div>
      <div v-else class="z-50 flex flex-col gap-1 rounded-xl border border-grey-300 bg-white p-1">
        <t-input-text v-model="selectedText" size="sm" placeholder="Text"></t-input-text>
        <t-input-text v-model="url" size="sm" placeholder="Add url">
          <template #prefix><link1-linear class="text-grey-700" size="1.5rem" /></template>
        </t-input-text>
        <div class="flex justify-end">
          <t-button :disabled="url.length === 0 || selectedText.length === 0" size="sm" @click="format('link')">
            Add link
          </t-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  CodeLinear,
  TextBoldLinear,
  TextItalicLinear,
  TextStrikethroughLinear,
  Link1Linear,
  PaperclipSlashLinear,
  ExternalLinkLinear,
} from '@trengo/trengo-icons';
import { vOnClickOutside } from '@vueuse/components';

import Straight from '@/components/Channels/CreateChannelModals/WhatsApp/SkeletonLoader/Straight.vue';
import { formatWhatsAppText } from '@/util/GlobalHelpers';
import { convertFormattingToPlainText } from '@/util/stringHelpers';
type FormattingType = 'italic' | 'bold' | 'strikethrough' | 'inlineCode' | 'link';
export default {
  components: {
    Straight,
    CodeLinear,
    TextItalicLinear,
    TextBoldLinear,
    TextStrikethroughLinear,
    Link1Linear,
    PaperclipSlashLinear,
    ExternalLinkLinear,
  },
  directives: { onClickOutside: vOnClickOutside },
  props: {
    elementId: { type: String, default: 'content-editable-container' },
    modelValue: { type: String, default: '' },
    multiline: { type: Boolean, default: false },
    filePaste: { type: Boolean, default: false },
    autofocus: { type: Boolean, default: false },
    maxHeight: { type: String, default: '400px' },
    returnPlainText: { type: Boolean, default: false },
    insertPlainText: { type: Boolean, default: false },
    allowFormatting: { type: Boolean, default: false },
    placeholder: { type: String, default: '' },
    allowLinkFormatting: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },
  },
  emits: ['change', 'enter', 'delete', 'focus', 'blur', 'keyup', 'filePaste', 'update:modelValue'],

  data() {
    return {
      isLinkingActive: false,
      changed: false,
      changeTimer: null,
      selRange: null,
      removePrev: document.querySelector('.remove-prev'),
      selectedText: '',
      showTooltip: false,
      ignoreNextOutsideClick: false,
      tooltipPosition: { top: 0, left: 0 },
      url: '',
      currentRange: null,
      showPlaceholder: false,
    };
  },
  computed: {
    tooltipStyle() {
      return { top: `${this.tooltipPosition.top}px`, left: `${this.tooltipPosition.left}px` };
    },
    currentSelection() {
      const selection = window.getSelection();
      if (selection && selection.rangeCount > 0) {
        return selection.getRangeAt(0);
      }
      return null;
    },
  },
  watch: {
    modelValue() {
      this.checkPlaceholder();
    },
    isLoading(newValue, oldValue) {
      if (this.$refs.el && oldValue === true && newValue === false && !this.insertPlainText) {
        this.$refs.el.innerHTML = formatWhatsAppText(this.modelValue ?? '');
      }
    },
  },
  mounted() {
    if (this.$refs.el) {
      if (this.insertPlainText) {
        this.$refs.el.innerText = this.modelValue ?? '';
      } else {
        this.$refs.el.innerHTML = formatWhatsAppText(this.modelValue ?? '');
      }
    }

    this.checkPlaceholder();

    this.$nextTick(() => {
      if (!this.$refs.el) {
        return;
      }

      this.$refs.el.addEventListener('paste', (e) => {
        if (window.isLoadedFromApp) {
          // fix for IOS bug
          setTimeout(() => {
            this.$refs.el[this.returnPlainText ? 'innerText' : 'innerHTML'] = stripHtml(
              this.$refs.el[this.returnPlainText ? 'innerText' : 'innerHTML'],
            );
            this.$emit('update:modelValue', this.getPlainText());
            this.$emit('change');
          }, 250);
        } else {
          if (e.clipboardData.files.length > 0) {
            e.clipboardData.items.forEach((file) => {
              if (file.kind === 'file') {
                e.preventDefault();
                const blob = file.getAsFile();

                if (blob) {
                  this.$emit('filePaste', blob);
                }
              }
            });

            return;
          }

          if (e.clipboardData) {
            e.preventDefault();

            // execCommand is deprecated - however it still works and Firefox doesn't support the Clipboard API yet
            // So, we just have to keep it in for now. Hopefully a better solution presents itself in the future
            document.execCommand('insertText', false, (e.originalEvent || e).clipboardData.getData('text/plain'));
          }

          // Trigger change event
          this.$emit('update:modelValue', this.getPlainText());
        }
      });
    });

    document.addEventListener('selectionchange', this.selectionChanged);
  },

  unmounted() {
    document.removeEventListener('selectionchange', this.selectionChanged);
    this.removePrev?.removeEventListener('DOMNodeRemoved', this.removePreviousElements);
  },

  methods: {
    openExternalLink() {
      const selection = window.getSelection();
      if (!selection) return;

      let node = selection.anchorNode;
      while (node && node !== this.$refs.el) {
        if (node.nodeName === 'A') {
          const url = node.getAttribute('href');
          if (url) {
            window.open(url, '_blank');
          }
          break;
        }
        node = node.parentNode;
      }
    },
    triggerLinkForm() {
      if (this.isFormatted('link')) {
        this.format('link'); // Remove the format and revert to plain text
      } else {
        this.isLinkingActive = true;
        this.tooltipPosition.top -= 100;
      }
    },
    clickedOutside() {
      this.isLinkingActive = false;
      if (this.ignoreNextOutsideClick) return;
      this.showTooltip = false;
    },
    calculateTooltipPosition(rect, contentRect) {
      let top = rect.top - contentRect.top - 45;
      let left = rect.left - contentRect.left + rect.width / 2 - (this.allowLinkFormatting ? 80 : 60);

      // Ensure tooltip doesn't overflow the right edge
      if (left + 170 > contentRect.width) {
        left = contentRect.width - 170;
      }

      // Ensure tooltip doesn't overflow the left edge
      if (left < 0) {
        left = 0;
      }

      // Ensure tooltip doesn't overflow the bottom edge
      if (top + 50 > contentRect.height) {
        top = contentRect.height - 50;
      }

      return { top, left };
    },

    async handleTextSelection(event: Event) {
      const editableContent = this.$refs.el;

      // check if it's a link click
      let isClickOnLink = false;
      let node = event.target as Element;
      while (node && node !== editableContent) {
        if (node.nodeName === 'A') {
          const rect = node.getBoundingClientRect();
          const contentRect = editableContent.getBoundingClientRect();

          this.tooltipPosition = this.calculateTooltipPosition(rect, contentRect);

          this.selectedText = node.textContent || '';
          this.showTooltip = true;
          this.ignoreNextOutsideClick = true;
          setTimeout(() => {
            this.ignoreNextOutsideClick = false;
          }, 0); // Delay just enough to ignore the next immediate click

          // Store the current range
          const selection = window.getSelection();
          if (selection && selection.rangeCount > 0) {
            this.currentRange = selection.getRangeAt(0);
          }

          isClickOnLink = true;
          break;
        }
        if (node.parentNode) {
          node = node.parentNode as Element;
        }
      }
      if (isClickOnLink) {
        return;
      }

      // otherwise show tooltip
      const selection = window.getSelection();
      if (!selection) return;

      if (selection.rangeCount > 0 && editableContent.contains(selection.anchorNode)) {
        const range = selection.getRangeAt(0);
        const selectedText = selection.toString();

        if (selectedText.length > 0) {
          this.currentRange = range; // Store the current range
          const rect = range.getBoundingClientRect();
          const contentRect = editableContent.getBoundingClientRect();

          this.tooltipPosition = this.calculateTooltipPosition(rect, contentRect);

          this.selectedText = selectedText;
          this.showTooltip = true;
          this.ignoreNextOutsideClick = true;
          setTimeout(() => {
            this.ignoreNextOutsideClick = false;
          }, 0); // Delay just enough to ignore the next immediate click
        } else {
          this.showTooltip = false;
          this.currentRange = null;
        }
      } else {
        this.currentRange = null;
      }
    },

    isFormatted(action: FormattingType) {
      const formatMap: Record<FormattingType, string> = {
        bold: 'B',
        italic: 'EM',
        strikethrough: 'DEL',
        inlineCode: 'CODE',
        link: 'A',
      };
      const tagName = formatMap[action];
      if (!tagName) return false;

      const selection = window.getSelection();
      if (!selection) return false;

      let node = selection.anchorNode;

      // Check if the selection is a range
      if (this.currentRange) {
        const range = this.currentRange.cloneRange();
        const container = document.createElement('div');
        container.appendChild(range.cloneContents());

        // Check if any ancestor of the selected text matches the tagName
        node = range.startContainer;
      }

      // Traverse up the DOM to check if any ancestor matches the tagName
      while (node && node !== this.$refs.el) {
        if (node.nodeName === tagName) {
          return true;
        }
        node = node.parentNode;
      }

      return false;
    },

    format(action: FormattingType) {
      const selection = window.getSelection();
      if (!selection) return;

      selection.removeAllRanges();
      selection.addRange(this.currentRange);

      const formatMap: Record<FormattingType, string> = {
        bold: 'b',
        italic: 'em',
        strikethrough: 'del',
        inlineCode: 'code',
        link: 'a',
      };

      const tagName = formatMap[action];
      if (!tagName) return;

      const range = selection.getRangeAt(0);

      if (this.isFormatted(action)) {
        // Revert the formatting by traversing through the parents and replacing the correct parent with a text node
        let node = range.startContainer;
        while (node && node !== this.$refs.el) {
          if (node.nodeName === tagName.toUpperCase()) {
            const parent = node.parentNode;
            const textNode = document.createTextNode(node.textContent || '');
            parent.replaceChild(textNode, node);
            break;
          }
          node = node.parentNode;
        }
      } else {
        // Apply the formatting
        const element = document.createElement(tagName);

        if (action === 'link') {
          const textNode = document.createTextNode(this.selectedText);
          element.appendChild(textNode);
          element.setAttribute('href', this.url);
          element.setAttribute('target', '_blank');
          element.style.color = '#3b82f6'; // Tailwind class text-blue-500
          element.style.textDecoration = 'underline'; // Tailwind class underline
          range.deleteContents(); // Remove the old text
        } else {
          element.appendChild(range.extractContents());
        }
        range.insertNode(element);
      }

      // Clean up selection
      selection.removeAllRanges();
      this.showTooltip = false;
      this.currentRange = null;
      this.isLinkingActive = false;
      this.url = '';
      // Emit change
      this.$emit('update:modelValue', this.getPlainText());
      this.$emit('change');
    },

    getPlainText(): string {
      return convertFormattingToPlainText(this.returnPlainText ? this.$refs.el?.innerText : this.$refs.el?.innerHTML);
    },

    onEnter(e) {
      if (!e.shiftKey && !this.multiline) {
        this.$emit('enter', e);
        e.preventDefault();
      }
    },

    onChange(e) {
      this.checkPlaceholder();
      this.changed = true;
      this.$emit('keyup', e);
      this.$emit('update:modelValue', this.getPlainText());
      clearTimeout(this.changeTimer);
      this.changeTimer = setTimeout(() => {
        this.$emit('change');
        this.changed = false;
      }, 2000);
    },

    checkPlaceholder() {
      const text = this.getPlainText();
      this.showPlaceholder = !text || text === '<br>';
    },

    onFocus() {
      this.$emit('focus');
    },

    onBlur() {
      this.$emit('blur', this.getPlainText());
      if (this.changed) {
        this.$emit('change');
        this.changed = false;
        clearTimeout(this.changeTimer);
      }
    },

    selectionChanged() {
      if (
        !window.getSelection() ||
        !window.getSelection().baseNode ||
        !this.$refs.el?.contains(window.getSelection().baseNode.parentNode)
      ) {
        return;
      }
      this.selRange = this.saveSelection();
    },

    saveSelection() {
      if (window.getSelection) {
        const sel = window.getSelection();
        if (sel.getRangeAt && sel.rangeCount) {
          return sel.getRangeAt(0);
        }
      } else if (document.selection && document.selection.createRange) {
        return document.selection.createRange();
      }
      return null;
    },

    restoreSelection(range) {
      if (range) {
        if (window.getSelection) {
          const sel = window.getSelection();
          sel.removeAllRanges();
          sel.addRange(range);
        } else if (document.selection && range.select) {
          range.select();
        }
      }
    },

    insertTextAtCursor(text, element = null, removeOnBackspace = false) {
      let sel, range;
      if (window.getSelection) {
        sel = window.getSelection();
        if (sel.getRangeAt && sel.rangeCount) {
          range = sel.getRangeAt(0);
          range.deleteContents();
          let textNode;

          if (!element) {
            textNode = document.createTextNode(text);
          } else {
            textNode = document.createElement(element);
            if (removeOnBackspace) {
              textNode.className = 'sms-tag';
              textNode.contentEditable = false;
            }
            textNode.innerHTML = text;
          }
          if (removeOnBackspace) {
            //required for erasing in firefox
            const newTextNode = document.createElement('span');
            newTextNode.className = 'remove-prev';
            range.insertNode(newTextNode);
          }
          range.insertNode(textNode);
          sel.removeAllRanges();
          range = range.cloneRange();
          range.selectNode(textNode);
          range.collapse(false);
          sel.addRange(range);

          if (removeOnBackspace) {
            this.$nextTick(() => {
              this.removePrev.addEventListener('DOMNodeRemoved', this.removePreviousElements);
            });
          }
        }
      } else if (document.selection && document.selection.createRange) {
        range = document.selection.createRange();
        range.pasteHTML(text);
        range.select();
      }
    },

    insertText(text) {
      this.restoreSelection(this.selRange);
      this.$refs.el.focus();
      this.insertTextAtCursor(text);
      if (this.returnPlainText) {
        this.$emit('update:modelValue', this.$el.innerText.replace(/\u00A0/g, ` `));
      } else {
        this.$emit('update:modelValue', this.$el.innerHTML);
      }
    },
    removePreviousElements(e: Event) {
      if (e.target instanceof Element && e.target.previousElementSibling) {
        e.target.previousElementSibling.remove();
      }
    },
  },
};
</script>
<style scoped lang="scss">
.tooltip-container {
  z-index: 9999;
}

.tooltip-container > div {
  z-index: 9999;
}

.selected {
  background-color: #e2e8f0;
}

.placeholder {
  position: absolute;
  color: #a0aec0; /* Tailwind class text-gray-400 */
  pointer-events: none;
  user-select: none;
}
</style>
