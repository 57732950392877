<template>
  <div v-if="loaded" class="row-col">
    <email-settings
      v-if="channelType === 'EMAIL'"
      :action="action"
      @reload-channel-index-list="$emit('reloadChannelIndexList')"
    />
    <div v-else class="row-row">
      <div class="row-body scrollable">
        <channel-created-modal :route="`/admin/channel2/${channelType}`" :badge="channelType" />
        <div ref="scrollableContainer" class="row-inner">
          <div class="p-a p-b-0 container">
            <div class="p-a">
              <router-link :to="'/admin/channels2/' + record.type.toLowerCase()" class="mb-8 ml-2 flex items-center">
                <img
                  class="mr-2 inline-block h-3"
                  :src="`${$root.assetsURL}img/action_icons/icon-arrow-left.svg`"
                  alt="back"
                />
                <span class="mx-2 font-semibold">{{ $t('settings.channels_back_to_overview') }}</span>
              </router-link>

              <wab-verification-reminder v-if="showWABReminder" />
              <form autocomplete="off" class="form-horizontal" @submit.prevent="save">
                <div class="box">
                  <div class="box-header">
                    <h2>{{ $t('settings.channels_general') }}</h2>
                  </div>
                  <div class="box-divider m-a-0"></div>
                  <div class="box-body">
                    <div class="form-group row">
                      <label class="col-sm-2 form-control-label">
                        {{ $t('settings.channels_internal_name') }}
                        <span class="text-danger">*</span>
                      </label>
                      <div class="col-sm-10">
                        <input v-model="record.title" type="text" autocomplete="off" class="form-control" />
                        <span class="form-text">{{ $t('settings.channels_the_internal_name_of_this_channel') }}</span>
                      </div>
                    </div>
                    <div v-if="showMoreGeneralSettings()">
                      <div v-show="hasColorInput" class="form-group row">
                        <label class="col-sm-2 form-control-label">{{ $t('settings.channels_color') }}</label>
                        <div class="col-sm-10">
                          <color-picker v-model="record.color" :default="null" />
                          <span class="form-text">
                            {{
                              $t(
                                'settings.channels_optional_select_a_color_for_this_channel_used_in_the_ticket_overview',
                              )
                            }}
                          </span>
                        </div>
                      </div>

                      <div v-show="hasNotificationSound" class="form-group row">
                        <label class="col-sm-2 form-control-label">{{ $t('settings.channels_notification') }}</label>
                        <div class="col-sm-10">
                          <div class="input-group">
                            <select v-model="record.notification_sound" class="form-control custom-select">
                              <option value="chat.mp3">
                                {{ $t('settings.channels_notification_option_chat_default') }}
                              </option>
                              <option value="hold-on.mp3">
                                {{ $t('settings.channels_notification_option_hold_on') }}
                              </option>
                              <option value="light.mp3">{{ $t('settings.channels_notification_option_light') }}</option>
                              <option value="open-ended.mp3">
                                {{ $t('settings.channels_notification_option_open_ended') }}
                              </option>
                              <option value="plucky.mp3">
                                {{ $t('settings.channels_notification_option_plucky') }}
                              </option>
                              <option value="">
                                {{ $t('settings.channels_notification_option_no_notification') }}
                              </option>
                            </select>
                            <span class="input-group-addon cursor-pointer" @click="playNotificationSound">
                              <i class="fa fa-play" aria-hidden="true"></i>
                            </span>
                          </div>
                          <span class="form-text">
                            {{
                              $t(
                                'settings.channels_choose_the_notification_sound_or_turn_notifications_off_for_messages',
                              )
                            }}
                          </span>
                        </div>
                      </div>

                      <div v-show="hasBusinessHours" class="form-group row">
                        <label class="col-sm-2 form-control-label">{{ $t('settings.channels_business_hours') }}</label>
                        <div class="col-sm-10">
                          <select v-model="record.business_hour_id" class="form-control">
                            <option
                              v-for="businessHour in businessHours"
                              :key="businessHour.id"
                              :value="businessHour.id"
                            >
                              {{ businessHour.name }}
                            </option>
                          </select>
                          <span class="form-text">
                            {{
                              $t(
                                'settings.channels_business_hours_determine_during_which_hours_you_can_be_reached_on_this_channel',
                              )
                            }}
                            <router-link
                              v-if="userStore.hasPermission(PERMISSION.SETTINGS__BUSINESS_HOURS__MANAGE)"
                              class="btn btn-xs btn-default"
                              :to="SETTINGS_URL.ADMIN__BUSINESS_HOURS"
                            >
                              {{ $t('settings.channels_manage_business_hours') }}
                            </router-link>
                          </span>
                        </div>
                      </div>

                      <div v-show="hasNotificationEmail" class="form-group row">
                        <label class="col-sm-2 form-control-label">
                          {{ $t('settings.channels_notification_email') }}
                        </label>
                        <div class="col-sm-10">
                          <input
                            v-model="record.notification_email"
                            type="text"
                            autocomplete="off"
                            class="form-control"
                          />
                          <span class="form-text">
                            {{ $t('settings.channels_notification_email_delimited_by_commas_info_text') }}
                          </span>
                        </div>
                      </div>

                      <div v-show="hasReopenClosedTickets" class="form-group row">
                        <label class="col-sm-2 form-control-label -mt-2">
                          {{ $t('settings.channels_reopen_closed_conversations') }}
                        </label>
                        <div class="col-sm-10">
                          <label class="ui-switch success m-t-xs m-r">
                            <input v-model="record.reopen_closed_ticket" type="checkbox" true-value="1" />
                            <i></i>
                          </label>
                          <span class="form-text">
                            {{ $t('settings.channels_reopen_closed_conversations_info_text') }}
                          </span>

                          <div v-if="record.reopen_closed_ticket" class="bg mt-4 rounded-lg bg-grey-200 p-4">
                            <div class="flex items-center">
                              <div style="width: 300px">
                                {{ $t('settings.channels_assign_conversations_to_the_previous_user') }}
                              </div>
                              <label class="ui-switch success">
                                <input v-model="record.reassign_reopened_ticket" type="checkbox" />
                                <i></i>
                              </label>
                            </div>
                            <div class="mt-4 flex flex-wrap items-center">
                              <div class="mb-2 lg:mb-0" style="width: 300px">
                                {{ $t('settings.channels_do_not_reopen_conversations_after') }}
                              </div>
                              <div class="">
                                <input
                                  v-model="record.reopen_closed_ticket_time_window_days"
                                  type="number"
                                  autocomplete="off"
                                  class="form-control mr-1"
                                  style="width: 60px; display: inline-block; padding: 5px"
                                />
                                <span class="text-grey-600">
                                  {{ $t('settings.channels_days_of_no_messages') }}
                                  <span class="ml-2 text-xs">
                                    {{ $t('settings.channels_to_prevent_large_threads') }}
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="action === 'create' || (action === 'edit' && !record.is_private)" class="box">
                  <div class="box-header">
                    <h2>{{ $t('settings.channels_access_access') }}</h2>
                  </div>
                  <div class="box-divider m-a-0"></div>
                  <div class="box-body">
                    <div v-show="hasTeams && canBePrivate && action === 'create'" class="form-group row">
                      <label class="col-sm-2 form-control-label">
                        {{ $t('settings.channels_access_type') }}
                        <span class="text-danger">*</span>
                      </label>
                      <div class="col-sm-10">
                        <div class="flex select-none">
                          <div
                            class="mr-2 flex-1 cursor-pointer rounded-lg border p-4"
                            :class="{
                              'border-2 border-grey-200': !record.is_private,
                              'border-white': record.is_private,
                            }"
                            @click="record.is_private = false"
                          >
                            <div class="flex">
                              <div class="mr-3">
                                <div
                                  class="inline-block-24px flex h-24 items-center justify-center rounded-full border-2 border-grey-200"
                                >
                                  <div
                                    v-if="!record.is_private"
                                    class="h-full w-full rounded-full border-2 border-white bg-green"
                                  ></div>
                                </div>
                              </div>
                              <div>
                                <div class="font-bold">{{ $t('settings.channels_access_team_channel') }}</div>
                                <div class="mt-1 text-grey-600">
                                  {{ $t('settings.channels_access_conversations_team_selection_info_text') }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="ml-2 flex-1 cursor-pointer rounded-lg border p-4"
                            :class="{
                              'border-2 border-grey-200': record.is_private,
                              'border-white': !record.is_private,
                            }"
                            @click="record.is_private = true"
                          >
                            <div class="flex">
                              <div class="mr-3">
                                <div class="inline-block-24px h-24 rounded-full border-2 border-grey-200">
                                  <div
                                    v-if="record.is_private"
                                    class="h-full w-full rounded-full border-2 border-white bg-green"
                                  ></div>
                                </div>
                              </div>
                              <div>
                                <div class="font-bold">
                                  {{ $t('settings.channels_access_private_channel') }}
                                  <label class="label mb-0">beta</label>
                                </div>
                                <div class="mt-1 text-grey-600">
                                  {{
                                    $t('settings.channels_access_conversations_private_channel_only_by_user_info_text')
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="hasTeams && !record.is_private" class="form-group row">
                      <label class="col-sm-2 form-control-label">
                        {{ $t('settings.channels_access_teams') }}
                        <span class="text-danger">*</span>
                      </label>
                      <div class="col-sm-10">
                        <multiselect
                          v-model="record.teams"
                          label="name"
                          track-by="id"
                          :allow-empty="false"
                          :options="teams"
                          :searchable="true"
                          :close-on-select="true"
                          :show-labels="false"
                          :multiple="true"
                          :placeholder="$t('settings.channels_access_select_one_or_more_teams')"
                          @select="onSelectTeamOption"
                        >
                          <template #option="props">
                            <div class="option__desc">
                              <span
                                v-tooltip="
                                  props.option.isDisabled && {
                                    content: $t('general.team_has_workload_balancing_activated', {
                                      team: props.option.name,
                                    }),
                                    popperClass: 'team-select-tooltip',
                                    placement: 'bottom-start',
                                  }
                                "
                                class="option__title flex flex-row"
                                :class="{ 'bg-transparent text-grey-400': props.option.isDisabled }"
                              >
                                <span>{{ props.option.name }}</span>
                                <span v-if="props.option.isDisabled" class="ml-3">
                                  <load-balance-linear class="text-grey-400" size="1rem" />
                                </span>
                              </span>
                            </div>
                          </template>
                        </multiselect>
                        <span class="form-text">
                          {{
                            $t(
                              'settings.channels_access_select_the_teams_that_have_access_to_conversations_from_this_channel',
                            )
                          }}
                        </span>
                      </div>
                    </div>
                    <div v-show="canBePrivate && record.is_private" class="form-group row">
                      <label class="col-sm-2 form-control-label">
                        {{ $t('settings.channels_access_user') }}
                        <span class="text-danger">*</span>
                      </label>
                      <div v-if="action === 'create'" class="col-sm-10">
                        <multiselect
                          v-model="record.users"
                          label="name"
                          track-by="id"
                          :allow-empty="false"
                          :options="privateInboxUsers"
                          :searchable="true"
                          :close-on-select="true"
                          :show-labels="false"
                          :multiple="false"
                          :placeholder="$t('settings.channels_access_select_a_user_that_belongs_to_this_inbox')"
                        ></multiselect>
                        <span class="form-text">
                          {{ $t('settings.channels_access_select_a_user_that_belongs_to_this_inbox_info_text') }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="canBePrivate && action === 'edit' && record.is_private" class="box">
                  <div class="box-header">
                    <h2>
                      <i class="fa fa-lock text-grey-500"></i>
                      {{ $t('settings.channels_access_private_inbox') }}
                    </h2>
                  </div>
                  <div class="box-divider m-a-0"></div>
                  <div class="box-body">
                    <div class="form-group row">
                      <label class="col-sm-2 form-control-label">{{ $t('settings.channels_access_user') }}</label>
                      <div class="col-sm-10">
                        <div class="flex select-none">
                          <div class="">
                            <div class="mt-1">
                              <router-link
                                :to="
                                  userStore.hasPermission(PERMISSION.PERMISSIONS__USERS__MANAGE)
                                    ? '/admin/users/' + record.users[0].id
                                    : null
                                "
                              >
                                <div class="flex select-none leading-none">
                                  <div class="mr-2">
                                    <avatar
                                      width="40"
                                      :abbr="record.users[0].abbr"
                                      :color="record.users[0].color"
                                      :image="record.users[0].profile_image"
                                    ></avatar>
                                  </div>
                                  <div>
                                    <div class="mb-2 text-base font-bold">{{ record.users[0].first_name }}</div>
                                    <div v-if="record.users[0].teams.length" class="text-muted text-ellipsis text-sm">
                                      {{ record.users[0].teams.map((t) => t.name).join(', ') }}
                                    </div>
                                  </div>
                                </div>
                              </router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <component
                  :is="channelType"
                  ref="channelAction"
                  v-model="record"
                  :action="action"
                  :show-footer="showFooter"
                  :ready="ready"
                  :scrollable-container-ref="scrollableContainerRef"
                  :set-create-text="setCreateText"
                  :overflow-phone-number-prop="overflow_phone_number"
                  :outside-business-hours-phone-number-prop="outside_business_hours_phone_number"
                  :full-queue-phone-number-prop="full_queue_phone_number"
                  @reload-channel-index-list="$emit('reloadChannelIndexList')"
                  @update-ready="updateReady"
                  @update-show-footer="updateShowFooter"
                  @call-init-warning-method="callInitWarningMethod"
                  @update-loaded="updateLoaded"
                  @update-loading="updateLoading"
                  @call-update-channel-method="updateChannel"
                  @update-outside-business-hours-phone-number="updateOutsideBusinessHoursPhoneNumber"
                  @update-full-queue-phone-number="updateFullQueuePhoneNumber"
                  @update-overflow-phone-number="updateOverflowPhoneNumber"
                />

                <component
                  :is="channelType"
                  v-if="showInfoChannel"
                  ref="channelInfo"
                  v-model="record"
                  action="info"
                  :show-footer="showFooter"
                  :ready="ready"
                  :scrollable-container-ref="scrollableContainerRef"
                  :set-create-text="setCreateText"
                  :overflow-phone-number-prop="overflow_phone_number"
                  :outside-business-hours-phone-number-prop="outside_business_hours_phone_number"
                  :full-queue-phone-number-prop="full_queue_phone_number"
                  @reload-channel-index-list="$emit('reloadChannelIndexList')"
                  @update-ready="updateReady"
                  @update-show-footer="updateShowFooter"
                  @call-init-warning-method="callInitWarningMethod"
                  @update-loaded="updateLoaded"
                  @update-loading="updateLoading"
                  @call-update-channel-method="updateChannel"
                  @update-outside-business-hours-phone-number="updateOutsideBusinessHoursPhoneNumber"
                  @update-full-queue-phone-number="updateFullQueuePhoneNumber"
                  @update-overflow-phone-number="updateOverflowPhoneNumber"
                />

                <t-inline-banner v-if="shouldShowAutoTopUpBanner" type="default" class="mb-6">
                  <template #icon><info-linear size="1.25rem" /></template>
                  <div class="mr-4 flex flex-col">
                    <div class="work-load-wrapper">
                      <div class="text-sm font-medium leading-5 text-grey-800">
                        {{ $t('channels.usage_is_deducted_from_your_balance', { channel: getChannelKey }) }}
                      </div>
                    </div>
                  </div>
                  <template #action>
                    <t-button
                      class="open-external"
                      btn-style="secondary"
                      size="sm"
                      target="_blank"
                      @click="onLearnMoreClicked"
                    >
                      <div class="flex flex-row items-center">
                        <span class="t-text-sm-emphasize mr-1">{{ $t('general.learn_more') }}</span>
                        <arrow-top-right-linear class="text-grey-700" :size="20" />
                      </div>
                    </t-button>
                  </template>
                </t-inline-banner>

                <div v-if="showFooter" class="box">
                  <div class="box-body flow-root">
                    <button
                      v-if="action == 'edit'"
                      type="submit"
                      :class="{ loader: saving }"
                      class="btn success text-white"
                      :disabled="!ready || uploading || saving || !ready_to_save"
                      v-text="saving ? $t('settings.channels_updating') : $t('settings.channels_update_channel')"
                    ></button>
                    <button
                      v-if="action == 'create'"
                      type="submit"
                      :class="{ loader: saving }"
                      class="btn success text-white"
                      :disabled="!ready || uploading || saving || !ready_to_save"
                      v-text="saving ? $t('settings.channels_creating') : createText"
                    ></button>
                    <button
                      v-if="action == 'edit'"
                      type="button"
                      class="btn btn-link text-muted pull-right"
                      @click="deleteChannel()"
                    >
                      {{ $t('channels.delete_button') }}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <delete-channel-modal
      :channel-id="route.params.id as string"
      :channel-type="route.params.channel as string"
      :confirmation-text="`${route.params.channel}-${route.params.id}`"
    />
  </div>
</template>

<script lang="ts">
import { Compact } from '@ckpack/vue-color';
import { ArrowTopRightLinear, InfoLinear, LoadBalanceLinear } from '@trengo/trengo-icons';
import { map } from 'lodash';
import { mapStores } from 'pinia';
import { defineComponent } from 'vue';
import Multiselect from 'vue-multiselect';
import { useRoute } from 'vue-router';

import ChannelCreatedModal from '@/components/Channels/ChannelCreatedModal';
import WabVerificationReminder from '@/components/Channels/WabVerificationReminder';
import { CHANNEL_TYPE, FEATURE_FLAG_VOLUME, PERMISSION } from '@/Configs/Constants';
import { SETTINGS_URL } from '@/Configs/Constants/navigation/settingsUrl';
import { WhatsappProviders } from '@/Configs/Constants/whatsapp';
import eventBus from '@/eventBus';
import { useCompanyProfileStore, useFeatureFlagStore, useSubscriptionStore, useUserStore } from '@/store/pinia';
import { flashError } from '@/util/flashNotification';

import ChatChannel from './ChatChannel';
import CustomChannel from './CustomChannel';
import DeleteChannelModal from './DeleteChannelModal/DeleteChannelModal.vue';
import EmailSettings from './EmailSettings';
import FacebookChannel from './FacebookChannel';
import GoogleBusinessMessagesChannel from './GoogleBusinessMessagesChannel';
import InstagramChannel from './InstagramChannel';
import SmsChannel from './SmsChannel';
import { useDeleteChannelModalStore } from './stores/deleteChannelModalStore';
import TelegramChannel from './TelegramChannel';
import VoipChannel from './VoipChannel';
import WaBusiness from './WaBusiness';
import ZipwhipChannel from './ZipwhipChannel';
import RouterWarningOnEdit from '../../mixins/RouterWarningOnEdit';
import Avatar from '../Avatar';
import ColorPicker from '../Elements/ColorPicker';

let previewSound;

export default defineComponent({
  name: 'ChannelEdit',
  components: {
    InfoLinear,
    ArrowTopRightLinear,
    LoadBalanceLinear,
    WabVerificationReminder,
    ChannelCreatedModal,
    Multiselect,
    'compact-picker': Compact,
    SMS: SmsChannel,
    CHAT: ChatChannel,
    VOIP: VoipChannel,
    TELEGRAM: TelegramChannel,
    FACEBOOK: FacebookChannel,
    ZIPWHIP: ZipwhipChannel,
    INSTAGRAM: InstagramChannel,
    WA_BUSINESS: WaBusiness,
    CUSTOM: CustomChannel,
    GBM: GoogleBusinessMessagesChannel,
    ColorPicker,
    EmailSettings,
    Avatar,
    DeleteChannelModal,
  },

  mixins: [RouterWarningOnEdit],
  props: {
    action: {
      type: String,
      default: 'create',
    },
  },
  emits: ['reloadChannelIndexList'],

  watch: {
    'record.is_private'(v) {
      if (v && this.action === 'create') {
        this.record.users = this.$root.user;
      }
    },
  },

  computed: {
    ...mapStores(useUserStore, useSubscriptionStore, useFeatureFlagStore, useCompanyProfileStore),
    hasUsageBasedSubscription() {
      return this.companyProfileStore.isUsageBasedPricingModel;
    },
    shouldShowAutoTopUpBanner() {
      const channelsToShowBanner = [CHANNEL_TYPE.WA_BUSINESS, CHANNEL_TYPE.SMS, CHANNEL_TYPE.VOIP];
      if ([CHANNEL_TYPE.VOYS, CHANNEL_TYPE.AIRCALL].includes(this.record?.voipChannel?.provider)) {
        return false;
      }
      return (
        this.hasUsageBasedSubscription &&
        this.featureFlagStore.isEnabled(FEATURE_FLAG_VOLUME.TVE_SHOW_BANNER_FOR_AUTO_TOPUP) &&
        channelsToShowBanner.includes(this.channelType)
      );
    },
    getChannelKey() {
      if (this.channelType === CHANNEL_TYPE.WA_BUSINESS) {
        return 'WhatsApp';
      }
      if (this.channelType === CHANNEL_TYPE.VOIP) {
        return 'Voice';
      }
      if (this.channelType === CHANNEL_TYPE.SMS) {
        return 'SMS';
      }
      return '';
    },
    showWABReminder() {
      return (
        CHANNEL_TYPE.WA_BUSINESS === this.channelType &&
        this.record.whatsappChannel.provider === WhatsappProviders.TRENGO_BSP.id &&
        this.action === 'edit' &&
        !this.record?.whatsappChannel?.approved_at
      );
    },
    privateInboxUsers() {
      return this.$root.getBillableUsers();
    },

    showInfoChannel() {
      if (this.action === 'create') {
        return false;
      }

      return (
        this.channelType === 'FACEBOOK' ||
        this.channelType === 'TELEGRAM' ||
        this.channelType === 'EMAIL' ||
        this.channelType === 'ZIPWHIP' ||
        this.channelType === 'INSTAGRAM' ||
        this.channelType === 'WA_BUSINESS'
      );
    },

    ready_to_save() {
      return true;
    },

    canBePrivate() {
      return this.channelType === 'EMAIL';
    },

    hasTeams() {
      return true;
    },

    hasAutoReplies() {
      if (this.record.type === 'SMS' && this.record.smsChannel.type === 'SEND_ONLY') {
        return false;
      }

      if (
        this.record.type === 'VOIP' ||
        this.record.type === 'EMAIL' ||
        this.record.type === 'FACEBOOK' ||
        this.record.type === 'INSTAGRAM'
      ) {
        return false;
      }

      return true;
    },

    hasBusinessHours() {
      const isVoys = this.record.type === 'VOIP' && this.record?.voipChannel?.provider === 'VOYS';
      const isAircall = this.record.type === 'VOIP' && this.record?.voipChannel?.provider === 'AIRCALL';
      return !isVoys && !isAircall;
    },

    hasColorInput() {
      if (this.record.type === 'SMS' && this.record.smsChannel.type === 'SEND_ONLY') {
        return false;
      }

      // voip uses different sound
      if (this.record.type === 'VOIP') {
        return false;
      }

      return true;
    },

    hasNotificationSound() {
      if (this.record.type === 'SMS' && this.record.smsChannel.type === 'SEND_ONLY') {
        return false;
      }

      // voip uses different sound
      if (this.record.type === 'VOIP') {
        return false;
      }

      return true;
    },

    hasNotificationEmail() {
      if (this.record.type === 'SMS' && this.record.smsChannel.type === 'SEND_ONLY') {
        return false;
      }

      if (this.record.type === 'EMAIL') {
        return false;
      }

      if (this.record.type === 'VOIP') {
        return false;
      }

      return true;
    },

    hasReopenClosedTickets() {
      return ['EMAIL', 'WA_BUSINESS', 'FACEBOOK', 'TELEGRAM', 'SMS', 'INSTAGRAM'].includes(this.record.type);
    },

    outside_business_hours_phone_number: {
      get() {
        if (this.findOverFlowRecord('OUTSIDE_BUSINESS_HOURS')) {
          return this.findOverFlowRecord('OUTSIDE_BUSINESS_HOURS').phone_number;
        }
        return null;
      },
      set(newVal) {
        if (this.findOverFlowRecord('OUTSIDE_BUSINESS_HOURS')) {
          this.findOverFlowRecord('OUTSIDE_BUSINESS_HOURS').phone_number = newVal;
        } else {
          this.record.voipChannel.voip_overflow.push({
            voip_channel_id: this.record.voipChannel.id,
            type: 'OUTSIDE_BUSINESS_HOURS',
            phone_number: newVal,
          });
        }
        this.record.voipChannel.outside_business_hours_phone_number = newVal;
      },
    },

    full_queue_phone_number: {
      get() {
        if (this.findOverFlowRecord('FULL_QUEUE')) {
          return this.findOverFlowRecord('FULL_QUEUE').phone_number;
        }
        return null;
      },
      set(newVal) {
        if (this.findOverFlowRecord('FULL_QUEUE')) {
          this.findOverFlowRecord('FULL_QUEUE').phone_number = newVal;
        } else {
          this.record.voipChannel?.voip_overflow.push({
            voip_channel_id: this.record.voipChannel.id,
            type: 'FULL_QUEUE',
            phone_number: newVal,
          });
        }
        this.record.voipChannel.full_queue_phone_number = newVal;
      },
    },

    overflow_phone_number: {
      get() {
        if (this.findOverFlowRecord('NO_AGENTS_AVAILABLE')) {
          return this.findOverFlowRecord('NO_AGENTS_AVAILABLE').phone_number;
        }
        return null;
      },
      set(newVal) {
        if (this.findOverFlowRecord('NO_AGENTS_AVAILABLE')) {
          this.findOverFlowRecord('NO_AGENTS_AVAILABLE').phone_number = newVal;
        } else {
          this.record.voipChannel.voip_overflow.push({
            voip_channel_id: this.record.voipChannel.id,
            type: 'NO_AGENTS_AVAILABLE',
            phone_number: newVal,
          });
        }
        this.record.voipChannel.overflow_phone_number = newVal;
      },
    },

    sentUpdatedEvent() {
      return this.record.type === 'FACEBOOK' || this.record.type === 'INSTAGRAM';
    },

    isLegacyChannelTypeSetting() {
      return !['EMAIL'].includes(this.$route.params.channel.toUpperCase());
    },
  },

  setup() {
    const route = useRoute();
    const deleteChannelModalStore = useDeleteChannelModalStore();

    return {
      route,
      deleteChannelModalStore,
    };
  },

  data() {
    return {
      PERMISSION,
      SETTINGS_URL,
      ready: true,
      dirty: false,
      uploading: false,
      loaded: false,
      businessHours: [],
      record: {
        type: this.$route.params.channel.toUpperCase(),
        notification_email: '',
        notification_sound: 'chat.mp3',
        username: '',
        password: '',
        password_is_null: false,
        title: this.$route.params.channel,
        color: null,
        teams: [],
        users: [],
        reopen_closed_ticket: 1,
        business_hour_id: null,
        is_private: false,
        reassign_reopened_ticket: false,
        reopen_closed_ticket_time_window_days: 30,

        voipChannel: {
          welcome_greeting_id: null,
          voicemail_greeting_id: null,
          no_answer_greeting_id: null,
          outgoing_caller_id: null,
          allow_calls_outside_business_hours: true,
          record: true,
          queue_size_limit: 5,
          queue_time_limit: 30,
          country: 'NL',
          phone_number: {
            requires_address: false,
          },
          voip_overflow: [],
          outside_business_hours_phone_number: null,
          overflow_phone_number: null,
          voip_ivr_menu_id: null,
          meta: {},
          enable_voicemail: 1,
          store_missed_calls: 1,
          provider: 'TWILIO_TRENGO',
          sip_devices: [
            {
              user_id: null,
              extension: null,
              meta: {},
            },
          ],
          terms: true,
        },

        whatsappChannel: {
          meta: {},
          sms_channel_id: null,
          provider: 'sandbox',
        },

        waSandboxNumbers: [],
        voipSandboxNumbers: [],

        smsChannel: {
          type: 'SEND_ONLY',
          meta: {},
          provider: 'TWILIO_TRENGO',
          country: 'NL',
        },

        facebookChannel: {},
        instagramChannel: {
          instagram_business_account_id: null,
          private_messages: 0,
          comments: 1,
          mentions: 1,
        },

        gbmChannel: {
          brand_id: null,
          agent_id: null,
          meta: {
            logoAttachmentId: null,

            gbmAgent: {
              businessMessagesAgent: null,
            },
            gbmBrand: null,
            agentVerificationContact: null,
          },
        },
      },
      saving: false,
      teams: [],
      channelType: this.$route.params.channel.toUpperCase(),
      createText: this.$t('settings.channels_create_channel'),
      showFooter: true,
      permission: false,
      scrollableContainerRef: this.$refs.scrollableContainer,
    };
  },
  mounted() {
    if (!this.isLegacyChannelTypeSetting) {
      this.loaded = true;
      return;
    }

    this.channel = this.$route.params.type;

    if (this.action === 'edit') {
      axios
        .get('/api/v2/channels/' + this.$route.params.id)
        .then((result) => {
          this.record = result.data;
          if (!result.data.facebookChannel) {
            this.record.facebookChannel = { private_messages: true };
          }
          if (!this.record.business_hour_id && this.businessHours && this.businessHours.length) {
            this.record.business_hour_id = this.businessHours[0]['id'];
          }
          this.$nextTick(() => this.initWarning());
        })
        .catch((res) => {
          if (res.response.status) {
            flashError(this.$t('channels.fetch_unsuccessful_does_not_exist'));
            router.push('/admin/channels2/' + this.channelType.toLowerCase());
          }
        });
    } else {
      this.$nextTick(() => this.initWarning());

      if (this.channelType === CHANNEL_TYPE.WA_BUSINESS && this.$route.query.bsp) {
        this.setBspFields();
      }
    }

    axios.get('/api/v2/business_hours').then((r) => {
      if (this.loaded && !this.record.business_hour_id && r.data.data && r.data.data.length) {
        this.record.business_hour_id = r.data.data[0]['id'];
        this.$nextTick(() => this.initWarning());
      }

      this.businessHours = r.data.data;
    });

    axios.get('/api/v2/teams').then((res) => {
      this.teams = res.data.data;
      this.teams = this.teams.map((team) => {
        return { ...team, isDisabled: team.load_balancing_enabled };
      });
      if (this.action === 'create') {
        this.record.teams.push(res.data.data[0]);
        this.$nextTick(() => this.initWarning());

        if (this.shouldSaveOnCreate()) {
          this.save();
        }
      }
    });

    eventBus.$on('update-channel-record', (record) => {
      this.record = record;
    });

    eventBus.$on('show-channel-edit-footer', () => {
      this.showFooter = this.ready = true;
    });
  },
  updated() {
    if (this.$refs.scrollableContainer && !this.scrollableContainerRef) {
      this.scrollableContainerRef = this.$refs.scrollableContainer;
    }
  },

  methods: {
    onLearnMoreClicked($event: Event) {
      $event.preventDefault();
      window.open('https://help.trengo.com/article/new-plan-pricing#third-party-carrier-cost', '_blank');
    },
    onSelectTeamOption(item) {
      this.$nextTick(() => {
        if (item.isDisabled) {
          this.record.teams = this.record.teams.filter((team) => team.id !== item.id);
        }
      });
    },
    setBspFields() {
      this.record.whatsappChannel.meta.phoneId = this.$route.query.phoneId;
      this.record.whatsappChannel.meta.wabaId = this.$route.query.wabaId;
      this.record.whatsappChannel.provider = 'trengo_bsp';
    },

    findOverFlowRecord(overflow_type) {
      return this.record?.voipChannel?.voip_overflow.find((o) => o.type === overflow_type);
    },

    async save() {
      if (this.saving || this.uploading) {
        return;
      }

      this.saving = true;

      if (this.$refs.channelAction && this.$refs.channelAction.beforeSave) {
        await this.$refs.channelAction.beforeSave();
      }

      if (this.action === 'create') {
        return this.createChannel();
      } else {
        this.updateChannel();
      }
    },

    createChannel() {
      const moreOptions = {
        team_ids: this.mapTeamIds(),
        user_ids: this.mapUserIds(),
      };
      if (this.record?.whatsappChannel?.provider === '360dialog') {
        this.record.whatsappChannel.meta['not_via_trengo'] = true;
      }

      axios
        .post('/api/v2/channels', { ...this.record, ...moreOptions })
        .then((res) => {
          this.saving = false;
          this.$emit('reloadChannelIndexList');
          this.initWarning(false);

          if (this.$root.isInOnboardingFlow) {
            $('#channel-created-modal').modal('show');
          } else {
            router.push('/admin/channels2/' + this.record.type.toLowerCase() + '/' + res.data.id);
            this.flashSuccess(this.$t('settings.channels_the_channel_has_been_created_successfully'));
          }
          eventBus.$emit('user-add-first-channel');
          this.onSaved();
        })
        .catch(() => {
          this.saving = false;
        });
    },

    updateChannel() {
      axios
        .put('/api/v2/channels/' + this.record.id, {
          ...this.record,
          ...{ team_ids: this.mapTeamIds(), user_ids: this.mapUserIds() },
        })
        .then(() => {
          this.saving = false;
          this.$emit('reloadChannelIndexList');
          this.flashSuccess(this.$t('channels.update_successful'));
          this.onSaved();

          if (this.sentUpdatedEvent) {
            this.$refs.channelInfo.updated();
          }
        })
        .catch(() => {
          this.saving = false;
        });
    },

    deleteChannel() {
      this.deleteChannelModalStore.openModal(this.$t('channels.delete_confirm_will_remove_all_conversations'));
    },

    mapTeamIds() {
      if (this.record.is_private) {
        return [];
      }
      return map(this.record.teams, 'id');
    },

    mapUserIds() {
      if (!this.record.users || !this.record.is_private) {
        return [];
      }
      return [this.record.users.id || this.record.users[0].id];
    },

    updateColorValue(color) {
      this.record.color = color.hex;
    },

    onSaved() {
      this.initWarning();
      this.$root.loadAuthorizedChannels().then(() => {
        if (this.record.type === 'VOIP') {
          this.reloadVoip();
          $('.modal').modal('hide');
          this.record.voipIdentity = 1;
          this.initWarning(false);
        }
        if (this.record.type === 'WA_BUSINESS') {
          this.$tStorage.removeItem('return_path');
          this.$tStorage.removeItem('wa_submission');
        }
      });
      this.$root.loadAllChannels();
    },

    onDeleted() {
      this.$root.loadAuthorizedChannels().then(() => {
        if (this.record.type === 'VOIP') {
          this.reloadVoip();
        }
      });
      this.$root.loadAllChannels();
    },

    reloadVoip() {
      eventBus.$emit('voip.channels.changed');
    },

    playNotificationSound() {
      if (this.record.notification_sound) {
        let preview;
        if (!previewSound) {
          preview = new Audio('/sound/channel_notification/' + this.record.notification_sound);
          previewSound = preview;
        } else {
          previewSound.src = '/sound/channel_notification/' + this.record.notification_sound;
          previewSound.load();
          preview = previewSound;
        }
        preview.play().catch(() => {});
      }
    },

    shouldSaveOnCreate() {
      if (
        this.record.type === 'VOIP' &&
        [CHANNEL_TYPE.VOYS, CHANNEL_TYPE.AIRCALL].includes(this.record.voipChannel.provider)
      ) {
        return true;
      }
      return false;
    },

    showMoreGeneralSettings() {
      if (this.action === 'create') {
        return false;
      }
      return true;
    },
    handleOpenModal() {
      this.openMigrationModal = true;
    },
    handleCloseModal() {
      this.openMigrationModal = false;
    },
    setCreateText(text) {
      this.createText = text;
    },
    updateReady(ready) {
      this.ready = ready;
    },
    updateShowFooter(showFooter) {
      this.showFooter = showFooter;
    },
    callInitWarningMethod(value) {
      this.initWarning(value);
    },
    updateLoaded(value) {
      this.loaded = value;
    },
    updateLoading(value) {
      this.uploading = value;
    },
    updateOutsideBusinessHoursPhoneNumber(value) {
      this.outside_business_hours_phone_number = value;
    },
    updateFullQueuePhoneNumber(value) {
      this.full_queue_phone_number = value;
    },
    updateOverflowPhoneNumber(value) {
      this.overflow_phone_number = value;
    },
  },
  beforeRouteLeave(to, from, next) {
    this.onBeforeRouteLeaveGuard(to, from, next);
  },
  beforeRouteUpdate(to, from, next) {
    this.onBeforeRouteUpdateGuard(to, from, next);
  },
});
</script>

<style lang="scss">
.team-select-tooltip {
  .tooltip-arrow {
    @apply hidden;
  }

  .tooltip-inner {
    width: fit-content;
    max-width: max-content;
  }
}
</style>
