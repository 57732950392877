<template>
  <div>
    <div class="sms-composer rounded-xl border-1 border-grey-300" :class="{ error }">
      <contenteditable
        ref="messageEditor"
        :model-value="message"
        class="message-editor t-text-desktop-paragraph-sm min-h-100px flex-1 overflow-auto px-4 pb-0 pt-3"
        placeholder="Enter your message"
        :multiline="true"
        data-test="sms-composer"
        max-height="11.5rem"
        :allow-formatting="true"
        :allow-link-formatting="false"
        @update:model-value="$emit('message', $event)"
        @input="contentEditableContentChanged"
        @blur="onContentEditableBlur"
      ></contenteditable>
      <footer class="flex justify-between px-3 pb-2 pt-1">
        <div class="flex">
          <div class="t-text-desktop-paragraph-xs pointer mr-1 flex items-center text-grey-800">
            <dropdown
              ref="dropdown_quick_replies"
              :options="quickReplies"
              label="title"
              placement="top"
              :active-state="false"
              width="350px"
              max-height="420px"
              dropdown-class="sms-composer-qr"
              :show-search-icon="true"
              data-test="toggle-qr-dropdown"
              @selected="insertText"
              @open="toggleQRDropdown"
              @close="toggleQRDropdown"
            >
              <template #heading>
                <div class="t-text-desktop-paragraph-md pt-4 text-center leading-none text-grey-900">
                  {{ $t('tickets.insert_quick_reply') }}
                </div>
              </template>
              <template #toggle>
                <div class="tag-quick-reply flex items-center rounded-md px-2 py-1" :class="{ QRDropdownIsOpen }">
                  <quick-reply-custom-linear size="20px" class="mr-1" />
                  Quick reply
                </div>
              </template>
              <template #option="prop">
                <span class="t-text-desktop-label-sm px-4 text-grey-800">{{ prop.option.title }}</span>
                <br />
                <span class="t-text-desktop-paragraph-sm px-4 text-grey-800">
                  {{ stripHtml(prop.option.message) }}
                </span>
              </template>
            </dropdown>
          </div>
          <div class="t-text-desktop-paragraph-xs pointer mr-6 flex items-center text-grey-800">
            <dropdown
              ref="dropdown_tags"
              :options="tags"
              label="title"
              placement="top"
              :active-state="false"
              width="220px"
              dropdown-class="sms-composer-tag"
              :search="false"
              data-test="toggle-tag-dropdown"
              @selected="insertText"
              @open="toggleTagDropdown"
              @close="toggleTagDropdown"
            >
              <template #toggle>
                <div class="tag-toggle flex items-center rounded-md px-2 py-1" :class="{ tagDropdownIsOpen }">
                  <div
                    v-tooltip="{ placement: 'top', content: $t('broadcast.insert_custom_field_tag') }"
                    class="t-text-desktop-paragraph-xs pointer flex items-center text-grey-800"
                  >
                    <square-brackets-custom-linear size="20px" class="mr-1" />
                    Tags
                  </div>
                </div>
              </template>
              <template #option="prop">
                <span class="t-text-desktop-paragraph-sm px-3 text-grey-800">{{ prop.option.identifier }}</span>
              </template>
            </dropdown>
          </div>
        </div>
        <div class="t-text-desktop-paragraph-xs flex items-center text-grey-800">
          {{ displayedCount }}
          <info-linear
            v-tooltip="{ placement: 'top', content: 'Exceeding the 160 character limit will split up your message' }"
            size="15px"
            class="ml-2 text-grey-900"
          />
        </div>
      </footer>
    </div>
    <t-error-item v-if="error" data-test="message-error" class="mb-1 mt-1" :text="$t('broadcast.message_error')" />
  </div>
</template>

<script lang="ts">
import { InfoLinear, QuickReplyCustomLinear, SquareBracketsCustomLinear } from '@trengo/trengo-icons';
import { map, find, uniqBy } from 'lodash';
import { mapState } from 'vuex';

import Contenteditable from '@/components/ContentEditable.vue';
import Dropdown from '@/components/ReplyForm/Dropdown.vue';
import eventBus from '@/eventBus';
import ticketRepository from '@/repositories/Ticket';
import { getQrRegex } from '@/util/stringHelpers';

export default {
  name: 'SmsComposer',
  components: {
    Contenteditable,
    QuickReplyCustomLinear,
    SquareBracketsCustomLinear,
    InfoLinear,
    Dropdown,
  },
  props: {
    channel: {
      type: Object,
      default: () => ({}),
    },
    message: {
      type: String,
      default: '',
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['message'],
  data() {
    return {
      quickReplies: [],
      contentEditableSelRange: null,
      QRDropdownIsOpen: false,
      tagDropdownIsOpen: false,
      tags: [
        { title: 'Ticket ID', identifier: '[ticket_id]' },
        { title: 'Contact name', identifier: '[contact_name]' },
        { title: 'Agent name', identifier: '[agent_name]' },
        { title: 'Profile name', identifier: '[profile_name]' },
      ],
    };
  },
  computed: {
    displayedCount() {
      const count = this.stripHtml(this.message).length;
      return count !== 1 ? count + ' characters' : count + ' character';
    },
    ...mapState({
      customFieldsContact: (state) => state.customFields.customContactFields,
    }),
  },
  watch: {
    channel: {
      handler(channel) {
        ticketRepository.loadQuickReplies(channel.type, channel.id, true).then((res) => {
          this.quickReplies = res;
        });
      },
      immediate: true,
    },
  },
  mounted() {
    this.loadTags();
  },
  methods: {
    stripText(string) {
      string = this.stripHtml(string);
      string = string.replace(/(?:\r\n|\r|\n)/g, '<br />');
      return string;
    },
    insertContentEditableStringAtCaret(string) {
      this.$refs.messageEditor.insertTextAtCursor(string, 'span');
      this.$emit('message', this.$refs.messageEditor.$el.innerHTML);
    },
    insertContentEditableLinkAtCaret(string) {
      this.$refs.messageEditor.insertTextAtCursor(string, 'a', true);
      this.$emit('message', this.$refs.messageEditor.$el.innerHTML);
    },
    insertText(reply) {
      let replyMessage = reply;
      let isATag = true;
      replyMessage = reply?.identifier;
      if (reply.message) {
        isATag = false;
        replyMessage = reply.message;
      }
      replyMessage = this.stripText(replyMessage);
      if (!this.$refs.messageEditor.$el.innerHTML.includes('<span class="caret-position"></span>')) {
        this.$refs.messageEditor.restoreSelection(this.contentEditableSelRange);
        // if messaging editor has focus
        if (document?.activeElement.classList.contains('message-editor')) {
          if (isATag) {
            this.insertContentEditableLinkAtCaret(replyMessage);
          } else {
            //parse QR for tags
            const re = /\[[^\]]*\]/g;
            replyMessage = replyMessage.replace(re, (arg1) => {
              if (
                find(this.tags, function (o) {
                  return o.identifier === arg1;
                })
              ) {
                return '<span><a class="sms-tag" contenteditable="false">' + arg1 + '</a></span>';
              } else return arg1;
            });
            this.insertContentEditableStringAtCaret(replyMessage);
          }
          // if messaging editor has no focus
        } else {
          this.$emit('message', (this.$refs.messageEditor.$el.innerHTML = replyMessage + this.message));
        }
      } else {
        this.replaceShortcodes(replyMessage);
      }
      this.$refs.messageEditor.onChange();
    },
    onMessageUpdate() {
      this.$refs.messageEditor.$el.innerHTML = this.message || '';
    },
    replaceShortcodes(string = '') {
      // replace and update messaging editor
      if (this.$refs.messageEditor.$el.innerHTML.includes('<span class="caret-position"></span>')) {
        this.$emit(
          'message',
          this.$refs.messageEditor.$el.innerHTML
            .replace('<span class="caret-position"></span>', string)
            .replaceAll('<span></span>', string)
            .replaceAll(getQrRegex(), (match, group1) => group1),
        );
        this.onMessageUpdate();
        window.placeCaretAtEnd(this.$refs.messageEditor.$el);
      }
    },
    contentEditableContentChanged() {
      const matches = [...this.$refs.messageEditor.$el.innerHTML.trim().matchAll(getQrRegex())];
      if (matches?.length) {
        this.$refs.messageEditor.insertTextAtCursor('<span class="caret-position"></span>', 'span');
        this.openQuickReplyDropdown(this.$refs.dropdown_quick_replies, document.querySelector('.caret-position'));
        this.$emit('message', this.$refs.messageEditor.$el.innerHTML);
      }
    },
    openQuickReplyDropdown(ref, position = null) {
      if (ref) {
        this.quickReplyOpenDropdown = true;
        eventBus.$emit('OPEN_DROPDOWN', ref, position);
      }
    },
    onContentEditableBlur(value) {
      this.$emit('message', value);
      this.contentEditableSelRange = this.$refs.messageEditor.saveSelection();
    },
    toggleQRDropdown() {
      if (!this.QRDropdownIsOpen) {
        this.$nextTick(() => {
          // needed for tag coloring
          this.$refs.messageEditor.$el.focus();
        });
      }
      this.QRDropdownIsOpen = !this.QRDropdownIsOpen;
    },
    toggleTagDropdown() {
      if (!this.tagDropdownIsOpen) {
        this.$nextTick(() => {
          // needed for tag coloring
          this.$refs.messageEditor.$el.focus();
        });
      }
      this.tagDropdownIsOpen = !this.tagDropdownIsOpen;
    },
    loadTags() {
      map(this.customFieldsContact, (f) => {
        if (f.identifier && f.identifier.toLowerCase() !== 'null') {
          this.tags.push({ title: f.title, identifier: '[' + f.identifier + ']' });
        }
      });
      this.tags = uniqBy(this.tags, 'identifier');
    },
    stripHtml(str: string) {
      if (str == null) {
        return;
      }
      // First replace HTML tags with empty string
      str = str.replace(/<\/?[^>]+(>|$)/g, '');
      // Then decode HTML entities
      str = str
        .replace(/&nbsp;/g, ' ') // Replace &nbsp; with regular space
        .replace(/&amp;/g, '&') // Replace &amp; with &
        .replace(/&lt;/g, '<') // Replace &lt; with <
        .replace(/&gt;/g, '>'); // Replace &gt; with >
      return str;
    },
  },
};
</script>

<style lang="scss" src="./SmsComposer.scss" />
