// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { toArray } from 'lodash';

import { fetchTeams, fetchUsers } from '@/api';
import { ENDPOINT } from '@/api/constants';
import { timezoneDictionary } from '@/components/Pages/Sections/Timezones';
import { STATUS_CODE } from '@/Configs/Constants/StatusCodes';
import eventBus from '@/eventBus';
import { retryRequestIfResponseMatchesStatusCode } from '@/util/request';

import type { Team, User } from '@/api/types';

export default class {
  /**
   *
   * @param page
   * @param filters
   * @param isTicketListUpdatedGraterThanFilterEnabled
   * @returns {Promise}
   */
  static getAllTickets(page, filters, isTicketListUpdatedGraterThanFilterEnabled: boolean = false) {
    return new Promise((resolve) => {
      const p = {
        page: page,
        status: filters.status,
        users: filters.users,
        channels: filters.channels,
        teams: filters.teams,
        labels: filters.labels,
        last_message_type: filters.last_message_type,
        sort: filters.sort,
        term: filters.term,
        favorited: filters.favorited,
        mentioned: filters.mentioned,
        custom_view_id: filters.custom_view_id,
        reminders: filters.reminders,
        watching: filters.watching,
        feed: filters.feed,
        scope: filters.scope,
        created_at_start: filters.created_at_start,
        created_at_end: filters.created_at_end,
        only_unlabelled: filters.only_unlabelled,
      };

      if (filters.searchRange) {
        switch (filters.searchRange) {
          case 'last-week':
            p.updated_at_greater_than = moment().subtract(1, 'week').toISOString(true);
            break;
          case 'last-month':
            p.updated_at_greater_than = moment().subtract(1, 'month').toISOString(true);
            break;
          case 'all-time':
            p.updated_at_greater_than = moment.utc('2016').toISOString(true);
            break;
        }
      }

      if(!p.term && filters.searchRange && isTicketListUpdatedGraterThanFilterEnabled){
        p.updated_at_gt = p.updated_at_greater_than
        delete p.updated_at_greater_than
      }

      axios
        .get('/api/v2/tickets', { cancelToken: axiosRequestToken('tickets.index'), params: p })
        .then((res) => {
          if (res) {
            resolve(res);
          }
        })
        .catch(() => {});
    });
  }

  /**
   *
   * @param ticket_id
   * @param search_in_cache
   * @param with_messages
   * @returns {*|i}
   */
  static getTicket(ticket_id, search_in_cache = true, with_messages = 0) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/v2/tickets/' + ticket_id, {
          cancelToken: axiosRequestToken('tickets.show'),
          headers: { 'x-socket-id': PusherInstance.connection.socket_id },
        })
        .then((res) => {
          if (res) {
            resolve(res.data);
          }
        })
        .catch(function (e) {
          reject(e);
        });
    });
  }

  /**
   * @param ticket_id
   * @returns {Promise}
   */
  static getEmailSignature(ticket_id) {
    return new Promise((resolve, reject) => {
      $.get('/client-api/tickets/' + ticket_id + '/signature').then((result) => {
        resolve(result);
      });
    });
  }

  /**
   * @param channel_id
   */
  static getEmailSignatureByChannel(channel_id) {
    return new Promise((resolve, reject) => {
      $.get('/client-api/channels/' + channel_id + '/signature').then((result) => {
        resolve(result);
      });
    });
  }

  /**
   * @returns {Promise}
   */
  static loadInitialData() {
    return new Promise((resolve) => {
      Promise.all([
        axios.get('/api/v2/ticket_results'),
        axios.get('/api/v2/custom_fields'),
        axios.get('/api/v2/labels'),
        axios.get('/api/v2/contact_groups'),
        axios.get('/api/v2/locale_codes'),
        axios.get('/api/v2/channels'),
        axios.get('/api/v2/me/channels'),
        axios.get('/api/v2/translations'),
        axios.get('/api/v2/me'),
        axios.get('/api/v2/countries'),
        axios.get('/client-api/onboarding'),
        axios.get('/api/v2/plugins'),
        axios.get('/api/v2/me/do_not_disturb_schedule'),
        axios.get('/api/v2/custom_views'),
        axios.get('/client-api/company_profile'),
        axios.get('/api/v2/agency'),
        axios.get('/api/v2/csat'),
      ]).then((res) => {
        const data = {
          ticket_results: res[0].data.data,
          ticket_custom_fields: toArray(
            res[1].data.data.filter((field) => {
              return field.type === 'TICKET';
            }),
          ),
          profile_custom_fields: toArray(
            res[1].data.data.filter((field) => {
              return field.type === 'PROFILE';
            }),
          ),
          contact_custom_fields: toArray(
            res[1].data.data.filter((field) => {
              return field.type === 'CONTACT';
            }),
          ),
          user_custom_fields: toArray(
            res[1].data.data.filter((field) => {
              return field.type === 'USER';
            }),
          ),
          labels: res[2].data.data,
          contact_groups: res[3].data,
          locale_codes: res[4].data,
          allChannels: res[5].data.data,
          channels: res[6].data.data,
          translations: res[7].data,
          user: res[8].data,
          countries: res[9].data,
          onboarding: res[10].data,
          plugins: res[11].data.data,
          doNotDisturb: res[12].data,
          custom_views: res[13].data.data,
          company_profile: res[14].data,
          agency: res[15].data,
          surveys: res[16].data.map(({ name: text, id }) => ({ id, text })),
        };
        //transform old timezone into new one, if applicable
        data.user.timezone = timezoneDictionary[data.user.timezone]
          ? timezoneDictionary[data.user.timezone]
          : data.user.timezone;

        fetchUsers().then((res) => {
          data.users = res.data.data as User[];
          const index = data.users.findIndex((u) => u.id === parseInt(data.user.id));
          if (index < 0) {
            data.users = [...data.users, data.user];
          }
          fetchTeams().then((res) => {
            data.teams = res.data.data as Team[];
            resolve(data);
          });
        });
      });
    });
  }

  /**
   * @param ticket_id
   * @param page
   * @returns {Promise|any}
   */
  static getMessages(ticket_id, page) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/v2/tickets/' + ticket_id + '/messages', {
          cancelToken: axiosRequestToken('tickets.messages'),
          params: { page: page },
        })
        .then((result) => {
          if (result) {
            resolve(result);
          }
        });
    });
  }

  /**
   * @param ticket_id
   * @param message_id
   * @returns {Promise<any>}
   */
  static getMessage(ticket_id, message_id) {
    return new Promise((resolve, reject) => {
      axios.get('/api/v2/tickets/' + ticket_id + '/messages/' + message_id).then((result) => {
        resolve(result);
      });
    });
  }

  /**
   *
   * @param channel_type
   * @param channel_id
   * @param without_attachments
   * @returns {Promise<any>}
   */
  static loadQuickReplies(channel_type, channel_id, without_attachments) {
    return new Promise((resolve, reject) => {
      const channel_type_to_quick_reply_type = {
        CHAT: 'MESSAGING',
        FACEBOOK: 'MESSAGING',
        WA_BUSINESS: 'MESSAGING',
        TELEGRAM: 'MESSAGING',
        SMS: 'SMS',
        EMAIL: 'EMAIL',
        VOIP: 'MESSAGING',
      };

      axios
        .get('/api/v2/quick_replies', {
          params: {
            channel_id: channel_id,
            type: channel_type_to_quick_reply_type[channel_type],
            without_attachments: without_attachments,
          },
        })
        .then((result) => {
          resolve(result.data.data);
        });
    });
  }

  /**
   * @param message_id
   * @returns {Promise}
   */
  static fetchFullEmailMessage(message_id) {
    return new Promise((resolve, reject) => {
      $.get('/client-api/tickets/message/' + message_id + '/full-email-message').then((result) => {
        resolve(result);
      });
    });
  }

  /**
   *
   * @param ticket_id
   * @param label_ids
   * @returns {Promise|any}
   */
  static setTicketLabels(ticket_id, label_ids) {
    return new Promise((resolve, reject) => {
      $.post('/client-api/tickets/label-ticket/' + ticket_id, { label_id: label_ids }).then((result) => {
        resolve(result);
      });
    });
  }

  /**
   * @param ticket_id
   * @param user_id
   * @param note
   * @returns {Promise}
   */
  static assignTicket(ticket_id, user_id, note) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/v2/tickets/' + ticket_id + '/assign', {
          ticket_id: ticket_id,
          user_id: user_id,
          note: note,
          type: 'user',
        })
        .then((result) => {
          resolve(result.data);
          eventBus.$emit('user-assigned-first-ticket');
        });
    });
  }

  /**
   * @param ticket_id
   * @param team_id
   * @param note
   * @returns {Promise}
   */
  static assignTicketToTeam(ticket_id, team_id, note) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/v2/tickets/' + ticket_id + '/assign', {
          ticket_id: ticket_id,
          team_id: team_id,
          note: note,
          type: 'team',
        })
        .then((result) => {
          resolve(result.data);
          eventBus.$emit('user-assigned-first-ticket');
        });
    });
  }

  static assignTickets(ticket_ids, user_id, note) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/v2/bulk', {
          action: 'assign_ticket_to_user',
          payload: {
            ticket_ids: ticket_ids,
            user_id: user_id,
            note: note,
          },
        })
        .then((result) => {
          resolve(result);
          eventBus.$emit('user-assigned-first-ticket');
        });
    });
  }

  static assignTicketsToTeam(ticket_ids, team_id, note) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/v2/bulk', {
          action: 'assign_ticket_to_team',
          payload: {
            ticket_ids: ticket_ids,
            team_id: team_id,
            note: note,
          },
        })
        .then((result) => {
          resolve(result);
          eventBus.$emit('user-assigned-first-ticket');
        });
    });
  }

  /**
   * @param ticket_id
   * @param message
   * @param noteMode
   * @param attachments
   * @param token
   * @param delivery_method
   * @returns {*|i}
   */
  static postMessage(ticket_id, message, noteMode, attachments = [], token = null, delivery_method = null) {
    const formData = new FormData();
    //formData.append('socket_id', PusherInstance.connection.socket_id);
    formData.append('message', message);
    formData.append('internal_note', noteMode);
    formData.append('token', token);
    formData.append('delivery_method', delivery_method);

    if (attachments.length > 0) {
      for (let i = 0, len = attachments.length; i < len; i++) {
        if (attachments[i]['attachment_id'] != null) {
          formData.append('quick_reply_attachment_ids[]', attachments[i]['attachment_id']);
        } else {
          formData.append('uploads[]', attachments[i]['obj']);
        }
      }
    }

    return new Promise((resolve, reject) => {
      retryRequestIfResponseMatchesStatusCode(
        {
          method: 'POST',
          url: `${ENDPOINT.TICKETS}/${ticket_id}/messages`,
          data: formData,
        },
        {
          statusCodes: [STATUS_CODE.NOT_FOUND],
        },
      )
        .then((result) => {
          resolve(result);
        })
        .catch(function (e) {
          reject(e);
        });
    });
  }

  /**
   * @param ticket_id
   * @param ticket_result_id
   * @returns {Promise}
   */
  static closeTicket(ticket_id, ticket_result_id = null) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/v2/tickets/' + ticket_id + '/close', { ticket_result_id: ticket_result_id })
        .then((result) => {
          resolve(result.data);
          eventBus.$emit('ticket-archived', this.ticket_id);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  /**
   * @param ticket_id
   * @returns {Promise}
   */
  static deleteTicket(ticket_id) {
    return new Promise((resolve, reject) => {
      axios.delete('/api/v2/tickets/' + ticket_id).then((result) => {
        resolve(result);
      });
    });
  }

  /**
   * @param ticket_id
   * @returns {Promise}
   */
  static reOpenTicket(ticket_id) {
    return new Promise((resolve, reject) => {
      axios.post('/api/v2/tickets/' + ticket_id + '/reopen', { ticket_id: ticket_id }).then((result) => {
        resolve(result.data);
      });
    });
  }
  /**
   * @param ticket_id
   * @returns {Promise}
   */
  static markAsSpam(ticket_id) {
    return new Promise((resolve, reject) => {
      axios.post('/api/v2/tickets/' + ticket_id + '/spam').then((result) => {
        resolve(result.data);
      });
    });
  }
  /**
   * @param ticket_id
   * @returns {Promise}
   */
  static unMarkAsSpam(ticket_id) {
    return new Promise((resolve, reject) => {
      axios.delete('/api/v2/tickets/' + ticket_id + '/spam').then((result) => {
        resolve(result.data);
      });
    });
  }

  /**
   * @param ticket_id
   * @param target_id
   * @returns {Promise}
   */
  static mergeTicket(ticket_id, target_id) {
    return new Promise((resolve, reject) => {
      axios.post('/api/v2/tickets/' + ticket_id + '/merge', { source_ticket_id: target_id }).then((result) => {
        resolve(result);
      });
    });
  }

  /**
   * @param ticket_id
   * @param message_id
   * @returns {Promise}
   */
  static readMessage(ticket_id, message_id) {
    return new Promise((resolve) => {
      retryRequestIfResponseMatchesStatusCode(
        {
          method: 'POST',
          url: `${ENDPOINT.TICKETS}/${ticket_id}/messages/${message_id}/read`,
        },
        {
          statusCodes: [STATUS_CODE.NOT_FOUND],
        },
      ).then((result) => {
        resolve(result);
      });
    });
  }

  static createTicket(data) {
    return axios.post('/client-api/tickets/store', data);
  }

  /**
   * @param dropped_file
   * @returns {Promise}
   */
  static sendMediaFromModal(dropped_file = null) {
    const data = new FormData($('#MediaModal form')[0]);
    if (dropped_file != null) {
      data.append('file', dropped_file);
    }

    return new Promise((resolve, reject) => {
      $.ajax({
        url: '/client-api/tickets/media',
        type: 'POST',
        data: data,
        cache: false,
        contentType: false,
        processData: false,
      })
        .then(function (result) {
          resolve(result);
        })
        .catch(function (result) {
          reject(result);
        });
    });
  }

  /**
   * @param ticket_id
   * @param message_id
   * @param data
   * @returns {Promise<any>}
   */
  static reactToMessage(ticket_id, message_id, data) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/v2/tickets/' + ticket_id + '/messages/' + message_id + '/interaction', data)
        .then((result) => {
          resolve(result);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }
}
