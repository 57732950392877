<template>
  <t-modal v-model="isOpen" variant="narrow" class="delete-channel-modal" @close="onCancel">
    <template #header>
      <h2 class="t-text-h3">
        {{ $t('channels.delete_confirm_title') }}
      </h2>
    </template>

    <template #default>
      <p class="mb-6 text-grey-800">
        {{ message }}
      </p>

      <div class="flex flex-col gap-2">
        <label class="text-base font-medium">
          {{ $t('channels.type_to_delete', { text: props.confirmationText }) }}
        </label>
        <t-input-text id="delete-confirmation" v-model="deleteConfirmation" size="md" class="w-full" :placeholder=props.confirmationText />
      </div>
    </template>

    <template #footer>
      <div class="flex justify-end gap-3">
        <t-button btn-style="secondary" @click="onCancel">
          {{ $t('general.cancel') }}
        </t-button>
        <t-button btn-style="danger" :disabled="!isConfirmationValid || isDeleting" @click="handleConfirm">
          {{ $t('general.delete') }}
        </t-button>
      </div>
    </template>
  </t-modal>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';

import { useDeleteChannelModalStore } from '../stores/deleteChannelModalStore';

const props = defineProps<{
  channelId: string;
  channelType: string;
  confirmationText: string;
}>();

const store = useDeleteChannelModalStore();
const { isOpen, message, deleteConfirmation } = storeToRefs(store);

const isConfirmationValid = computed(() => {
  return deleteConfirmation.value === props.confirmationText;
});

const isDeleting = ref(false);

function onCancel(): void {
  store.closeModal();
}

async function handleConfirm(): Promise<void> {
  if (isDeleting.value) return;

  isDeleting.value = true;
  try {
    await store.deleteChannel(props.channelId, props.channelType);
  } finally {
    isDeleting.value = false;
  }
}
</script>
