import moment from 'moment';

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions/Unicode_Property_Escapes
// https://stackoverflow.com/a/72727900/8610114 - Excellent explanation for the first half of this regex
export const EMOJI_REGEX = /(\p{Emoji}\uFE0F)+|\p{Emoji_Presentation}+/gu;

export type DurationBits = {
  hours: string;
  minutes: string;
  seconds: string;
};

export function isOnlyEmoji(text = '', allowSpaces = false): boolean {
  if (!text || !EMOJI_REGEX.test(text)) {
    return false;
  }

  const strippedString = stripZeroWidthJoiner(text);

  if (allowSpaces) {
    return strippedString.replace(EMOJI_REGEX, '').trim().length === 0;
  }

  return strippedString.replace(EMOJI_REGEX, '').length === 0;
}

// Many emoji are combined with a Zero Width Space (Unicode: /u200d). This function will strip
// those spaces in order to make regex comparisons consistent
function stripZeroWidthJoiner(text: string): string {
  return text.replace(/\u200d/gu, '');
}

export function escapeHtml(unsafeText?: string): string {
  const entities = {
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    '&': '&amp;',
    "'": '&#39;',
    '`': '&#x60;',
  };

  return String(unsafeText).replace(/[<>"'`]/g, (substring) => entities[substring as keyof typeof entities]);
}

export const randomString = (length: number) => {
  let text = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < length; i++) text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
};

export function snakeKebabToCamel(text: string): string {
  if (!/[-_]/g.test(text)) {
    return text;
  }

  return text.toLowerCase().replace(/[-_][a-z0-9]/g, (replaceValue) => {
    return replaceValue.slice(-1).toUpperCase();
  });
}

export function randomStringFromArray<T extends string>(textArray: T[]): T {
  return textArray[Math.floor(Math.random() * textArray.length)];
}

/** Takes in a duration as the number of milliseconds and returns the bits that make up the time (hours, minutes and seconds).
 *
 * Moment doesn't have a good way of formatting time durations (despite providing ways of converting durations), so we have to do it manually
 * @param duration time duration in milliseconds
 */
export function formatDuration(duration: number): DurationBits {
  const momentDuration = moment.duration(duration);

  const hours = Math.floor(momentDuration.asHours());
  const minutes = Math.floor(momentDuration.asMinutes()) - hours * 60;
  const seconds = Math.floor(momentDuration.asSeconds()) - hours * 3600 - minutes * 60;

  return {
    hours: hours < 10 ? `0${hours}` : String(hours),
    minutes: minutes < 10 ? `0${minutes}` : String(minutes),
    seconds: seconds < 10 ? `0${seconds}` : String(seconds),
  };
}

/** Takes an email and returns an obscured version of it. The username and the domain are obscured by showing the first character and then replacing the rest with asterisks. Top-level domain is shown unchanged. */
export function obscureEmail(email: string) {
  if (!email.includes('@')) {
    return email;
  }
  const [username, domain] = email.split('@');
  const obscuredUsername = username.slice(0, 1) + '*'.repeat(username.length - 1);
  const topLevelDomainIndex = domain.lastIndexOf('.');
  const obscuredDomain =
    topLevelDomainIndex === -1
      ? domain.slice(0, 1) + '*'.repeat(domain.length - 1)
      : domain.slice(0, 1) + '*'.repeat(topLevelDomainIndex - 1) + domain.slice(topLevelDomainIndex);

  return `${obscuredUsername}@${obscuredDomain}`;
}

export function convertFormattingToPlainText(text: string) {
  if (!text) {
    return '';
  }
  return (
    text
      // Ensure space when a formatting tag is inside a word
      .replace(/(\w)(<(b|em|del|code)>)/g, '$1 $2') // Space before opening tag if inside a word
      .replace(/(<\/(b|em|del|code)>)(?=\w)/g, '$1 ') // Space after closing tag if followed by a word

      // Convert formatting tags to markdown while trimming spaces inside
      .replace(/<b>\s*(.*?)\s*<\/b>/g, '*$1*')
      .replace(/<em>\s*(.*?)\s*<\/em>/g, '_$1_')
      .replace(/<del>\s*(.*?)\s*<\/del>/g, '~$1~')
      .replace(/<code>\s*(.*?)\s*<\/code>/g, '```$1```')
  );
}

export function getQrRegex() {
  return /(^|[\s>;])(\/qr)/g; // https://regex101.com/r/JTSrTS/1
}
