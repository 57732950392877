<template>
  <div v-if="template?.id" class="flex flex-col gap-6">
    <upload-file
      v-if="mediaComponent"
      :media-type="mediaType"
      :template-id="template.id"
      @update-selected-file="handleFileChange"
      @handle-file-reset="handleFileReset"
    />
    <div v-if="computedCustomFieldTags?.length" class="flex flex-col flex-wrap gap-2">
      <div class="t-text-md-emphasize">{{ $t('whatsapp.body') }}</div>
      <div class="flex flex-wrap gap-2">
        <div v-for="(tag, i) in computedCustomFieldTags" :key="i" class="min-w-[256px] flex-1">
          <search-bar
            v-model.trim="computedInputTags[i].value"
            :options="tagOptions(i)"
            :error="error && computedInputTags[i].value === ''"
            size="sm"
            :placeholder="tag.value || tag.key"
            data-test="'tag-input-'+i"
            @input="(e) => handleFilterTags(e.target.value, i)"
            @keydown="(e) => onKeyDown(e, i)"
          >
            <template #item="slotProps">
              <div
                class="tag-item pointer t-text-desktop-paragraph-sm mx-3 my-1 rounded-xl px-2 py-1 text-grey-800 hover:bg-grey-300"
                @click="(e) => handleItemClick(e.currentTarget.innerText, i)"
              >
                {{ slotProps.item.identifier }}
              </div>
            </template>
          </search-bar>
        </div>
      </div>
      <div v-if="computedCustomFieldTags?.length" class="t-text-desktop-paragraph-sm text-grey-600">
        {{ $t('broadcast.select_an_existing_tag_to_replace_each_variable_or_enter_some_plain_text') }}
      </div>
      <t-error-item v-if="error" data-test="tags-error" :text="$t('broadcast.please_fill_in_all_tags')" />
    </div>
  </div>
</template>

<script lang="ts">
import { sanitize } from 'dompurify';
import { cloneDeep, filter, map, uniqBy } from 'lodash';
import { mapState } from 'vuex';

import { fetchTemplateMedia } from '@/components/WabBroadcasting/api/index.js';
import SearchBar from '@/components/WabBroadcasting/components/SearchBar';
import UploadFile from '@/components/WabBroadcasting/components/UploadFile/UploadFile.vue';
import { TEMPLATE_TAG_TYPE, TEMPLATE_HEADER_TYPE, ALLOWED_VIDEO_FORMATS } from '@/Configs/Constants/whatsapp';

import type {
  TemplateHeaderMedia,
  BroadcastMedia,
  TemplateTag,
  TemplateComponent,
} from '@/components/WaTemplates/types';
import type { Template } from '@/types/waTemplates';
import type { PropType } from 'vue';

export default {
  name: 'WhatsappComposer',
  components: {
    SearchBar,
    UploadFile,
  },
  props: {
    template: {
      type: Object as PropType<Template>,
      default: () => ({}),
      required: true,
    },
    modelValue: {
      type: Array as PropType<TemplateTag[]>,
      default: () => [],
    },
    error: {
      type: Boolean,
      default: false,
    },
    isEditingBroadcast: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['updateTags', 'update:modelValue', 'updateTemplateMedia'],
  data() {
    return {
      tags: [] as TemplateTag[],
      parsedOriginalMessage: '',
      sanitizedMessage: '',
      editedMessage: '',
      quickReplies: [],
      filteredAutocompleteTags: [],
      allAutocompleteTags: [
        { title: 'Ticket ID', identifier: '[ticket_id]' },
        { title: 'Contact name', identifier: '[contact_name]' },
        { title: 'Agent name', identifier: '[agent_name]' },
        { title: 'Profile name', identifier: '[profile_name]' },
      ],
      originalTemplateId: 0,
      backupTags: [],
      defaultTemplateMedia: null as BroadcastMedia | null,
      dynamicTemplateMedia: null as TemplateHeaderMedia | null,
    };
  },
  computed: {
    computedInputTags: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    ...mapState({
      customFieldsContact: (state) => state.customFields.customContactFields,
    }),
    mediaComponent(): TemplateComponent | undefined {
      //Todo @aleksandra: Use utility isMedia funtion
      return this.template.components?.find(
        (component: TemplateComponent) =>
          component?.sub_type &&
          (component.sub_type.toLowerCase() === TEMPLATE_HEADER_TYPE.IMAGE ||
            component.sub_type.toLowerCase() === TEMPLATE_HEADER_TYPE.VIDEO),
      );
    },
    mediaFile(): TemplateHeaderMedia | BroadcastMedia {
      return this.dynamicTemplateMedia || this.defaultTemplateMedia;
    },
    mediaType() {
      return this.mediaComponent?.sub_type;
    },
    mediaTagValue(): string {
      if (this.isEditingBroadcast) {
        const hasInputTagValue = this.computedInputTags.find(
          (tag: TemplateTag) => tag.type === TEMPLATE_TAG_TYPE.TEMPLATE_COMPONENT,
        )?.value;
        if (hasInputTagValue) {
          return hasInputTagValue;
        }
        return this.defaultTemplateMedia?.id?.toString() || '';
      }
      return this.defaultTemplateMedia?.id?.toString() || '';
    },
    computedCustomFieldTags(): TemplateTag[] {
      return this.computedInputTags.filter((tag: TemplateTag) => tag.type === TEMPLATE_TAG_TYPE.CUSTOM_FIELD);
    },
    allowedVideoFormat() {
      return ALLOWED_VIDEO_FORMATS[0];
    },
  },
  watch: {
    template: {
      async handler(template: Template) {
        if (!template) return;

        this.resetTemplateMedia();

        if (!this.originalTemplateId) {
          this.originalTemplateId = template.id;
        }

        const customFieldTags: string[] = template.message?.match(/{{(.*?)}}/g) || [];
        this.parsedOriginalMessage = template.message;

        const templateTagsPlaceholders: TemplateTag[] = [];

        customFieldTags?.forEach((tag: string, i: number) => {
          this.parsedOriginalMessage = this.parsedOriginalMessage.replace(
            tag,
            '<span class="template-tag text-leaf-500">' + tag + '</span>',
          );

          templateTagsPlaceholders[i] = {
            value:
              this.originalTemplateId === template.id &&
              this.backupTags[i]?.value !== undefined &&
              this.backupTags[i]?.value !== ''
                ? this.backupTags[i].value
                : '',
            key: tag,
            type: TEMPLATE_TAG_TYPE.CUSTOM_FIELD,
          };
        });

        if (this.mediaComponent) {
          await this.loadTemplateMedia();

          const mediaTag: TemplateTag = {
            key: this.mediaComponent.id?.toString() || '',
            type: TEMPLATE_TAG_TYPE.TEMPLATE_COMPONENT,
            value: this.mediaTagValue,
          };

          templateTagsPlaceholders.push(mediaTag);
        }

        this.$emit('updateTags', templateTagsPlaceholders);
        this.tags = templateTagsPlaceholders;
        this.editedMessage = this.parsedOriginalMessage;
      },
    },
    editedMessage: {
      handler(message) {
        this.sanitizedMessage = sanitize(message, { ALLOWED_TAGS: ['span'], ALLOWED_ATTR: ['class'] });
      },
      immediate: true,
    },
    computedInputTags: {
      deep: true,
      handler() {
        if (!this.backupTags.length && this.computedInputTags[0]?.value) {
          this.backupTags = cloneDeep(this.computedInputTags);
        }
        this.editedMessage = this.parsedOriginalMessage;
        this.tags?.forEach((tag, i) => {
          const tagValue =
            this.computedInputTags[i].value !== undefined && this.computedInputTags[i].value !== ''
              ? this.computedInputTags[i].value
              : tag.key;
          this.editedMessage = this.editedMessage.replace(
            tag.key,
            '<span class="template-tag text-leaf-500">' + tagValue + '</span>',
          );
        });
        this.$emit('updateTags', this.computedInputTags);
      },
      immediate: true,
    },
    mediaFile: {
      handler(newVal) {
        if (newVal) {
          this.$emit('updateTemplateMedia', newVal);
        }
      },
    },
  },
  mounted() {
    this.loadAutocompleteTags();
  },
  methods: {
    loadAutocompleteTags() {
      map(this.customFieldsContact, (f) => {
        if (f.identifier && f.identifier.toLowerCase() !== 'null') {
          this.allAutocompleteTags.push({ title: f.title, identifier: '[' + f.identifier + ']' });
        }
      });
      this.allAutocompleteTags = uniqBy(this.allAutocompleteTags, 'identifier');
    },
    handleFilterTags(value, i) {
      this.filteredAutocompleteTags.splice(
        i,
        1,
        this.allAutocompleteTags.filter((item) => item.identifier.toLowerCase().includes(value.toLowerCase())),
      );
    },
    tagOptions(i) {
      if (this.filteredAutocompleteTags[i] && this.filteredAutocompleteTags[i].length) {
        return this.filteredAutocompleteTags[i];
      } else return this.allAutocompleteTags;
    },
    handleItemClick(value: string, i: number) {
      this.computedInputTags[i].value = value;
    },
    onKeyDown($event, i) {
      if (filter(this.allAutocompleteTags, (tag) => tag.identifier === $event.target.value).length) {
        if ($event.code === 'Backspace') {
          this.computedInputTags.splice(i, 1, { value: '', key: this.tags[i] });
        }
      }
    },
    async fetchBroadcastMedia(mediaId: string): Promise<BroadcastMedia | void> {
      try {
        if (!mediaId) {
          return;
        }

        const { data }: { data: BroadcastMedia } = await fetchTemplateMedia(mediaId);
        return data;
      } catch (error) {
        console.error('error', error);
      }
    },
    async handleFileChange(files: File[]): Promise<void> {
      let imgURL;
      if (files[0] instanceof File) {
        imgURL = URL.createObjectURL(files[0]);
      }

      this.dynamicTemplateMedia = {
        url: imgURL,
        file: files[0],
      };

      if (this.mediaComponent) {
        // Clear existing template media ID when a new media is uploaded
        const mediaTagIndex = this.computedInputTags.findIndex(
          (tag: TemplateTag) => tag.type === TEMPLATE_TAG_TYPE.TEMPLATE_COMPONENT,
        );

        if (mediaTagIndex >= 0) {
          this.computedInputTags[mediaTagIndex].value = '';
        }
      }

      this.$emit('updateTemplateMedia', this.dynamicTemplateMedia);
    },
    handleFileReset(): void {
      this.dynamicTemplateMedia = null;
      this.$emit('updateTemplateMedia', this.mediaFile);
    },
    resetTemplateMedia() {
      this.defaultTemplateMedia = null;
      this.dynamicTemplateMedia = null;
      this.$emit('updateTemplateMedia', this.mediaFile);
    },
    async loadTemplateMedia(): Promise<void> {
      try {
        if (this.mediaComponent?.value) {
          this.defaultTemplateMedia = await this.fetchBroadcastMedia(this.mediaComponent.value);
        }

        if (!this.isEditingBroadcast) return;

        const mediaTag = this.computedInputTags.find(
          (tag: TemplateTag) => tag.type === TEMPLATE_TAG_TYPE.TEMPLATE_COMPONENT,
        );

        const hasMedia = mediaTag?.value;
        const isDifferentFromDefaultMedia = mediaTag?.value !== this.mediaComponent?.value;

        if (hasMedia && isDifferentFromDefaultMedia) {
          this.dynamicTemplateMedia = await this.fetchBroadcastMedia(mediaTag.value);
        }
      } catch (error) {
        console.error('Failed to load template media:', error);
      }
    },
  },
};
</script>
