import { h } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';

import { generateDangerZoneToken } from '@/api';
import { CHANNEL_TYPE } from '@/Configs/Constants';
import { LOCAL_STORAGE } from '@/Configs/Constants/localStorage';
import { AUTH_URL } from '@/Configs/Constants/navigation/authUrl';
import { handleMiddleware } from '@/middleware';
import { useUserStore } from '@/store/pinia';
import { useRoutesStore } from '@/store/pinia/routes';
import { trackPageWithSegmentAnalytics } from '@/util/analytics/segmentAnalytics';
import { flashError } from '@/util/flashNotification';

import dashboard from './dashboard';
import store from '../store/index';
import admin from './admin/admin';
import { ROUTE_NAME } from './constants';

import type { RouteRecord } from './types';

const EmptyComponent = { render: () => h('div') };

const routes: RouteRecord[] = [
  {
    path: AUTH_URL.LOGIN,
    name: ROUTE_NAME.LOGIN,
    props: true,
    component: () => import(/* webpackChunkName: "Login" */ '../components/User/Login.vue'),
    meta: { allowUnauthenticated: true },
  },
  {
    path: '/reset-password/request-email',
    name: ROUTE_NAME.REQUEST_EMAIL,
    props: true,
    component: () => import(/* webpackChunkName: "RequestEmail" */ '../components/User/RequestEmail.vue'),
    meta: { allowUnauthenticated: true },
  },
  {
    path: '/reset-password/reset-password/:token',
    name: ROUTE_NAME.RESET_PASSWORD,
    component: () => import(/* webpackChunkName: "ResetPassword" */ '../components/User/ResetPassword.vue'),
    meta: { allowUnauthenticated: true },
  },
  {
    path: AUTH_URL.PASSWORD,
    name: ROUTE_NAME.SETUP_PASSWORD,
    component: () => import(/* webpackChunkName: "SetupPassword" */ '../components/User/SetupPassword.vue'),
  },
  {
    path: AUTH_URL.SETUP_2FA,
    name: ROUTE_NAME.SETUP_2FA,
    component: () => import(/* webpackChunkName: "SetupTwoFactorAuth" */ '../components/User/SetupTwoFactorAuth'),
  },
  {
    path: '/composer/:ticketId?',
    name: ROUTE_NAME.COMPOSER_POPOUT,
    component: () => import(/* webpackChunkName: "ComposerPopout" */ '../components/ComposerPopout.vue'),
  },
  {
    path: '/',
    name: ROUTE_NAME.HOME,
    component: () => import(/* webpackChunkName: "Dashboard" */ '../components/Dashboard.vue'),
    children: [...dashboard, ...admin],
  },
  {
    path: '/verification',
    name: ROUTE_NAME.VERIFICATION,
    beforeEnter: async (to) => {
      if (!to.query.secret) {
        console.error('No secret provided for verification');
        return '/';
      }
      try {
        const {
          data: { token },
        } = await generateDangerZoneToken(to.query.secret as string);
        localStorage.setItem(LOCAL_STORAGE.DANGER_ZONE_TOKEN, token);
      } catch (error: unknown) {
        console.error(error);
        type TokenError = Error & {
          response?: { data?: { message?: string } };
        };

        const isTokenError = (error: unknown): error is TokenError => (error as TokenError).response !== undefined;

        if (isTokenError(error)) {
          flashError(error?.response?.data?.message || error?.message || 'An error occurred');
        }
      }
      return '/';
    },
    component: EmptyComponent, // necessary for beforeEnter to work
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: 'active',
});

router.beforeEach(async (to) => {
  document.title = to.meta.title ? `${to.meta.title} - Trengo` : 'Trengo';

  const redirect = await handleMiddleware(to);
  // if middleware returns a redirect, return it here
  if (redirect && redirect.name) {
    return redirect;
  }

  const user = store.getters['initialData/user'];
  const shouldRedirectToMFASetup = store.getters['initialData/shouldRedirectToMFASetup'];
  if (user?.needs_new_pw && to.path !== AUTH_URL.PASSWORD) {
    return AUTH_URL.PASSWORD;
  }
  if (shouldRedirectToMFASetup && to.path !== AUTH_URL.SETUP_2FA) {
    return AUTH_URL.SETUP_2FA;
  }

  const deprecatedChannels = [CHANNEL_TYPE.TWITTER, CHANNEL_TYPE.WECHAT];
  const isChannelDeprecated = deprecatedChannels.some(
    (channel) => typeof to.params.channel === 'string' && channel.toLowerCase() === to.params.channel?.toLowerCase(),
  );

  if (isChannelDeprecated) {
    return '/';
  }

  return true;
});

router.afterEach((to, from) => {
  // FIXME: https://linear.app/trengo/issue/WALL-615/add-full-path-to-routes-store-and-update-subscriptionmanagers
  // eslint-disable-next-line vue-composable/composable-placement
  const userStore = useUserStore();
  const user = userStore.user;
  const fullPath = to.fullPath;
  if (to.fullPath !== from.fullPath && user) {
    trackPageWithSegmentAnalytics({ user, route: fullPath });
  }

  // eslint-disable-next-line vue-composable/composable-placement
  const routesStore = useRoutesStore();
  routesStore.updateRouteState(to, from);

  if (typeof Appcues !== 'undefined') {
    setTimeout(() => Appcues.page(), 1000);
  }

  // Remove modal backdrop
  $('.modal-backdrop').remove();
});

export default router;
