<template>
  <div class="flex items-center my-8">
    <call-incoming-fill class="mr-2 text-leaf-500" />

    <p class="m-0 p-0">
      Inbound call at:
      <strong>+31 123123123</strong>
    </p>
  </div>

  <avatar v-if="hasProfile" />
  <div v-else class="rounded-full bg-grey-800 text-grey-500 p-3">
    <user-fill size="48" />
  </div>

  <p class="m-0 p-0 mt-2"><strong>John Doe (+31 123123123)</strong></p>

  <div class="flex items-center justify-between mt-6 w-1/2">
    <div class="rounded-full flex flex-col items-center group cursor-pointer">
      <button
        class="rounded-full p-3 bg-error-500 group-hover:bg-error-700 active:!bg-error-300 transition-colors"
        @click="emit('ignore')"
      >
        <call-muted-fill size="32" />
      </button>

      <p class="m-0 p-0 mt-1 text-xs text-grey-500 group-hover:text-grey-200 transition-colors select-none">Ignore</p>
    </div>

    <div class="flex flex-col items-center group cursor-pointer">
      <button
        class="rounded-full p-3 bg-leaf-500 group-hover:bg-leaf-700 active:!bg-leaf-300 transition-colors"
        @click="emit('accept')"
      >
        <call-received-fill size="32" />
      </button>

      <p class="m-0 p-0 mt-1 text-xs text-grey-500 group-hover:text-grey-200 transition-colors select-none">Accept</p>
    </div>
  </div>

  <button
    type="button"
    class="bg-grey-800 mt-auto mb-8 rounded-lg w-fit px-2 py-1 hover:bg-grey-700 active:bg-grey-600 transition-colors"
    @click="emit('close')"
  >
    View queue & active calls
  </button>
</template>

<script setup lang="ts">
import { CallIncomingFill, CallReceivedFill, CallMutedFill, UserFill } from '@trengo/trengo-icons';
import { ref } from 'vue';

import Avatar from '@/components/Avatar.vue';

const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'accept'): void;
  (e: 'ignore'): void;
}>();

const hasProfile = ref(false);
</script>
