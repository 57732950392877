import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-e53fab36"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "flex w-full flex-col",
  id: "comment-composer",
  "data-test": "commentComposerWrapper"
}
const _hoisted_2 = { class: "comment-composer__tabs-wrapper" }
const _hoisted_3 = ["onClick", "data-test"]
const _hoisted_4 = { ref: "bottom" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.tabs, (tab) => {
        return _withDirectives((_openBlock(), _createElementBlock("div", {
          key: tab.index
        }, [
          _createElementVNode("div", {
            onClick: ($event: any) => ($options.changeTab(tab.index, true)),
            class: _normalizeClass(["comment-composer__tab", ['tab-' + tab.slotName, $data.selectedIndex === tab.index && 'comment-composer__selected-tab', $options.cursorStyle]]),
            "data-test": $options.snakeKebabToCamel(tab.slotName) + 'TabButton'
          }, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(tab.icon), {
              class: "icon",
              size: "1.25rem"
            })),
            _createTextVNode(" " + _toDisplayString(_ctx.$t(tab.title)), 1 /* TEXT */)
          ], 10 /* CLASS, PROPS */, _hoisted_3)
        ])), [
          [_vShow, tab.enabled]
        ])
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.tabs, (tab) => {
      return (_openBlock(), _createElementBlock("div", {
        key: tab.index
      }, [
        _createElementVNode("div", {
          ref_for: true,
          ref: tab.slotName,
          class: _normalizeClass(["comment-composer__content-wrapper", 'wrapper-' + tab.slotName])
        }, [
          _renderSlot(_ctx.$slots, tab.slotName, {}, undefined, true)
        ], 2 /* CLASS */)
      ]))
    }), 128 /* KEYED_FRAGMENT */)),
    _createElementVNode("div", _hoisted_4, null, 512 /* NEED_PATCH */)
  ]))
}