<template>
  <t-table-list-row data-test="ticket-inisight-table-row">
    <t-table-list-cell class="w-[120px] break-words pr-4">
      <p
        class="
          t-text-paragraph-sm
          m-0
          text-grey-600
          hover:cursor-pointer hover:text-leaf-500 hover:underline hover:underline-offset-2
        "
      >
        <router-link :to="`/tickets/${availableData.ticket_id}`">
          {{ availableData.ticket_id }}
        </router-link>
      </p>
    </t-table-list-cell>
    <t-table-list-cell v-if="availableColumns.Assignee" class="max-w-[320px] break-words pr-4">
      <div class="flex flex-row items-center">
        <t-avatar
          v-if="availableData.assignee.profile_image"
          :image="availableData.assignee.profile_image"
          width="32"
        />
        <img v-else :src="getUnassignedImage" width="32px" />
        <p class="t-text-desktop-paragraph-sm m-0 ml-2 w-full truncate text-grey-800">
          {{ availableData.assignee.full_name || getUnassignedDisplayName(availableData.ticket_status) }}
        </p>
      </div>
    </t-table-list-cell>
    <t-table-list-cell v-if="availableColumns.ConversationStatus" class="flex w-[85px] items-center pr-4">
      <t-badge
        class="w-fit"
        :variant="getStatusVariant"
        :text="$t(`reports_v2.${availableData.ticket_status.toLowerCase()}`)"
      />
    </t-table-list-cell>
    <t-table-list-cell v-if="availableColumns.AttachedLabels" class="max-w-[320px] pr-4">
      <div class="flex flex-row items-center">
        <ticket-insight-labels :labels="this.availableData.attached_labels" />
      </div>
    </t-table-list-cell>
    <t-table-list-cell v-if="availableColumns.Channel" class="max-w-[320px] pr-4">
      <t-tag variant="channel" size="sm" :avatar="getChannelIconSrc(availableData.channel.type)" class="max-w-[300px]">
        {{ availableData.channel.title }}
      </t-tag>
    </t-table-list-cell>
    <t-table-list-cell v-if="availableColumns.CSATScore" data-test="csat" class="max-w-[320px] break-words pl-4">
      <ticket-insight-csat :score="availableData.csat" />
    </t-table-list-cell>
    <t-table-list-cell
      v-if="availableColumns.FirstResolutionTimeWithoutBusinessHours"
      class="max-w-[320px] break-words pl-4"
      data-test="first_resolution_time_without_business_hours"
    >
      <ticket-insight-time-cell :time="availableData.first_resolution_time_without_business_hours" />
    </t-table-list-cell>
    <t-table-list-cell v-if="availableColumns.FirstResolutionTime" class="max-w-[320px] break-words pl-4">
      <ticket-insight-time-cell :time="availableData.first_resolution_time_with_business_hours" />
    </t-table-list-cell>
    <t-table-list-cell
      v-if="availableColumns.TotalResolutionTimeWithoutBusinessHours"
      class="max-w-[320px] break-words pl-4"
    >
      <ticket-insight-time-cell :time="availableData.total_resolution_time_without_business_hours" />
    </t-table-list-cell>
    <t-table-list-cell v-if="availableColumns.TotalResolutionTime" class="max-w-[320px] break-words pl-4">
      <ticket-insight-time-cell :time="availableData.total_resolution_time_with_business_hours" />
    </t-table-list-cell>
    <t-table-list-cell
      v-if="availableColumns.FirstResponseTimeWithoutBusinessHours"
      class="max-w-[320px] break-words pl-4"
    >
      <ticket-insight-time-cell :time="availableData.first_reply_time_without_business_hours" />
    </t-table-list-cell>
    <t-table-list-cell v-if="availableColumns.FirstResponseTime" class="max-w-[320px] break-words pl-4">
      <ticket-insight-time-cell :time="availableData.first_reply_time_with_business_hours" />
    </t-table-list-cell>
    <t-table-list-cell
      v-if="availableColumns.FirstHumanResponseTimeWithoutBusinessHours"
      class="max-w-[320px] break-words pl-4"
    >
      <ticket-insight-time-cell :time="availableData.first_human_reply_time_without_business_hours" />
    </t-table-list-cell>
    <t-table-list-cell
      v-if="availableColumns.FirstHumanResponseTime"
      class="max-w-[320px] break-words pl-4"
    >
      <ticket-insight-time-cell :time="availableData.first_human_reply_time_with_business_hours" />
    </t-table-list-cell>
    <t-table-list-cell v-if="availableColumns.CreatedAt" class="break-words pl-4">
      <ticket-insight-date-cell :time="availableData.created_at" />
    </t-table-list-cell>
    <t-table-list-cell v-if="availableColumns.AssignedAt" class="break-words pl-4">
      <ticket-insight-date-cell :time="availableData.assigned_at" />
    </t-table-list-cell>
    <t-table-list-cell v-if="availableColumns.ClosedAt" class="break-words pl-4">
      <ticket-insight-date-cell :time="availableData.closed_at" />
    </t-table-list-cell>
    <t-table-list-cell v-if="availableColumns.MessagesSent" class="break-words pl-4">
      <p class="t-text-sm m-0 text-right text-grey-600">
        {{ availableData.messages_sent }}
      </p>
    </t-table-list-cell>
    <t-table-list-cell v-if="availableColumns.ChannelType && availableData.channel" class="break-words pl-4">
      <p class="t-text-sm m-0 text-grey-600">
        {{ availableData.channel.type }}
      </p>
    </t-table-list-cell>
    <t-table-list-cell v-if="availableColumns.ContactEmail" class="break-words pl-4">
      <p class="t-text-sm m-0 text-grey-600">
        {{ availableData.contact_email }}
      </p>
    </t-table-list-cell>
    <t-table-list-cell v-if="availableColumns.ContactPhone" class="break-words pl-4">
      <p class="t-text-sm m-0 text-grey-600">
        {{ availableData.contact_phone }}
      </p>
    </t-table-list-cell>
    <t-table-list-cell v-if="availableColumns.ContactCustomFields" class="break-words pl-4">
      <p class="t-text-sm m-0 text-grey-600">
        {{ availableData.contact_custom_fields }}
      </p>
    </t-table-list-cell>
    <t-table-list-cell v-if="availableColumns.ProfileCustomFields" class="break-words pl-4">
      <p class="t-text-sm m-0 text-grey-600">
        {{ availableData.profile_custom_fields }}
      </p>
    </t-table-list-cell>
    <t-table-list-cell v-if="availableColumns.TicketCustomFields" class="break-words pl-4">
      <p class="t-text-sm m-0 text-grey-600">
        {{ availableData.ticket_custom_fields }}
      </p>
    </t-table-list-cell>
    <t-table-list-cell v-if="availableColumns.TicketResult" class="break-words pl-4">
      <p class="t-text-sm m-0 text-grey-600">
        {{ availableData.ticket_result }}
      </p>
    </t-table-list-cell>
    <t-table-list-cell class="w-[6rem] break-words pl-4 text-right">
      <router-link :to="`/tickets/${availableData.ticket_id}`">
        <t-button btn-style="secondary" class="!rounded-lg" size="sm">{{ $t('reports_v2.view') }}</t-button>
      </router-link>
    </t-table-list-cell>
  </t-table-list-row>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import Unassigned from './assets/unassigned.svg';
import TicketInsightCsat from './TicketInsightCSAT.vue';
import TicketInsightDateCell from './TicketInsightDateCell.vue';
import TicketInsightLabels from './TicketInsightLabels.vue';
import TicketInsightTimeCell from './TicketInsightTimeCell.vue';

import type { DefaultAvailableColumns, TicketInsightsResponse } from './utils';
import type { PropType } from 'vue';

type AvaialableColumns = typeof DefaultAvailableColumns;

export default defineComponent({
  name: 'TicketInsightRow',
  components: { TicketInsightLabels, TicketInsightCsat, TicketInsightTimeCell, TicketInsightDateCell },
  props: {
    availableData: {
      type: Object as PropType<TicketInsightsResponse>,
      default: {} as TicketInsightsResponse,
    },
    availableColumns: {
      type: Object as PropType<AvaialableColumns>,
      default: {} as AvaialableColumns,
    },
  },
  computed: {
    getUnassignedImage() {
      return Unassigned;
    },
    getStatusVariant() {
      if (this.availableData.ticket_status === 'ASSIGNED') return 'info';
      if (this.availableData.ticket_status === 'OPEN') return 'warning';
      return 'default';
    },
  },
  methods: {
    handleGetAvaialbleColumns(columns: AvaialableColumns) {
      this.availableColumns = columns;
    },

    getChannelIconSrc(channelType: string) {
      return `${this.$root.assetsURL}img/channels_icons/${channelType.toLowerCase()}.svg`;
    },

    getUnassignedDisplayName(ticketStatus: string) {
      return ticketStatus === 'ASSIGNED' ? 'Bot' : this.$t('reports_v2.unassigned');
    },
  },
});
</script>
