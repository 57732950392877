<template>
  <call-panel
    v-show="isTabActive && isInActiveCall && shouldShowActiveCall"
    @close="shouldShowActiveCall = false"
    @call-accepted="onCallAccepted"
    @call-ignored="onCallIgnored"
  />

  <div v-show="isTabActive && !shouldShowActiveCall" class="flex-1 flex flex-col h-full overflow-y-auto px-2">
    <button
      v-if="isInActiveCall"
      type="button"
      class="bg-grey-800 mb-4 rounded-lg w-fit px-2 py-1 hover:bg-grey-700 active:bg-grey-600 transition-colors"
      @click="shouldShowActiveCall = true"
    >
      Return to call
    </button>

    <p class="flex items-center rounded-lg bg-grey-800 text-grey-400 pl-3 m-0 mb-4 w-fit">
      {{ $t('voip.voip_queue') }}
      <span class="bg-green text-white py-2 px-3 font-bold rounded-lg h-full ml-2">{{ queuedCalls.length }}</span>
    </p>

    <p v-if="queuedCalls.length === 0" class="m-0 p-0 text-grey-600">{{ $t('voip.voip_no_queued_calls') }}</p>
    <div v-else class="flex flex-col">
      <active-call-card v-for="call in queuedCalls" :key="call.token" :call="call" />
    </div>

    <span class="w-full h-[2px] bg-grey-800 my-4 rounded-2xl" />

    <p class="flex items-center rounded-lg bg-grey-800 text-grey-400 pl-3 m-0 mb-4 w-fit">
      {{ $t('voip.voip_in_progress') }}
      <span class="bg-green text-white py-2 px-3 font-bold rounded-lg h-full ml-2">{{ inProgressCalls.length }}</span>
    </p>

    <p v-if="inProgressCalls.length === 0" class="m-0 p-0 text-grey-600">{{ $t('voip.voip_no_calls_in_progress') }}</p>
    <div v-else class="flex flex-col">
      <active-call-card v-for="call in inProgressCalls" :key="call.token" :call="call" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';

import ActiveCallCard from '@/components/Voip/components/Panel/ActiveCallCard.vue';
import CallPanel from '@/components/Voip/components/Panel/CallPanel.vue';
import useVoipStore from '@/store/pinia/voip';

defineProps<{
  isTabActive: boolean;
}>();

const voipStore = useVoipStore();

const queuedCalls = computed(() => voipStore.callLog.filter((call) => call.status === 'QUEUED'));
const inProgressCalls = computed(() => voipStore.callLog.filter((call) => call.status === 'IN_PROGRESS'));

const isInActiveCall = ref(true);
const shouldShowActiveCall = ref(false);

function onCallAccepted() {}

function onCallIgnored() {}
</script>
