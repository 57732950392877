import SectionTitle from '../components/Admin/SectionTitle.vue';
import TelInput from '../components/TelInput.vue';
import Sidebar from '../components/Sidebar.vue';
import DateRangePicker from '../UIKit/Molecules/DateRangePicker.vue';
import DropdownSelector from '../UIKit/Molecules/DropdownSelector.vue';
import Modal from '../UIKit/Organisms/Modal';
import Multiselect from '../UIKit/Organisms/Multiselect';
import DatePicker from '../UIKit/Organisms/DatePicker';
import Dropdown from '../UIKit/Organisms/Dropdown';
import Popup from '../UIKit/Organisms/Popup.vue';

const components = [
  {
    name: 'admin-section-title',
    path: SectionTitle,
  },
  {
    name: 'tel-input',
    path: TelInput,
  },
  {
    name: 'sidebar',
    path: Sidebar,
  },
  {
    name: 'date-range-picker',
    path: DateRangePicker,
  },
  {
    name: 'atomic-dropdown-selector',
    path: DropdownSelector,
  },
  {
    name: 'atomic-modal',
    path: Modal,
  },
  {
    name: 'atomic-multiselect',
    path: Multiselect,
  },
  {
    name: 'atomic-datepicker',
    path: DatePicker,
  },
  {
    name: 'atomic-dropdown',
    path: Dropdown,
  },
  {
    name: 'atomic-popup',
    path: Popup,
  },
];

export default components;
