import { PERMISSION } from '@/Configs/Constants';

import type { RouteRecord } from '../types';

const usersRoutes: RouteRecord[] = [
  {
    path: '/admin/users',
    component: () => import(/* webpackChunkName: "UsersPage" */ '../../components/Users/pages/UsersPage'),
    meta: { permissionRequired: PERMISSION.PERMISSIONS__USERS__MANAGE },
  },
];

export default usersRoutes;
