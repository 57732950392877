import Axios from 'axios';
import { throttle, toArray } from 'lodash';

import { RESPONSE_MESSAGE } from '@/Configs/Constants/ApiResponseMessages';
import { FEATURE_FLAG_ACTIVATION } from '@/Configs/Constants/featureFlag';
import { AUTH_URL } from '@/Configs/Constants/navigation/authUrl';
import { STATUS_CODE } from '@/Configs/Constants/StatusCodes';
import eventBus from '@/eventBus';
import { useAuthStore, useFeatureFlagStore } from '@/store/pinia';
import { flashError, flashSuccess, flashWarning } from '@/util/flashNotification';

const BASE_URL = process.env.BASE_URL || `${window?.location.origin}/`;
axios.defaults.baseURL = BASE_URL;
window.base_url = BASE_URL;
axios.defaults.withCredentials = true;
window.axios = Axios;

axios.interceptors.response.use(
  (res) => res,
  function (error) {
    // eslint-disable-next-line vue-composable/composable-placement
    const authStore = useAuthStore();
    if (
      error.config?.meta?.bypassAxiosInterceptors &&
      error.config?.meta?.bypassList?.includes(error.response.status)
    ) {
      console.info('Bypassing axios interceptors');
      return Promise.reject(error);
    }
    if (error.message === 'Network Error' && !error.response) {
      flashError(error);
      return;
    }
    if (!error.response) {
      return;
    }

    switch (error.response.status) {
      case STATUS_CODE.UNPROCESSABLE_ENTITY: {
        if (isAssignToTeamError(error)) {
          break;
        }

        if (error.response?.data?.errors) {
          flashError(toArray(error.response.data.errors).flat().join('<br />'));
          break;
        }

        flashError(error.response.data.message);
        break;
      }

      case STATUS_CODE.TOO_MANY_REQUESTS:
        throttle(() => {
          flashError(
            `${error.response.data.message} Please try again after ${error.response.data.retry_after} seconds`,
          );
        }, 2000);
        trackErrorMessage(error);
        break;

      case STATUS_CODE.INTERNAL_SERVER_ERROR:
        if (!isIntegrationsError(error)) {
          flashError(error?.response?.data?.message || RESPONSE_MESSAGE.SOMETHING_WENT_WRONG);
        }
        break;

      case STATUS_CODE.UNAUTHORIZED:
        if (error?.response?.data?.error === RESPONSE_MESSAGE.NEED_TO_SETUP_2FA) {
          window.router.push({ name: 'setup-2fa ' });
        } else {
          authStore.logout();
          if (window.location.pathname !== AUTH_URL.LOGOUT && window.location.pathname !== AUTH_URL.LOGIN) {
            window.location.href = AUTH_URL.LOGOUT;
          }
        }
        break;

      case STATUS_CODE.FORBIDDEN:
        if (error.response.data.error === RESPONSE_MESSAGE.UPGRADE_REQUIRED) {
          flashError(error.respone.data.error);
        } else if (error.response.data.mfa_token) {
          // Handled in Login.vue
        } else {
          window.router.push({ name: 'unauthorized' });
        }
        break;

      case STATUS_CODE.PAYMENT_REQUIRED: {
        const getModalId = (responseMessage: string) => {
          switch (responseMessage) {
            case RESPONSE_MESSAGE.SUBSCRIPTION_EXPIRED:
              return '#TrialExpiredModal';
            case RESPONSE_MESSAGE.SUBSCRIPTION_INACTIVE:
              return '#SubscriptionInactiveModal';
            default:
              return null;
          }
        };

        const modalId = getModalId(error.response.data.error);
        if (modalId) {
          $(modalId).modal({
            keyboard: false,
            backdrop: 'static',
          });
        }

        break;
      }

      case STATUS_CODE.SERVICE_UNAVAILABLE:
        if (error.response.data && error.response.data.message === RESPONSE_MESSAGE.BE_RIGHT_BACK) {
          flashWarning(
            'Please try again in a few seconds or check status.trengo.com',
            'Scheduled maintenance in progress',
          );
        }
        break;

      default:
        if (error?.response?.data?.message) {
          flashSuccess(error.response.data.message);
        }
        break;
    }

    return Promise.reject(error);
  },
);

function trackErrorMessage(error: any) {
  // eslint-disable-next-line vue-composable/composable-placement
  const featureFlagStore = useFeatureFlagStore();
  const isErrorTrackingMessageEnabled = featureFlagStore.isEnabled(FEATURE_FLAG_ACTIVATION.TA_FRONTEND_TRACKING);
  if (!isErrorTrackingMessageEnabled || !window.faro) {
    return;
  }
  const errorTypeEventName = error.response.status >= 500 ? 'server_error_message' : 'client_error_message';
  const uuid = error?.response?.data?.message?.match(/\(([a-f0-9-]+)\)/)?.[1];
  window?.faro?.api?.pushEvent(errorTypeEventName, {
    url: '' + error.response.config.url,
    method: '' + error.response.config.method,
    http_code: '' + error.response.status,
    error_message: '' + error?.response?.data?.message,
    uuid: '' + uuid,
  });
}

function isIntegrationsError(error: any) {
  if (error.request && error.request.responseURL.indexOf('api/v2/integrations/') > -1) {
    let msg = RESPONSE_MESSAGE.SOMETHING_WENT_WRONG;
    if (error.response.data.message) {
      msg = error.response.data.message;
    }
    const url = new URL(error.request.responseURL);
    const pluginId = url.searchParams.get('plugin_id');
    eventBus.$emit('integrations.error.' + pluginId, msg);
    return true;
  }
  return false;
}

function isAssignToTeamError(error: any) {
  if (error.request && error.request.responseURL.indexOf('api/v2/tickets/' + window.assigned_team_ticket_id) > -1) {
    delete window.assigned_team_ticket_id;
    return true;
  }
}

const CancelToken = axios.CancelToken;
const cancelRequests: Array<() => void> = [];

window.singleRequest = function (method: string, url: string, data: Record<string, unknown> = {}) {
  const tmp = cancelRequests[this.baseUrl(url)];
  if (tmp !== undefined) {
    tmp();
  }

  let canceler;
  data.cancelToken = new CancelToken(function executor(c) {
    canceler = c;
  });
  // @ts-expect-error FIXME: replace this utility with something maintainable
  cancelRequests[window.baseUrl(url)] = canceler;

  // @ts-expect-error FIXME: replace this utility with something maintainable
  return axios[method](url, data);
};

window.baseUrl = function (url: string) {
  return url.split('?')[0];
};
