<template>
  <external-link2-linear v-if="btn.subType === WA_TEMPLATE_COMPONENT_SUB_TYPE.URL" class="mr-2 inline" />
  <phone-fill v-if="btn.subType === WA_TEMPLATE_COMPONENT_SUB_TYPE.PHONE_NUMBER" class="mr-2 inline" />
  <span>{{ btn.value }}</span>
</template>

<script setup lang="ts">
import { ExternalLink2Linear, PhoneFill } from '@trengo/trengo-icons';

import { WA_TEMPLATE_COMPONENT_SUB_TYPE } from '@/Configs/Constants/whatsapp';

import type { TemplateButtonItem } from '../types';

defineProps<{
  btn: TemplateButtonItem;
}>();
</script>
