<template>
  <div>
    <div class="my-2 font-bold">{{ title }}</div>
    <div v-if="formText" class="form-text pb-2">{{ formText }}</div>
    <content-editable
      ref="editor"
      v-model="element.meta[attribute]"
      :insert-plain-text="true"
      :return-plain-text="true"
      autofocus
      :placeholder="placeholder"
      :max-height="'none'"
      class="form-control custom-placeholder-styling border"
      style="border-radius: 0.5rem 0.5rem 0 0.5rem"
    ></content-editable>
    <div
      v-if="showEmojiPicker"
      ref="emojiContainer"
      v-click-away="hideEmojipicker"
      class="emoji-container"
      style="bottom: 0"
    >
      <picker @insertEmoji="insertEmoji"></picker>
    </div>
    <div class="clear">
      <span
        class="bg-gray-500 pull-right rounded-bottom flex border-b border-l border-r px-2 pt-1 text-sm text-grey-600"
        style="background-color: #ececec"
      >
        <dropdown :options="customFields" label="label" max-height="330px" width="250px" @selected="selected">
          <template #toggle>
            <span v-tooltip="{ content: $t('flowbot.insert_variable_hover'), delay: 300 }" class="pointer mr-1">
              <i class="text-md material-icons text-grey-600">label</i>
            </span>
          </template>
          <template #option="prop">
            <div class="flex">
              <span class="ml-2 flex-1 text-ellipsis" style="padding-right: 4px">{{ prop.option.title }}</span>
            </div>
          </template>
          <!--                    <template slot="afterList">-->
          <!--                        <div class="flex">-->
          <!--                            <span class="ml-2 text-ellipsis flex-1" style="padding-right: 4px;"><i class="material-icons material-icons-xs text-grey-800">create</i> Create custom fields <router-link to="/admin/custom_fields" class="btn btn-xs btn-default">here</router-link></span>-->
          <!--                        </div>-->
          <!--                    </template>-->
        </dropdown>
        <span
          v-tooltip="{ content: $t('flowbot.step_text_insert_emoticon'), delay: 300 }"
          class="pointer"
          @click="showEmojiPicker = !showEmojiPicker"
        >
          <i class="text-md material-icons">insert_emoticon</i>
        </span>
      </span>
    </div>
  </div>
</template>

<script>
import { mixin as VueClickAway } from 'vue3-click-away';

import ContentEditable from '../../ContentEditable';
import Picker from '../../Elements/EmojiPicker';
import Dropdown from '../../ReplyForm/Dropdown';

export default {
  mixins: [VueClickAway],

  props: {
    element: {
      type: Object,
    },
    placeholder: {
      type: String,
    },
    customFields: {
      type: Array,
    },
    attribute: {
      type: String,
      default: 'message',
    },
    title: {
      type: String,
      default: 'Message',
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    formText: {
      type: String,
    },
  },

  data() {
    return {
      showEmojiPicker: false,
    };
  },

  watch: {
    element: {
      handler: function (val, oldVal) {
        if (val.meta && val.meta[this.attribute]) {
          this.element.meta[this.attribute] = val.meta[this.attribute].replace('&nbsp;', ' ');
        } else {
          this.element.meta[this.attribute] = '';
        }
      },
      deep: true,
    },
  },

  components: {
    ContentEditable,
    Picker,
    Dropdown,
  },

  methods: {
    insertEmoji(emoji) {
      this.$refs.editor.insertText(emoji.emoji);
      this.showEmojiPicker = false;
    },

    hideEmojipicker() {
      this.showEmojiPicker = false;
    },

    selected(data) {
      this.$refs.editor.insertText('{ ' + data.value + ' }');
    },

    focus() {
      // Autofocus fix
      setTimeout(
        function (that) {
          window.placeCaretAtEnd(that.$refs.editor.$refs.el);
        },
        50,
        this,
      );
    },
  },

  mounted() {
    if (this.autofocus) {
      this.focus();
    }
  },
};
</script>

<style lang="scss">
.rounded-bottom {
  border-radius: 0 0 10px 10px;
}
.emoji-container {
  position: inherit;
  /*bottom: -164px !important;*/
  /*top: 0px;*/
  /*left: -6px;*/
  z-index: 999999999999999999999;
  .emoji-browser {
    width: inherit;
  }
}
</style>
