<template>
  <div class="flex w-full flex-col" data-test="ticket-sidebar">
    <div class="border-b border-grey-300 border-opacity-20 pb-3">
      <div class="flex flex-row items-center p-3">
        <h5 class="t-text-h5 mb-0 font-bold text-grey-800" data-test="sidebar-ticket-id"># {{ ticket.id }}</h5>
        <t-icon-button class="ml-auto" data-test="close-sidebar-button" @click="$emit('closeSidebar')">
          <cross-linear size="1.5rem" />
        </t-icon-button>
      </div>

      <sidebar-infobox class="px-3" :contact="ticket.contact" :channel="ticket.channel" />
    </div>

    <div class="mt-2 flex flex-row border-b border-grey-300 border-opacity-20" data-test="sidebar-tabs-wrapper">
      <t-tab-item
        class="ml-3"
        size="sm"
        :is-active="activeTab === 'contacts'"
        :label="$tc('user_management.contacts')"
        data-test="sidebar-tab-contacts"
        @click="toggleTab('contacts')"
      />
      <t-tab-item
        size="sm"
        :is-active="activeTab === 'integrations'"
        :label="$tc('integration_hub.integrations')"
        data-test="sidebar-tab-integrations"
        @click="toggleTab('integrations')"
      />
    </div>

    <div
      v-if="activeTab === 'contacts'"
      class="flex w-full flex-col overflow-y-auto"
      data-test="sidebar-contacts-tab-wrapper"
    >
      <conversation-dropdown :ticket="ticket" />

      <visitor-details-dropdown v-if="ticket.channel.type === 'CHAT'" :ticket="ticket" :online="visitorOnlineStatus" />

      <ticket-fields-dropdown v-if="isItemActive('ticketFields')" :ticket="ticket" />

      <contact-fields-dropdown v-if="isItemActive('contactFields')" :ticket="ticket" :contact="ticket.contact" />

      <!-- Journeys -->
      <journeys-dropdown
        v-if="hasJourneysSidebar && isItemActive('journeyRuns')"
        :ticket="ticket"
      />

      <contact-group-dropdown
        v-if="isItemActive('contactGroups') && $root.contactGroups.length > 0"
        :contact="ticket.contact"
      />

      <attachments-dropdown
        v-if="isItemActive('attachments') && ticket.attachments.length > 0"
        :attachments="ticket.attachments"
      />

      <timetracking-dropdown v-if="isItemActive('timeTracking')" :ticket="ticket" />

      <related-tickets-dropdown
        v-if="isItemActive('relatedTickets') && ticket.related_tickets.length > 0"
        :related-tickets="ticket.related_tickets"
        :main-ticket-id="ticket.id"
        @remove-related="removeRelated"
      />

      <!-- Contact Moments -->
      <contact-moments-dropdown
        v-if="isItemActive('contactMoments') && ticket.contact !== undefined"
        :contact="ticket.contact"
        :current-ticket="ticket"
      />

      <!-- Contact Notes -->
      <notes-dropdown v-if="isItemActive('contactNotes')" :contact="ticket.contact" />

      <profile-fields-dropdown
        v-if="isItemActive('profileFields') && ticket.contact.profile !== null && ticket.contact.profile.length > 0"
        :profile="ticket.contact.profile[0]"
      />

      <!-- Profile Notes -->
      <notes-dropdown
        v-if="isItemActive('profileNotes') && ticket.contact.profile !== null && ticket.contact.profile.length > 0"
        note-type="profile"
        :profile="ticket.contact.profile[0]"
      />

      <!-- Profile Moments -->
      <contact-moments-dropdown
        v-if="isItemActive('profileMoments') && ticket.contact.profile !== null && ticket.contact.profile.length > 0"
        :current-ticket="ticket"
        :profiles="ticket.contact.profile"
      />
    </div>

    <div v-else-if="!loadingIntegrations && activeTab === 'integrations'" class="flex w-full flex-col overflow-y-auto">
      <div
        v-if="visibleIntegrationInstallations.length === 0 && visibleLegacyIntegrations.length === 0"
        class="mx-3 mt-3"
      >
        <no-integrations-placeholder />
      </div>

      <div v-else class="flex w-full flex-col overflow-y-auto">
        <integration-dropdowns
          v-if="isIntegrationHubEnabled"
          :installations="visibleIntegrationInstallations"
          :ticket="ticket"
          data-test="sidebar-integrations-tab-wrapper"
        />

        <legacy-integration-dropdowns
          :integrations="visibleLegacyIntegrations"
          :ticket="ticket"
          data-test="sidebar-integrations-tab-wrapper"
        />
      </div>
    </div>

    <t-spinner v-else class="mx-auto my-auto" size="2rem" />

    <customise-sidebar-items
      v-if="activeTab === 'contacts'"
      class="mt-auto"
      :label="$tc('user_management.contacts')"
      :items="sidebarItems"
      item-tab="contact"
      data-test="customiser-sidebar-items-contacts"
      @item-toggled="persistItemState"
    />

    <customise-sidebar-items
      v-if="activeTab === 'integrations'"
      class="mt-auto"
      :label="$tc('integration_hub.integrations')"
      :items="allIntegrations"
      item-tab="integrations"
      data-test="customiser-sidebar-items-integrations"
      @item-toggled="persistIntegrationState"
    />
  </div>
</template>

<script lang="ts">
import { CrossLinear } from '@trengo/trengo-icons';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import CustomiseSidebarItems from '@/components/TicketSidebar/CustomiseSidebarItems.vue';
import AttachmentsDropdown from '@/components/TicketSidebar/DropdownItems/AttachmentsDropdown.vue';
import ContactFieldsDropdown from '@/components/TicketSidebar/DropdownItems/ContactFieldsDropdown.vue';
import ContactGroupDropdown from '@/components/TicketSidebar/DropdownItems/ContactGroupDropdown.vue';
import ContactMomentsDropdown from '@/components/TicketSidebar/DropdownItems/ContactMomentsDropdown.vue';
import ConversationDropdown from '@/components/TicketSidebar/DropdownItems/ConversationDropdown.vue';
import NotesDropdown from '@/components/TicketSidebar/DropdownItems/NotesDropdown.vue';
import JourneysDropdown from '@/components/TicketSidebar/DropdownItems/JourneysDropdown.vue';
import ProfileFieldsDropdown from '@/components/TicketSidebar/DropdownItems/ProfileFieldsDropdown.vue';
import RelatedTicketsDropdown from '@/components/TicketSidebar/DropdownItems/RelatedTicketsDropdown.vue';
import TicketFieldsDropdown from '@/components/TicketSidebar/DropdownItems/TicketFieldsDropdown.vue';
import TimetrackingDropdown from '@/components/TicketSidebar/DropdownItems/TimetrackingDropdown.vue';
import VisitorDetailsDropdown from '@/components/TicketSidebar/DropdownItems/VisitorDetailsDropdown.vue';
import IntegrationDropdowns from '@/components/TicketSidebar/Integrations/IntegrationDropdowns.vue';
import LegacyIntegrationDropdowns from '@/components/TicketSidebar/Legacy/IntegrationDropdowns.vue';
import NoIntegrationsPlaceholder from '@/components/TicketSidebar/NoIntegrationsPlaceholder.vue';
import SidebarInfobox from '@/components/TicketSidebar/SidebarInfobox.vue';
import { FEATURE_FLAG_INBOX } from '@/Configs/Constants';
import { useFeatureFlagStore } from '@/store/pinia';

import type Ticket from '@/types/tickets';
import type { PropType } from 'vue';

export type SidebarItem = {
  visible: boolean;
  label: string;
  itemId: string;
};

export default defineComponent({
  name: 'TicketSidebar',
  emits: ['closeSidebar', 'onClientComposing', 'statusChanged'],
  components: {
    NoIntegrationsPlaceholder,
    IntegrationDropdowns,
    LegacyIntegrationDropdowns,
    CustomiseSidebarItems,
    AttachmentsDropdown,
    ContactFieldsDropdown,
    ContactGroupDropdown,
    ContactMomentsDropdown,
    ConversationDropdown,
    CrossLinear,
    NotesDropdown,
    ProfileFieldsDropdown,
    RelatedTicketsDropdown,
    SidebarInfobox,
    TicketFieldsDropdown,
    TimetrackingDropdown,
    VisitorDetailsDropdown,
    JourneysDropdown,
  },

  props: {
    ticket: {
      type: Object as PropType<Ticket>,
      default: () => ({}),
    },
    visitorOnlineStatus: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loadingIntegrations: true,
      activeTab: 'contacts' as 'contacts' | 'integrations',
      integrations: [],
      legacyIntegrations: [],
      allIntegrations: [],
      sidebarItems: [
        { visible: true, itemId: 'ticketFields', label: this.$tc('ticket_sidebar.ticket_fields') },
        { visible: true, itemId: 'contactFields', label: this.$tc('ticket_sidebar.contact_fields') },
        { visible: true, itemId: 'journeyRuns', label: this.$tc('ticket_sidebar.journey_runs') },
        { visible: true, itemId: 'contactGroups', label: this.$tc('ticket_sidebar.contact_groups') },
        { visible: true, itemId: 'attachments', label: this.$tc('ticket_sidebar.attachments') },
        { visible: true, itemId: 'timeTracking', label: this.$tc('ticket_sidebar.time_tracking') },
        { visible: true, itemId: 'relatedTickets', label: this.$tc('ticket_sidebar.linked_conversations') },
        { visible: true, itemId: 'contactMoments', label: this.$tc('ticket_sidebar.contact_moments') },
        { visible: true, itemId: 'contactNotes', label: this.$tc('ticket_sidebar.contact_notes') },
        { visible: true, itemId: 'profileFields', label: this.$tc('ticket_sidebar.profile_fields') },
        { visible: true, itemId: 'profileNotes', label: this.$tc('ticket_sidebar.profile_notes') },
        { visible: true, itemId: 'profileMoments', label: this.$tc('ticket_sidebar.profile_moments') },
      ] as SidebarItem[],
    };
  },

  computed: {
    ...mapGetters({
      allIntegrationInstallations: 'integrations/getAllInstallations',
    }),

    isIntegrationHubEnabled(): boolean {
      return useFeatureFlagStore().isEnabled(FEATURE_FLAG_INBOX.INTEGRATION_HUB);
    },

    hasJourneysSidebar(): boolean {
      return useFeatureFlagStore().isEnabled(FEATURE_FLAG_INBOX.FLOWS_SHOW_JOURNEYS_SIDEBAR);
    },

    hasProfile() {
      return !!this.ticket.contact.profile[0];
    },

    visibleLegacyIntegrations() {
      return this.legacyIntegrations.filter((integration) => integration.visible);
    },

    visibleIntegrationInstallations() {
      return this.integrations.filter((integration) => integration.visible);
    },
  },

  methods: {
    toggleTab(tab: 'contacts' | 'integrations') {
      this.activeTab = tab;
      localStorage.setItem('activeSidebarTab', tab);
    },

    removeRelated(relatedId: Ticket['id']) {
      const relatedTicketIndex = this.ticket.related_tickets.findIndex((ticket: Ticket) => ticket.id === relatedId);

      // findIndex returns -1 if no match is found: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/findIndex#return_value
      if (relatedTicketIndex !== -1) {
        this.ticket.related_tickets.splice(relatedTicketIndex, 1);
      }
    },

    isItemActive(itemId: SidebarItem['itemId']) {
      const item = this.sidebarItems.find((item: SidebarItem) => item.itemId === itemId);

      return item.visible;
    },

    async loadLegacyIntegrations() {
      let pluginIds: number[] = [];

      const savedPlugins = await this.$tStorage.getItem(this.$root.user.id + '_plugins');

      if (savedPlugins) {
        pluginIds = savedPlugins;
      } else {
        pluginIds = this.$root.plugins.map((plugin) => plugin.id);
      }

      let mappedIntegrations = pluginIds
        .map((id: number) => {
          return this.$root.plugins.find((plugin) => plugin.id === id);
        })
        .filter((integration) => integration !== undefined);
      mappedIntegrations = mappedIntegrations.map((item) => {
        return {
          ...item,
          visible: true,
          itemId: 'old_' + item.id,
        };
      });

      this.legacyIntegrations = mappedIntegrations;
    },

    persistItemState(itemId: SidebarItem['itemId'], visibility: SidebarItem['visible']) {
      const item = this.sidebarItems.find((item: SidebarItem) => item.itemId === itemId);

      localStorage.setItem(`${item.itemId}SidebarItemVisibility`, String(visibility));
    },

    persistIntegrationState(itemId: string, visibility: boolean) {
      const item = this.allIntegrations.find((item) => item.itemId === itemId);
      localStorage.setItem(`${item.itemId.replace(/\s/gm, '')}SidebarIntegrationVisibility`, String(visibility));
    },

    onClientCompose(message: string) {
      this.$emit('onClientComposing', message);
    },

    onStatusChange(status: boolean) {
      this.$emit('statusChanged', status);
    },

    async loadIntegrations() {
      if (!this.isIntegrationHubEnabled) {
        return;
      }
      await this.$store.dispatch('integrations/fetchInstallations');
      this.integrations = this.allIntegrationInstallations.map((integration) => {
        return {
          ...integration,
          installation_name: integration.name,
          itemId: `new_${integration.installation_id}`,
          name: integration.installation_name,
          visible: true,
        };
      });
    },
  },

  async mounted() {
    if (localStorage.getItem('activeSidebarTab')) {
      this.activeTab = localStorage.getItem('activeSidebarTab');
    }

    this.sidebarItems.forEach((item: SidebarItem) => {
      const storedState = localStorage.getItem(`${item.itemId}SidebarItemVisibility`);

      item.visible = storedState ? JSON.parse(storedState) : true;
    });

    try {
      this.loadingIntegrations = true;
      await this.loadLegacyIntegrations();
      await this.loadIntegrations();
    } catch (e) {
      console.error(e);
    }

    if (this.isIntegrationHubEnabled) {
      this.allIntegrations = [...this.integrations, ...this.legacyIntegrations];
    } else {
      this.allIntegrations = [...this.legacyIntegrations];
    }

    this.legacyIntegrations.forEach((item) => {
      const storedState = localStorage.getItem(`${item.itemId.replace(/\s/gm, '')}SidebarIntegrationVisibility`);

      item.visible = storedState ? JSON.parse(storedState) : true;
    });

    this.integrations.forEach((item) => {
      const storedState = localStorage.getItem(`${item.itemId.replace(/\s/gm, '')}SidebarIntegrationVisibility`);

      item.visible = storedState ? JSON.parse(storedState) : true;
    });

    this.loadingIntegrations = false;
  },
});
</script>
