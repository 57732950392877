<template>
  <div class="wab-recipients">
    <wab-setting-item>
      <template #label>
        <div class="recipients-header">
          <div class="title">
            {{ $t('broadcast.recipients') }}
            <span v-if="phoneList.length" class="selected-number">
              {{ $t('broadcast.broadcast_number_recipients_added', { total: itemsCount }) }}
            </span>
          </div>
          <div v-if="phoneList.length" class="actions">
            <button class="edit-btn" @click="openModal(true)">
              <span>{{ $t('broadcast.edit') }}</span>
              <edit3-linear />
            </button>
          </div>
        </div>
      </template>

      <div class="recipients-list mt-2" :class="{ error }" data-test="recipients-list-field">
        <div class="badge-list">
          <recipients-badge
            v-for="item in phoneList"
            :key="item.phone"
            :name="item.name"
            :group="item.groupTitle || null"
            :phone="item.phone"
            @delete="deletePhone(item)"
          />
        </div>

        <button
          v-if="!phoneList.length"
          class="add-button t-text-desktop-paragraph-sm fade-in"
          data-test="add-recipient-btn"
          @click="openModal(false)"
        >
          <plus-linear size="1rem" class="mr-1" />
          {{ $t('broadcast.add_recipients') }}
        </button>
      </div>

      <div class="input-info t-text-desktop-paragraph-sm flex flex-col space-y-2">
        <a
          href="https://help.trengo.com/en/articles/268968-requirements-and-limitations-for-whatsapp-broadcasting"
          class="input-info-link flex items-center"
          target="_blank"
          data-test="learn-more-link"
        >
          {{ $t('broadcast.learn_more') }}
          <arrow-right-linear size="1rem" class="ml-2" />
        </a>
        <a
          href="https://developers.facebook.com/docs/whatsapp/messaging-limits/"
          class="input-info-link flex items-center"
          target="_blank"
          data-test="learn-more-link"
        >
          {{ $t('broadcast.read_more_about_quality_ratings') }}
          <arrow-right-linear size="1rem" class="ml-2" />
        </a>
      </div>
    </wab-setting-item>

    <wab-recipients-modal
      v-model="modalIsOpen"
      :selected-contacts="contacts"
      :selected-groups="groups"
      :manual-selected="manualSelected"
      :edit="isEdit"
      :initial-list-length="initialListLength"
      @select="onContactSelect"
      @group-select="onContactGroupSelect"
      @manual="onManual"
      @add="onAdd"
      @cancel="onCancel"
    />
  </div>
</template>

<script>
import { ArrowRightLinear, Edit3Linear, PlusLinear } from '@trengo/trengo-icons';
import { cloneDeep, uniqBy } from 'lodash';

import { getContactsInGroups } from '@/components/WabBroadcasting/api';
import RecipientsBadge from '@/components/WabBroadcasting/components/RecipientsBadge';
import WabRecipientsModal from '@/components/WabBroadcasting/components/WabRecipientsModal';
import WabSettingItem from '@/components/WabBroadcasting/components/WabSettingItem';

export default {
  name: 'WabRecipients',
  emits: ['update:modelValue'],
  components: {
    RecipientsBadge,
    WabRecipientsModal,
    WabSettingItem,
    PlusLinear,
    Edit3Linear,
    ArrowRightLinear,
  },
  data() {
    return {
      modalIsOpen: false,
      contacts: {},
      groups: {},
      groupContacts: {},
      manualSelected: [],
      isEdit: false,
      initialListLength: -1,
      phoneList: [],
      itemsCount: 0,
    };
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
    deleted: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    computedValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  watch: {
    modelValue: {
      deep: true,
      inmediate: true,
      handler(value) {
        const list = [];

        if (value?.contacts)
          Object.values(value?.contacts).forEach((contact) => {
            list.push({
              ...contact,
              isContact: true,
            });
          });

        value?.manual.forEach((item) => {
          list.push({ phone: item.value, isContact: false });
        });
        this.initialListLength = list.length;
        this.phoneList = this.groupByGroupsItems(list);
        this.itemsCount = list.length;
      },
    },
  },
  methods: {
    groupByGroupsItems(list) {
      return uniqBy(list, (item) => (item.groupId !== undefined ? item.groupId : JSON.stringify(item)));
    },
    onContactSelect(contact) {
      if (contact.isSelected) {
        this.contacts = Object.assign({}, this.contacts, {
          [contact.id]: {
            isSelected: contact.isSelected,
            phone: contact.phone,
            name: contact.name,
            id: contact.id,
          },
        });
      } else {
        delete this.contacts[contact.id];
      }
    },
    async onContactGroupSelect(group) {
      const contactsRes = await getContactsInGroups(group.id);
      const contacts = contactsRes.data;

      if (group.isSelected) {
        if (!contacts) return;

        Object.values(contacts).forEach((contact) => {
          const isInManual = this.manualSelected
            .map((o) => o.value.replace(/\D/g, ''))
            .includes(contact.phone.replace(/\D/g, ''));

          if (!isInManual)
            this.contacts[contact.id] = {
              isSelected: group.isSelected,
              phone: contact.phone,
              name: contact.name,
              id: contact.id,
              groupId: group.id,
              groupTitle: group.title,
            };
        });

        this.groups[group.id] = group.isSelected;
      } else {
        Object.values(contacts).forEach((contact) => {
          delete this.contacts[contact.id];
        });
        delete this.groups[group.id];
      }
    },
    onManual(value) {
      this.manualSelected = value;
    },
    onAdd(object) {
      this.computedValue = cloneDeep(object);
    },
    syncData() {
      this.contacts = cloneDeep(this.computedValue.contacts);
      this.manualSelected = cloneDeep(this.computedValue.manual);

      this.groups = cloneDeep(this.computedValue.groups);
    },
    deletePhone(item) {
      if (item) {
        this.syncData();
      }

      if (item.groupId) {
        delete this.groups[item.groupId];
        this.contacts = Object.keys(this.contacts).reduce((newObj, key) => {
          if (this.contacts[key].groupId !== item.groupId) {
            newObj[key] = this.contacts[key];
          }
          return newObj;
        }, {});
        this.computedValue.contacts = cloneDeep(this.contacts);
        this.computedValue.groups = cloneDeep(this.groups);
      }

      if (item.isContact) {
        delete this.contacts[item.id];
        this.computedValue.contacts = cloneDeep(this.contacts);
      } else {
        this.manualSelected = this.manualSelected.filter((val) => val.value !== item.phone);
        this.computedValue.manual = cloneDeep(this.manualSelected);
      }
    },
    openModal(edit) {
      this.syncData();
      this.modalIsOpen = true;
      this.isEdit = edit;
    },
    onCancel() {
      this.syncData();
    },
    closeModal() {
      this.modalIsOpen = false;
    },
  },
};
</script>

<style scoped lang="scss" src="./WabRecipients.scss" />
