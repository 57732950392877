import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "t-text-desktop-paragraph-md pb-2 text-grey-800"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "t-text-desktop-label-lg border-b border-grey-300 pb-4 pt-4 text-grey-900" }
const _hoisted_5 = { class: "flex items-center justify-between" }
const _hoisted_6 = { "data-test": "confirm-broadcast-modal-total" }
const _hoisted_7 = {
  key: 0,
  class: "t-text-desktop-paragraph-sm pt-1 text-grey-600"
}
const _hoisted_8 = { class: "t-text-desktop-paragraph-md flex items-center justify-between border-b border-grey-300 py-4" }
const _hoisted_9 = { class: "text-grey-600" }
const _hoisted_10 = {
  class: "text-grey-900",
  "data-test": "confirm-broadcast-modal-remaining-balance"
}
const _hoisted_11 = { class: "t-text-desktop-paragraph-md flex items-center justify-between border-b border-grey-300 py-4" }
const _hoisted_12 = { class: "text-grey-600" }
const _hoisted_13 = {
  class: "text-grey-900",
  "data-test": "confirm-broadcast-modal-recipients"
}
const _hoisted_14 = { class: "t-text-desktop-paragraph-md flex items-center justify-between py-4" }
const _hoisted_15 = { class: "text-grey-600" }
const _hoisted_16 = {
  class: "text-grey-900",
  "data-test": "confirm-broadcast-modal-send-date"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_t_button = _resolveComponent("t-button")!
  const _component_t_modal = _resolveComponent("t-modal")!

  return (_openBlock(), _createBlock(_component_t_modal, {
    modelValue: $options.computedValue,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($options.computedValue) = $event)),
    "data-test": "confirm-broadcast-modal",
    title: _ctx.$t('broadcast.confirm_title'),
    variant: "narrow",
    onClose: $options.onCancel
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_t_button, {
        "btn-style": "secondary",
        "data-test": "confirm-broadcast-modal-cancel",
        onClick: $options.onCancel
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('general.cancel')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onClick"]),
      _createVNode(_component_t_button, {
        "data-test": "confirm-broadcast-modal-confirm",
        disabled: $props.isConfirmBtnDisabled,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('submit')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('general.confirm')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["disabled"])
    ]),
    default: _withCtx(() => [
      ($props.senderType === _ctx.SMS)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createTextVNode(_toDisplayString(_ctx.$t('broadcast.confirm_characters_message', { characters: $options.charactersLabel, messages: $options.messages })) + " ", 1 /* TEXT */),
            ($options.messages === 1)
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t('broadcast.confirm_message')), 1 /* TEXT */))
              : _createCommentVNode("v-if", true),
            ($options.messages > 1)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t('broadcast.confirm_messages')), 1 /* TEXT */))
              : _createCommentVNode("v-if", true)
          ]))
        : _createCommentVNode("v-if", true),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", null, _toDisplayString($options.totalLabel), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_6, "€" + _toDisplayString($options.formattedTotalAmount), 1 /* TEXT */)
        ]),
        ($props.tagsAdded)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.$t('broadcast.confirm_free_tags_added')), 1 /* TEXT */))
          : _createCommentVNode("v-if", true)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('broadcast.confirm_remaining_balance')), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_10, "€" + _toDisplayString($options.remainingBalance), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t('broadcast.confirm_number_recipients')), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_13, _toDisplayString($props.recipientsQuantity), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("div", _hoisted_15, _toDisplayString($options.sendDateLabel), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_16, _toDisplayString($props.sendDate), 1 /* TEXT */)
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "title", "onClose"]))
}