import { PERMISSION } from '@/Configs/Constants';

import type { RouteRecord } from '../types';

const chatbotsRoutes: RouteRecord[] = [
  {
    path: '/chatbots',
    redirect: '/admin/chatbots',
  },
  {
    path: '/chatbots/:catchAll(.*)',
    redirect: (to) => {
      return '/admin/chatbots/' + to.params.catchAll;
    },
  },
  {
    path: '/admin/chatbots',
    component: () => import(/* webpackChunkName: "Chatbots" */ '../../components/Chatbots/Index.vue'),
    meta: { permissionRequired: PERMISSION.SETTINGS__CHATBOTS__MANAGE },
    children: [
      {
        path: '/admin/chatbots/:id',
        component: () => import(/* webpackChunkName: "Chatbots" */ '../../components/Chatbots/Edit.vue'),
        meta: { permissionRequired: PERMISSION.SETTINGS__CHATBOTS__MANAGE },
        children: [
          {
            path: '/admin/chatbots/:id/questions',
            component: () => import(/* webpackChunkName: "Chatbots" */ '../../components/Chatbots/Questions.vue'),
            meta: { permissionRequired: PERMISSION.SETTINGS__CHATBOTS__MANAGE },
          },
          {
            path: '/admin/chatbots/:id/categories',
            component: () => import(/* webpackChunkName: "Chatbots" */ '../../components/Chatbots/Categories.vue'),
            meta: { permissionRequired: PERMISSION.SETTINGS__CHATBOTS__MANAGE },
          },
          {
            path: '/admin/chatbots/:id/categories/create',
            component: () => import(/* webpackChunkName: "Chatbots" */ '../../components/Chatbots/Category.vue'),
            props: { action: 'create' },
            meta: { permissionRequired: PERMISSION.SETTINGS__CHATBOTS__MANAGE },
          },
          {
            path: '/admin/chatbots/:id/categories/:category_id',
            component: () => import(/* webpackChunkName: "Chatbots" */ '../../components/Chatbots/Category.vue'),
            props: { action: 'update' },
            meta: { permissionRequired: PERMISSION.SETTINGS__CHATBOTS__MANAGE },
          },
          {
            path: '/admin/chatbots/:id/playground',
            component: () => import(/* webpackChunkName: "Chatbots" */ '../../components/Chatbots/Testing.vue'),
            meta: { permissionRequired: PERMISSION.SETTINGS__CHATBOTS__MANAGE },
          },
          {
            path: '/admin/chatbots/:id/mismatches',
            component: () => import(/* webpackChunkName: "Chatbots" */ '../../components/Chatbots/Mismatches.vue'),
            meta: { permissionRequired: PERMISSION.SETTINGS__CHATBOTS__MANAGE },
          },
          {
            path: '/admin/chatbots/:id/settings',
            component: () => import(/* webpackChunkName: "Chatbots" */ '../../components/Chatbots/Settings.vue'),
            meta: { permissionRequired: PERMISSION.SETTINGS__CHATBOTS__MANAGE },
          },
        ],
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "Chatbots" */ '../../components/AdminIndexBox.vue'),
        props: { boxName: 'chatbots' },
        meta: { permissionRequired: PERMISSION.SETTINGS__CHATBOTS__MANAGE },
      },
    ],
  },
];

export default chatbotsRoutes;
