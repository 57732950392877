import { PERMISSION } from '@/Configs/Constants';

import type { RouteRecord } from '../types';

const teamsRoutes: RouteRecord[] = [
  {
    path: '/admin/teams',
    component: () => import(/* webpackChunkName: "Teams" */ '../../components/Teams/Index.vue'),
    meta: { permissionRequired: PERMISSION.PERMISSIONS__TEAMS__MANAGE },
    children: [
      {
        path: '/admin/teams/create',
        component: () => import(/* webpackChunkName: "Teams" */ '../../components/PageNotFound.vue'),
        meta: { permissionRequired: PERMISSION.PERMISSIONS__TEAMS__MANAGE },
      },
      {
        path: '/admin/teams/:id',
        component: () => import(/* webpackChunkName: "Teams" */ '../../components/Teams/pages/Edit.vue'),
        meta: { permissionRequired: PERMISSION.PERMISSIONS__TEAMS__MANAGE },
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "Teams" */ '../../components/AdminIndexBox.vue'),
        props: { boxName: 'teams' },
        meta: { permissionRequired: PERMISSION.PERMISSIONS__TEAMS__MANAGE },
      },
    ],
  },
];

export default teamsRoutes;
