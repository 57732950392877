<template>
  <div class="flex-1 flex flex-col items-center h-full px-2">
    <inbound-call-panel
      v-if="!shouldShowActiveCall"
      @close="emit('close')"
      @accept="onCallAccepted"
      @ignore="onCallIgnored"
    />

    <active-call-panel v-else @end-call="onCallEnd" @view-queue="emit('close')" />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

import ActiveCallPanel from '@/components/Voip/components/Panel/ActiveCallPanel.vue';
import InboundCallPanel from '@/components/Voip/components/Panel/InboundCallPanel.vue';

const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'call-accepted'): void;
  (e: 'call-ignored'): void;
}>();

const shouldShowActiveCall = ref(false);

function onCallAccepted() {
  shouldShowActiveCall.value = true;
  emit('call-accepted');
}

function onCallIgnored() {
  emit('call-ignored');
}

function onCallEnd() {
  shouldShowActiveCall.value = false;
  emit('close');
}
</script>
