<template>
  <t-modal
    v-model="computedValue"
    data-test="confirm-broadcast-modal"
    :title="$t('broadcast.confirm_title')"
    variant="narrow"
    @close="onCancel"
  >
    <div v-if="senderType === SMS" class="t-text-desktop-paragraph-md pb-2 text-grey-800">
      {{ $t('broadcast.confirm_characters_message', { characters: charactersLabel, messages: messages }) }}
      <span v-if="messages === 1">{{ $t('broadcast.confirm_message') }}</span>
      <span v-if="messages > 1">{{ $t('broadcast.confirm_messages') }}</span>
    </div>
    <div class="t-text-desktop-label-lg border-b border-grey-300 pb-4 pt-4 text-grey-900">
      <div class="flex items-center justify-between">
        <div>{{ totalLabel }}</div>
        <div data-test="confirm-broadcast-modal-total">€{{ formattedTotalAmount }}</div>
      </div>
      <div v-if="tagsAdded" class="t-text-desktop-paragraph-sm pt-1 text-grey-600">
        {{ $t('broadcast.confirm_free_tags_added') }}
      </div>
    </div>
    <div class="t-text-desktop-paragraph-md flex items-center justify-between border-b border-grey-300 py-4">
      <div class="text-grey-600">{{ $t('broadcast.confirm_remaining_balance') }}</div>
      <div class="text-grey-900" data-test="confirm-broadcast-modal-remaining-balance">€{{ remainingBalance }}</div>
    </div>
    <div class="t-text-desktop-paragraph-md flex items-center justify-between border-b border-grey-300 py-4">
      <div class="text-grey-600">{{ $t('broadcast.confirm_number_recipients') }}</div>
      <div class="text-grey-900" data-test="confirm-broadcast-modal-recipients">{{ recipientsQuantity }}</div>
    </div>
    <div class="t-text-desktop-paragraph-md flex items-center justify-between py-4">
      <div class="text-grey-600">{{ sendDateLabel }}</div>
      <div class="text-grey-900" data-test="confirm-broadcast-modal-send-date">{{ sendDate }}</div>
    </div>
    <template #footer>
      <t-button btn-style="secondary" data-test="confirm-broadcast-modal-cancel" @click="onCancel">
        {{ $t('general.cancel') }}
      </t-button>
      <t-button data-test="confirm-broadcast-modal-confirm" :disabled="isConfirmBtnDisabled" @click="$emit('submit')">
        {{ $t('general.confirm') }}
      </t-button>
    </template>
  </t-modal>
</template>

<script lang="ts">
import { currency } from '@/components/WabBroadcasting/utils/Utils.js';
import { CHANNEL_TYPE } from '@/Configs/Constants';

export default {
  name: 'ConfirmBroadcastModal',
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    messageLength: {
      type: Number,
      default: 0,
    },
    senderType: {
      type: String,
      default: '',
    },
    scheduled: {
      type: Boolean,
      default: false,
    },
    tagsAdded: {
      type: Boolean,
      default: false,
    },
    sendDate: {
      type: String,
      default: '',
    },
    totalAmount: {
      type: String,
      default: '',
    },
    balance: {
      type: Number,
      default: 0,
    },
    recipientsQuantity: {
      type: Number,
      default: 0,
    },
    isConfirmBtnDisabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'submit'],
  computed: {
    computedValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    charactersLabel() {
      if (this.tagsAdded) {
        return '~' + this.messageLength;
      } else {
        return this.messageLength;
      }
    },
    messages() {
      return Math.ceil(this.messageLength / 160);
    },
    sendDateLabel() {
      if (this.scheduled) {
        return this.$t('broadcast.confirm_scheduled_send_date_label');
      } else {
        return this.$t('broadcast.confirm_send_date_label');
      }
    },
    totalLabel() {
      if (this.tagsAdded) {
        return this.$t('broadcast.confirm_estimated_total');
      } else {
        return this.$t('broadcast.confirm_total');
      }
    },
    remainingBalance() {
      return currency(this.balance - this.totalAmount);
    },
    formattedTotalAmount() {
      return currency(this.totalAmount);
    },
  },
  created() {
    this.WA_BUSINESS = CHANNEL_TYPE.WA_BUSINESS;
    this.SMS = CHANNEL_TYPE.SMS;
  },
  methods: {
    async onCancel() {
      this.computedValue = false;
    },
  },
};
</script>
