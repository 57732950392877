import { PhoneNumberFormat as PNF, PhoneNumberUtil } from 'google-libphonenumber';
import axios from 'axios';
import eventBus from '@/eventBus';
import { duplicateObject } from '@/util/helpers';
import { randomString } from '@/util/stringHelpers';
import { Device } from '@twilio/voice-sdk';
import { toRaw } from 'vue';

/**
 * @deprecated Many of the things this class is doing have been deprecated in Twilio's 2.x.x version
 * of their voice SDK. Additionally, many of the patterns seen here are to be avoided considering
 * modern best practices (such as the loadTwilio() function below loading a script tag pointing to
 * Twilio's content CDN).
 *
 * As such, this class is to be considered deprecated. It will still be used behind a feature flag
 * ((UPDATED_TWILIO_SDK) for the time being, however it will be phased out and deleted once the full
 * SDK migration happens and is stabilized.
 */
class LegacyVoip {

  /**
   ** @param token
   * @param user
   * @param is_mobile
   */
  constructor(token, user, is_mobile = false) {
    this.user = user;
    this.token = token;
    this.twilio = null;
    this.events = {};
    this.connection = null;
    this.pusherChannel = null;
    this.isMobile = is_mobile;
    this.channel = null;
    this.device = null;
  }

  /**
   * @return void
   */
  async initialize() {
    if (this.isMobile) {
      setTimeout(() => {
        this.initPusher();
        this.trigger('initialized');
      }, 200);
    } else {
      await this.initToken();
      this.initPusher();
      this.trigger('initialized');

      // If token is about to expire, refresh it
      this.device.on(Device.EventName.TokenWillExpire, async () => {
        await this.initToken();
      })
    }
  }

  async initToken(channel_id = null) {
    if (this.isMobile) {
      return;
    }

    let tokenResponse;
    if (channel_id) {
      tokenResponse = await axios.get('/client-api/voip/token?channel_id=' + channel_id);
    } else {
      tokenResponse = await axios.get('/client-api/voip/token');
    }

    if (this.device) {
      toRaw(this.device).updateToken(tokenResponse.data.token);
    } else {
      this.device = new Device(tokenResponse.data.token, {
        edge: 'frankfurt',
        closeProtection: true,
        appName: window.APP_ENV === 'production' ? 'prod-frontend' : 'stg-frontend',
        appVersion: __BUILD_INFORMATION__?.hash ?? '',
        logLevel: 'error',
        enableImprovedSignalingErrorPrecision: true,
      });
    }
  }

  /**
   * @return void
   */
  initPusher() {
    this.pusherChannel = window.PusherInstance.subscribe('private-voip-' + CHANNEL_PREFIX);

    this.pusherChannel.bind('InboundCall', (data) => {
      this.trigger('on_inbound_call', data);
    });

    this.pusherChannel.bind('ConferenceStarted', (data) => {
      this.trigger('on_call_started', data);
    });

    this.pusherChannel.bind('OutboundMobileCallStarted', (data) => {
      this.trigger('outbound_call_ringing', data);
    });

    this.pusherChannel.bind('CallEnded', (data) => {
      this.trigger('on_call_ended', data);
    });

    this.pusherChannel.bind('client-' + this.user.id + '.intern-call', (data) => {
      this.trigger('on_intern_call_inbound', data);
    });

    this.pusherChannel.bind('client-' + this.user.id + '.intern-call-rejected', (data) => {
      this.trigger('on_intern_call_rejected', data);
    });

    this.pusherChannel.bind('client-call-transferred', (data) => {
      this.trigger('on_call_transferred', data);
    });

    this.pusherChannel.bind('client-' + this.user.id + '.intern-call-cancelled', (data) => {
      this.trigger('on_intern_call_cancelled', data);
    });

    this.pusherChannel.bind('client-team.intern-call', (data) => {
      this.trigger('on_intern_team_call', data);
    });

    this.pusherChannel.bind('client-team.intern-call-cancelled', (data) => {
      this.trigger('on_intern_team_call_cancelled', data);
    });

    this.pusherChannel.bind('ConferenceThirdPartyJoined', (data) => {
      this.trigger('on_intern_call_accepted', data);
    });

    this.pusherChannel.bind('ConferenceThirdPartyLeft', (data) => {
      this.trigger('on_intern_call_third_party_left', data);
    });

    this.pusherChannel.bind('CallAcceptedByExternalPhone', (data) => {
      this.trigger('on_external_phone_accepted_call', data);
    });

    this.pusherChannel.bind('client-accepted-call', (data) => {
      this.trigger('on_client_accepted_call', data);
    });
  }

  /**
   * @param mute
   */
  setMute(mute) {
    if (this.isMobile) {
      return;
    }

    if (this.connection === null) {
      console.log('No active connection to mute');
      return;
    }

    toRaw(this.connection).mute(mute);
  }

  /**
   * @param hold
   * @param token
   * @returns {*|i}
   */
  setHold(hold, token) {
    return new Promise((resolve) => {
      axios.post('/voip-api/hold/' + token, { hold: hold }).then(() => {
        resolve();
      });
    });
  }

  /**
   * @return void
   */
  endCall() {
    if (!this.isMobile) {
      toRaw(this.device).disconnectAll();
    }

    this.connection = null;
  }

  /**
   * @param phone
   * @param channel_id
   * @param provider
   */
  async startCall(phone, channel_id, provider) {
    await this.initToken(channel_id);

    if (provider === 'TWILIO_SANDBOX') {
      eventBus.$emit('voip.sandbox_reject_outbound_call');
      return;
    }

    try {
      var PNF = require('google-libphonenumber').PhoneNumberFormat;
      var phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
      var phoneNumber = phoneUtil.parse(phone, $('.multiselect__input').intlTelInput('getSelectedCountryData').iso2);

      let callToken = randomString(30);

      if (this.user.voip_device === 'MOBILE' || this.isMobile) {
        axios
          .post('/api/v2/voip/mobile_outbound', {
            token: callToken,
            phone_number: phoneUtil.format(phoneNumber, PNF.INTERNATIONAL),
            channel_id: channel_id,
          })
          .then(() => {
            this.trigger('outbound_mobile_call_initiated', {
              token: callToken,
            });
          });
      } else {
        this.connection = await toRaw(this.device).connect({
          params: {
            PhoneNumber: phoneUtil.format(phoneNumber, PNF.INTERNATIONAL),
            ChannelPrefix: CHANNEL_PREFIX,
            ChannelId: channel_id,
            UserId: this.user.id,
            type: 'OUTBOUND',
            token: callToken,
          }
        });

        axios
          .get(
            '/client-api/voip/tickets/contact?phone=' +
              phoneUtil.format(phoneNumber, PNF.INTERNATIONAL) +
              '&channel_id=' +
              channel_id,
          )
          .then((res) => {
            this.trigger('outbound_call_ringing', {
              contact: res.data.contact,
              ticket_id: res.data.ticket_id,
              user_id: res.data.user_id,
              channel: res.data.channel,
            });
          });

        this.trigger('outbound_call_initiated', {
          token: callToken,
        });
      }
    } catch (e) {
      console.log(e);
      this.trigger('error', {
        message: 'The provided phone number is invalid.',
      });
    }
  }

  /**
   * @param token
   */
  async acceptInboundCall(token) {
    setTimeout(
      async () => {
        this.connection = await toRaw(this.device).connect({
          params: {
            type: 'INBOUND',
            token: token,
          }
        });
        this.trigger('inbound_call_initialized');
      },
      Math.round(Math.random() * 1000),
    ); // Random 1 to 1000ms to prevent duplicate answers
  }

  /**
   * @param token
   */
  async acceptInternCall(token) {
    this.connection = await toRaw(this.device).connect({
      params: {
        type: 'INTERN',
        token: token,
      }
    });
    this.trigger('on_intern_call_initialized');
  }

  /**
   * @param event
   * @param data
   */
  trigger(event, data) {
    this.events[event](data);
  }

  /**
   * @param name
   * @param callback
   */
  on(name, callback) {
    this.events[name] = callback;
  }

  transferToUser(user, call) {
    call.intern = true;
    call.internCall.user = user;

    let from = duplicateObject(this.user);
    from.teams = null;

    this.pusherChannel.trigger('client-' + call.internCall.user.id + '.intern-call', {
      call: call,
      from: from,
    });
  }

  transferToTeam(team, call) {
    call.intern = true;

    let from = duplicateObject(this.user);
    from.teams = null;

    this.pusherChannel.trigger('client-team.intern-call', {
      call: call,
      target: team.id,
      from: from,
    });
  }

  /**
   * @param phone
   * @param call
   */
  transferToPhone(phone, call) {
    const phoneUtil = PhoneNumberUtil.getInstance();
    var phoneNumber = phoneUtil.parse(phone, $('.multiselect__input').intlTelInput('getSelectedCountryData').iso2);

    axios.post('/voip-api/participant/' + call.token, { phone: phoneUtil.format(phoneNumber, PNF.INTERNATIONAL) });
  }

  async executeTransfer(call) {
    await axios.post('/voip-api/transfer/' + call.token, {
      user_id: call.internCall.user.id,
      referer: call.userId,
    });
    this.pusherChannel.trigger('client-call-transferred', {
      call: call,
      user: call.internCall.user,
      user_id: call.internCall.user.id,
    });
  }

  cancelTransfer(call) {
    if (call.internCall.user.is_extern == true) {
      axios.post('/voip-api/cancel-transfer/' + call.token);
    } else if (call.internCall.team_id != null) {
      this.pusherChannel.trigger('client-team.intern-call-cancelled', {
        target: call.internCall.team_id,
        token: call.token,
      });
    } else {
      this.pusherChannel.trigger('client-' + call.internCall.user.id + '.intern-call-cancelled', {});
    }
  }

  rejectInternCall(user_id) {
    this.pusherChannel.trigger('client-' + user_id + '.intern-call-rejected', {});
  }

  sendAcceptedCallEvent(call) {
    let user = duplicateObject(this.user);

    user.teams = null;

    this.pusherChannel.trigger('client-accepted-call', {
      call: call,
      user: user,
    });

    axios.post('/voip-api/cancel-mobile-forward/' + call.token);
  }

  dtmf(key) {
    if (this.isMobile) {
      return;
    }

    if (toRaw(this.device).activeConnection() == null) {
      return;
    }

    toRaw(this.device).activeConnection().sendDigits(key);
  }
}

export default LegacyVoip;
