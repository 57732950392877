import { ENDPOINT } from '@/api/constants';
import { request } from '@/util';

import type { TemplateResponse, UrlParams } from '@/types/waTemplates';
import type { AxiosPromise } from 'axios';

export function fetchWaTemplates(urlParams?: UrlParams): AxiosPromise<TemplateResponse> {
  const endpoint = urlParams
    ? `${ENDPOINT.WA_TEMPLATES}?${new URLSearchParams(urlParams)?.toString()}`
    : ENDPOINT.WA_TEMPLATES;
  return request(endpoint, 'GET');
}
