import type { TranslationFunction } from '@/types';
import { useFeatureFlagStore } from '@/store/pinia';
import { FEATURE_FLAG_INBOX } from '@/Configs/Constants';

export type DropDownItems = {
  Assignee: string;
  ConversationStatus: string;
  AttachedLabels: string;
  Channel: string;
  FirstResolutionTime: string;
  TotalResolutionTime: string;
  FirstResponseTime: string;
  FirstResolutionTimeWithoutBusinessHours: string;
  TotalResolutionTimeWithoutBusinessHours: string;
  FirstResponseTimeWithoutBusinessHours: string;
  FirstHumanResponseTime?: string;
  FirstHumanResponseTimeWithoutBusinessHours?: string;
  CreatedAt: string;
  AssignedAt: string;
  ClosedAt: string;
  MessagesSent: string;
  ChannelType: string;
  ContactEmail: string;
  ContactPhone: string;
  TicketCustomFields: string;
  ContactCustomFields: string;
  ProfileCustomFields: string;
  TicketResult: string;
};

export const fetchDropdownItems: (t: TranslationFunction) => DropDownItems = (t) => {
  const featureFlagStore = useFeatureFlagStore();
  const isHumanResponseTimeEnabled = featureFlagStore.isEnabled(FEATURE_FLAG_INBOX.TI_HUMAN_RESPONSE_TIME_METRICS);

  const baseItems: Partial<DropDownItems> = {
    Assignee: t('reports_v2.assignee'),
    ConversationStatus: t('reports_v2.conversation_status'),
    AttachedLabels: t('reports_v2.attached_labels'),
    Channel: t('reports_v2.channel'),
    FirstResolutionTimeWithoutBusinessHours: t('reports_v2.first_resolution_time_without_business_hours'),
    FirstResolutionTime: t('reports_v2.first_resolution_time'),
    TotalResolutionTimeWithoutBusinessHours: t('reports_v2.total_resolution_time_without_business_hours'),
    TotalResolutionTime: t('reports_v2.total_resolution_time'),
    FirstResponseTimeWithoutBusinessHours: t('reports_v2.first_response_time_without_business_hours'),
    FirstResponseTime: t('reports_v2.first_response_time'),
    ...(isHumanResponseTimeEnabled && {
      FirstHumanResponseTimeWithoutBusinessHours: t('reports_v2.first_human_response_time_without_business_hours'),
      FirstHumanResponseTime: t('reports_v2.first_human_response_time_with_business_hours'),
    }),
    CreatedAt: t('reports_v2.created_on'),
    AssignedAt: t('reports_v2.assigned_on'),
    ClosedAt: t('reports_v2.closed_on'),
    MessagesSent: t('reports_v2.messages_sent'),
    ChannelType: t('reports_v2.channel_type'),
    ContactEmail: t('reports_v2.contact_email'),
    ContactPhone: t('reports_v2.contact_phone'),
    ContactCustomFields: t('reports_v2.contact_custom_fields'),
    TicketCustomFields: t('reports_v2.ticket_custom_fields'),
    ProfileCustomFields: t('reports_v2.profile_custom_fields'),
    TicketResult: t('reports_v2.ticket_result'),
  };

  return baseItems as DropDownItems;
};
