<template>
  <div class="schedule-send-date" data-test="schedule-send-date">
    <div class="t-text-md-emphasize">{{ $t('broadcast.schedule_send_date') }}</div>
    <div class="ml-3 mt-3">
      <label class="pointer my-1 flex items-center" for="now-radio">
        <t-input-radio
          id="now-radio"
          v-model="selectedSchedule"
          name="date"
          value="now"
          data-test="now-radio"
          class="mt-1"
        />
        <span id="now-radio-label">
          {{ $t('broadcast.scheduled_date_now') }}
        </span>
      </label>
    </div>
    <div class="ml-3 flex h-48px items-center">
      <label class="pointer flex items-center" for="later-radio">
        <t-input-radio
          id="later-radio"
          v-model="selectedSchedule"
          name="date"
          value="later"
          data-test="later-radio"
          class="mt-1"
        />
        <span id="later-radio-label">
          {{ $t('broadcast.scheduled_date_later') }}
        </span>
      </label>
      <div
        v-if="isScheduledForLater"
        class="input-container ml-6 flex w-2/3 items-center rounded-xl border bg-white"
        :class="{ error: !validation.date }"
        aria-live="polite"
        aria-atomic="true"
        :aria-invalid="!validation.date"
        aria-label="Schedule date input"
      >
        <div ref="datepicker" class="t-text-desktop-paragraph-md min-w-full">
          <atomic-datepicker
            id="datepicker"
            v-model="selectedDate"
            aria-label="Datepicker to select a schedule date"
            :ranges="false"
            format-display="DD/MM/YYYY"
            full-width
            :as-unix="false"
            tabindex="0"
            :placeholder="today"
            data-test="datepicker"
            :min-date="yesterday"
            opens="center"
          >
            <template #prependIcon>
              <calendar-dates-linear size="18px" class="ml-1 mr-2.5" data-test="datepicker-icon" aria-hidden="true" />
            </template>
          </atomic-datepicker>
        </div>
      </div>
      <div class="ml-4 w-1/3">
        <search-bar
          v-if="isScheduledForLater"
          id="time-searchbar"
          aria-label="Search bar to select time"
          :model-value="selectedTime"
          :options="availableTimes"
          size="lg"
          placeholder="12:00"
          class="b-1"
          :error="!validation.time || !validation.timeFormat"
          prepend-icon="wab-broadcasting/time"
          :prepend-icon-size="18"
          data-test="time-searchbar"
          :height="405"
        >
          <template #item="slotProps">
            <div
              class="time-item pointer t-text-desktop-paragraph-sm mx-3 my-1 rounded-xl px-2 py-1 text-grey-800 hover:bg-grey-300"
              aria-label="Available time option"
              role="menuitem"
              @click="selectedTime = slotProps.item"
            >
              {{ slotProps.item }}
            </div>
          </template>
        </search-bar>
      </div>
    </div>
    <template v-if="errorMessages">
      <t-error-item
        v-for="(error, index) in errorMessages"
        :key="index"
        :text="error"
        data-test="error-message"
        role="alert"
        aria-atomic="true"
      />
    </template>
  </div>
</template>

<script lang="ts">
import { CalendarDatesLinear } from '@trengo/trengo-icons';
import moment from 'moment';

import SearchBar from '@/components/WabBroadcasting/components/SearchBar';

import selectableTimes from './allTimes.js';

import type { ScheduleSendValidation } from '@/components/WabBroadcasting/types/broadcasting.ts';
import type { PropType } from 'vue';

export default {
  name: 'ScheduleSendDate',
  components: { SearchBar, CalendarDatesLinear },
  props: {
    validation: {
      type: Object as PropType<ScheduleSendValidation>,
      default: () => ({}),
    },
    schedule: {
      type: String as PropType<'now' | 'later'>,
      default: 'now',
    },
    date: {
      type: String,
      default: '',
    },
    time: {
      type: String,
      default: '',
    },
  },
  emits: ['update:date', 'update:time', 'update:schedule'],
  computed: {
    timezone() {
      return this.$root.user.timezone;
    },
    isScheduledForToday() {
      const now = moment().tz(this.timezone);
      return moment(this.selectedDate, 'YYYY-MM-DD').isSame(now, 'day');
    },
    today() {
      return moment().tz(this.timezone).format('DD/MM/YYYY');
    },
    yesterday() {
      return moment().tz(this.timezone).subtract(1, 'days').format('YYYY-MM-DD');
    },
    availableTimes() {
      const now = moment().tz(this.timezone);
      if (!this.isScheduledForToday) {
        return selectableTimes;
      }
      return selectableTimes.filter((time) => moment(time, 'HH:mm').isAfter(now));
    },
    isScheduledForLater() {
      return this.selectedSchedule === 'later';
    },
    errorMessages() {
      if (!this.isScheduledForLater) return null;
      const errors = [];
      const { date, time, timeFormat, timeAfterNow } = this.validation;
      if (!date) errors.push(this.$t('broadcast.select_date_to_schedule_broadcast_placeholder'));
      if (!time) errors.push(this.$t('broadcast.select_time_to_schedule_broadcast_placeholder'));
      if (!timeFormat) errors.push(this.$t('broadcast.enter_valid_time_to_schedule_broadcast'));
      if (date && time && !timeAfterNow) errors.push(this.$t('broadcast.enter_future_time_to_schedule_broadcast'));
      return errors.length ? errors : null;
    },

    selectedSchedule: {
      get() {
        return this.schedule;
      },
      set(value: 'now' | 'later') {
        this.$emit('update:schedule', value);
      },
    },
    selectedDate: {
      get() {
        return this.date;
      },
      set(value: ISOString) {
        this.$emit('update:date', value);
      },
    },
    selectedTime: {
      get() {
        return this.time;
      },
      set(value: string) {
        this.$emit('update:time', value);
      },
    },
  },
};
</script>

<style lang="scss">
.schedule-send-date :is(.daterangepicker.openscenter) {
  bottom: 50px;
}
</style>
