<template>
  <div v-on-click-outside="close" class="relative">
    <button
      class="h-full px-3 py-2 bg-grey-800 border-r border-grey-700 flex items-center gap-2 font-mono"
      @click.stop="toggleDropdown()"
    >
      <span>{{ selectedCountry.flag }} +{{ selectedCountry.callingCode }}</span>
      <span
        class="text-grey-500 transition-transform origin-center text-xs font-mono"
        :class="{ 'rotate-180': showDropdown }"
      >
        ▼
      </span>
    </button>

    <div v-if="showDropdown" class="absolute left-0 top-full w-92 max-w-[22.25rem] max-h-[19rem] overflow-y-auto z-10">
      <input
        ref="searchRef"
        v-model="search"
        type="text"
        placeholder="Search countries..."
        class="w-full px-4 py-2 bg-grey-800 border-y border-grey-700"
        @click.stop
        @keydown.down.prevent="navigateList('down')"
        @keydown.up.prevent="navigateList('up')"
        @keydown.enter.prevent="handleEnter()"
        @keydown.esc.prevent="close()"
      />

      <div
        v-for="(country, index) in filteredCountries"
        :key="country.code"
        :data-country-index="index"
        class="cursor-pointer flex items-center bg-grey-800 px-4 py-2 border-b-1 border-grey-700 hover:bg-grey-700"
        :class="{ 'bg-grey-700': selectedIndex === index }"
        @click.stop="selectCountry(country)"
      >
        <div class="flex flex-col flex-1">
          <span class="flex items-center">
            <span class="mr-1 text-lg">{{ country.flag }}</span>
            {{ country.name }}
          </span>
          <span class="text-sm text-grey-500">+{{ country.callingCode }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { vOnClickOutside } from '@vueuse/components';
import { ref, computed, nextTick, watch } from 'vue';

import { countryCodes } from '@/Configs/Constants/countryCodes';

import type { Country } from '@/Configs/Constants/countryCodes';

const props = defineProps<{
  parsedCountryCode?: Country['callingCode'];
}>();

const emit = defineEmits<{
  (e: 'select', country: Country): void;
  (e: 'dropdownChange', isOpen: boolean): void;
}>();

const searchRef = ref<HTMLInputElement | null>(null);

const countries = ref(countryCodes);
const search = ref('');
const showDropdown = ref(false);
const selectedIndex = ref(-1);

const selectedCountry = ref<Country>({
  name: 'Netherlands',
  code: 'NL',
  callingCode: '31',
  flag: '🇳🇱',
});

watch(
  () => props.parsedCountryCode,
  (code) => {
    if (code) {
      const matchingCountry = countries.value.find(({ callingCode }) => callingCode === code);

      if (matchingCountry) {
        selectedCountry.value = matchingCountry;
      }
    }
  },
  { immediate: true },
);

const filteredCountries = computed(() => {
  if (!search.value) {
    return countries.value;
  }

  const searchTerm = search.value.toLowerCase();

  return countries.value.filter(
    (country) => country.name.toLowerCase().includes(searchTerm) || country.callingCode.includes(searchTerm),
  );
});

function navigateList(direction: 'up' | 'down') {
  if (!showDropdown.value || filteredCountries.value.length === 0) return;

  const lastIndex = filteredCountries.value.length - 1;

  if (direction === 'down') {
    if (selectedIndex.value >= lastIndex) {
      selectedIndex.value = 0;
    } else {
      selectedIndex.value++;
    }
  } else {
    if (selectedIndex.value <= 0) {
      selectedIndex.value = lastIndex;
    } else {
      selectedIndex.value--;
    }
  }

  // Scroll the selected item into view
  const selectedElement = document.querySelector(`[data-country-index="${selectedIndex.value}"]`);
  if (selectedElement) {
    selectedElement.scrollIntoView({ block: 'nearest' });
  }
}

function handleEnter() {
  if (showDropdown.value && selectedIndex.value >= 0) {
    const selectedCountry = filteredCountries.value[selectedIndex.value];
    if (selectedCountry) {
      selectCountry(selectedCountry);
    }
  }
}

async function toggleDropdown() {
  showDropdown.value = !showDropdown.value;
  emit('dropdownChange', showDropdown.value);

  if (showDropdown.value) {
    await nextTick(); // Doesn't focus the input unless we wait for the component to finish rerendering
    searchRef.value?.focus();
    selectedIndex.value = -1;
  }
}

function selectCountry(country: Country) {
  selectedCountry.value = country;
  showDropdown.value = false;
  emit('select', country);
  emit('dropdownChange', false);
}

function close() {
  showDropdown.value = false;
  emit('dropdownChange', false);
}

defineExpose({
  // Expose the close method to the parent component so it can close the dropdown if needed
  close,
});
</script>
