// A reimplementation of jQuery Growl plugin without jQuery

// Define types for type safety
type Transitions = {
  [key: string]: string;
};

type GrowlSettings = {
  namespace: string;
  duration: number;
  close: string;
  location: string;
  style: string;
  size: string;
  delayOnHover: boolean;
  fixed?: boolean;
  url?: string;
  title?: string;
  message?: string;
};

// Utility to handle transitions
const Animation = {
  transitions: {
    webkitTransition: 'webkitTransitionEnd',
    mozTransition: 'mozTransitionEnd',
    oTransition: 'oTransitionEnd',
    transition: 'transitionend',
  } as Transitions,

  getTransition(element: HTMLElement): string | null {
    for (const [type, result] of Object.entries(this.transitions) as [string, string][]) {
      if (element.style[type as keyof CSSStyleDeclaration] != null) {
        return result;
      }
    }
    return null;
  },
};

type GrowlOptions = {
  size?: 'large' | 'small';
  duration?: number;
  namespace?: string;
  title?: string;
  location?: string;
  message: string;
  style?: string;
};

export class Growl {
  private static settings: GrowlSettings = {
    namespace: 'growl',
    duration: 3200,
    close: '&#215;',
    location: 'default',
    style: 'default',
    size: 'medium',
    delayOnHover: true,
  };

  private settings: GrowlSettings;
  private growlsElement: HTMLElement;

  static initialize(): HTMLElement {
    if (!document.getElementById('growls')) {
      const container = document.createElement('div');
      container.id = 'growls';
      document.body.appendChild(container);
    }
    return document.getElementById('growls')!;
  }

  static growl(options: GrowlOptions): void {
    this.initialize();
    new Growl(options);
  }

  constructor(options: GrowlOptions) {
    this.settings = { ...Growl.settings, ...options };
    this.growlsElement = document.getElementById('growls')!;
    this.growlsElement.setAttribute('class', this.settings.location);

    this.render();
  }

  private render(): void {
    const growl = this.createGrowlElement();
    this.growlsElement.appendChild(growl);
    this.bindEvents(growl);

    if (this.settings.fixed) {
      this.present(growl);
    } else {
      this.cycle(growl);
    }
  }

  private createGrowlElement(): HTMLElement {
    const div = document.createElement('div');
    div.innerHTML = this.html();
    return div.firstElementChild as HTMLElement;
  }

  private bindEvents(growl: HTMLElement): void {
    growl.addEventListener('click', (e) => this.handleClick(e));

    if (this.settings.delayOnHover) {
      growl.addEventListener('mouseenter', () => this.handleMouseEnter(growl));
      growl.addEventListener('mouseleave', () => this.handleMouseLeave(growl));
    }

    growl.addEventListener('contextmenu', (e) => this.close(e));
    growl.querySelector(`.${this.settings.namespace}-close`)!.addEventListener('click', (e) => this.close(e));
  }

  private handleClick(event: MouseEvent): void {
    if (this.settings.url) {
      event.preventDefault();
      event.stopPropagation();

      const url = this.settings.url;
      if (url.startsWith('event:')) {
        const [event, param] = url.replace('event:', '').split(',');
        (window as any).eventBus.$emit(event, param);
      } else {
        (window as any).vue_redirect(url);
      }
      (window as any).eventBus.$emit('notification-clicked', {});
    }
  }

  private handleMouseEnter(growl: HTMLElement): void {
    // Clear any pending animations/timeouts
    growl.style.animationPlayState = 'paused';
    clearTimeout((growl as any).dismissTimeout);
  }

  private handleMouseLeave(growl: HTMLElement): void {
    this.waitAndDismiss(growl);
  }

  private close(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    const growl = (event.target as HTMLElement).closest(`.${this.settings.namespace}`) as HTMLElement;
    this.dismiss(growl);
  }

  private present(growl: HTMLElement): void {
    this.animate(growl, `${this.settings.namespace}-incoming`, 'out');
  }

  private dismiss(growl: HTMLElement): void {
    this.animate(growl, `${this.settings.namespace}-outgoing`, 'in', () => {
      growl.remove();
    });
  }

  private cycle(growl: HTMLElement): void {
    this.present(growl);
    this.waitAndDismiss(growl);
  }

  private waitAndDismiss(growl: HTMLElement): void {
    (growl as any).dismissTimeout = setTimeout(() => {
      this.dismiss(growl);
    }, this.settings.duration);
  }

  private animate(element: HTMLElement, className: string, direction: 'in' | 'out', callback?: () => void): void {
    const transition = Animation.getTransition(element);

    if (direction === 'in') {
      element.classList.remove(className);
    } else {
      element.classList.add(className);
    }

    if (direction === 'in') {
      element.classList.add(className);
    } else {
      element.classList.remove(className);
    }

    if (callback) {
      if (transition) {
        element.addEventListener(transition, callback, { once: true });
      } else {
        callback();
      }
    }
  }

  private html(): string {
    return `
      <div class="${this.settings.namespace} ${this.settings.namespace}-${this.settings.style} ${this.settings.namespace}-${this.settings.size}">
        <div class="${this.settings.namespace}-close">${this.settings.close}</div>
        <div class="${this.settings.namespace}-title">${this.settings.title || ''}</div>
        <div class="${this.settings.namespace}-message">${this.settings.message || ''}</div>
      </div>
    `;
  }
}
