<template>
  <div>
    <div class="mb-2 flex items-center justify-between">
      <span class="t-text-md-emphasize">{{ $t('broadcast.broadcast_name') }}</span>
      <t-badge size="sm" btn-style="secondary" :text="$t('broadcast.broadcast_name_optional_label')" />
    </div>

    <div class="t-text-sm mb-2 text-grey-600">
      {{ $t('broadcast.for_internal_use_only_and_not_visible_to_your_customers') }}
    </div>
    <atomic-input
      v-model="nameModel"
      size="md"
      :placeholder="$t('broadcast.broadcast_name_placeholder')"
      :autofocus="action === 'create'"
      data-test="name-input"
    />
  </div>

  <div>
    <div class="t-text-md-emphasize mb-2">{{ $t('broadcast.broadcast_sender') }}</div>
    <t-dropdown
      v-model="senderModel"
      :items="computedFilteredSenders"
      value-index="id"
      text-index="title"
      :placeholder="$t('broadcast.broadcast_sender')"
      light-version
      size="md"
      item-class="t-text-desktop-paragraph-md text-grey-800"
      data-test="sender-dropdown"
      :error="!validation.sender"
      :disabled="isEditingBroadcast"
    >
      <template #start>
        <div
          v-if="!senders.length && isSmsEnabled && hasPermissionToManageWhatsapp"
          class="t-text-desktop-paragraph-sm mx-4 my-3 text-grey-600"
        >
          {{ $t('broadcast.broadcast_no_whatsapp_and_sms_sender_channel_error') }}
        </div>
        <div
          v-if="!senders.length && !isSmsEnabled && hasPermissionToManageWhatsapp"
          class="t-text-desktop-paragraph-sm mx-4 my-3 text-grey-600"
        >
          {{ $t('broadcast.broadcast_no_whatsapp_sender_channel_error') }}
        </div>
        <div
          v-if="!senders.length && !hasPermissionToManageWhatsapp"
          class="t-text-desktop-paragraph-sm mx-4 my-3 text-grey-600"
        >
          {{ $t('broadcast.broadcast_no_sender_check_with_admin_error') }}
        </div>
        <div v-if="senders.length" class="mx-4 my-3 flex h-40px flex-row rounded-xl bg-grey-200 px-4">
          <img alt="icon-search" style="width: 16px" :src="`${$root.assetsURL}img/icon-search.svg`" class="mr-2" />
          <input
            v-model="searchValueModel"
            class="t-text-desktop-paragraph-md w-full bg-transparent text-grey-600 placeholder-grey-600"
            :placeholder="$t('broadcast.search_in_broadcast_channels')"
          />
        </div>
      </template>
      <template #end>
        <div v-if="requiresWhatsappUpgrade">
          <div class="t-text-desktop-paragraph-sm mx-4 border-t border-grey-300 px-2 py-4 text-grey-600">
            <img class="mr-2 inline-block h-4" :src="`${$root.assetsURL}img/wab-broadcasting/whatsapp-grey.svg`" />
            {{ $t('broadcast.whatsapp_is_only_available_on_enterprise') }}
          </div>
        </div>
        <div v-if="!isSmsEnabled && userStore.hasPermission(PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE)">
          <div class="t-text-desktop-paragraph-sm mx-4 border-t border-grey-300 px-2 py-4 text-grey-600">
            <img class="mr-2 inline-block h-4" :src="`${$root.assetsURL}img/wab-broadcasting/sms-grey.svg`" />
            {{ $t('broadcast.please_contact_support_to_enable_sms_for_broadcasting') }}
          </div>
        </div>
      </template>
    </t-dropdown>
    <t-error-item
      v-if="!validation.sender"
      data-test="sender-error"
      class="mb-1 mt-1"
      :text="$t('broadcast.select_broadcast_sender')"
    />
  </div>

  <div v-if="senderObject?.type === CHANNEL_TYPE.WA_BUSINESS" class="flex flex-col gap-6">
    <div>
      <span class="t-text-md-emphasize">{{ $t('broadcast.message_template') }}</span>
      <div class="t-text-desktop-paragraph-xs mb-2 text-grey-600">
        {{ $t('broadcast.broadcast_dont_spam_with_not_allowed_messages') }}
      </div>
      <t-dropdown
        v-model="templateIdModel"
        :items="waTemplates"
        value-index="id"
        text-index="title"
        :placeholder="$t('broadcast.select_template_field_label')"
        light-version
        size="md"
        item-class="t-text-desktop-paragraph-md text-grey-800"
        data-test="template-dropdown"
        :error="!validation.template"
      >
        <template #start>
          <div v-if="!waTemplates.length" class="t-text-desktop-paragraph-sm mx-4 my-3 text-grey-600">
            {{ $t('broadcast.broadcast_no_template_create_or_wait_for_whatsapp_error') }}
          </div>
        </template>
      </t-dropdown>
      <t-error-item
        v-if="!validation.template"
        data-test="template-error"
        class="mb-1 mt-1"
        :text="$t('broadcast.please_select_a_template_for_your_broadcast')"
      />
    </div>
    <whatsapp-composer
      v-model="tagsModel"
      :template="template"
      :error="!validation.tags"
      :is-editing-broadcast="isEditingBroadcast"
      @update-tags="$emit('onUpdateTags', $event)"
      @update-template-media="$emit('onUpdateTemplateMedia', $event)"
    />
  </div>

  <div v-if="senderObject && senderObject.type === CHANNEL_TYPE.SMS">
    <div class="t-text-md-emphasize mb-2">{{ $t('broadcast.broadcast_message') }}</div>
    <sms-composer
      :channel="senderObject"
      :message="messageModel"
      :error="!validation.message"
      @message="$emit('update:message', $event)"
    />
    <t-inline-banner v-if="tagsAdded" class="mt-4">
      <template #icon>
        <info-linear />
      </template>
      {{ $t('broadcast.broadcast_tag_content_length_error_hint') }}
    </t-inline-banner>
  </div>
</template>

<script setup lang="ts">
import { InfoLinear } from '@trengo/trengo-icons';
import { computed } from 'vue';

import SmsComposer from '@/components/WabBroadcasting/components/SmsComposer';
import WhatsappComposer from '@/components/WabBroadcasting/components/WhatsappComposer';
import { PERMISSION } from '@/Configs/Constants';
import { CHANNEL_TYPE } from '@/Configs/Constants/channel';
import { useUserStore } from '@/store/pinia';

import type { TemplateHeaderMedia, TemplateTag } from '@/components/WaTemplates/types';
import type { Channel } from '@/store/pinia/channels/types';
import type { Template } from '@/types/waTemplates';

const props = defineProps<{
  name: string;
  sender: number | null;
  senderObject: Channel | null;
  computedFilteredSenders: Channel[];
  senders: Channel[];
  searchValue: string;
  requiresWhatsappUpgrade: boolean;
  isSmsEnabled: boolean;
  isEditingBroadcast: boolean;
  validation: {
    sender: boolean;
    template: boolean;
    tags: boolean;
    message: boolean;
    recipients: boolean;
  };
  templateId: number | null;
  waTemplates: Template[];
  template: Template | null | undefined;
  tags: TemplateTag[];
  message: string;
  tagsAdded: boolean;
  action: string;
}>();

const emit = defineEmits<{
  (e: 'update:name', value: string): void;
  (e: 'update:sender', value: number | null): void;
  (e: 'update:searchValue', value: string): void;
  (e: 'update:templateId', value: number | null): void;
  (e: 'update:tags', value: TemplateTag[]): void;
  (e: 'update:message', value: string): void;
  (e: 'onUpdateTags', value: TemplateTag[]): void;
  (e: 'onUpdateTemplateMedia', value: TemplateHeaderMedia): void;
  (e: 'updateMessage', value: string): void;
}>();

const userStore = useUserStore();

const nameModel = computed({
  get: () => props.name,
  set: (value) => emit('update:name', value),
});

const senderModel = computed({
  get: () => props.sender,
  set: (value) => emit('update:sender', value),
});

const searchValueModel = computed({
  get: () => props.searchValue,
  set: (value) => emit('update:searchValue', value),
});

const templateIdModel = computed({
  get: () => props.templateId,
  set: (value) => emit('update:templateId', value),
});

const tagsModel = computed({
  get: () => props.tags,
  set: (value) => emit('update:tags', value),
});

const messageModel = computed({
  get: () => props.message,
  set: (value) => emit('update:message', value),
});

const hasPermissionToManageWhatsapp = computed(() => {
  return userStore.hasPermission(PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE);
});
</script>
