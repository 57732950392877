<template>
  <div
    v-if="voipStore.isVoipReady && !hasGivenMicrophonePermissions"
    class="flex flex-col rounded-lg w-full px-3 py-4 border border-orange"
  >
    <p class="m-0 p-0 mb-2 text-center">To continue, please grant Trengo permission to manage your audio devices</p>
    <t-button class="w-fit mx-auto" @click="onGrantClick">Grant permission</t-button>
  </div>

  <div v-else class="flex flex-col">
    <label for="inputDevice" class="mb-1">{{ $t('voip.voip_the_input_device_during_phone_calls') }}</label>
    <select id="inputDevice" class="w-full border-none p-2 text-black rounded-lg">
      <option value="">Choose call input device</option>
      <option v-for="device in voipStore.inputAudioDevices" :key="device.deviceId" :value="device.deviceId">
        {{ device.label }}
      </option>
    </select>

    <label for="callOutput" class="mt-2 mb-1">
      {{ $t('voip.voip_the_output_device_during_phone_calls') }}
    </label>
    <div class="flex items-center">
      <select id="callOutput" class="w-full border-none p-2 text-black rounded-l-lg">
        <option value="">Choose call output device</option>
        <option v-for="device in voipStore.outputAudioDevices" :key="device.deviceId" :value="device.deviceId">
          {{ device.label }}
        </option>
      </select>

      <button class="bg-leaf-600 h-full rounded-r-lg px-3 hover:bg-leaf-700 active:bg-leaf-800 transition-colors">
        test
      </button>
    </div>

    <label for="callRingtone" class="mt-2 mb-1">
      {{ $t('voip.voip_ringtone_device') }}
    </label>
    <div class="flex items-center">
      <select id="callRingtone" class="w-full border-none p-2 text-black rounded-l-lg">
        <option value="">Choose ringtone device</option>
        <option v-for="device in voipStore.outputAudioDevices" :key="device.deviceId" :value="device.deviceId">
          {{ device.label }}
        </option>
      </select>

      <button class="bg-leaf-600 h-full rounded-r-lg px-3 hover:bg-leaf-700 active:bg-leaf-800 transition-colors">
        test
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';

import { useAgencyStore, useUserStore } from '@/store/pinia';
import useVoipStore from '@/store/pinia/voip';
import { hasMicrophonePermissions } from '@/util/voip';

const voipStore = useVoipStore();

const userStore = useUserStore();
const agencyStore = useAgencyStore();

const hasGivenMicrophonePermissions = ref(false);

onMounted(async () => {
  // We need to initialize the VOIP store in order to properly change VOIP device audio settings
  if (!voipStore.isVoipReady) {
    await voipStore.initializeVoipStore(agencyStore.agency!.channel_prefix, userStore.user!.id);
  }

  hasGivenMicrophonePermissions.value = await hasMicrophonePermissions();

  if (hasGivenMicrophonePermissions.value) {
    voipStore.setVoipAudioDevices();
  }
});

async function onGrantClick() {
  await voipStore.setVoipAudioDevices();
  hasGivenMicrophonePermissions.value = await hasMicrophonePermissions();
}
</script>
