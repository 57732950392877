import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex h-full w-full flex-1 pl-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_contacts_profiles_sidebar = _resolveComponent("contacts-profiles-sidebar")!
  const _component_portal = _resolveComponent("portal")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["h-full w-full flex-1 bg-white pl-4", { 'dark-light': _ctx.$route.path.includes('/sms/import') }])
  }, [
    _createVNode(_component_portal, { to: "sidebar" }, {
      default: _withCtx(() => [
        _createVNode(_component_contacts_profiles_sidebar)
      ]),
      _: 1 /* STABLE */
    }),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(), _createBlock(_component_router_view, {
        key: _ctx.$route.path,
        "search-term-prop": $data.searchTerm,
        onUpdateSearchTerm: $options.updateSearchTerm
      }, null, 8 /* PROPS */, ["search-term-prop", "onUpdateSearchTerm"]))
    ])
  ], 2 /* CLASS */))
}