<script type="text/babel">
import { FEATURE_FLAG_CHANNELS } from '@/Configs/Constants';
import { useFeatureFlagStore } from '@/store/pinia';

export default {
  data() {
    return {
      loading: false,
      provider: null,
      tab: 'twilio',
    };
  },
  computed: {
    isAircallMvpChannelCreationEnabled() {
      return useFeatureFlagStore().isEnabled(FEATURE_FLAG_CHANNELS.AIRCALL_MVP_CHANNEL_CREATION);
    },
  },

  mounted() {},
  
  methods: {
    createTwilioAccount() {
      window.open('https://www.twilio.com/try-twilio');
    },
  },

  beforeUnmount() {
    let createEmailModal = $('#CreateVoipModal');
    if (createEmailModal.length) {
      createEmailModal.modal('hide');
    }

    let modalBackdrop = $('.modal-backdrop.in');
    if (modalBackdrop.length) {
      modalBackdrop.remove();
    }
  },
};
</script>
<template>
  <div id="CreateVoipModal" class="modal" role="dialog">
    <form>
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header" style="border: none">
            <template v-if="provider !== null">
              <button type="button" class="float-left ml-1 mr-4 mt-1" @click="provider = null">
                <i class="fa fa-arrow-left" style="color: #dae1e7" aria-hidden="true"></i>
              </button>
            </template>
            <h5 class="modal-title">{{ $t('voice.add_voice_channel') }}</h5>
            <button type="button" class="close modal-close-btn" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="flex border border-l-0 border-r-0 text-center" style="background: #e9e9e9">
            <div
              class="flex-1 cursor-pointer p-4"
              :class="{ 'bg-white font-bold': tab === 'twilio' }"
              @click="tab = 'twilio'"
            >
              <i class="fa fa-laptop"></i>
              {{ $t('voice.phone_calls_via_the_browser') }}
            </div>
            <div
              class="flex-1 cursor-pointer p-4"
              :class="{ 'bg-white font-bold': tab === 'sip' }"
              @click="tab = 'sip'"
            >
              <i class="fa fa-laptop"></i>
              {{ $t('voice.phone_calls_via_sip_devices') }}
            </div>
          </div>
          <div class="modal-body border-0">
            <div v-if="tab === 'twilio'" class="form-group select-none">
              <div>
                <div class="my-4 mt-2 text-center">
                  <p class="text-muted">
                    {{ $t('voice.our_browser_platform_is_built_on_twilio') }}
                  </p>
                </div>
                <div class="flex w-full flex-col items-center justify-center">
                  <router-link
                    tag="button"
                    :to="'/admin/channels2/voip/create?provider=TWILIO'"
                    type="button"
                    active-class=""
                    class="btn email-provider-button shadow-full grey rounded"
                    style="background-color: white; color: #696969"
                    data-dismiss="modal"
                  >
                    <div class="pull-left email-provider-icon rounded">
                      <img
                        :src="`${$root.assetsURL}img/twilio.png`"
                        style="height: 24px; width: 24px"
                        class="rounded"
                      />
                    </div>
                    {{ $t('voice.use_my_own_twilio_account') }}
                  </router-link>
                  <button
                    type="button"
                    class="btn email-provider-button shadow-full grey rounded"
                    style="background-color: white; color: #696969"
                    @click.prevent="createTwilioAccount()"
                  >
                    <div class="pull-left email-provider-icon rounded">
                      <img
                        :src="`${$root.assetsURL}img/twilio.png`"
                        style="height: 24px; width: 24px; filter: grayscale(100%)"
                        class="rounded opacity-50"
                      />
                    </div>
                    {{ $t('voice.i_dont_have_a_twilio_account') }}
                  </button>
                </div>
              </div>
            </div>

            <div v-if="tab === 'sip'" class="form-group select-none">
              <div>
                <div class="my-4 mt-2 text-center">
                  <p class="text-muted">
                    {{ $t('voice.select_your_phone_provider_from_the_list') }}
                  </p>

                  <router-link
                    tag="button"
                    :to="'/admin/channels2/voip/create?provider=VOYS'"
                    type="button"
                    active-class=""
                    class="btn email-provider-button shadow-full grey rounded"
                    style="background-color: white; color: #696969"
                    data-dismiss="modal"
                  >
                    <div class="pull-left email-provider-icon rounded">
                      <img :src="`${$root.assetsURL}img/voys.svg`" style="height: 24px; width: 24px" />
                    </div>
                    {{ $t('voice.voys_nl') }}
                  </router-link>
                  <router-link
                    v-if="isAircallMvpChannelCreationEnabled"
                    tag="button"
                    :to="'/admin/channels2/voip/create?provider=AIRCALL'"
                    type="button"
                    active-class=""
                    class="btn email-provider-button shadow-full grey rounded"
                    style="background-color: white; color: #696969"
                    data-dismiss="modal"
                  >
                    <div class="pull-left email-provider-icon rounded">
                      <img :src="`${$root.assetsURL}img/aircall.svg`" style="height: 24px; width: 24px" />
                    </div>
                    Aircall
                  </router-link>
                  <router-link
                    tag="button"
                    :to="'/admin/channels2/voip/create?provider=CUSTOM'"
                    type="button"
                    active-class=""
                    class="btn email-provider-button shadow-full grey rounded"
                    style="background-color: white; color: #696969"
                    data-dismiss="modal"
                  >
                    <div class="pull-left email-provider-icon rounded"><i class="fa-fw md-18 far fa-phone"></i></div>
                    {{ $t('voice.custom_voip') }}
                  </router-link>

                  <span class="text-muted">
                    <small class="text-muted font-italic block pt-1 text-center">
                      {{ $t('voice.some_providers_may_not_be_available_in_your_county') }}
                    </small>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<style lang="scss">
.email-provider-button {
  padding: 13px 0 8px 0;
  margin: 20px auto;
  min-width: 350px;
  display: block;
}
.email-provider-icon {
  height: 30px;
  width: 30px;
  margin-top: -5px;
  margin-left: -7px;
  background: white;
  padding-top: 3px;
}
.email-provider-icon img {
  height: 18px;
  width: 18px;
}
</style>
