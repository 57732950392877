import { flashError } from '@/util/flashNotification';

export async function hasMicrophonePermissions(): Promise<boolean> {
  try {
    // @ts-expect-error https://github.com/microsoft/TypeScript/issues/33923
    const result = await navigator.permissions.query({ name: 'microphone' });
    return result.state === 'granted';
  } catch (err: unknown) {
    console.error(err);
    return false;
  }
}

export async function getAudioDevices(): Promise<{
  inputDevices: MediaDeviceInfo[];
  outputDevices: MediaDeviceInfo[];
}> {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });

    /**
     * On Firefox and Safari we have to first grab the devices while the stream is still active.
     * If we enumrateDevices after the stream is killed, FF and Safari will return blank entries
     * as no device is actually being used, I guess as a security measure.
     */
    const devices = await navigator.mediaDevices.enumerateDevices();
    const inputDevices = devices.filter((device) => device.kind === 'audioinput');
    const outputDevices = devices.filter((device) => device.kind === 'audiooutput');

    // Kill tracks because we only needed microphone permissions from user
    stream.getTracks().forEach((track) => track.stop());

    return {
      inputDevices,
      outputDevices,
    };
  } catch (err: unknown) {
    if (err instanceof DOMException) {
      switch (err.name) {
        case 'NotAllowedError':
          flashError(
            "Microphone permission was denied. This means you won't be able to make or receive calls. If you'd like to do so, please allow access to your microphone.",
          );
          throw err;
        case 'NotFoundError':
          flashError(
            "No microphone was found on your device. You won't be able to make or receive calls without a microphone.",
          );
          throw err;
        default:
          console.error('Unexpected DOM error while getting audio devices:', err);
          throw err;
      }
    } else {
      console.error('Error while getting audio devices:', err);
      throw err;
    }
  }
}
