<script type="text/babel">
import {join, map} from 'lodash';
import Multiselect from 'vue-multiselect';
import {PhoneNumberFormat as PNF, PhoneNumberUtil} from 'google-libphonenumber';

import PhoneInput from './PhoneInput.vue';
import Avatar from '../../Avatar';

export default {
  name: 'TransferInput',
  emits: ['transferSelected', 'closeTransfer'],
  data() {
    return {
      transferType: 'user',
      phone: null,
      team: null,
      user: null,
    };
  },

  components: {
    Avatar,
    Multiselect,
    PhoneInput,
  },

  mounted() {
    $('.phone_transfer_input').intlTelInput({
      preferredCountries: ['nl', 'be', 'de', 'fr', 'es', 'uk', 'it'],
      onlyCountries: ['nl', 'be', 'de', 'fr', 'es', 'uk', 'it', 'mx', 'pr'],
      utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/12.1.4/js/utils.js',
      geoIpLookup: function (callback) {
        $.get('https://ipinfo.io', function () {
        }, 'jsonp').always(function (resp) {
          var countryCode = resp && resp.country ? resp.country : '';
          callback(countryCode);
        });
      },
      nationalMode: true,
      autoPlaceholder: 'off',
    });
  },

  methods: {
    getTeams(teams) {
      return join(map(teams, 'name'), ', ');
    },

    userSelected(user) {
      if (user.voip_status === 'CALLING') {
        return;
      }

      if (user.voip_device == 'MOBILE') {
        this.transferType = 'extern';
        this.phoneSelected(user.phone, user);
        return;
      }

      this.$emit('transferSelected', {
        type: 'user',
        user: user,
      });
    },

    teamSelected(team) {
      this.$emit('transferSelected', {
        type: 'team',
        team: team,
      });
    },

    phoneSelected(phone, user = null) {
      try {
        const phoneUtil = PhoneNumberUtil.getInstance();
        let country = $('.Typeahead__input').length
            ? $('.Typeahead__input').intlTelInput('getSelectedCountryData').iso2
            : null;

        if (country != null) {
          phone = phoneUtil.parse(phone, country).format(phoneNumber, PNF.INTERNATIONAL);
        }

        this.$emit('transferSelected', {
          type: 'extern',
          phone: phone,
          user: user,
        });
      } catch (e) {
        console.log(e);
        $.growl.error({title: 'Error', message: 'Invalid phone number'});
      }
    },
  },
};
</script>

<template>
  <div class="select-none" style="min-height: 304px">
    <div class="w-full items-center justify-center font-bold"
         style="display: inline-flex; vertical-align: middle">
      <span class="flex-1 text-center text-base leading-none">Transfer call</span>
      <i
          class="text-md material-icons ml-auto cursor-pointer text-grey-600 hover:brightness-90"
          @click="$emit('closeTransfer')"
      >
        close
      </i>
    </div>
    <div class="transfer_types pt-4">
      <div class="mb-4 flex items-center justify-center rounded-lg bg-grey-200">
        <span
            :class="{ 'ticket-sub-filter--active': transferType === 'user' }"
            class="ticket-sub-filter flex-1 text-center text-sm text-grey-600"
            @click="transferType = 'user'"
        >
          User
        </span>
        <span
            :class="{ 'ticket-sub-filter--active': transferType === 'team' }"
            class="ticket-sub-filter flex-1 text-center text-sm text-grey-600"
            @click="transferType = 'team'"
        >
          Team
        </span>
        <span
            :class="{ 'ticket-sub-filter--active': transferType === 'extern' }"
            class="ticket-sub-filter flex-1 text-center text-sm text-grey-600"
            @click="transferType = 'extern'"
        >
          Extern
        </span>
      </div>
      <multiselect
          v-show="transferType == 'user'"
          v-model="user"
          :reset-after="true"
          deselect-label=""
          :searchable="true"
          :close-on-select="true"
          placeholder="Select user"
          label="name"
          :options="$root.users"
          :allow-empty="false"
          track-by="id"
          @select="userSelected"
      >
        <template #noResult>
          <span>
            <div class="mt-8 text-center text-grey-600">No results found.</div>
          </span>
        </template>
        <template #option="user">
          <div class="flex items-center">
            <div class="relative">
              <avatar
                  width="32"
                  :color="user.option.color"
                  :abbr="user.option.abbr"
                  :image="user.option.profile_image"
              ></avatar>
              <strong
                  class="label absolute rounded border border-white"
                  style="width: 10px; height: 10px; padding: 0; bottom: 0; right: 0"
                  :class="{
                  success: user.option.is_online && user.option.user_status == 'ONLINE',
                  warning: user.option.is_online && user.option.user_status == 'AWAY',
                  danger: !user.option.is_online,
                }"
              ></strong>
            </div>
            <div class="ml-2 leading-none">
              {{ user.option.name }}
            </div>
          </div>
        </template>
      </multiselect>
      <multiselect
          v-show="transferType == 'team'"
          v-model="team"
          :reset-after="true"
          placeholder="Select team"
          label="name"
          :options="$root.teams"
          deselect-label=""
          :allow-empty="false"
          track-by="id"
          @select="teamSelected"
      >
        <div class="mt-8 text-center text-grey-600">No results found.</div>
        <template #option="team">
          <div class="flex items-center">
            <div class="relative">
              <span
                  class="circle inline-block-40px avatar flex-shrink-0 bg-grey-200"
                  style="width: 32px; height: 32px; line-height: 32px"
              >
                <i class="material-icons text-md leading-none" style="padding-top: 6px">people</i>
              </span>
              <b
                  class="label bg-grey absolute rounded"
                  style="width: 10px; height: 10px; padding: 0; bottom: 0; right: 0"
                  :class="{ success: team.option.online_users_count > 0, danger: team.option.online_users_count == 0 }"
              ></b>
            </div>
            <div class="ml-2 leading-none">
              {{ team.option.name }}
              <span v-if="team.option.users_count > 0" class="text-muted p-l-xs text-xs">
                ({{ team.option.online_users_count }})
              </span>
            </div>
          </div>
        </template>
      </multiselect>
      <phone-input v-show="transferType == 'extern'" :dark="false"
                   @selected="phoneSelected"></phone-input>
    </div>
  </div>
</template>

<style lang="scss">
.transfer_types {
  .multiselect__content-wrapper {
    max-height: 160px !important;
    border-radius: 0;
    border: none;
    padding: 0;
    margin: 0;
    margin-top: 15px;
  }

  .multiselect__tags {
    border-radius: 10px !important;
    border: 2px solid #ebedf5;
    background: #fff;
  }

  .multiselect__option {
    background: white !important;
    padding: 0 0 10px;
  }

  .multiselect__option:hover {
    font-weight: bold;
  }

  .multiselect__select {
    display: none;
  }

  .multiselect__option--highlight:after {
    display: none;
  }
}
</style>
