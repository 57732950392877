<template>
  <div class="flex items-center my-8">
    <call-incoming-fill v-if="isInbound" class="mr-2 text-leaf-500" />
    <call-outgoing-fill v-else class="mr-2 text-leaf-500" />

    <p class="m-0 p-0">
      <span v-if="isInbound">Inbound</span>
      <span v-else>Outbound</span>
      call at:
      <strong>+31 123123123</strong>
    </p>
  </div>

  <avatar v-if="hasProfile" />
  <div v-else class="rounded-full bg-grey-800 text-grey-500 p-3">
    <user-fill size="32" />
  </div>

  <p class="m-0 p-0 mt-2"><strong>John Doe (+31 123123123)</strong></p>

  <div class="flex items-center justify-between w-1/2 mt-4">
    <button class="rounded-full flex flex-col items-center group cursor-pointer" @click="isMuted = !isMuted">
      <span class="rounded-full p-2 transition-colors" :class="{ 'bg-grey-700 group-hover:bg-grey-600': isMuted }">
        <mic-slash-fill v-if="!isMuted" size="24" />
        <mic-fill v-else size="24" />
      </span>

      <p class="m-0 p-0 mt-1 text-xs text-grey-500 group-hover:text-grey-200 transition-colors select-none">Mute</p>
    </button>

    <button class="rounded-full flex flex-col items-center group cursor-pointer" @click="isPaused = !isPaused">
      <span class="rounded-full p-2 transition-colors" :class="{ 'bg-grey-700 group-hover:bg-grey-600': isPaused }">
        <pause-fill v-if="!isPaused" size="24" />
        <play-fill v-else size="24" />
      </span>

      <p class="m-0 p-0 mt-1 text-xs text-grey-500 group-hover:text-grey-200 transition-colors select-none">Pause</p>
    </button>

    <button class="rounded-full flex flex-col items-center group cursor-pointer">
      <span class="rounded-full p-2 transition-colors">
        <call-redirected-fill size="24" />
      </span>

      <p class="m-0 p-0 mt-1 text-xs text-grey-500 group-hover:text-grey-200 transition-colors select-none">Forward</p>
    </button>
  </div>

  <button class="flex flex-col items-center mt-4 rounded-full group" @click="emit('end-call')">
    <span class="rounded-full p-2 bg-error-500 group-hover:bg-error-700 group-active:!bg-error-300 transition-colors">
      <call-decline-fill size="32" />
    </span>

    <p class="m-0 p-0 mt-1 text-xs text-grey-500 group-hover:text-grey-200 transition-colors select-none">Hang up</p>
  </button>

  <div class="mt-auto flex flex-col items-center w-full gap-y-2 mb-4">
    <button
      type="button"
      class="bg-grey-800 rounded-lg px-2 py-1 hover:bg-grey-700 active:bg-grey-600 transition-colors w-fit"
      @click="emit('view-queue')"
    >
      View queue & active calls
    </button>

    <button
      type="button"
      class="bg-grey-800 rounded-lg px-2 py-1 hover:bg-grey-700 active:bg-grey-600 transition-colors w-fit"
      @click="emit('view-queue')"
    >
      Open conversation
    </button>
  </div>
</template>

<script setup lang="ts">
import {
  CallIncomingFill,
  CallOutgoingFill,
  CallRedirectedFill,
  CallDeclineFill,
  UserFill,
  MicSlashFill,
  MicFill,
  PauseFill,
  PlayFill,
} from '@trengo/trengo-icons';
import { ref } from 'vue';

import Avatar from '@/components/Avatar.vue';

const emit = defineEmits<{
  (e: 'end-call'): void;
  (e: 'view-queue'): void;
}>();

const isInbound = ref(true);
const hasProfile = ref(false);

const isMuted = ref(false);
const isPaused = ref(false);
</script>
