import { PERMISSION } from '@/Configs/Constants';

import type { RouteRecord } from '../types';

const webWidgetsRoutes: RouteRecord[] = [
  {
    path: '/admin/web_widgets',
    component: () => import(/* webpackChunkName: "WebWidgets" */ '../../components/WebWidgets/Index.vue'),
    meta: { permissionRequired: PERMISSION.SETTINGS__WEBSITE_WIDGETS__MANAGE },
    children: [
      {
        path: '/admin/web_widgets/create',
        component: () => import(/* webpackChunkName: "WebWidgets" */ '../../components/WebWidgets/Edit.vue'),
        props: { action: 'create' },
        meta: { permissionRequired: PERMISSION.SETTINGS__WEBSITE_WIDGETS__MANAGE },
      },
      {
        path: '/admin/web_widgets/:id',
        component: () => import(/* webpackChunkName: "WebWidgets" */ '../../components/WebWidgets/Edit.vue'),
        props: { action: 'edit' },
        meta: { permissionRequired: PERMISSION.SETTINGS__WEBSITE_WIDGETS__MANAGE },
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "WebWidgets" */ '../../components/WebWidgets/EntitlementRequiredPage.vue'),
        meta: { permissionRequired: PERMISSION.SETTINGS__WEBSITE_WIDGETS__MANAGE },
      },
    ],
  },
];

export default webWidgetsRoutes;
