import { h } from 'vue';
import { RouterView } from 'vue-router';

import { PERMISSION } from '@/Configs/Constants';

import type { RouteRecord } from '../types';

const widgetAutomationRoutes: RouteRecord[] = [
  {
    path: '/admin/widget_automation',
    component: { render: () => h(RouterView) },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "WidgetAutomation" */ '../../components/WidgetAutomation/Index.vue'),
        meta: { permissionRequired: PERMISSION.SETTINGS__WIDGET_AUTOMATIONS__MANAGE },
      },
      {
        path: 'clone/:clone',
        component: () => import(/* webpackChunkName: "WidgetAutomation" */ '../../components/WidgetAutomation/Edit.vue'),
        props: { action: 'create' },
        meta: { permissionRequired: PERMISSION.SETTINGS__WIDGET_AUTOMATIONS__MANAGE },
      },
      {
        path: 'create/:template([a-zA-Z-_.0-9]+)',
        component: () => import(/* webpackChunkName: "WidgetAutomation" */ '../../components/WidgetAutomation/Edit.vue'),
        props: { action: 'create' },
        meta: { permissionRequired: PERMISSION.SETTINGS__WIDGET_AUTOMATIONS__MANAGE },
      },
      {
        path: 'create',
        component: () => import(/* webpackChunkName: "WidgetAutomation" */ '../../components/WidgetAutomation/Edit.vue'),
        props: { action: 'create' },
        meta: { permissionRequired: PERMISSION.SETTINGS__WIDGET_AUTOMATIONS__MANAGE },
      },
      {
        path: ':id(\\d+)',
        component: () => import(/* webpackChunkName: "WidgetAutomation" */ '../../components/WidgetAutomation/Edit.vue'),
        props: { action: 'edit' },
        meta: { permissionRequired: PERMISSION.SETTINGS__WIDGET_AUTOMATIONS__MANAGE },
      },
    ],
  },
];

export default widgetAutomationRoutes;
