<template>
  <div class="h-full w-full flex-1 bg-white pl-4" :class="{ 'dark-light': $route.path.includes('/sms/import') }">
    <portal to="sidebar">
      <contacts-profiles-sidebar></contacts-profiles-sidebar>
    </portal>
    <div class="flex h-full w-full flex-1 pl-4">
      <router-view
        :key="$route.path"
        :search-term-prop="searchTerm"
        @update-search-term="updateSearchTerm"
      ></router-view>
    </div>
  </div>
</template>
<script lang="ts">
import ContactsProfilesSidebar from './ContactsProfilesSidebar';
import IconClass from '../IconClass';

export default {
  components: {
    ContactsProfilesSidebar,
  },
  data() {
    return {
      searchTerm: '',
    };
  },

  mounted() {
    this.searchTerm = '';
  },

  methods: {
    getIconClass(type) {
      return IconClass[type];
    },
    updateSearchTerm(value) {
      this.searchTerm = value;
    },
  },
};
</script>
