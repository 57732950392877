import { request } from '@/util/request';

import { BASE_URL, ENDPOINT } from '../constants';

import type { CheckDangerZoneAccess, FetchLogInToken, GenerateDangerZoneToken } from '../types/auth';

export function getAuthActivity(socketId: string) {
  return request<void>(`${ENDPOINT.AUTH_ACTIVITY}`, 'POST', { socket_id: socketId });
}
export function getDirectLoginUrl() {
  return request<unknown>(`${ENDPOINT.DIRECT_LOGIN_URL}`, 'GET');
}

export function fetchLogInToken() {
  return request<FetchLogInToken>(`${ENDPOINT.LOGIN_TOKEN}`, 'GET');
}

export function fetchAdminEvents(resource: string) {
  return request<unknown>(`${BASE_URL}/${resource}`, 'GET');
}

export function checkDangerZoneAccess(token: string) {
  return request<CheckDangerZoneAccess>(`${ENDPOINT.USER_VERIFICATION_VERIFY_TOKEN}`, 'POST', {
    verification_token: token,
  });
}

export function generateDangerZoneToken(secret: string) {
  return request<GenerateDangerZoneToken>(`${ENDPOINT.USER_VERIFICATION_GENERATE_TOKEN}`, 'POST', { secret });
}

export function sendDangerZoneVerificationEmail() {
  return request<void>(`${ENDPOINT.USER_VERIFICATION_SEND_EMAIL}`, 'POST');
}

export function initializeAuthUser() {
  return request<unknown>(`${ENDPOINT.INITIALIZE_AUTH_USER}`, 'GET');
}
