import axios from 'axios';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

import { useMeChannelsStore } from '@/store/pinia';
import { flashSuccess } from '@/util/flashNotification';

export const useDeleteChannelModalStore = defineStore('deleteChannelModal', () => {
  const { t } = useI18n();
  const router = useRouter();
  const isOpen = ref<boolean>(false);
  const message = ref<string>('');
  const deleteConfirmation = ref<string>('');
  const meChannelsStore = useMeChannelsStore();

  function openModal(newMessage: string): void {
    isOpen.value = true;
    message.value = newMessage;
    deleteConfirmation.value = '';
  }

  function closeModal(): void {
    isOpen.value = false;
    deleteConfirmation.value = '';
  }

  async function deleteChannel(channelId: string, channelType: string): Promise<void> {
    try {
      await axios.delete(`/api/v2/channels/${channelId}`);
      router.push(`/admin/channels2/${channelType.toLowerCase()}`);
      await meChannelsStore.getChannels();
      flashSuccess(t('channels.delete_successful'));
      closeModal();
    } catch {
      // Channel deletion failed - error will be handled by axios interceptor
    }
  }

  return {
    // State
    isOpen,
    message,
    deleteConfirmation,

    // Actions
    openModal,
    closeModal,
    deleteChannel,
  };
});
