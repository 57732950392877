<template>
  <div class="flex-1 flex flex-col h-full overflow-y-auto px-2">
    <audio ref="inboundAudioRef">
      <source src="https://media.twiliocdn.com/sdk/js/client/sounds/releases/1.0.0/incoming.mp3" type="audio/mpeg" />
    </audio>

    <p class="rounded-lg bg-grey-800 text-grey-400 p-3 py-2 m-0 mb-4 w-fit">{{ $t('voip.voip_status') }}</p>

    <voip-status />

    <div
      v-if="isAvailableForCalls"
      class="my-1 flex items-center rounded-lg border border-grey-700 py-2 px-3 hover:brightness-90 cursor-pointer select-none"
      :class="{ 'border-orange': shouldForwardToPhone && !isCallForwardingPossible }"
      @click.prevent="shouldForwardToPhone = !shouldForwardToPhone"
    >
      <div class="mr-2 text-md">
        <call-outgoing-fill
          v-if="shouldForwardToPhone"
          :class="isCallForwardingPossible ? 'text-green' : 'text-orange'"
        />
        <laptop-fill v-else class="text-green" />
      </div>

      <p v-if="shouldForwardToPhone" class="m-0 p-0 text-truncate">
        {{ $t('voip.voip_forward_calls_to_your') }}
        <strong>{{ $t('voip.voip_phone') }}</strong>
      </p>

      <p v-else class="m-0 p-0 text-truncate">
        {{ $t('voip.voip_receive_calls_in_your') }}
        <strong>{{ $t('voip.voip_browser') }}</strong>
      </p>

      <p class="m-0 ml-auto p-0 text-xs leading-none text-grey-500">
        {{ $t('voip.voip_change') }}
      </p>
    </div>

    <div
      v-if="isAvailableForCalls && shouldForwardToPhone && !isCallForwardingPossible"
      class="my-1 flex flex-col rounded-lg border border-orange text-orange py-2 px-3"
    >
      <p class="m-0 p-0 mb-1">
        <strong>Calls are not forwarded yet</strong>
      </p>

      <p class="m-0 p-0">
        <!-- There's a typo in this copy but I won't change it now due to effort opening a BE PR -->
        {{ $t('voip.voip_enter_a_phone_number_in_your') }}
        <router-link to="/profile" class="underline hover:no-underline">{{ $t('voip.voip_your_profile') }}</router-link>
        {{ $t('voip.voip_to_enable_call_forwarding') }}
      </p>
    </div>

    <div
      v-else-if="isAvailableForCalls && shouldForwardToPhone"
      class="my-1 flex flex-col rounded-lg border border-grey-700 py-2 px-3"
    >
      <p class="m-0 p-0">
        {{ $t('voip.voip_inbound_and_outbound_phone_calls_will_be_forwarded_to') }}
        <router-link to="/profile" class="underline hover:no-underline">{{ userPhoneNumber }}</router-link>
      </p>
    </div>

    <template v-if="isAvailableForCalls && !shouldForwardToPhone && !isUserOnMobile">
      <p class="rounded-lg bg-grey-800 text-grey-400 p-3 py-2 m-0 my-4 w-fit">{{ $t('voip.voip_audio_settings') }}</p>
      <audio-device-settings />
    </template>
  </div>
</template>

<script setup lang="ts">
import { LaptopFill, CallOutgoingFill } from '@trengo/trengo-icons';
import { computed, ref } from 'vue';

import { vm } from '@/ApplicationHandler';
import AudioDeviceSettings from '@/components/Voip/components/Panel/AudioDeviceSettings.vue';
import VoipStatus from '@/components/Voip/Panel/VoipStatus.vue';

const isAvailableForCalls = computed(() => vm.$root?.user?.voip_status === 'ONLINE');
const isCallForwardingPossible = computed(() => isAvailableForCalls.value && !!vm.$root?.user?.phone);
const isUserOnMobile = computed(() => vm.$root?.mobileDevice);
const userPhoneNumber = computed(() => vm.$root?.user?.phone);

const shouldForwardToPhone = ref(false);
</script>
