<template>
  <div class="relative w-full" @click.stop>
    <div class="flex">
      <country-code-selector
        ref="countrySelector"
        v-model="countryModel"
        :parsed-country-code="detectedCountryCode"
        @dropdown-change="onCountryDropdownChange"
      />

      <div v-on-click-outside="closeDropdowns" class="flex-1" @click="toggleContactDropdown">
        <input
          ref="inputRef"
          v-model="displayValue"
          type="text"
          placeholder="Search contacts..."
          class="w-full outline-none px-4 py-2 bg-grey-800"
          @input="onInput"
          @keydown.enter.prevent="handleEnter"
          @keydown.esc.prevent="closeDropdowns"
          @keydown.down.prevent="navigateList('down')"
          @keydown.up.prevent="navigateList('up')"
        />
      </div>
    </div>

    <div v-if="showContactDropdown" class="absolute w-full max-h-[19rem] overflow-y-auto">
      <div v-if="isLoading" class="bg-grey-800 px-4 py-2">Loading...</div>

      <template v-else>
        <div
          v-for="(contact, index) in contacts"
          :key="contact.id"
          :data-index="index"
          class="cursor-pointer flex items-center bg-grey-800 px-4 py-2 border-b-1 first:border-t-1 border-grey-700 hover:bg-grey-700"
          :class="{ 'bg-grey-700': selectedIndex === index }"
          @click="selectItem(contact)"
        >
          <avatar width="35" :abbr="contact.abbr" :color="contact.color" :image="contact.profile_image" />

          <div class="flex flex-col flex-1 ml-4">
            <span v-if="contact.name">{{ contact.name }}</span>
            <span class="text-sm text-grey-500">{{ contact.phone_international }}</span>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { vOnClickOutside } from '@vueuse/components';
import { useDebounceFn } from '@vueuse/core';
import { ref, watch } from 'vue';

import { fetchContacts, type VoipContact } from '@/api/modules/voip';
import Avatar from '@/components/Avatar.vue';
import CountryCodeSelector from '@/components/Voip/components/Panel/CountryCodeSelector.vue';
import { countryCodes } from '@/Configs/Constants/countryCodes';

import type { Country } from '@/Configs/Constants/countryCodes';

const numberModel = defineModel<string>('number', { required: true, default: '' });

watch(
  numberModel,
  (newValue) => {
    if (newValue && !displayValue.value.includes('(') && newValue !== displayValue.value) {
      displayValue.value = newValue;
    }
  },
  { immediate: true },
);

const countryModel = defineModel<Country>('country', {
  required: true,
  default: {
    name: 'Netherlands',
    code: 'NL',
    callingCode: '31',
    flag: '🇳🇱',
  },
});

const emit = defineEmits<{
  select: [payload: { id?: number; phone_international: string; country: Country }];
}>();

const inputRef = ref<HTMLInputElement | null>(null);
const countrySelector = ref<InstanceType<typeof CountryCodeSelector> | null>(null);
const contacts = ref<VoipContact[]>([]);

const detectedCountryCode = ref<Country['callingCode']>('');
const showContactDropdown = ref(false);
const isLoading = ref(false);
const selectedIndex = ref(-1);

const searchQuery = ref('');
const displayValue = ref('');

watch(displayValue, (newValue) => {
  /**
   * Match direct country codes like +31 or 0031. Looks for:
   *
   * - If the start of the string
   * - Matches either a + or 00
   * - and has 1-3 digits
   *
   * It uses a non-capturing group `?:` for the first section to discard + or 00 matches, then a 2nd
   * capture group to catch the actual country code
   */
  const directPattern = /^(?:\+|00)(\d{1,3})/;

  /**
   * Similarly to the above, this matches the "Parsed" version of a number (see `selectItem` below).
   * These look like this: John Doe (+31 ...), Mary Sue (+1 ...)
   *
   * - Start with positive lookbehind to look for an opening `(+` sequence
   * - Capture 1-3 digits
   *
   * This can technically fail if people manually input something like `(+32` but I guess it doesn't
   * really matter either way
   */
  const parenthesesPattern = /(?<=\()\+?(\d{1,3})/;

  // Combine the 2 regexes with a | (OR) between them
  const match = newValue.match(new RegExp(`${directPattern.source}|${parenthesesPattern.source}`));
  const parsedCode = match?.[1] || match?.[2];

  if (parsedCode) {
    detectedCountryCode.value = parsedCode;

    // Also update the country if we find a match
    const matchingCountry = countryCodes.find(({ callingCode }) => callingCode === parsedCode);
    if (matchingCountry) {
      countryModel.value = matchingCountry;
    }
  }
});

const debouncedSearch = useDebounceFn((input: string) => {
  isLoading.value = true;

  fetchContacts(input)
    .then((response) => {
      contacts.value = response.data;
      selectedIndex.value = -1;
    })
    .finally(() => {
      isLoading.value = false;
    });
}, 250);

function onInput() {
  showContactDropdown.value = true;
  searchQuery.value = displayValue.value;
  debouncedSearch(searchQuery.value);

  numberModel.value = displayValue.value;
}

function handleEnter() {
  if (showContactDropdown.value && selectedIndex.value >= 0 && contacts.value[selectedIndex.value]) {
    selectItem(contacts.value[selectedIndex.value]);
  } else {
    const phoneNumber = displayValue.value.trim();

    displayValue.value = phoneNumber;
    numberModel.value = phoneNumber;

    // Check for country code in direct input
    const match = phoneNumber.match(/^(?:\+|00)(\d{1,3})/);
    if (match) {
      const parsedCode = match[1];
      const matchingCountry = countryCodes.find(({ callingCode }) => callingCode === parsedCode);
      if (matchingCountry) {
        countryModel.value = matchingCountry;
      }
    }

    closeDropdowns();
    inputRef?.value?.blur();
  }
}

function navigateList(direction: 'up' | 'down') {
  if (!showContactDropdown.value || contacts.value.length === 0) return;

  const lastIndex = contacts.value.length - 1;

  if (direction === 'down') {
    if (selectedIndex.value >= lastIndex) {
      selectedIndex.value = 0;
    } else {
      selectedIndex.value++;
    }
  } else {
    if (selectedIndex.value <= 0) {
      selectedIndex.value = lastIndex;
    } else {
      selectedIndex.value--;
    }
  }

  const selectedElement = document.querySelector(`[data-index="${selectedIndex.value}"]`);
  if (selectedElement) {
    selectedElement.scrollIntoView({ block: 'nearest' });
  }
}

function closeDropdowns() {
  showContactDropdown.value = false;
  countrySelector.value?.close();
  selectedIndex.value = -1;
}

function toggleContactDropdown() {
  showContactDropdown.value = !showContactDropdown.value;
  countrySelector.value?.close();

  if (!showContactDropdown.value) {
    selectedIndex.value = -1;
  }
}

function onCountryDropdownChange(isOpen: boolean) {
  if (isOpen) {
    showContactDropdown.value = false;
    selectedIndex.value = -1;
  }
}

function selectItem(contact: VoipContact) {
  const phone = contact.phone_international;

  displayValue.value = contact.name ? `${contact.name} (${phone})` : phone;
  numberModel.value = phone;

  emit('select', {
    id: contact.id,
    phone_international: phone,
    country: countryModel.value,
  });

  closeDropdowns();
  inputRef?.value?.blur();
}
</script>
