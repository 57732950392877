import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withModifiers as _withModifiers, withKeys as _withKeys, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, Fragment as _Fragment, resolveDirective as _resolveDirective, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-244a91b5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "relative flex" }
const _hoisted_2 = ["id"]
const _hoisted_3 = {
  key: 1,
  class: "placeholder"
}
const _hoisted_4 = {
  key: 0,
  class: "z-50 flex flex-row items-center space-x-1 rounded-2xl border border-grey-300 bg-white p-1 shadow-lg"
}
const _hoisted_5 = {
  key: 1,
  class: "border-l-1 border-grey-300 pl-1"
}
const _hoisted_6 = {
  key: 1,
  class: "z-50 flex flex-col gap-1 rounded-xl border border-grey-300 bg-white p-1"
}
const _hoisted_7 = { class: "flex justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_straight = _resolveComponent("straight")!
  const _component_text_bold_linear = _resolveComponent("text-bold-linear")!
  const _component_t_icon_button = _resolveComponent("t-icon-button")!
  const _component_text_italic_linear = _resolveComponent("text-italic-linear")!
  const _component_text_strikethrough_linear = _resolveComponent("text-strikethrough-linear")!
  const _component_code_linear = _resolveComponent("code-linear")!
  const _component_external_link_linear = _resolveComponent("external-link-linear")!
  const _component_paperclip_slash_linear = _resolveComponent("paperclip-slash-linear")!
  const _component_link1_linear = _resolveComponent("link1-linear")!
  const _component_t_input_text = _resolveComponent("t-input-text")!
  const _component_t_button = _resolveComponent("t-button")!
  const _directive_tooltip = _resolveDirective("tooltip")!
  const _directive_on_click_outside = _resolveDirective("on-click-outside")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.isLoading)
      ? (_openBlock(), _createBlock(_component_straight, {
          key: 0,
          width: 160,
          height: 12
        }))
      : _createCommentVNode("v-if", true),
    _withDirectives(_createElementVNode("div", {
      id: $props.elementId,
      ref: "el",
      contenteditable: true,
      "data-hj-suppress": "",
      class: "flex-1 break-words",
      style: _normalizeStyle([{ maxHeight: $props.maxHeight }, {"word-break":"break-word"}]),
      onKeyup: [
        _cache[0] || (_cache[0] = _withKeys(_withModifiers(($event: any) => (_ctx.$emit('delete')), ["prevent"]), ["delete"])),
        _cache[4] || (_cache[4] = _withModifiers(($event: any) => ($options.onChange($event)), ["prevent"])),
        _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => ($options.handleTextSelection && $options.handleTextSelection(...args)))
      ],
      onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($options.onFocus && $options.onFocus(...args))),
      onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($options.onBlur && $options.onBlur(...args))),
      onKeydown: _cache[3] || (_cache[3] = _withKeys(
//@ts-ignore
(...args) => ($options.onEnter && $options.onEnter(...args)), ["enter"])),
      onPaste: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => ($options.onChange && $options.onChange(...args))),
      onInput: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => ($options.onChange && $options.onChange(...args))),
      onMouseup: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => ($options.handleTextSelection && $options.handleTextSelection(...args)))
    }, null, 44 /* STYLE, PROPS, NEED_HYDRATION */, _hoisted_2), [
      [_vShow, !$props.isLoading]
    ]),
    ($data.showPlaceholder && !$props.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString($props.placeholder), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    ($data.showTooltip && ($props.allowFormatting || $props.allowLinkFormatting))
      ? _withDirectives((_openBlock(), _createElementBlock("div", {
          key: 2,
          ref: "tooltip",
          class: "tooltip-container absolute z-50",
          style: _normalizeStyle($options.tooltipStyle)
        }, [
          (!$data.isLinkingActive)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                ($props.allowFormatting)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createVNode(_component_t_icon_button, {
                        class: _normalizeClass({ selected: $options.isFormatted('bold') }),
                        onClick: _cache[9] || (_cache[9] = ($event: any) => ($options.format('bold')))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_text_bold_linear, {
                            class: "text-grey-700",
                            size: "1.5rem"
                          })
                        ]),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["class"]),
                      _createVNode(_component_t_icon_button, {
                        class: _normalizeClass({ selected: $options.isFormatted('italic') }),
                        onClick: _cache[10] || (_cache[10] = ($event: any) => ($options.format('italic')))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_text_italic_linear, {
                            class: "text-grey-700",
                            size: "1.5rem"
                          })
                        ]),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["class"]),
                      _createVNode(_component_t_icon_button, {
                        class: _normalizeClass({ selected: $options.isFormatted('strikethrough') }),
                        onClick: _cache[11] || (_cache[11] = ($event: any) => ($options.format('strikethrough')))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_text_strikethrough_linear, {
                            class: "text-grey-700",
                            size: "1.5rem"
                          })
                        ]),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["class"]),
                      _createVNode(_component_t_icon_button, {
                        class: _normalizeClass({ selected: $options.isFormatted('inlineCode') }),
                        onClick: _cache[12] || (_cache[12] = ($event: any) => ($options.format('inlineCode')))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_code_linear, {
                            class: "text-grey-700",
                            size: "1.5rem"
                          })
                        ]),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["class"])
                    ], 64 /* STABLE_FRAGMENT */))
                  : _createCommentVNode("v-if", true),
                ($props.allowLinkFormatting)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      ($options.isFormatted('link'))
                        ? _withDirectives((_openBlock(), _createBlock(_component_t_icon_button, {
                            key: 0,
                            class: "mr-1",
                            onClick: $options.openExternalLink
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_external_link_linear, {
                                class: "text-grey-700",
                                size: "1.5rem"
                              })
                            ]),
                            _: 1 /* STABLE */
                          }, 8 /* PROPS */, ["onClick"])), [
                            [_directive_tooltip, { placement: 'top', content: 'Open link' }]
                          ])
                        : _createCommentVNode("v-if", true),
                      _withDirectives((_openBlock(), _createBlock(_component_t_icon_button, { onClick: $options.triggerLinkForm }, {
                        default: _withCtx(() => [
                          ($options.isFormatted('link'))
                            ? (_openBlock(), _createBlock(_component_paperclip_slash_linear, {
                                key: 0,
                                class: "text-grey-700",
                                size: "1.5rem"
                              }))
                            : (_openBlock(), _createBlock(_component_link1_linear, {
                                key: 1,
                                class: "text-grey-700",
                                size: "1.5rem"
                              }))
                        ]),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["onClick"])), [
                        [_directive_tooltip, { placement: 'top', content: $options.isFormatted('link') ? 'Unlink' : 'Insert link' }]
                      ])
                    ]))
                  : _createCommentVNode("v-if", true)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(_component_t_input_text, {
                  modelValue: $data.selectedText,
                  "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => (($data.selectedText) = $event)),
                  size: "sm",
                  placeholder: "Text"
                }, null, 8 /* PROPS */, ["modelValue"]),
                _createVNode(_component_t_input_text, {
                  modelValue: $data.url,
                  "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => (($data.url) = $event)),
                  size: "sm",
                  placeholder: "Add url"
                }, {
                  prefix: _withCtx(() => [
                    _createVNode(_component_link1_linear, {
                      class: "text-grey-700",
                      size: "1.5rem"
                    })
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["modelValue"]),
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_t_button, {
                    disabled: $data.url.length === 0 || $data.selectedText.length === 0,
                    size: "sm",
                    onClick: _cache[15] || (_cache[15] = ($event: any) => ($options.format('link')))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Add link ")
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["disabled"])
                ])
              ]))
        ], 4 /* STYLE */)), [
          [_directive_on_click_outside, $options.clickedOutside]
        ])
      : _createCommentVNode("v-if", true)
  ]))
}