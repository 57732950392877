import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "select-none pl-3 pt-4" }
const _hoisted_2 = { style: {"width":"20px"} }
const _hoisted_3 = { style: {"width":"20px"} }
const _hoisted_4 = { style: {"width":"20px"} }
const _hoisted_5 = { style: {"width":"20px"} }
const _hoisted_6 = { style: {"width":"20px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FolderLinear = _resolveComponent("FolderLinear")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_collapse = _resolveComponent("collapse")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_collapse, {
      "collapse-key": "collapseContacts",
      "default-open": true,
      "hide-arrow-icon": true
    }, {
      "collapse-title": _withCtx(() => [
        _createElementVNode("div", null, [
          _createElementVNode("h6", null, _toDisplayString(_ctx.$t('contacts.title')), 1 /* TEXT */)
        ])
      ]),
      "collapse-content": _withCtx(() => [
        _createElementVNode("div", null, [
          ($options.shouldShowLink)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: "/contacts/all",
                class: "collapse-menu-item selector-filter-all-contacts t-text-sm flex cursor-pointer items-center rounded",
                "active-class": "bg-grey-200 text-grey-900"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_FolderLinear)
                  ]),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('contacts.all_contacts')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }))
            : _createCommentVNode("v-if", true),
          ($options.shouldShowLink)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 1,
                class: "collapse-menu-item t-text-sm flex items-center rounded text-grey-600",
                "active-class": "bg-grey-200 text-grey-900",
                to: "/sms/import"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_FolderLinear)
                  ]),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('sms.import_contacts')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }))
            : _createCommentVNode("v-if", true),
          ($options.hasPrivateChannels)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 2,
                to: "/contacts/mine",
                class: "collapse-menu-item selector-filter-my-contacts t-text-sm flex cursor-pointer items-center rounded",
                "active-class": "bg-grey-200 text-grey-900"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_FolderLinear)
                  ]),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('contacts.my_contacts')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }))
            : _createCommentVNode("v-if", true)
        ])
      ]),
      _: 1 /* STABLE */
    }),
    ($options.shouldShowLink)
      ? (_openBlock(), _createBlock(_component_collapse, {
          key: 0,
          "collapse-key": "collapseContacts",
          class: "mt-6",
          "default-open": true,
          "hide-arrow-icon": true
        }, {
          "collapse-title": _withCtx(() => [
            _createElementVNode("div", null, [
              _createElementVNode("h6", null, _toDisplayString(_ctx.$t('profile.filter_profiles')), 1 /* TEXT */)
            ])
          ]),
          "collapse-content": _withCtx(() => [
            _createElementVNode("div", null, [
              _createVNode(_component_router_link, {
                to: "/profiles",
                class: "collapse-menu-item selector-filter-all-profiles t-text-sm flex cursor-pointer items-center rounded",
                "active-class": "bg-grey-200 text-grey-900"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_FolderLinear)
                  ]),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('profile.all_profiles')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              })
            ])
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    ($options.contactGroupsFeatureFlagEnabled && $options.shouldShowLink)
      ? (_openBlock(), _createBlock(_component_collapse, {
          key: 1,
          "collapse-key": "collapseContacts",
          class: "mt-6",
          "default-open": true,
          "hide-arrow-icon": true
        }, {
          "collapse-title": _withCtx(() => [
            _createElementVNode("div", null, [
              _createElementVNode("h6", null, _toDisplayString(_ctx.$t('broadcast.contact_group')), 1 /* TEXT */)
            ])
          ]),
          "collapse-content": _withCtx(() => [
            _createElementVNode("div", null, [
              _createVNode(_component_router_link, {
                to: "/contact_groups",
                class: "collapse-menu-item selector-filter-all-profiles t-text-sm flex cursor-pointer items-center rounded",
                "active-class": "bg-grey-200 text-grey-900"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_FolderLinear)
                  ]),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('broadcast.all_contact_groups')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              })
            ])
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true)
  ]))
}