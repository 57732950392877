import { PERMISSION } from '@/Configs/Constants';

import type { RouteRecord } from '../types';

const emailThemeRoutes: RouteRecord[] = [
  {
    path: '/admin/email_themes',
    component: () => import(/* webpackChunkName: "EmailTheme" */ '../../components/EmailTheme/Index.vue'),
    meta: { permissionRequired: PERMISSION.SETTINGS__CHANNELS__MANAGE },
    children: [
      {
        path: '/admin/email_themes/create',
        component: () => import(/* webpackChunkName: "EmailTheme" */ '../../components/EmailTheme/Edit.vue'),
        props: { action: 'create' },
        meta: { permissionRequired: PERMISSION.SETTINGS__CHANNELS__MANAGE },
      },
      {
        path: '/admin/email_themes/:id',
        component: () => import(/* webpackChunkName: "EmailTheme" */ '../../components/EmailTheme/Edit.vue'),
        props: { action: 'edit' },
        meta: { permissionRequired: PERMISSION.SETTINGS__CHANNELS__MANAGE },
      },
    ],
  },
];

export default emailThemeRoutes;
