import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, withCtx as _withCtx, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2cf7bf56"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "create-broadcast flex h-full w-full flex-col justify-center bg-grey-200" }
const _hoisted_2 = { class: "flex h-full w-full items-center justify-center gap-3 px-4 py-4" }
const _hoisted_3 = { class: "my-4 flex h-full w-full min-w-[480px] max-w-[800px] flex-col items-start justify-center self-center rounded-xl border border-solid border-grey-300 bg-white" }
const _hoisted_4 = { class: "flex w-full flex-1 flex-col gap-6 overflow-y-auto p-8" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "whitespace-nowrap" }
const _hoisted_8 = {
  key: 0,
  class: "flex h-full w-full min-w-[320px] max-w-[464px] items-center justify-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_create_broadcast_header = _resolveComponent("create-broadcast-header")!
  const _component_create_broadcast_form = _resolveComponent("create-broadcast-form")!
  const _component_wab_recipients = _resolveComponent("wab-recipients")!
  const _component_t_error_item = _resolveComponent("t-error-item")!
  const _component_recipients_warning_banner = _resolveComponent("recipients-warning-banner")!
  const _component_schedule_send_date = _resolveComponent("schedule-send-date")!
  const _component_cross_octagon_linear = _resolveComponent("cross-octagon-linear")!
  const _component_external_link2_linear = _resolveComponent("external-link2-linear")!
  const _component_t_button = _resolveComponent("t-button")!
  const _component_t_inline_banner = _resolveComponent("t-inline-banner")!
  const _component_create_broadcast_footer = _resolveComponent("create-broadcast-footer")!
  const _component_template_preview = _resolveComponent("template-preview")!
  const _component_whatsapp_disclaimer_modal = _resolveComponent("whatsapp-disclaimer-modal")!
  const _component_confirm_broadcast_modal = _resolveComponent("confirm-broadcast-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_create_broadcast_header, { title: _ctx.titleLabel }, null, 8 /* PROPS */, ["title"]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_create_broadcast_form, {
            name: _ctx.name,
            "onUpdate:name": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
            sender: _ctx.sender,
            "onUpdate:sender": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.sender) = $event)),
            "search-value": _ctx.searchValue,
            "onUpdate:searchValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.searchValue) = $event)),
            "template-id": _ctx.templateId,
            "onUpdate:templateId": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.templateId) = $event)),
            tags: _ctx.tags,
            "onUpdate:tags": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.tags) = $event)),
            message: _ctx.message,
            "onUpdate:message": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.message) = $event)),
            "sender-object": _ctx.senderObject,
            "computed-filtered-senders": _ctx.computedFilteredSenders,
            senders: _ctx.senders,
            "requires-whatsapp-upgrade": _ctx.requiresWhatsappUpgrade,
            "is-sms-enabled": _ctx.isSmsEnabled,
            "is-editing-broadcast": _ctx.isEditingBroadcast,
            validation: _ctx.validation,
            "wa-templates": _ctx.waTemplates,
            template: _ctx.template,
            "tags-added": _ctx.tagsAdded,
            action: _ctx.action,
            onOnUpdateTags: _ctx.onUpdateTags,
            onOnUpdateTemplateMedia: _ctx.onUpdateTemplateMedia
          }, null, 8 /* PROPS */, ["name", "sender", "search-value", "template-id", "tags", "message", "sender-object", "computed-filtered-senders", "senders", "requires-whatsapp-upgrade", "is-sms-enabled", "is-editing-broadcast", "validation", "wa-templates", "template", "tags-added", "action", "onOnUpdateTags", "onOnUpdateTemplateMedia"]),
          _createElementVNode("div", null, [
            _createVNode(_component_wab_recipients, {
              modelValue: _ctx.recipientsLocal,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.recipientsLocal) = $event)),
              error: !_ctx.validation.recipients,
              "show-limit": _ctx.senderObject?.type === _ctx.CHANNEL_TYPE.WA_BUSINESS,
              "is-editing": _ctx.isEditingBroadcast
            }, null, 8 /* PROPS */, ["modelValue", "error", "show-limit", "is-editing"]),
            (!_ctx.validation.recipients)
              ? (_openBlock(), _createBlock(_component_t_error_item, {
                  key: 0,
                  "data-test": "recipients-error",
                  class: "mb-1 mt-1",
                  text: 
                _ctx.$t('broadcast.recipients_error', {
                  date: _ctx.dateRadio === 'now' ? _ctx.$t('general.send').toLowerCase() : _ctx.$t('general.schedule').toLowerCase(),
                })
              
                }, null, 8 /* PROPS */, ["text"]))
              : _createCommentVNode("v-if", true),
            _createVNode(_component_recipients_warning_banner, {
              modelValue: _ctx.showRecipientsWarning,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.showRecipientsWarning) = $event))
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _createVNode(_component_schedule_send_date, {
            date: _ctx.date,
            "onUpdate:date": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.date) = $event)),
            time: _ctx.time,
            "onUpdate:time": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.time) = $event)),
            schedule: _ctx.dateRadio,
            "onUpdate:schedule": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.dateRadio) = $event)),
            validation: _ctx.validation
          }, null, 8 /* PROPS */, ["date", "time", "schedule", "validation"]),
          (_ctx.insufficientBalance)
            ? (_openBlock(), _createBlock(_component_t_inline_banner, {
                key: 0,
                type: "error",
                class: "tags-warning mt-12",
                "data-test": "insufficient-balance-warning"
              }, {
                icon: _withCtx(() => [
                  _createVNode(_component_cross_octagon_linear)
                ]),
                action: _withCtx(() => [
                  _createVNode(_component_t_button, {
                    class: "flex items-center",
                    size: "sm",
                    "btn-style": "secondary",
                    "data-test": "topup-button",
                    onClick: _ctx.topUp
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('broadcast.top_up')), 1 /* TEXT */),
                      _createVNode(_component_external_link2_linear, {
                        size: "14px",
                        class: "info-icon ml-2 text-grey-900"
                      })
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["onClick"])
                ]),
                default: _withCtx(() => [
                  (_ctx.userStore.hasPermission(_ctx.PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$t('broadcast.you_dont_have_enough_balance_to_send_this_broadcast')), 1 /* TEXT */))
                    : _createCommentVNode("v-if", true),
                  (!_ctx.userStore.hasPermission(_ctx.PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.$t('broadcast.broadcast_not_enough_balance_contact_admin_error')), 1 /* TEXT */))
                    : _createCommentVNode("v-if", true)
                ]),
                _: 1 /* STABLE */
              }))
            : _createCommentVNode("v-if", true)
        ]),
        _createVNode(_component_create_broadcast_footer, {
          "formatted-amount": _ctx.formattedAmount,
          "cost-label": _ctx.costLabel,
          "insufficient-balance": _ctx.insufficientBalance,
          "submit-label": _ctx.submitLabel,
          onCancel: _ctx.handleGoBack,
          onSubmit: _ctx.handleSubmit
        }, null, 8 /* PROPS */, ["formatted-amount", "cost-label", "insufficient-balance", "submit-label", "onCancel", "onSubmit"])
      ]),
      _createVNode(_Transition, {
        name: "template-preview",
        mode: "out-in"
      }, {
        default: _withCtx(() => [
          (_ctx.template?.id)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createVNode(_component_template_preview, {
                  "is-create-mode": true,
                  "selected-header-type": _ctx.templateHeader?.sub_type?.toLowerCase(),
                  "template-header": _ctx.previewHeader,
                  "template-body": _ctx.templateBody,
                  "template-footer": _ctx.templateFooter,
                  buttons: _ctx.templateButtons
                }, null, 8 /* PROPS */, ["selected-header-type", "template-header", "template-body", "template-footer", "buttons"])
              ]))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _createVNode(_component_whatsapp_disclaimer_modal, {
      modelValue: _ctx.whatsappDisclaimerModalIsOpen,
      "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.whatsappDisclaimerModalIsOpen) = $event)),
      onSubmit: _ctx.disclaimerAccepted
    }, null, 8 /* PROPS */, ["modelValue", "onSubmit"]),
    _createVNode(_component_confirm_broadcast_modal, {
      modelValue: _ctx.confirmBroadcastModalIsOpen,
      "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.confirmBroadcastModalIsOpen) = $event)),
      "sender-type": _ctx.senderObject && _ctx.senderObject.type,
      "message-length": _ctx.stripHtml(_ctx.message).length,
      scheduled: _ctx.dateRadio === 'later',
      "tags-added": _ctx.tagsAdded,
      "send-date": _ctx.sendDateModalLabel,
      "total-amount": _ctx.amount,
      balance: _ctx.balance,
      "recipients-quantity": _ctx.recipients.contacts.length + _ctx.recipients.numbers.length,
      "is-confirm-btn-disabled": _ctx.isConfirmBtnDisabled,
      onSubmit: _ctx.confirmBroadcast
    }, null, 8 /* PROPS */, ["modelValue", "sender-type", "message-length", "scheduled", "tags-added", "send-date", "total-amount", "balance", "recipients-quantity", "is-confirm-btn-disabled", "onSubmit"])
  ]))
}