import { ENDPOINT } from '@/api/constants';
import { request } from '@/util';

import type { Channel } from '@/types';
import type Contact from '@/types/contact';
import type { AxiosPromise } from 'axios';

type TokenResponse = {
  token: string;
};

export type VoipContact = {
  id: number;
  color: string;
  abbr: string;
  profile_image: string;
  name: string;
  phone: string;
  phone_international: string;
};

export type CallHistoryItem = {
  id: number;
  agency_id: number;
  channel_id: number;
  contact_id: number;
  ticket_id: number;
  user_id: number;
  token: string;
  type: 'OUTBOUND' | 'INBOUND'; // Perhaps there's more types?
  from: string;
  to: string;
  conference_sid: string;
  status: 'COMPLETED'; // Presumably there's more statuses?
  leader_sid: string;
  pricing: string;
  duration: number;
  recording_url: string;
  recording_sid: string;
  created_at: string;
  updated_at: string;
  formatted_date: string;
  formatted_duration: string; // The one I see is in the format 45s, 1m45s etc.
  contact: Contact;
  channel: Channel;

  // These 2 came in as `null` on every request I checked, but I might've missed the real type somewhere.
  picked_up_from_queue_at?: unknown;
  failure_reason?: unknown;
};

export function fetchVoipToken(channelId?: number): AxiosPromise<TokenResponse> {
  return request(ENDPOINT.VOIP_TOKEN, 'GET', {}, {}, { channel_id: channelId });
}

export function fetchContacts(query: string): AxiosPromise<VoipContact[]> {
  return request(ENDPOINT.VOIP_CONTACTS, 'GET', {}, {}, { search: query });
}

export function fetchCallHistory(): AxiosPromise<CallHistoryItem[]> {
  return request(ENDPOINT.VOIP_CALL_HISTORY, 'GET');
}
