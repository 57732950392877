<template>
  <div>
    <div v-if="mode === notesMode.LIST" class="mb-4">
      <a class="btn btn-block btn-default mb-4" @click="setCreateMode()">Create engagement</a>
      <div v-show="notes.length > 0" class="box">
        <div class="box-body">
          <div
            v-for="note in notes"
            :key="note.note_id"
            class="b-b no-p-space mb-2 break-words"
            @mouseover="hoverIndex = note.note_id"
            @mouseout="hoverIndex = null"
          >
            <div class="flex">
              <span class="text-muted" style="min-height: 21px">{{ getOwner(note.owner_id).full_name }}</span>
              <span v-show="hoverIndex == note.note_id" class="text-muted ml-auto">
                <a v-if="settings.updateNotes === true" @click="editNote(note)">
                  <i class="fa fa-pencil text-xs"></i>
                </a>
                <a v-if="settings.deleteNotes === true" @click="deleteNote(note)">
                  <i class="fa fa-remove text-xs"></i>
                </a>
              </span>
            </div>
            <div class="text-muted mt-1 text-xs">
              {{ note.date }}
            </div>
            <div class="mb-2 mt-1">
              <i v-show="settings.hasNotesSubject">{{ note.subject }}</i>
              <div v-html="note.body"></div>
            </div>
          </div>
        </div>
      </div>
      <template v-if="loading">
        <Loader></Loader>
      </template>
    </div>

    <div v-if="[notesMode.EDIT, notesMode.CREATE].includes(mode)">
      <div class="box">
        <div class="box-body">
          <form @submit.prevent="onSubmit">
            <div v-show="settings.hasNotesSubject" class="form-group">
              <label class="text-muted mb-1">Subject</label>
              <input v-model="form.subject" class="form-control" />
            </div>
            <div class="form-group">
              <label class="text-muted mb-1">Note</label>
              <textarea v-model="form.body" class="form-control" rows="4"></textarea>
            </div>
            <div v-show="settings.hasCustomNoteOwner" class="form-group">
              <label class="text-muted mb-1">Owner</label>
              <select v-model="form.owner_id" class="form-control mb-2">
                <option v-for="owner in owners" :key="owner" :value="owner.owner_id">
                  {{ owner.full_name }} ({{ owner.email }})
                </option>
              </select>
            </div>
            <button
              class="btn success btn-block mt-2 text-white"
              type="submit"
              :disabled="saving"
              :class="{ loader: saving }"
            >
              Save
            </button>
          </form>
        </div>
      </div>
      <div class="text-center">
        <a class="text-muted text-xs" @click="cancel()">Cancel</a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Loader from '@/components/Integrations/Loader.vue';
import { NOTES_MODE } from '@/Configs/Constants';

import type { NotesMode } from '@/types/integrations';

export default {
  name: 'CrmNotes',

  components: {
    Loader,
  },
  props: {
    plugin: {
      type: Object,
      default: () => ({}),
    },
    ticket: {
      type: Object,
      default: () => ({}),
    },
    settings: {
      type: Object,
      default: () => ({}),
    },
    owners: {
      type: Array,
      default: () => [],
    },
    getOwner: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      mode: NOTES_MODE.LIST as NotesMode,
      notes: [],
      form: {},
      hoverIndex: null,
      saving: false,
      loading: true,
      notesMode: NOTES_MODE,
    };
  },

  mounted() {
    this.resetForm();
    this.loadNotes();
  },

  methods: {
    loadNotes() {
      axios
        .get('/api/v2/integrations/crm/notes?plugin_id=' + this.plugin.id + '&ticket_id=' + this.ticket.id)
        .then((res) => {
          this.notes = res.data;
          this.loading = false;
        });
    },

    async deleteNote(note) {
      const shouldDeleteNote = await this.$tConfirm('Are you sure you want to delete this note?', {
        delete: true,
        title: this.$t('general.are_you_sure'),
      });
      if (shouldDeleteNote) {
        axios.delete('/api/v2/integrations/crm/notes/' + note.note_id + '?plugin_id=' + this.plugin.id).then(() => {
          this.loadNotes();
        });
      }
    },

    editNote(note) {
      this.form = {
        id: note.note_id,
        body: note.body,
        subject: note.subject,
        // type: engagement.engagement.type,
        owner_id: note.owner_id,
      };

      this.mode = this.notesMode.EDIT;
    },

    onSubmit() {
      if (this.saving || this.form.body.length === 0) {
        return;
      }

      this.saving = true;

      const url =
        this.mode === this.notesMode.EDIT
          ? '/api/v2/integrations/crm/notes/' +
            this.form.id +
            '?plugin_id=' +
            this.plugin.id +
            '&ticket_id=' +
            this.ticket.id
          : '/api/v2/integrations/crm/notes?plugin_id=' + this.plugin.id + '&ticket_id=' + this.ticket.id;
      const method = this.mode === this.notesMode.EDIT ? 'put' : 'post';

      axios[method](url, this.form)
        .then(() => {
          this.form.body = '';
          this.mode = this.notesMode.LIST;
          this.resetForm();
          this.loadNotes();
          this.saving = false;
        })
        .catch(() => {
          this.saving = false;
        });
    },

    resetForm() {
      this.form = {
        id: null,
        type: 'NOTE',
        status: 'NOT_STARTED',
        body: '',
        owner_id: '',
        startTime: '',
      };
    },

    cancel() {
      this.resetForm();
      this.mode = this.notesMode.LIST;
    },

    setCreateMode() {
      this.mode = this.notesMode.CREATE;
    },
  },
};
</script>
