import { ROUTE_NAME } from '../constants';

import type { RouteRecord } from '../types';

const unauthorizedRoutes: RouteRecord[] = [
  {
    name: ROUTE_NAME.UNAUTHORIZED,
    path: '/unauthorized',
    component: () => import(/* webpackChunkName: "Unauthorized" */ '@/components/common/Unauthorized'),
    meta: { allowUnauthenticated: true },
  },
];

export default unauthorizedRoutes;
