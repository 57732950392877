import { uploadFile } from '../api';

import type { FileResponse } from '@/api';
import type { File } from '@/types';

export const saveFile = async (formData: File): Promise<FileResponse | void> => {
  try {
    const response = await uploadFile(formData);
    return response.data;
  } catch (error: unknown) {
    if (error instanceof Error) throw new Error('Error while uploading file', error);
  }
};

/* FIXME:
 * there are multiple implementations of this function across the codebase.
 * This particular one used to be a global utility on window, but was only
 * used inside the Composer. Since differences between all the implementations
 * are really tricky to test, I'm leaving this as is, but moving it to the Composer
 * scope. */
export const convertHTMLToText = (value: string) =>
  value
    // Convert `&amp;` to `&`.
    .replace(/&amp;/gi, '&')
    // Replace spaces.
    .replace(/&nbsp;/gi, ' ')
    .replace(/\s+/g, ' ')
    // Remove "<b>".
    .replace(/<b>/gi, '')
    .replace(/<\/b>/gi, '')
    // Remove "<strong>".
    .replace(/<strong>/gi, '')
    .replace(/<\/strong>/gi, '')
    // Remove "<i>".
    .replace(/<i>/gi, '')
    .replace(/<\/i>/gi, '')
    // Remove "<em>".
    .replace(/<em>/gi, '')
    .replace(/<\/em>/gi, '')
    // Remove "<u>".
    .replace(/<u>/gi, '')
    .replace(/<\/u>/gi, '')
    // Tighten up "<" and ">".
    .replace(/>\s+/g, '>')
    .replace(/\s+</g, '<')
    // Replace "<br>".
    .replace(/<br>/gi, '\n')
    .replace(/<br \/>/gi, '\n')
    .replace(/<br\/>/gi, '\n')
    // Replace "<div>" (from Chrome).
    .replace(/<div>/gi, '\n')
    .replace(/<\/div>/gi, '')
    // Replace "<p>" (from IE).
    .replace(/<p>/gi, '\n')
    .replace(/<\/p>/gi, '')
    // No more than 2x newline, per "paragraph".
    .replace(/\n\n+/g, '\n\n')
    // Whitespace before/after.
    .trim();

export const stripHtmlToString = (string: string | null | undefined): string => {
  if (!string) {
    return '';
  }
  // Match only valid HTML tags (e.g., <tag>, </tag>, <tag attr="value">)
  return string.replace(/<\/?[a-z][\w-]*(\s+[a-z][\w-]*(="[^"]*")?)*\s*\/?>/gi, '');
};
