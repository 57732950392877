import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "CreateTicketModal",
  ref: "modal",
  class: "modal animated fadeIn",
  role: "dialog",
  style: {"animation-duration":"0.3s"}
}
const _hoisted_2 = {
  class: "modal-centered modal-dialog",
  style: {"height":"90vh","overflow-y":"auto","transform":"translate(-50%, -50%)","margin":"0"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_composer = _resolveComponent("composer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.open)
        ? (_openBlock(), _createBlock(_component_composer, {
            key: 0,
            "new-ticket": true,
            "default-channel": _ctx.defaultChannel,
            "composer-instance-id": "create-ticket-composer",
            "draft-key": "new",
            "default-contact": _ctx.defaultContact,
            path: "/tickets/create",
            onPopout: _ctx.closeModal,
            onSent: _ctx.onSent
          }, null, 8 /* PROPS */, ["default-channel", "default-contact", "onPopout", "onSent"]))
        : _createCommentVNode("v-if", true)
    ])
  ], 512 /* NEED_PATCH */))
}