import { PERMISSION } from '@/Configs/Constants';

import type { RouteRecord } from '../types';

const customViewsRoutes: RouteRecord[] = [
  {
    path: '/admin/custom_views',
    component: () => import(/* webpackChunkName: "CustomViews" */ '../../components/CustomViews/Index.vue'),
    meta: { permissionRequired: PERMISSION.SETTINGS__VIEWS__MANAGE },
    children: [
      {
        path: '/admin/custom_views/create',
        component: () => import(/* webpackChunkName: "CustomViews" */ '../../components/CustomViews/Edit.vue'),
        props: { action: 'create' },
        meta: { permissionRequired: PERMISSION.SETTINGS__VIEWS__MANAGE },
      },
      {
        path: '/admin/custom_views/:id',
        component: () => import(/* webpackChunkName: "CustomViews" */ '../../components/CustomViews/Edit.vue'),
        props: { action: 'edit' },
        meta: { permissionRequired: PERMISSION.SETTINGS__VIEWS__MANAGE },
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "CustomViews" */ '../../components/AdminIndexBox.vue'),
        props: { boxName: 'views' },
        meta: { permissionRequired: PERMISSION.SETTINGS__VIEWS__MANAGE },
      },
    ],
  },
];

export default customViewsRoutes;
