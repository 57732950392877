<template>
  <div class="flex-1 flex flex-col h-full overflow-y-auto px-2">
    <p class="rounded-lg bg-grey-800 text-grey-400 p-3 py-2 m-0 mb-4 w-fit">History</p>

    <div v-for="item in historyItems" :key="item.id" class="flex items-center border-b border-grey-800 p-2 min-w-0">
      <button
        class="flex items-center rounded-full bg-leaf-500 p-2 mr-3 hover:bg-leaf-600 transition-colors active:bg-leaf-400"
        @click.prevent="emit('call', item)"
      >
        <phone-linear size="16" />
      </button>

      <div class="flex flex-col min-w-0 w-full">
        <div class="truncate">
          {{ item?.contact?.full_name ?? item.contact.formatted_phone }}
        </div>

        <div class="flex items-center w-full">
          <div class="mr-2 text-leaf-500">
            <call-incoming-fill v-if="item.type === 'INBOUND'" />
            <call-outgoing-fill v-else />
          </div>

          <p class="truncate text-grey-500 m-0 p-0 mr-2">{{ item.channel.title }}</p>

          <div class="flex items-center text-grey-500 m-0 ml-auto p-0">
            {{ item.formatted_date }}

            <span class="ml-1">
              <time-linear />
            </span>
          </div>
        </div>
      </div>
    </div>

    <div v-if="historyItems.length === 0">No call history has been found</div>
  </div>
</template>

<script setup lang="ts">
import { PhoneLinear, CallIncomingFill, CallOutgoingFill, TimeLinear } from '@trengo/trengo-icons';
import { onMounted, ref } from 'vue';

import { type CallHistoryItem, fetchCallHistory } from '@/api/modules/voip';

const emit = defineEmits<{
  (e: 'call', item: CallHistoryItem): void;
}>();

const historyItems = ref<CallHistoryItem[]>([]);
const isLoading = ref(false);

onMounted(() => {
  isLoading.value = true;

  fetchCallHistory()
    .then((response) => (historyItems.value = response.data))
    .finally(() => (isLoading.value = false));
});
</script>
