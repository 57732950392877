<template>
  <header
    class="relative flex w-full items-center border-b-1 border-grey-300 px-8 py-6"
    data-test="create-broadcast-header"
  >
    <div class="header-content">
      <router-link :to="'/broadcasting'" class="pointer flex items-center gap-6" data-test="back-button">
        <arrow-left-linear class="inline-block text-grey-600" size="1.25rem" />
        <h3 class="t-text-h4 mb-0">{{ title }}</h3>
      </router-link>
    </div>
  </header>
</template>

<script setup lang="ts">
import { ArrowLeftLinear } from '@trengo/trengo-icons';

defineProps<{
  title: string;
}>();
</script>
