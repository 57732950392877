<script setup lang="ts">
import { EditFill, PlusLinear } from '@trengo/trengo-icons';
import { ref, computed } from 'vue';

import AddChannelModal from '@/components/OverviewPage/components/AddChannel/AddChannelModal';
import { CHANNEL_TYPE, PERMISSION } from '@/Configs/Constants';
import { useChannelsStore, useUserStore } from '@/store/pinia';

import type { Channel } from '@/store/pinia/channels/types';

const userStore = useUserStore();
const channelsStore = useChannelsStore();

const openAddChannel = ref(false);
const userHasCreatedChannels = computed(() =>
  Boolean(
    channelsStore.channels?.filter((channel: Channel) => {
      return channel.type !== CHANNEL_TYPE.HELP_CENTER && channel.created_by_user;
    })?.length,
  ),
);
const assetsPath = ref(Assets_path);

const toggleAddChannelModal = () => {
  openAddChannel.value = !openAddChannel.value;
};

const createConversation = () => {
  window.eventBus.$emit('modals.create-ticket.open');
};
</script>

<template>
  <div class="hidden h-full flex-1 select-none flex-col items-center justify-center text-center lg:flex">
    <img
      loading="lazy"
      :src="`${assetsPath}img/no-tickets-empty-state.svg`"
      alt="Add your first channel to start exploring"
      class="min-w-64 min-h-auto"
    />

    <div v-if="userHasCreatedChannels" class="m-6">
      <p class="t-text-h4 m-0 mt-4 text-grey-800">{{ $t('tickets.engage_with_customers') }}</p>
      <p class="t-text-md m-0 mt-2 text-grey-600">{{ $t('tickets.no_ticket_selected') }}</p>
      <t-button
        v-if="userStore.hasPermission(PERMISSION.INBOX__CONVERSATIONS__CREATE)"
        type="button"
        class="hidden-md-down selector-create-conversation m-auto mt-6 flex items-center"
        data-test="start-conversation-button-placeholder"
        @click="createConversation()"
      >
        <edit-fill class="mr-1" size="16" />
        {{ $t('tickets.start_conversation') }}
      </t-button>
      <add-channel-modal v-model="openAddChannel" />
    </div>
    <div v-else class="m-6">
      <p class="t-text-h4 m-0 mt-4 text-grey-800">{{ $t('tickets.welcome_to_trengo') }}</p>
      <p class="t-text-md m-0 mt-2 text-grey-600">{{ $t('tickets.welcome_to_trengo_description') }}</p>
      <t-button
        v-if="userStore.hasPermission(PERMISSION.SETTINGS__CHANNELS__MANAGE)"
        class="m-auto mt-6 inline-flex items-center"
        @click="toggleAddChannelModal"
      >
        <plus-linear size="1rem" class="mr-1" />
        {{ $t('channels.create') }}
      </t-button>
      <add-channel-modal v-model="openAddChannel" />
    </div>
  </div>
</template>
