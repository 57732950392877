<template>
  <div class="h-full flex flex-col">
    <!-- Keypad grid -->
    <div class="flex-1 grid grid-cols-3 gap-2 pt-4 pb-2 px-8">
      <button
        v-for="key in keys"
        :key="key.value"
        type="button"
        class="flex flex-col items-center justify-center cursor-pointer text-white transition-colors select-none active:!text-grey-700 hover:!text-grey-500"
        @click="handleKeyPress(key.value)"
      >
        <span class="text-2xl">{{ key.value }}</span>
        <span class="text-xs text-grey-500">
          <!-- The nbsp is a small hack to ensure the number is top-aligned when there's no letters -->
          {{ key?.letters ?? '&nbsp;' }}
        </span>
      </button>

      <div class="flex items-center col-start-2">
        <button
          class="mx-auto p-4 flex items-center justify-center bg-leaf-500 rounded-full transition-colors hover:bg-leaf-600 active:bg-leaf-700"
        >
          <phone-fill size="24" class="text-white" />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PhoneFill } from '@trengo/trengo-icons';

const keys = [
  { value: '1', letters: null },
  { value: '2', letters: 'ABC' },
  { value: '3', letters: 'DEF' },
  { value: '4', letters: 'GHI' },
  { value: '5', letters: 'JKL' },
  { value: '6', letters: 'MNO' },
  { value: '7', letters: 'PQRS' },
  { value: '8', letters: 'TUV' },
  { value: '9', letters: 'WXYZ' },
  { value: '*', letters: null },
  { value: '0', letters: '+' },
  { value: '#', letters: null },
];

const numberModel = defineModel<string>('number', { required: true });

function handleKeyPress(value: string) {
  numberModel.value = (numberModel.value || '') + value;
}
</script>
