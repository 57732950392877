<template>
  <div class="h-full w-full overflow-auto bg-grey-100">
    <reporting-page :heading-title="$t('reports_v2.agent_performance')" class="h-full w-full overflow-scroll">
      <template #right>
        <div class="flex flex-row items-center">
          <date-filter-dropdown
            class="w-min"
            :timezone="$root.user.timezone"
            :default-label="$t('reports_v2.last_seven_days')"
            :default-time="interval"
            @change="handleGetIntervals"
          />

          <t-button class="w-fit flex-1" size="sm" @click="exportReport">
            {{ $t('reports_v2.download_table') }}
          </t-button>
        </div>
      </template>

      <div v-show="data.length" class="mt-4 h-screen overflow-auto" data-test="agents-table">
        <div
          ref="agents-table"
          class="relative w-max overflow-hidden rounded-2xl border-1 border-grey-300"
          style="min-width: 100%"
        >
          <t-table-list v-if="data.length" alternate class="p-0" :has-border="false">
            <t-table-list-row head data-test="agents-table-header">
              <t-table-list-head>{{ $t('reports_v2.agent') }}</t-table-list-head>
              <t-table-list-head class="pr-4 text-right">
                <p class="m-0 mr-1">{{ $t('reports_v2.first_resolution_time_without_business_hours') }}</p>
              </t-table-list-head>
              <t-table-list-head class="pr-4 text-right">
                {{ $t('reports_v2.total_resolution_time_without_business_hours') }}
              </t-table-list-head>
              <t-table-list-head class="pr-4 text-right">
                {{ $t('reports_v2.first_response_time_without_business_hours') }}
              </t-table-list-head>
              <t-table-list-head class="pr-4 text-right">
                {{ $t('reports_v2.assigned_tickets') }}
              </t-table-list-head>
              <t-table-list-head class="pl-4 text-right">
                {{ $t('reports_v2.messages_sent') }}
              </t-table-list-head>
              <t-table-list-head class="pl-4 text-right">
                {{ $t('reports_v2.closed_tickets') }}
              </t-table-list-head>
              <t-table-list-head class="pl-4 pr-4 text-right">
                {{ $t('reports_v2.internal_comments') }}
              </t-table-list-head>
            </t-table-list-row>

            <agent-row
              v-for="(item, index) in data"
              :key="index"
              :within-business-hours="isWithinBusinessHours"
              :available-data="item"
            />
            <t-table-list-row v-if="hasSpinner">
              <single-row-skeleton
                :style="`width:${tableWidth}px`"
                data-test="single-row-skeleton"
                class="absolute px-4"
                :animation="true"
                :show-error="false"
              />
            </t-table-list-row>
          </t-table-list>
        </div>
        <infinite-loading @infinite="fetchMoreData">
          <template #spinner>
            <div></div>
          </template>
          <template #no-more>
            <div></div>
          </template>
          <template #no-results>
            <div></div>
          </template>
        </infinite-loading>
      </div>
      <table-skeleton v-if="!data.length" :animation="!loaded" :show-error="!data.length && loaded" />
    </reporting-page>
  </div>
</template>

<script lang="ts">
import moment from 'moment';
import { defineComponent } from 'vue';
import infiniteLoading from 'vue-infinite-loading';

import ReportingPage from '@/components/common/ReportingPage';
import TableSkeleton from '@/components/common/TableSkeleton/Index.vue';
import SingleRowSkeleton from '@/components/common/TableSkeleton/SingleRowSkeleton.vue';
import DateFilterDropdown from '@/components/Reporting/Components/V2/DateFilterDropdown/DateFilterDropdown.vue';
import { useReportStore } from '@/components/Reporting/store';
import eventBus from '@/eventBus';
import { useToastMessageStore } from '@/store/pinia/toastMessage';

import AgentRow from './AgentRow.vue';
import { callExportAPI, fetchAgents, fetchPerformanceReportExport } from './api';
import { useTicketInsightsStore } from './store';
import { manipulateThePageColumns, DefaultAvailableColumns, AvailableColumnsMapper } from './utils';
import { mapFilters } from '../WorkloadManagement/utils';

import type { Agent } from './utils';
import type { Moment } from 'moment';
import type { StateChanger } from 'vue-infinite-loading';

type AvailableColumns = typeof DefaultAvailableColumns;
type Data = {
  availableColumns: AvailableColumns;
  data: Agent[];
  page: number;
  loaded: boolean;
  hasSpinner: boolean;
  sort: { order_by: string; sort_by: string } | Record<string, never>;
  enableDownloadButton: boolean;
  ticketInsightsStore: ReturnType<typeof useTicketInsightsStore>;
  toastMessageState: ReturnType<typeof useToastMessageStore>;
  tableWidth: number;
  reportStore: ReturnType<typeof useReportStore>;
  filters: string;
  interval: {
    startDate: string;
    endDate: string;
  };
  isWithinBusinessHours: boolean;
};

type PusherData = {
  status: 'COMPLETED' | 'PENDING' | 'FAILED';
  file_path: string;
  export_id: number;
};

const availableColumns: AvailableColumns = DefaultAvailableColumns;

export default defineComponent({
  name: 'TicketInsights',
  components: {
    SingleRowSkeleton,
    ReportingPage,
    AgentRow,
    TableSkeleton,
    DateFilterDropdown,
    infiniteLoading,
  },
  data(): Data {
    return {
      availableColumns: availableColumns,
      data: [] as Agent[],
      page: 1,
      loaded: false,
      hasSpinner: false,
      sort: {},
      enableDownloadButton: true,
      ticketInsightsStore: useTicketInsightsStore(),
      toastMessageState: useToastMessageStore(),
      tableWidth: 0,
      reportStore: useReportStore(),
      filters: '',
      interval: {
        startDate: '',
        endDate: '',
      },
      isWithinBusinessHours: true,
    };
  },

  created() {
    this.fetchFiltersFromRoute();
    this.$nextTick(() => {
      this.tableWidth = this.$refs['agents-table'].clientWidth;
      this.ticketInsightsStore = useTicketInsightsStore();
      this.page = 1;
      this.loadData();
      if (this.$route?.name === 'reportingTicketInsightsSpecificPage') {
        this.availableColumns = manipulateThePageColumns(this.$route.params.page);
      } else {
        this.availableColumns = { ...availableColumns };
      }
      this.reportStore.$patch({
        reportingFilters: {
          hasLabel: true,
          hasBusinessHours: true,
          hasAgent: true,
        },
      });
    });

    eventBus.$on('reporting-filter', this.fetchDataByFilters);
  },
  unmounted() {
    this.availableColumns = DefaultAvailableColumns;
    this.reportStore.$patch({
      agentsAmount: 0,
      reportingFilters: {
        hasBusinessHours: false,
        hasAgent: false,
      },
    });
    eventBus.$off('reporting-filter', this.fetchDataByFilters);
  },
  methods: {
    fetchDataByFilters() {
      this.data = [];
      this.loaded = false;
      setTimeout(() => {
        this.page = 1;
        this.loadData();
      }, 500);
    },
    encodeDates(date: { startDate: Moment; endDate: Moment }): { encodedStartDate: string; encodedEndDate: string } {
      const formatStartDate = date.startDate
        .tz(this.$root.user.timezone)
        .tz(this.$root.user.timezone)
        .format('YYYY-MM-DDT00:00:ssZ');
      const formatEndDate = date.endDate.tz(this.$root.user.timezone).format('YYYY-MM-DDT23:59:ssZ');
      const encodedStartDate = encodeURIComponent(formatStartDate);
      const encodedEndDate = encodeURIComponent(formatEndDate);

      return { encodedStartDate, encodedEndDate };
    },
    handleGetIntervals(date: { startDate: Moment; endDate: Moment }) {
      const { encodedStartDate, encodedEndDate }: { encodedStartDate: string; encodedEndDate: string } =
        this.encodeDates(date);
      this.$router.replace({
        query: {
          filters: JSON.stringify({
            ...(this.$route.query.filters ? JSON.parse(this.$route.query.filters) : {}),
            start_date: encodedStartDate,
            end_date: encodedEndDate,
          }),
        },
      });
      this.loaded = false;
      this.data = [];
      this.page = 1;
      setTimeout(() => {
        this.loadData();
      }, 500);
    },
    bindUserDownloadReportingChannel() {
      this.$root.userChannel.bind('ReportingExportProgress', (data: PusherData) =>
        this.fetchDownloadReportingStatus(data),
      );
    },
    unbindUserDownloadReportingChannel() {
      this.$root.userChannel.unbind('ReportingExportProgress');
    },
    fetchDownloadReportingStatus(data: PusherData) {
      this.ticketInsightsStore.$patch({
        isDownloadInProgress: false,
      });
      if (data.status === 'COMPLETED') {
        this.downloadReportingCompletedStatus(data.file_path);
      }
      if (data.status === 'FAILED') {
        this.downloadReportingFailedStatus();
      }
    },
    async createReportingLinkAndDownload(file_path: string) {
      window.open(file_path, '_blank');
    },
    downloadReportingCompletedStatus(file_path: string) {
      this.toastMessageState.$patch({
        hasCloseIcon: false,
        iconType: 'success',
        hasIcon: true,
        text: this.$t('reports_v2.your_file_has_been_downloaded'),
      });
      this.createReportingLinkAndDownload(file_path);
      this.toastMessageState.hideToastMessage();
      this.unbindUserDownloadReportingChannel();
    },
    downloadReportingFailedStatus() {
      this.toastMessageState.$patch({
        hasCloseIcon: true,
        hasIcon: true,
        iconType: 'alert',
        text: this.$t('reports_v2.failed_to_download'),
      });
      this.unbindUserDownloadReportingChannel();
    },
    downloadReport() {
      const columns = Object.entries(this.availableColumns)
        .filter(([_, value]) => value)
        .map(([key]) => AvailableColumnsMapper[key as keyof typeof AvailableColumnsMapper])
        .filter(Boolean);
      callExportAPI({ columns }, this.sort).then(() => {
        this.toastMessageState.closeToastMessage();
        this.ticketInsightsStore.$patch({
          isDownloadInProgress: true,
        });
        this.toastMessageState.$patch({
          showToast: true,
          text: this.$t('reports_v2.preparing_download_file'),
        });
        this.bindUserDownloadReportingChannel();
      });
    },
    fetchFiltersFromRoute(): void {
      const fetchFilters = this.$route.query.filters;
      const parsedFilters = JSON.parse(fetchFilters || '""');
      this.isWithinBusinessHours =
        parsedFilters.with_business_hours !== undefined ? !!parsedFilters.with_business_hours : true;
      this.filters = mapFilters(parsedFilters);
      if (Object.keys(parsedFilters).length && 'start_date' in parsedFilters && 'end_date' in parsedFilters) {
        const decodeStartDate = decodeURIComponent(parsedFilters.start_date);
        const decodeEndDate = decodeURIComponent(parsedFilters.end_date);
        this.interval = {
          startDate: moment(decodeStartDate).tz(this.$root.user.timezone),
          endDate: moment(decodeEndDate).tz(this.$root.user.timezone),
        };
      }
    },
    loadData() {
      this.fetchFiltersFromRoute();
      if (!this.filters || (!this.filters.includes('start_date') && !this.filters.includes('end_date'))) {
        const start_date = encodeURIComponent(
          moment().tz(this.$root.user.timezone).subtract(6, 'days').startOf('day').format('YYYY-MM-DDT00:00:ssZ'),
        );
        const end_date = encodeURIComponent(moment().tz(this.$root.user.timezone).format('YYYY-MM-DDT23:59:ssZ'));

        const dateFilters = mapFilters({
          start_date,
          end_date,
        });

        this.filters = this.filters ? `${this.filters}&${dateFilters}` : dateFilters;
      }

      fetchAgents(this.page, this.sort, this.filters)
        .then((res) => {
          this.data = [...this.data, ...res.data.data];
          this.loaded = true;
          this.reportStore.$patch({
            agentsAmount: res.data.meta.total,
          });
        })
        .catch((error) => {
          console.error(error);
          this.loaded = true;
        });
    },

    sortBy(field: string, sort_order: 'asc' | 'desc') {
      if (!field || !sort_order) {
        return;
      }
      this.loaded = false;
      this.data = [];
      this.page = 1;
      this.sort = { order_by: field, sort_order };
      fetchAgents(1, this.sort, this.filters).then((res) => {
        this.data = [...this.data, ...res.data.data];
        this.loaded = true;
      });
    },

    async fetchMoreData(scroll: StateChanger) {
      this.tableWidth = this.$refs['agents-table'].clientWidth;
      this.hasSpinner = true;

      if (this.page === 1) {
        this.page++;
      }

      const currentPage = this.page;

      try {
        const getData = await fetchAgents(currentPage, this.sort, this.filters);

        if (!getData.data.data.length) {
          scroll.complete();
          this.hasSpinner = false;
          return;
        }

        // If we received fewer than 25 items and we know there should be more
        // (based on the metadata total count), retry the fetch
        if (getData.data.data.length < 25 && getData.data.meta.total > this.data.length + getData.data.data.length) {
          // Wait a brief moment before retrying
          setTimeout(async () => {
            try {
              const retryData = await fetchAgents(currentPage, this.sort, this.filters);
              if (retryData.data.data.length) {
                this.data = [...this.data, ...retryData.data.data];
                this.page = currentPage + 1;
              }
              scroll.loaded();
              this.hasSpinner = false;
            } catch (error) {
              console.error('Retry fetch failed:', error);
              scroll.loaded();
              this.hasSpinner = false;
            }
          }, 1000);
          return;
        }

        this.data = [...this.data, ...getData.data.data];
        this.page = currentPage + 1;
        scroll.loaded();
        this.hasSpinner = false;
      } catch (error) {
        console.error(error);
        this.hasSpinner = false;
        scroll.error();
      }
    },

    exportReport() {
      fetchPerformanceReportExport(this.filters).then(() => {
        this.toastMessageState.closeToastMessage();
        this.ticketInsightsStore.$patch({
          isDownloadInProgress: true,
        });
        this.toastMessageState.$patch({
          showToast: true,
          text: this.$t('reports_v2.preparing_download_file'),
        });
        this.bindUserDownloadReportingChannel();
      });
    },
  },
});
</script>
