<template>
  <video :src="source" controls class="block max-h-[500px] max-w-full rounded-lg" playsinline preload="metadata">
    <source :src="source" :type="ALLOWED_VIDEO_FORMATS[0]" />
    {{ $t('whatsapp.video_not_supported') }}
  </video>
</template>

<script setup lang="ts">
import { ALLOWED_VIDEO_FORMATS } from '@/Configs/Constants/whatsapp';

defineOptions({
  name: 'VideoMessage',
});

type Props = {
  source: string;
};

withDefaults(defineProps<Props>(), {
  source: '',
});
</script>
