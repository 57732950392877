<script>
import _ from 'lodash';
import Multiselect from 'vue-multiselect';

export default {
  components: {
    Multiselect,
  },
  emits: ['update:record', 'update:locales'],
  props: {
    channels: {
      type: Array,
      default: () => [],
    },
    record: {
      type: Object,
      default: () => ({}),
    },
    locales: {
      type: Array,
      default: () => [],
    },
    getFullCountryName: {
      type: Function,
      default: null,
    },
    enabledChannels: {
      type: Array,
      default: () => [],
    },
    defaultTranslations: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      tab: 'en',
      collapsed: false,
    };
  },
  computed: {
    localesValue: {
      get() {
        return this.locales;
      },
      set(val) {
        this.$emit('update:locales', val);
      },
    },
    recordValue: {
      get() {
        return this.record;
      },
      set(val) {
        this.$emit('update:record', val);
      },
    },
  },
  watch: {
    'recordValue.languages'() {
      this.tab = this.recordValue.languages[0];
    },
  },
  methods: {
    getDefaultTranslation(lang, k, name) {
      let result = this.defaultTranslations?.[lang]?.[k]?.[name];
      if (result) return result;

      result = this.defaultTranslations?.en?.[k]?.[name];
      if (result) return result;

      return '';
    },
  },
};
</script>

<template>
  <div class="box">
    <div class="box-header b-b">
      <h3>{{ $t('web_widget.translations') }}</h3>
    </div>
    <div class="box-body">
      <div class="form-group row">
        <label class="col-md-2 form-control-label">
          {{ $t('web_widget.languages') }}
          <span class="text-danger">*</span>
        </label>
        <div class="col-md-10">
          <multiselect
            v-model="localesValue"
            label="name"
            track-by="id"
            :allow-empty="false"
            :options="$root.localeCodesList"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            :multiple="true"
            :placeholder="$t('web_widget.select_up_to_20_languages')"
          ></multiselect>
          <span class="form-text">
            {{ $t('web_widget.select_the_available_languages') }}
          </span>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-2 form-control-label">
          {{ $t('web_widget.default_language') }}
          <span class="text-danger">*</span>
        </label>
        <div class="col-md-10">
          <select v-model="recordValue.default_lang" class="form-control">
            <option v-for="locale_code in recordValue.languages" :value="locale_code">
              {{ getFullCountryName(locale_code) }}
            </option>
          </select>
          <span class="form-text">
            {{ $t('web_widget.default_language_description') }}
          </span>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-2 form-control-label">{{ $t('web_widget.translations') }}</label>
        <div class="col-md-10">
          <div
            class="nav-active-border b-success bottom box"
            style="
              margin-bottom: 10px;
              border: none;
              box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15), 0 -1px 0 rgba(0, 0, 0, 0.02);
              border-radius: 0;
            "
          >
            <a
              v-for="lang in recordValue.languages"
              class="nav-link p-4"
              :class="{ active: tab === lang }"
              @click="tab = lang"
            >
              {{ getFullCountryName(lang) }}
            </a>
          </div>
          <div class="translations-panel">
            <div v-for="lang in recordValue.languages" v-show="lang == tab" class="translation-input">
              <div
                v-for="(v, k) in recordValue.translations[lang]"
                v-show="enabledChannels.indexOf(k) !== -1"
                :key="k"
                class="translation-input"
              >
                <div class="text-muted text-uppercase mb-2 mt-4 text-xs">{{ k.replace('_', ' ') }}</div>
                <div v-for="(def, name) in v">
                  <input
                    v-model="recordValue.translations[lang][k][name]"
                    type="text"
                    class="form-control mb-1"
                    :placeholder="getDefaultTranslation(lang, k, name)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
