<template>
  <div
    class="relative flex h-full min-h-[234px] w-full flex-col items-center justify-center overflow-y-auto rounded-xl bg-contain bg-center py-6 pl-[41.25px] pr-[35.25px] shadow-[inset_0px_0px_0px_1px_rgba(0,0,0,0.1)]"
    :style="`background-image: url('${WHATSAPP_TEMPLATE_PREVIEW_IMAGE_URL}')`"
  >
    <div v-if="isContentVisible" class="relative h-full min-w-[94px] max-w-[282px] content-center break-words">
      <div class="last:pb-6">
        <div
          class="flex flex-col gap-1 rounded-md rounded-tl-none bg-white before:absolute before:-left-2 before:border-b-[12px] before:border-r-[12px] before:border-b-transparent before:border-r-white before:content-['']"
        >
          <p
            v-if="!!templateHeader && selectedHeaderType === TEMPLATE_HEADER_TYPE.TEXT"
            class="mb-0 px-2 pt-2 text-base font-semibold leading-6 text-grey-800"
            v-html="formattedText(templateHeader)"
          />
          <div v-if="hasMedia" class="p-1">
            <img v-if="selectedHeaderType === TEMPLATE_HEADER_TYPE.IMAGE" :src="headerMediaSrc" class="rounded-[4px]" />
            <video
              v-if="selectedHeaderType === TEMPLATE_HEADER_TYPE.VIDEO"
              :src="headerMediaSrc"
              class="rounded-[4px]"
              controls
              preload="metadata"
              controlsList="nodownload"
              playsinline
            >
              <source :src="headerMediaSrc" :type="ALLOWED_VIDEO_FORMATS[0]" />
              {{ $t('whatsapp.video_not_supported') }}
            </video>
          </div>
          <p class="mb-0 px-2 py-2 text-sm font-medium leading-5 text-grey-800" v-html="formattedText(templateBody)" />
          <div class="flex items-center justify-between gap-3 p-2">
            <p
              v-if="!!templateFooter"
              class="mb-0 flex-1 break-words text-xs font-medium leading-4 text-grey-600"
              v-html="formattedText(templateFooter)"
            />
            <p class="mb-0 ml-auto text-xs font-medium leading-4 text-grey-600">{{ currentTime }}</p>
          </div>
        </div>
      </div>
      <ul
        v-if="filteredButtons.length"
        class="mb-0 mt-1 flex w-full min-w-[94px] max-w-[282px] flex-col gap-1 pl-0 text-[#0096de] last:pb-6"
      >
        <li
          v-for="(btn, index) in filteredButtons"
          :key="btn.id"
          class="mb-0 flex h-9 w-full list-none items-center justify-center break-words rounded-md bg-white text-center text-base font-normal leading-5"
        >
          <button v-if="!isExpanded && index === 2" class="h-full w-full" @click="isExpanded = !isExpanded">
            <list-unordered2-fill class="mr-2 inline" />
            <span>{{ $t('whatsapp.template_see_all_options') }}</span>
          </button>
          <span v-else-if="isNonInteractiveButton(btn)">
            <wa-template-preview-button-content :btn="btn" />
          </span>
          <a
            v-else
            :href="getButtonHref(btn)"
            target="_blank"
            rel="noopener noreferrer"
            class="flex h-full w-full items-center justify-center no-underline"
          >
            <wa-template-preview-button-content :btn="btn" />
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { ListUnordered2Fill } from '@trengo/trengo-icons';
import moment from 'moment';
import { defineComponent } from 'vue';

import {
  TEMPLATE_HEADER_TYPE,
  WA_TEMPLATE_COMPONENT_SUB_TYPE,
  ALLOWED_VIDEO_FORMATS,
  WHATSAPP_TEMPLATE_PREVIEW_IMAGE_URL,
  TEMPLATE_HEADER_MEDIA_TYPES,
} from '@/Configs/Constants/whatsapp';
import { formatWhatsAppText } from '@/util/GlobalHelpers';

import WaTemplatePreviewButtonContent from './WaTemplatePreviewButtonContent.vue';

import type { TemplateButtonItem } from '../types';
import type { PropType } from 'vue';
export default defineComponent({
  name: 'TemplatePreview',
  components: { ListUnordered2Fill, WaTemplatePreviewButtonContent },
  props: {
    buttons: {
      type: Array as PropType<TemplateButtonItem[]>,
      default: () => [],
    },
    selectedHeaderType: {
      type: String,
      default: 'text',
    },
    templateBody: {
      type: String,
      default: '',
    },
    templateHeader: {
      type: String,
      default: '',
    },
    templateFooter: {
      type: String,
      default: '',
    },
    isCreateMode: Boolean,
  },
  data() {
    return {
      currentTime: moment().format('HH:mm'),
      timeIntervalId: null,
      isExpanded: this.buttons.length <= 3,
      TEMPLATE_HEADER_TYPE,
      WA_TEMPLATE_COMPONENT_SUB_TYPE,
      ALLOWED_VIDEO_FORMATS,
      WHATSAPP_TEMPLATE_PREVIEW_IMAGE_URL,
    };
  },
  computed: {
    defaultHeaderSrc(): string {
      return `${this.$root.assetsURL}img/wabusiness/template-image.svg`;
    },
    headerMediaSrc() {
      return this.isCreateMode && this.templateHeader ? this.templateHeader : this.defaultHeaderSrc;
    },
    isContentVisible(): boolean {
      return Boolean(
        this.templateBody ||
          this.templateHeader ||
          this.templateFooter ||
          this.selectedHeaderType === TEMPLATE_HEADER_TYPE.IMAGE ||
          this.selectedHeaderType === TEMPLATE_HEADER_TYPE.VIDEO,
      );
    },
    hasMedia(): boolean {
      return TEMPLATE_HEADER_MEDIA_TYPES.includes(this.selectedHeaderType);
    },
    filteredButtons(): TemplateButtonItem[] {
      const buttons = (this.buttons as TemplateButtonItem[]).filter((btn: TemplateButtonItem) => btn.value);
      return this.isExpanded ? buttons : buttons.slice(0, 3);
    },
  },
  watch: {
    buttons: {
      handler(newVal) {
        this.isExpanded = newVal.length <= 3;
      },
    },
  },
  mounted() {
    this.setCurrentTime();
  },
  beforeUnmount() {
    clearInterval(this.timeIntervalId);
  },

  methods: {
    isNonInteractiveButton(btn: TemplateButtonItem): boolean {
      return btn.subType == WA_TEMPLATE_COMPONENT_SUB_TYPE.QUICK_REPLY;
    },
    formattedText(text: string): string {
      return formatWhatsAppText(text);
    },
    setCurrentTime(): void {
      this.timeIntervalId = setInterval(() => {
        this.currentTime = moment().format('HH:mm');
      }, 1000);
    },
    getButtonHref(btn: TemplateButtonItem): string | undefined {
      switch (btn.subType) {
        case WA_TEMPLATE_COMPONENT_SUB_TYPE.URL:
          return btn.details;
        case WA_TEMPLATE_COMPONENT_SUB_TYPE.PHONE_NUMBER:
          return `tel:${btn.details}`;
        default:
          return '';
      }
    },
  },
});
</script>
