<template>
  <div class="flex flex-col gap-[6px]" data-test="broadcast-upload-file">
    <div class="t-text-md-emphasize">
      {{ mediaUploadTitle }}
    </div>
    <t-file-input
      :data-test="'broadcast-upload-file-input'"
      :value="file"
      :max-file-size="{
        size: mediaMaxSize,
        text: mediaMaxSizeInfo,
      }"
      :variant="mediaType.toLowerCase()"
      @update:value="handleFileChange"
      @reset="handleFileReset"
    />
    <p v-if="!file?.length" data-test="default-image-text" class="t-text-sm mb-0 text-grey-600">
      {{ mediaUploadDescription }}
    </p>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { FILE_SIZE_LIMIT_IN_DECIMAL_BYTES, WA_TEMPLATE_COMPONENT_SUB_TYPE } from '@/Configs/Constants/whatsapp';
import { convertBytesToMB } from '@/util/fileHelper';

const emit = defineEmits(['updateSelectedFile', 'handleFileReset']);

const props = defineProps<{
  mediaType: valueof<typeof WA_TEMPLATE_COMPONENT_SUB_TYPE>;
  templateId: number;
}>();

const isImage = computed(() => props.mediaType === WA_TEMPLATE_COMPONENT_SUB_TYPE.IMAGE);
const mediaMaxSize = computed(() =>
  isImage.value ? FILE_SIZE_LIMIT_IN_DECIMAL_BYTES.IMAGE : FILE_SIZE_LIMIT_IN_DECIMAL_BYTES.GENERAL,
);

const { t } = useI18n();

const mediaTypeTranslation = computed(() => {
  switch (props.mediaType) {
    case WA_TEMPLATE_COMPONENT_SUB_TYPE.IMAGE:
      return t('general.image').toLowerCase();
    case WA_TEMPLATE_COMPONENT_SUB_TYPE.VIDEO:
      return t('general.video').toLowerCase();
    default:
      return t('general.media').toLowerCase();
  }
});

const mediaUploadTitle = computed<string>(() => {
  return `${t('general.header')} ${mediaTypeTranslation.value}`;
});
const mediaUploadDescription = computed(() => {
  return `${t('broadcast.file_upload_info')} ${mediaTypeTranslation.value}`;
});
const mediaMaxSizeInfo = computed(() => {
  return t('whatsapp.max_file_size', {
    fileSize: convertBytesToMB(mediaMaxSize.value),
  });
});

const file = ref<File[]>([]);

const handleFileChange = (selectedFile: File[]) => {
  file.value = selectedFile;
  emit('updateSelectedFile', selectedFile);
};

const handleFileReset = () => {
  file.value = [];
  emit('handleFileReset');
};

watch(
  () => props.templateId,
  () => {
    handleFileReset();
  },
  { immediate: true },
);
</script>
