import { ENDPOINT } from '@/api/constants';
import { request } from '@/util';

import type { AxiosPromise } from 'axios';

/**
 * {@link https://developers.apideck.com/apis/ecommerce/reference#tag/Customers APIDeck Reference}
 */
export type EcommerceCustomer = {
  id?: string;
  full_name?: string;
  company_name?: string;
  emails: CustomerEmail[];
  phone_numbers: CustomerPhoneNumber[];
};

type CustomerEmail = {
  id: string;
  email: string;
  type?: string;
};

type CustomerPhoneNumber = {
  phone_number: string;
  area?: string;
  extension?: string;
  type?: string;
};

type OrderAddress = {
  id: string;
  type?: string;
  line1?: string;
  line2?: string;
  city?: string;
  state?: string;
  postal_code?: string;
  country?: string;
};

/**
 * {@link https://developers.apideck.com/apis/ecommerce/reference#tag/Orders APIDeck Reference}
 */
export type ECommerceOrder = {
  billing_address: OrderAddress;
  created_at: string;
  currency: string;
  customer: EcommerceCustomer;
  discounts: OrderDiscount[];
  fulfillment_status: string;
  note: string;
  order_id: string;
  order_number: string;
  external_order_id: string;
  order_status: string;
  order_url: string;
  payment_status: string;
  shipping_address: OrderAddress;
  shipping_cost: string;
  sub_total: string;
  total_amount: string;
  total_tax: string;
  tracking: OrderTracking[];
};

type OrderDiscount = {
  code: string;
  amount: string;
  percentage: string;
};

type OrderTracking = {
  provider: string;
  number: string;
  url: string;
  update_at: string;
};

export function fetchCommerceOrders(
  installation_id: number,
  email?: string,
  phone?: string
): AxiosPromise<ECommerceOrder[]> {
  const params = new URLSearchParams();

  if (email) {
    params.set('email', email);
  }
  if (phone) {
    params.set('phone', phone);
  }

  return request(`${ENDPOINT.INTEGRATIONS}/installations/${installation_id}/ecommerce/orders?${params}`, 'GET');
}
