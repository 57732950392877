<template>
  <div>
    <form-title
      v-if="showTitle"
      v-model="toggleValue"
      :badge-text="badgeText"
      :badge-variant="badgeVariant"
      :is-text-disabled="isTitleTextDisabled"
      :heading-text="headingText"
      :should-show-badge="shouldShowBadge"
      :should-show-switch="shouldShowSwitch"
      :is-toggle-disabled="isToggleDisabled"
      :subtitle="subtitle"
      :tooltip-text="tooltipText"
      @handle-click="handleClick"
    />
    <multi-choice-cards
      v-if="showMultiChoiceCards"
      :disabled="isMultiChoiceCardsDisabled"
      :selected-header-type="headerType"
      @handle-selected-header="$emit('handle-selected-header', $event)"
    />
    <t-inline-banner v-if="shouldShowBanner">
      <template #icon>
        <info-linear />
      </template>
      <p class="mb-0">{{ bannerMessage }}</p>
    </t-inline-banner>
    <wa-template-input
      v-if="showInputField"
      v-model="inputValue"
      :auto-focus-input="autoFocusInput"
      :disabled="disabled"
      :input-id="inputId"
      :is-valid-input="isValidInput"
      :show-template-end="showTemplateEnd"
      :input-type="inputType"
      :label="label"
      :max-length="maxLength"
      :placeholder="placeholder"
      :character-count="characterCount(inputValue)"
      :error-msg="errorMsg"
      :sub-label="subLabel"
      :tooltip-text="inputTooltipText"
      @handle-input-change="$emit('handle-input-change', $event)"
      @keyup="$emit('keyup', $event)"
      @paste="$emit('paste', $event)"
      @blur="$emit('blur', $event)"
    />
    <t-button
      v-if="showActionButton"
      :disabled="disableMigrationButton"
      type="button"
      size="sm"
      @click="$emit('go-to-migration')"
    >
      {{ btnText }}
    </t-button>
  </div>
</template>

<script lang="ts">
import { InfoLinear } from '@trengo/trengo-icons';
import { defineComponent } from 'vue';

import { TEMPLATE_HEADER_TYPE } from '@/Configs/Constants/whatsapp';

import FormTitle from '../FormTitle';
import MultiChoiceCards from '../MultiChoiceCards/MultiChoiceCards.vue';
import WaTemplateInput from '../WaTemplateInput';

import type { TemplateHeaderType } from '../types';
import type { PropType } from 'vue';

export default defineComponent({
  name: 'FormContent',
  components: { FormTitle, WaTemplateInput, MultiChoiceCards, InfoLinear },
  props: {
    activateToggle: {
      type: Boolean,
      default: false,
    },
    autoFocusInput: {
      type: Boolean,
      default: false,
    },
    badgeText: {
      type: String,
      default: '',
    },
    badgeVariant: {
      type: String,
      default: 'default',
    },
    bannerMessage: {
      type: String,
      default: '',
    },
    btnText: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isMultiChoiceCardsDisabled: {
      type: Boolean,
      default: false,
    },
    isTitleTextDisabled: {
      type: Boolean,
      default: false,
    },
    isToggleDisabled: {
      type: Boolean,
      default: false,
    },
    disableMigrationButton: {
      type: Boolean,
      default: false,
    },
    errorMsg: {
      type: String,
      default: '',
    },
    headingText: {
      type: String,
      default: '',
    },
    inputId: {
      type: String,
      default: '',
    },
    inputType: {
      type: String,
      default: 'text',
    },
    inputTooltipText: {
      type: String,
      default: '',
    },
    isValidInput: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    maxLength: {
      type: Number,
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    shouldShowBadge: {
      type: Boolean,
      default: false,
    },
    shouldShowBanner: {
      type: Boolean,
      default: false,
    },
    subtitle: {
      type: String,
      default: '',
    },
    shouldShowSwitch: {
      type: Boolean,
      default: false,
    },
    showMultiChoiceCards: {
      type: Boolean,
      default: false,
    },
    showTemplateEnd: {
      type: Boolean,
      default: false,
    },
    showInputField: {
      type: Boolean,
      default: false,
    },
    showActionButton: {
      type: Boolean,
      default: false,
    },
    showTitle: {
      type: Boolean,
      default: false,
    },
    subLabel: {
      type: String,
      default: '',
    },
    tooltipText: {
      type: String,
      default: '',
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
    selectedHeaderType: {
      type: String as PropType<TemplateHeaderType>,
      default: TEMPLATE_HEADER_TYPE.TEXT,
      validator: (value: string) => {
        return Object.values(TEMPLATE_HEADER_TYPE).includes(value as TemplateHeaderType);
      },
    },
  },
  emits: [
    'handle-input-change',
    'handle-selected-header',
    'toggle-switch',
    'go-to-migration',
    'keyup',
    'paste',
    'blur',
    'update:modelValue',
  ],
  data() {
    return {
      toggleOn: false,
      headerType: this.selectedHeaderType,
    };
  },
  computed: {
    inputValue: {
      get() {
        return this.modelValue;
      },
      set(value: string | number) {
        this.$emit('update:modelValue', value);
      },
    },
    toggleValue() {
      let newValue = this.toggleOn;
      if (this.isToggleDisabled && !this.activateToggle) {
        newValue = false;
      }
      if (this.activateToggle) {
        newValue = true;
      }
      return newValue;
    },
  },
  watch: {
    selectedHeaderType(val: TemplateHeaderType) {
      this.headerType = val;
    },
    toggleValue(val: boolean) {
      this.toggleOn = val;
    },
  },
  methods: {
    characterCount(text: string): string {
      return `${text.length}/${this.maxLength}`;
    },
    handleClick(): void {
      if (!this.isToggleDisabled) {
        this.toggleOn = !this.toggleOn;
      }

      this.$emit('toggle-switch', this.toggleOn);
    },
  },
});
</script>
