import { defineStore } from 'pinia';
import { ref } from 'vue';

import { fetchChannels } from '@/api';
import { CHANNEL_TYPE } from '@/Configs/Constants';

import type { MeChannelsResponse } from './types';
import type { Channel } from '@/types/common';

export const useMeChannelsStore = defineStore('meChannels', () => {
  const isLoading = ref(false);
  const isError = ref(false);
  const isInitialized = ref(false);
  const channels = ref<MeChannelsResponse['data']>();

  async function init() {
    if (isInitialized.value) return;
    try {
      await getChannels();
      isInitialized.value = true;
    } catch {
      isError.value = true;
    }
  }

  async function getChannels() {
    isError.value = false;
    isLoading.value = true;

    try {
      const res = await fetchChannels();
      channels.value = res.data.data.filter((c) => c.type !== CHANNEL_TYPE.HELP_CENTER) as Channel[];
    } catch {
      isError.value = true;
    } finally {
      isLoading.value = false;
    }

    return channels;
  }

  function updateChannelsList(newChannels: Channel[]) {
    channels.value = newChannels.filter((c) => c.type !== CHANNEL_TYPE.HELP_CENTER) as Channel[];
  }

  return {
    isLoading,
    isError,
    channels,
    getChannels,
    init,
    updateChannelsList,
  };
});
