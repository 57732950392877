import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-col gap-6"
}
const _hoisted_2 = {
  key: 1,
  class: "flex flex-col flex-wrap gap-2"
}
const _hoisted_3 = { class: "t-text-md-emphasize" }
const _hoisted_4 = { class: "flex flex-wrap gap-2" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = {
  key: 0,
  class: "t-text-desktop-paragraph-sm text-grey-600"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_upload_file = _resolveComponent("upload-file")!
  const _component_search_bar = _resolveComponent("search-bar")!
  const _component_t_error_item = _resolveComponent("t-error-item")!

  return ($props.template?.id)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        ($options.mediaComponent)
          ? (_openBlock(), _createBlock(_component_upload_file, {
              key: 0,
              "media-type": $options.mediaType,
              "template-id": $props.template.id,
              onUpdateSelectedFile: $options.handleFileChange,
              onHandleFileReset: $options.handleFileReset
            }, null, 8 /* PROPS */, ["media-type", "template-id", "onUpdateSelectedFile", "onHandleFileReset"]))
          : _createCommentVNode("v-if", true),
        ($options.computedCustomFieldTags?.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('whatsapp.body')), 1 /* TEXT */),
              _createElementVNode("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.computedCustomFieldTags, (tag, i) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: i,
                    class: "min-w-[256px] flex-1"
                  }, [
                    _createVNode(_component_search_bar, {
                      modelValue: $options.computedInputTags[i].value,
                      "onUpdate:modelValue": ($event: any) => (($options.computedInputTags[i].value) = $event),
                      modelModifiers: { trim: true },
                      options: $options.tagOptions(i),
                      error: $props.error && $options.computedInputTags[i].value === '',
                      size: "sm",
                      placeholder: tag.value || tag.key,
                      "data-test": "'tag-input-'+i",
                      onInput: (e) => $options.handleFilterTags(e.target.value, i),
                      onKeydown: (e) => $options.onKeyDown(e, i)
                    }, {
                      item: _withCtx((slotProps) => [
                        _createElementVNode("div", {
                          class: "tag-item pointer t-text-desktop-paragraph-sm mx-3 my-1 rounded-xl px-2 py-1 text-grey-800 hover:bg-grey-300",
                          onClick: (e) => $options.handleItemClick(e.currentTarget.innerText, i)
                        }, _toDisplayString(slotProps.item.identifier), 9 /* TEXT, PROPS */, _hoisted_5)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onUpdate:modelValue", "options", "error", "placeholder", "onInput", "onKeydown"])
                  ]))
                }), 128 /* KEYED_FRAGMENT */))
              ]),
              ($options.computedCustomFieldTags?.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.$t('broadcast.select_an_existing_tag_to_replace_each_variable_or_enter_some_plain_text')), 1 /* TEXT */))
                : _createCommentVNode("v-if", true),
              ($props.error)
                ? (_openBlock(), _createBlock(_component_t_error_item, {
                    key: 1,
                    "data-test": "tags-error",
                    text: _ctx.$t('broadcast.please_fill_in_all_tags')
                  }, null, 8 /* PROPS */, ["text"]))
                : _createCommentVNode("v-if", true)
            ]))
          : _createCommentVNode("v-if", true)
      ]))
    : _createCommentVNode("v-if", true)
}