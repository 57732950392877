import { PERMISSION } from '@/Configs/Constants';

import { ROUTE_NAME } from '../constants';

import type { RouteRecord } from '../types';

const workflowsRoutes: RouteRecord[] = [
  {
    path: '/workflows/:pathMatch(.*)*', // all paths starting with /workflows
    name: ROUTE_NAME.WORKFLOWS_OVERVIEW,
    component: () => import('@/components/Workflows/Pages/Overview.vue'),
    meta: { permissionRequired: PERMISSION.SETTINGS__AI_NAV__VIEW, title: 'Trengo AI' },
  },
  {
    path: '/journeys/:pathMatch(.*)*',
    redirect: { path: '/workflows' },
    meta: { permissionRequired: PERMISSION.SETTINGS__AI_NAV__VIEW,},
  },
];

export default workflowsRoutes;
