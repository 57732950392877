import { Growl } from './growl';

type NotificationType = 'error' | 'warning' | 'notice';

const hideAllGrowlNotifications = () => {
  document.querySelectorAll('.growl').forEach((el) => el.remove());
};

const showGrowlNotification = (type: NotificationType, title: string, message: string): void => {
  hideAllGrowlNotifications();

  Growl.growl({
    size: 'large',
    duration: 3000,
    namespace: 'growl',
    title,
    location: 'tc',
    message,
    style: type,
  });
};

export const flashError = (message: string, title = '') => {
  showGrowlNotification('error', title, message);
};

export const flashWarning = (message: string, title = '') => {
  showGrowlNotification('warning', title, message);
};

export const flashSuccess = (message: string, title = '') => {
  showGrowlNotification('notice', title, message);
};
