<template>
  <div class="flex items-center p-2 border-b border-grey-700 last:border-b-0 min-w-0">
    <button
      v-if="call.status === 'QUEUED'"
      class="rounded-full bg-leaf-500 p-2 mr-3 hover:bg-leaf-600 active:bg-leaf-400 transition-colors"
    >
      <call-calling-fill />
    </button>

    <avatar
      v-else
      class="mr-2"
      width="35"
      :abbr="call.contact?.abbr"
      :color="call.contact?.color"
      :image="call.contact?.profile_image"
    />

    <div class="flex flex-col min-w-0 w-full">
      <div class="truncate">
        {{ call.contact?.full_name ?? call.contact?.formatted_phone }}
      </div>

      <div class="flex items-center w-full">
        <div class="mr-2 text-leaf-500">
          <call-incoming-fill v-if="call.type === 'INBOUND'" />
          <call-outgoing-fill v-else />
        </div>

        <p class="truncate text-grey-500 m-0 p-0 mr-2">{{ call.channel?.title }}</p>

        <div class="flex items-center text-grey-500 m-0 ml-auto p-0">
          {{ call.formatted_date }}
        </div>

        <span class="ml-1">
          <time-linear />
        </span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { CallCallingFill, CallIncomingFill, CallOutgoingFill, TimeLinear } from '@trengo/trengo-icons';

import Avatar from '@/components/Avatar.vue';

import type { CallStartedData } from '@/components/Voip/constants/events';

defineProps<{
  call: CallStartedData;
}>();
</script>
