import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2251707e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "mt-auto h-full rounded-b-md bg-sun-100 p-4",
  "data-test": "internalCommentComposerWrapper"
}
const _hoisted_2 = { class: "pos-relative flex h-full flex-col" }
const _hoisted_3 = { class: "flex-1 bg-sun-100" }
const _hoisted_4 = {
  key: 0,
  class: "mt-4 overflow-auto pr-4",
  style: {"max-height":"12rem"}
}
const _hoisted_5 = { class: "mt-4 flex select-none justify-between" }
const _hoisted_6 = { class: "flex ml-auto flex-row items-center gap-x-2" }
const _hoisted_7 = { ref: "bottom" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_content_editable = _resolveComponent("content-editable")!
  const _component_message_attachment = _resolveComponent("message-attachment")!
  const _component_GenerateSummaryCTA = _resolveComponent("GenerateSummaryCTA")!
  const _component_emotion_smile_linear = _resolveComponent("emotion-smile-linear")!
  const _component_t_button = _resolveComponent("t-button")!
  const _component_emoji_picker = _resolveComponent("emoji-picker")!
  const _component_attachment_selector = _resolveComponent("attachment-selector")!
  const _component_add_note_button = _resolveComponent("add-note-button")!
  const _component_confirm_mention_unauthorized_users_modal = _resolveComponent("confirm-mention-unauthorized-users-modal")!
  const _directive_tooltip = _resolveDirective("tooltip")!
  const _directive_click_away = _resolveDirective("click-away")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("form", {
        class: "note flex flex-1 flex-col",
        onSubmit: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"]))
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_content_editable, {
            "element-id": 'privateComposer',
            ref: "editor",
            modelValue: _ctx.message,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.message) = $event)),
            "file-paste": true,
            class: "message-editor flex-1 overflow-auto rounded-b-md",
            style: {"min-height":"72px"},
            "max-height": "400px",
            placeholder: _ctx.$t('tickets.composer_add_note_placeholder'),
            "data-test": "internalCommentComposerContentEditable",
            onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.focus = true)),
            onBlur: _ctx.onBlur,
            onEnter: _ctx.onEnter,
            onFilePaste: _ctx.onFilePaste,
            "allow-formatting": false,
            "allow-link-formatting": false,
            "is-loading": _ctx.isLoading
          }, null, 8 /* PROPS */, ["modelValue", "placeholder", "onBlur", "onEnter", "onFilePaste", "is-loading"])
        ]),
        (_ctx.attachments.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.attachments, (attachment) => {
                return (_openBlock(), _createBlock(_component_message_attachment, {
                  key: attachment.id,
                  attachment: attachment,
                  onDeleteAttachment: _ctx.deleteAttachment
                }, null, 8 /* PROPS */, ["attachment", "onDeleteAttachment"]))
              }), 128 /* KEYED_FRAGMENT */))
            ]))
          : _createCommentVNode("v-if", true),
        _createElementVNode("div", _hoisted_5, [
          (_ctx.ticket?.id)
            ? (_openBlock(), _createBlock(_component_GenerateSummaryCTA, {
                key: 0,
                "ticket-id": _ctx.ticket.id
              }, null, 8 /* PROPS */, ["ticket-id"]))
            : _createCommentVNode("v-if", true),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", null, [
              _withDirectives((_openBlock(), _createBlock(_component_t_button, {
                ref: "emojiButton",
                size: "sm",
                "icon-only": true,
                "btn-style": 'secondary',
                "data-test": "internalCommentComposerEmojiPicker",
                onClick: _withModifiers(_ctx.toggleEmojiPicker, ["prevent"])
              }, {
                icon: _withCtx(() => [
                  _createVNode(_component_emotion_smile_linear, { size: "1.25rem" })
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["onClick"])), [
                [_directive_tooltip, {
                  placement: 'top',
                  content: _ctx.$t('tickets.insert_emoji'),
                  delay: { show: 500, hide: 0 },
                }]
              ]),
              (_ctx.showEmojiPicker)
                ? _withDirectives((_openBlock(), _createElementBlock("div", {
                    key: 0,
                    ref: "emojiContainer",
                    class: "absolute bottom-12 right-12",
                    style: _normalizeStyle(_ctx.computedEmojiContainerStyle)
                  }, [
                    _createVNode(_component_emoji_picker, { onInsertEmoji: _ctx.insertEmoji }, null, 8 /* PROPS */, ["onInsertEmoji"])
                  ], 4 /* STYLE */)), [
                    [_directive_click_away, 
                  () => {
                    _ctx.showEmojiPicker = false;
                  }
                ]
                  ])
                : _createCommentVNode("v-if", true)
            ]),
            _createVNode(_component_attachment_selector, {
              "is-redesign": _ctx.isInternalCommentAttachmentRedesignEnabled,
              "color-class": "text-black",
              "data-test": "internalCommentComposerAddAttachmentButton",
              onAttachment: _ctx.addAttachment
            }, null, 8 /* PROPS */, ["is-redesign", "onAttachment"]),
            _createVNode(_component_add_note_button, {
              disabled: !_ctx.isValid(),
              "data-test": "internalCommentAddNoteButton",
              onAddNote: _ctx.send
            }, null, 8 /* PROPS */, ["disabled", "onAddNote"])
          ])
        ]),
        _createElementVNode("div", _hoisted_7, null, 512 /* NEED_PATCH */)
      ], 32 /* NEED_HYDRATION */)
    ]),
    _createVNode(_component_confirm_mention_unauthorized_users_modal, { onAccept: _ctx.confirmMentioningUserIdsWithoutAccess }, null, 8 /* PROPS */, ["onAccept"])
  ]))
}