import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "wab-setting-item" }
const _hoisted_2 = {
  key: 0,
  class: "t-text-desktop-label-md mb-1"
}
const _hoisted_3 = {
  key: 1,
  class: "t-text-desktop-paragraph-xs mb-2 text-grey-600"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      (_ctx.$slots.label)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "label")
          ]))
        : _createCommentVNode("v-if", true),
      (_ctx.$slots.desc)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _renderSlot(_ctx.$slots, "desc")
          ]))
        : _createCommentVNode("v-if", true),
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}