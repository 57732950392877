<template>
  <div class="tab-dialpad flex h-full select-none flex-col">
    <div v-show="!($root.mobileDevice && call.status === 'in_progress')" class="call-actions flex-1">
      <div class="call-action hover:brightness-90" @click="dial(1)">
        <div class="call-action-icon"><div class="call-digit">1</div></div>
      </div>
      <div class="call-action hover:brightness-90" @click="dial(2)">
        <div class="call-action-icon">
          <div class="call-digit">2</div>
          <div class="call-action-text">ABC</div>
        </div>
      </div>
      <div class="call-action hover:brightness-90" @click="dial(3)">
        <div class="call-action-icon">
          <div class="call-digit">3</div>
          <div class="call-action-text">DEF</div>
        </div>
      </div>
      <div class="call-action hover:brightness-90" @click="dial(4)">
        <div class="call-action-icon">
          <div class="call-digit">4</div>
          <div class="call-action-text">GHI</div>
        </div>
      </div>
      <div class="call-action hover:brightness-90" @click="dial(5)">
        <div class="call-action-icon">
          <div class="call-digit">5</div>
          <div class="call-action-text">JKL</div>
        </div>
      </div>
      <div class="call-action hover:brightness-90" @click="dial(6)">
        <div class="call-action-icon">
          <div class="call-digit">6</div>
          <div class="call-action-text">MNO</div>
        </div>
      </div>
      <div class="call-action hover:brightness-90" @click="dial(7)">
        <div class="call-action-icon">
          <div class="call-digit">7</div>
          <div class="call-action-text">PQRS</div>
        </div>
      </div>
      <div class="call-action hover:brightness-90" @click="dial(8)">
        <div class="call-action-icon">
          <div class="call-digit">8</div>
          <div class="call-action-text">TUV</div>
        </div>
      </div>
      <div class="call-action hover:brightness-90" @click="dial(9)">
        <div class="call-action-icon">
          <div class="call-digit">9</div>
          <div class="call-action-text">WXYZ</div>
        </div>
      </div>
      <div class="call-action hover:brightness-90" @click="dial('*')">
        <div class="call-action-icon"><div class="call-digit text-grey-500">*</div></div>
      </div>
      <div class="call-action hover:brightness-90" @click="dial('0')">
        <div class="call-action-icon">
          <div class="call-digit">0</div>
          <div class="call-action-text">+</div>
        </div>
      </div>
      <div class="call-action hover:brightness-90" @click="dial('#')">
        <div class="call-action-icon"><div class="call-digit text-grey-500">#</div></div>
      </div>
      <div v-if="call.status == null || call.status == 'initializing'" class="call-action call-action-icon-fw">
        <div
          v-if="call.status == null"
          class="call-action-icon call-action-icon-green call-action-icon-rounded hover:brightness-90"
          @click="callAction()"
        >
          <i class="material-icons">call</i>
        </div>
        <div
          v-if="call.status === 'initializing'"
          class="call-action-icon call-action-icon-green call-action-icon-rounded"
        >
          <i class="fa fa-spin fa-spinner"></i>
        </div>
      </div>
    </div>
    <div
      v-show="call.status === 'initializing' && $root.mobileDevice && $root.user.phone != null"
      class="my-4 mt-4 mt-auto flex flex-col items-center text-center text-xs text-grey-500"
    >
      <div>Now calling {{ $root.user.phone }} to initiate the outbound call..</div>
      <div class="text-center">
        <button class="btn btn-xs my-4 text-white" @click="cancelOutboundCall">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import eventBus from '@/eventBus';
export default {
  name: 'Dialpad',

  props: {
    call: {
      type: Object,
      default: () => ({}),
    },
    device: {
      type: Object,
      default: () => ({}),
    },
    connection: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['cancel'],
  data: function () {
    return {
      phone: '',
    };
  },

  methods: {
    dial(key) {
      eventBus.$emit('voip.key-pressed', {
        key: key,
      });

      if (!this.connection) {
        return;
      }

      this.connection.sendDigits('' + key);
    },

    callAction() {
      eventBus.$emit('voip.key-pressed', {
        key: 'dial',
      });
    },

    inputChanged(arg) {
      this.phone = arg.phone;

      if (arg.call == true) {
        this.call();
      }
    },

    cancelOutboundCall() {
      this.$emit('cancel');
    },
  },
};
</script>
