import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useTicketInsightsStore = defineStore('ReportingTicketInsightsStore', () => {
  const isDownloadInProgress = ref(false);

  return {
    isDownloadInProgress,
  };
});
