import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "relative h-full min-w-[94px] max-w-[282px] content-center break-words"
}
const _hoisted_2 = { class: "last:pb-6" }
const _hoisted_3 = { class: "flex flex-col gap-1 rounded-md rounded-tl-none bg-white before:absolute before:-left-2 before:border-b-[12px] before:border-r-[12px] before:border-b-transparent before:border-r-white before:content-['']" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  key: 1,
  class: "p-1"
}
const _hoisted_6 = ["src"]
const _hoisted_7 = ["src"]
const _hoisted_8 = ["src", "type"]
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { class: "flex items-center justify-between gap-3 p-2" }
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = { class: "mb-0 ml-auto text-xs font-medium leading-4 text-grey-600" }
const _hoisted_13 = {
  key: 0,
  class: "mb-0 mt-1 flex w-full min-w-[94px] max-w-[282px] flex-col gap-1 pl-0 text-[#0096de] last:pb-6"
}
const _hoisted_14 = { key: 1 }
const _hoisted_15 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_list_unordered2_fill = _resolveComponent("list-unordered2-fill")!
  const _component_wa_template_preview_button_content = _resolveComponent("wa-template-preview-button-content")!

  return (_openBlock(), _createElementBlock("div", {
    class: "relative flex h-full min-h-[234px] w-full flex-col items-center justify-center overflow-y-auto rounded-xl bg-contain bg-center py-6 pl-[41.25px] pr-[35.25px] shadow-[inset_0px_0px_0px_1px_rgba(0,0,0,0.1)]",
    style: _normalizeStyle(`background-image: url('${_ctx.WHATSAPP_TEMPLATE_PREVIEW_IMAGE_URL}')`)
  }, [
    (_ctx.isContentVisible)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              (!!_ctx.templateHeader && _ctx.selectedHeaderType === _ctx.TEMPLATE_HEADER_TYPE.TEXT)
                ? (_openBlock(), _createElementBlock("p", {
                    key: 0,
                    class: "mb-0 px-2 pt-2 text-base font-semibold leading-6 text-grey-800",
                    innerHTML: _ctx.formattedText(_ctx.templateHeader)
                  }, null, 8 /* PROPS */, _hoisted_4))
                : _createCommentVNode("v-if", true),
              (_ctx.hasMedia)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    (_ctx.selectedHeaderType === _ctx.TEMPLATE_HEADER_TYPE.IMAGE)
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          src: _ctx.headerMediaSrc,
                          class: "rounded-[4px]"
                        }, null, 8 /* PROPS */, _hoisted_6))
                      : _createCommentVNode("v-if", true),
                    (_ctx.selectedHeaderType === _ctx.TEMPLATE_HEADER_TYPE.VIDEO)
                      ? (_openBlock(), _createElementBlock("video", {
                          key: 1,
                          src: _ctx.headerMediaSrc,
                          class: "rounded-[4px]",
                          controls: "",
                          preload: "metadata",
                          controlsList: "nodownload",
                          playsinline: ""
                        }, [
                          _createElementVNode("source", {
                            src: _ctx.headerMediaSrc,
                            type: _ctx.ALLOWED_VIDEO_FORMATS[0]
                          }, null, 8 /* PROPS */, _hoisted_8),
                          _createTextVNode(" " + _toDisplayString(_ctx.$t('whatsapp.video_not_supported')), 1 /* TEXT */)
                        ], 8 /* PROPS */, _hoisted_7))
                      : _createCommentVNode("v-if", true)
                  ]))
                : _createCommentVNode("v-if", true),
              _createElementVNode("p", {
                class: "mb-0 px-2 py-2 text-sm font-medium leading-5 text-grey-800",
                innerHTML: _ctx.formattedText(_ctx.templateBody)
              }, null, 8 /* PROPS */, _hoisted_9),
              _createElementVNode("div", _hoisted_10, [
                (!!_ctx.templateFooter)
                  ? (_openBlock(), _createElementBlock("p", {
                      key: 0,
                      class: "mb-0 flex-1 break-words text-xs font-medium leading-4 text-grey-600",
                      innerHTML: _ctx.formattedText(_ctx.templateFooter)
                    }, null, 8 /* PROPS */, _hoisted_11))
                  : _createCommentVNode("v-if", true),
                _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.currentTime), 1 /* TEXT */)
              ])
            ])
          ]),
          (_ctx.filteredButtons.length)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_13, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredButtons, (btn, index) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: btn.id,
                    class: "mb-0 flex h-9 w-full list-none items-center justify-center break-words rounded-md bg-white text-center text-base font-normal leading-5"
                  }, [
                    (!_ctx.isExpanded && index === 2)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 0,
                          class: "h-full w-full",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isExpanded = !_ctx.isExpanded))
                        }, [
                          _createVNode(_component_list_unordered2_fill, { class: "mr-2 inline" }),
                          _createElementVNode("span", null, _toDisplayString(_ctx.$t('whatsapp.template_see_all_options')), 1 /* TEXT */)
                        ]))
                      : (_ctx.isNonInteractiveButton(btn))
                        ? (_openBlock(), _createElementBlock("span", _hoisted_14, [
                            _createVNode(_component_wa_template_preview_button_content, { btn: btn }, null, 8 /* PROPS */, ["btn"])
                          ]))
                        : (_openBlock(), _createElementBlock("a", {
                            key: 2,
                            href: _ctx.getButtonHref(btn),
                            target: "_blank",
                            rel: "noopener noreferrer",
                            class: "flex h-full w-full items-center justify-center no-underline"
                          }, [
                            _createVNode(_component_wa_template_preview_button_content, { btn: btn }, null, 8 /* PROPS */, ["btn"])
                          ], 8 /* PROPS */, _hoisted_15))
                  ]))
                }), 128 /* KEYED_FRAGMENT */))
              ]))
            : _createCommentVNode("v-if", true)
        ]))
      : _createCommentVNode("v-if", true)
  ], 4 /* STYLE */))
}