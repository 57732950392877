<template>
  <div class="h-full w-full overflow-auto bg-grey-100">
    <reporting-page
      :heading-title="$t('reports_v2.ticket_insights')"
      :class="pageHeightClass"
      class="h-full w-full overflow-scroll"
    >
      <template #right>
        <div class="flex flex-row items-center justify-between">
          <available-columns-dropdown :selected-columns="availableColumns" @change="handleGetAvailableColumns" />

          <t-button
            v-if="ticketInsightsStore.isDownloadInProgress"
            v-tooltip="{
              placement: 'top',
              content: $t('reports_v2.download_queue_limit'),
              popperClass: 'tooltip-vuetify',
            }"
            disabled
            class="ml-4"
            size="sm"
          >
            {{ $t('reports_v2.download_table') }}
          </t-button>
          <t-button v-else class="ml-4" size="sm" @click="downloadReport">
            {{ $t('reports_v2.download_table') }}
          </t-button>
        </div>
      </template>
      <div v-show="data.length" class="mt-4 h-screen overflow-auto" data-test="ticket-inisight-table">
        <div
          ref="ticket-insight-table"
          class="relative w-max overflow-hidden rounded-2xl border-1 border-grey-300"
          style="min-width: 100%"
        >
          <t-table-list v-if="data.length" alternate class="p-0" :has-border="false">
            <t-table-list-row head data-test="ticket-inisight-table-header">
              <t-table-list-head>{{ $t('reports_v2.ticket_id') }}</t-table-list-head>
              <t-table-list-head v-if="availableColumns.Assignee" class="pr-4">
                <div class="flex flex-row items-center">
                  <p class="m-0 mr-1">{{ $t('reports_v2.assignee') }}</p>
                </div>
              </t-table-list-head>
              <t-table-list-head v-if="availableColumns.ConversationStatus" class="pr-4">
                {{ $t('reports_v2.status') }}
              </t-table-list-head>
              <t-table-list-head v-if="availableColumns.AttachedLabels" class="pr-4">
                {{ $t('reports_v2.attached_labels') }}
              </t-table-list-head>
              <t-table-list-head v-if="availableColumns.Channel" class="pr-4">
                {{ $t('reports_v2.channel') }}
              </t-table-list-head>
              <t-table-list-head
                v-if="availableColumns.FirstResolutionTimeWithoutBusinessHours"
                class="pl-4 text-right"
              >
                <div class="flex flex-row items-center justify-end">
                  <p class="m-0 mr-1">{{ $t('reports_v2.first_resolution_time_without_business_hours') }}</p>
                  <table-sort>
                    <template #item>
                      <div>
                        <t-list-item
                          :label="$t('reports_v2.time_low_high')"
                          @click="sortBy('first_resolution_time', 'asc')"
                        />
                        <t-list-item
                          :label="$t('reports_v2.time_high_low')"
                          @click="sortBy('first_resolution_time', 'desc')"
                        />
                      </div>
                    </template>
                  </table-sort>
                </div>
              </t-table-list-head>
              <t-table-list-head v-if="availableColumns.FirstResolutionTime" class="pl-4 text-right">
                <div class="flex flex-row items-center justify-end">
                  <p class="m-0 mr-1">{{ $t('reports_v2.first_resolution_time') }}</p>
                  <table-sort>
                    <template #item>
                      <div>
                        <t-list-item
                          :label="$t('reports_v2.time_low_high')"
                          @click="sortBy('first_resolution_time_opening_hours', 'asc')"
                        />
                        <t-list-item
                          :label="$t('reports_v2.time_high_low')"
                          @click="sortBy('first_resolution_time_opening_hours', 'desc')"
                        />
                      </div>
                    </template>
                  </table-sort>
                </div>
              </t-table-list-head>
              <t-table-list-head
                v-if="availableColumns.TotalResolutionTimeWithoutBusinessHours"
                class="pl-4 text-right"
              >
                <div class="flex flex-row items-center justify-end">
                  <p class="m-0 mr-1">{{ $t('reports_v2.total_resolution_time_without_business_hours') }}</p>
                  <table-sort>
                    <template #item>
                      <div>
                        <t-list-item
                          :label="$t('reports_v2.time_low_high')"
                          @click="sortBy('total_resolution_time', 'asc')"
                        />
                        <t-list-item
                          :label="$t('reports_v2.time_high_low')"
                          @click="sortBy('total_resolution_time', 'desc')"
                        />
                      </div>
                    </template>
                  </table-sort>
                </div>
              </t-table-list-head>
              <t-table-list-head v-if="availableColumns.TotalResolutionTime" class="pl-4 text-right">
                <div class="flex flex-row items-center justify-end">
                  <p class="m-0 mr-1">{{ $t('reports_v2.total_resolution_time') }}</p>
                  <table-sort>
                    <template #item>
                      <div>
                        <t-list-item
                          :label="$t('reports_v2.time_low_high')"
                          @click="sortBy('total_resolution_time_opening_hours', 'asc')"
                        />
                        <t-list-item
                          :label="$t('reports_v2.time_high_low')"
                          @click="sortBy('total_resolution_time_opening_hours', 'desc')"
                        />
                      </div>
                    </template>
                  </table-sort>
                </div>
              </t-table-list-head>
              <t-table-list-head v-if="availableColumns.FirstResponseTimeWithoutBusinessHours" class="pl-4 text-right">
                <div class="flex flex-row items-center justify-end">
                  <p class="m-0 mr-1">{{ $t('reports_v2.first_response_time_without_business_hours') }}</p>
                  <table-sort>
                    <template #item>
                      <div>
                        <t-list-item
                          :label="$t('reports_v2.time_low_high')"
                          @click="sortBy('first_response_time', 'asc')"
                        />
                        <t-list-item
                          :label="$t('reports_v2.time_high_low')"
                          @click="sortBy('first_response_time', 'desc')"
                        />
                      </div>
                    </template>
                  </table-sort>
                </div>
              </t-table-list-head>
              <t-table-list-head v-if="availableColumns.FirstResponseTime" class="pl-4 text-right">
                <div class="flex flex-row items-center justify-end">
                  <p class="m-0 mr-1">{{ $t('reports_v2.first_response_time') }}</p>
                  <table-sort>
                    <template #item>
                      <div>
                        <t-list-item
                          :label="$t('reports_v2.time_low_high')"
                          @click="sortBy('first_response_time_opening_hours', 'asc')"
                        />
                        <t-list-item
                          :label="$t('reports_v2.time_high_low')"
                          @click="sortBy('first_response_time_opening_hours', 'desc')"
                        />
                      </div>
                    </template>
                  </table-sort>
                </div>
              </t-table-list-head>
              <t-table-list-head
                v-if="availableColumns.FirstHumanResponseTimeWithoutBusinessHours"
                class="pl-4 text-right"
              >
                <div class="flex flex-row items-center justify-end">
                  <p class="m-0 mr-1 whitespace-nowrap">
                    {{ $t('reports_v2.first_human_response_time_without_business_hours') }}
                  </p>
                  <table-sort>
                    <template #item>
                      <div>
                        <t-list-item
                          :label="$t('reports_v2.time_low_high')"
                          @click="sortBy('first_human_response_time', 'asc')"
                        />
                        <t-list-item
                          :label="$t('reports_v2.time_high_low')"
                          @click="sortBy('first_human_response_time', 'desc')"
                        />
                      </div>
                    </template>
                  </table-sort>
                </div>
              </t-table-list-head>
              <t-table-list-head v-if="availableColumns.FirstHumanResponseTime" class="pl-4 text-right">
                <div class="flex flex-row items-center justify-end">
                  <p class="m-0 mr-1 whitespace-nowrap">
                    {{ $t('reports_v2.first_human_response_time_with_business_hours') }}
                  </p>
                  <table-sort>
                    <template #item>
                      <div>
                        <t-list-item
                          :label="$t('reports_v2.time_low_high')"
                          @click="sortBy('first_human_response_time_opening_hours', 'asc')"
                        />
                        <t-list-item
                          :label="$t('reports_v2.time_high_low')"
                          @click="sortBy('first_human_response_time_opening_hours', 'desc')"
                        />
                      </div>
                    </template>
                  </table-sort>
                </div>
              </t-table-list-head>
              <t-table-list-head v-if="availableColumns.CreatedAt" class="pl-4 text-right">
                <div class="flex flex-row items-center justify-end">
                  <p class="m-0 mr-1">{{ $t('reports_v2.created_on') }}</p>
                  <table-sort>
                    <template #item>
                      <div>
                        <t-list-item :label="$t('reports_v2.time_low_high')" @click="sortBy('created_at', 'asc')" />
                        <t-list-item :label="$t('reports_v2.time_high_low')" @click="sortBy('created_at', 'desc')" />
                      </div>
                    </template>
                  </table-sort>
                </div>
              </t-table-list-head>
              <t-table-list-head v-if="availableColumns.AssignedAt" class="pl-4 text-right">
                <div class="flex flex-row items-center justify-end">
                  <p class="m-0 mr-1">{{ $t('reports_v2.assigned_on') }}</p>
                  <table-sort>
                    <template #item>
                      <div>
                        <t-list-item :label="$t('reports_v2.time_low_high')" @click="sortBy('assigned_at', 'asc')" />
                        <t-list-item :label="$t('reports_v2.time_high_low')" @click="sortBy('assigned_at', 'desc')" />
                      </div>
                    </template>
                  </table-sort>
                </div>
              </t-table-list-head>
              <t-table-list-head v-if="availableColumns.ClosedAt" class="pl-4 text-right">
                <div class="flex flex-row items-center justify-end">
                  <p class="m-0 mr-1">{{ $t('reports_v2.closed_on') }}</p>
                  <table-sort>
                    <template #item>
                      <div>
                        <t-list-item :label="$t('reports_v2.time_low_high')" @click="sortBy('closed_at', 'asc')" />
                        <t-list-item :label="$t('reports_v2.time_high_low')" @click="sortBy('closed_at', 'desc')" />
                      </div>
                    </template>
                  </table-sort>
                </div>
              </t-table-list-head>
              <t-table-list-head v-if="availableColumns.MessagesSent" class="pl-4 text-right">
                <div class="flex flex-row items-center justify-end">
                  <p class="m-0 mr-1">{{ $t('reports_v2.messages_sent') }}</p>
                </div>
              </t-table-list-head>
              <t-table-list-head v-if="availableColumns.ChannelType" class="pl-4 text-right">
                <div class="flex flex-row items-center">
                  <p class="m-0 mr-1">{{ $t('reports_v2.channel_type') }}</p>
                </div>
              </t-table-list-head>
              <t-table-list-head v-if="availableColumns.ContactEmail" class="pl-4 text-right">
                <div class="flex flex-row items-center">
                  <p class="m-0 mr-1">{{ $t('reports_v2.contact_email') }}</p>
                </div>
              </t-table-list-head>
              <t-table-list-head v-if="availableColumns.ContactPhone" class="pl-4 text-right">
                <div class="flex flex-row items-center">
                  <p class="m-0 mr-1">{{ $t('reports_v2.contact_phone') }}</p>
                </div>
              </t-table-list-head>
              <t-table-list-head v-if="availableColumns.ContactCustomFields" class="pl-4 text-right">
                <div class="flex flex-row items-center">
                  <p class="m-0 mr-1">{{ $t('reports_v2.contact_custom_fields') }}</p>
                </div>
              </t-table-list-head>
              <t-table-list-head v-if="availableColumns.ProfileCustomFields" class="pl-4 text-right">
                <div class="flex flex-row items-center">
                  <p class="m-0 mr-1">{{ $t('reports_v2.profile_custom_fields') }}</p>
                </div>
              </t-table-list-head>
              <t-table-list-head v-if="availableColumns.TicketCustomFields" class="pl-4 text-right">
                <div class="flex flex-row items-center">
                  <p class="m-0 mr-1">{{ $t('reports_v2.ticket_custom_fields') }}</p>
                </div>
              </t-table-list-head>
              <t-table-list-head v-if="availableColumns.TicketResult" class="pl-4 text-right">
                <div class="flex flex-row items-center">
                  <p class="m-0 mr-1">{{ $t('reports_v2.ticket_result') }}</p>
                </div>
              </t-table-list-head>
              <t-table-list-head class="pr-4" />
            </t-table-list-row>

            <ticket-insight-row
              v-for="item in data"
              :key="item.ticket_id"
              :available-data="item"
              :available-columns="availableColumns"
            />
            <t-table-list-row v-if="hasSpinner">
              <single-row-skeleton
                :style="`width:${tableWidth}px`"
                data-test="single-row-skeleton"
                class="absolute px-4"
                :animation="true"
                :show-error="false"
              />
            </t-table-list-row>
          </t-table-list>
        </div>
        <infinite-loading @infinite="fetchMoreData">
          <template #spinner>
            <div></div>
          </template>
          <template #no-more>
            <div></div>
          </template>
          <template #no-results>
            <div></div>
          </template>
        </infinite-loading>
      </div>
      <table-skeleton v-if="!data.length" :animation="!loaded" :show-error="!data.length && loaded" />
    </reporting-page>
  </div>
</template>

<script lang="ts">
import moment from 'moment';
import { defineComponent } from 'vue';
import infiniteLoading from 'vue-infinite-loading';

import ReportingPage from '@/components/common/ReportingPage';
import TableSkeleton from '@/components/common/TableSkeleton/Index.vue';
import SingleRowSkeleton from '@/components/common/TableSkeleton/SingleRowSkeleton.vue';
import AvailableColumnsDropdown from '@/components/Reporting/Components/V2/AvailableColumnsDropdown/Index.vue';
import TableSort from '@/components/Reporting/Components/V2/TableSort/Index.vue';
import { useReportStore } from '@/components/Reporting/store';
import eventBus from '@/eventBus';
import { useToastMessageStore } from '@/store/pinia/toastMessage';

import { fetchTicketInsights, callExportAPI } from './api';
import { useTicketInsightsStore } from './store';
import TicketInsightRow from './TicketInsightRow.vue';
import { manipulateThePageColumns, DefaultAvailableColumns, AvailableColumnsMapper } from './utils';

import type { Sort } from './api';
import type { Filter } from '@/components/Reporting/Components/V2/FilterDropdown/FilterDropdown.vue';
import type { StateChanger } from 'vue-infinite-loading';
import { TicketInsightsResponse } from './types';
type AvaialableColumns = typeof DefaultAvailableColumns;

type ApiParams = {
  url: string;
  filters: string;
};

type Data = {
  availableColumns: AvaialableColumns;
  data: TicketInsightsResponse[];
  page: number;
  loaded: boolean;
  allDataLoaded: boolean;
  hasSpinner: boolean;
  sort: { order_by: string; sort_by: string } | Record<string, never>;
  enableDownloadButton: boolean;
  ticketInsightsStore: ReturnType<typeof useTicketInsightsStore>;
  toastMessageState: ReturnType<typeof useToastMessageStore>;
  apiParams: ApiParams;
  tableWidth: number;
  reportStore: ReturnType<typeof useReportStore>;
  filtersFromRouteQuery: string;
};

type PusherData = {
  status: 'COMPLETED' | 'PENDING' | 'FAILED';
  file_path: string;
  export_id: number;
};
const ITEM_LIST_PAGE_SIZE = 25;
const AvailableColumns: AvaialableColumns = DefaultAvailableColumns;
export default defineComponent({
  name: 'TicketInsights',
  components: {
    SingleRowSkeleton,
    ReportingPage,
    AvailableColumnsDropdown,
    TicketInsightRow,
    TableSort,
    TableSkeleton,
    infiniteLoading,
  },
  data(): Data {
    return {
      availableColumns: AvailableColumns as AvaialableColumns,
      data: [] as TicketInsightsResponse[],
      page: 1,
      loaded: false,
      allDataLoaded: false,
      hasSpinner: false,
      sort: {},
      enableDownloadButton: true,
      ticketInsightsStore: useTicketInsightsStore(),
      toastMessageState: useToastMessageStore(),
      apiParams: {} as ApiParams,
      tableWidth: 0,
      reportStore: useReportStore(),
      filtersFromRouteQuery: '',
    };
  },

  mounted() {
    this.tableWidth = this.$refs['ticket-insight-table'].clientWidth;
    this.ticketInsightsStore = useTicketInsightsStore();
    this.filters = this.$route.query.filters;
    this.filtersFromRouteQuery = this.$route.query.filters;
    if (this.$route?.name === 'reportingTicketInsightsSpecificPage') {
      this.availableColumns = manipulateThePageColumns(this.$route.params.page);
      this.apiParams = this.parseUrlFromPage(this.$route.params.page);
    } else {
      const filters = this.filtersFromRouteQuery ? this.parseFilters(this.filtersFromRouteQuery) : '';
      this.apiParams = { url: 'details/ticket', filters };
      this.availableColumns = { ...AvailableColumns };
    }

    this.reportStore.$patch({
      reportingFilters: {
        hasDatePicker: true,
        hasConversation: true,
        hasStatus: true,
        hasLabel: true,
        hasCSAT: false,
        hasTicketAssignedAt: true,
        hasTicketClosedAt: true,
        hasTicketCreatedAt: true,
        hasAgent: false,
      },
    });
    eventBus.$on('reporting-filter', this.fetchFilters);
    this.loadData();
  },
  unmounted() {
    eventBus.$off('reporting-filter', this.fetchFilters);
    this.availableColumns = DefaultAvailableColumns;
    this.reportStore.$patch({
      ticketsAmount: 0,
      reportingFilters: {
        hasDatePicker: false,
        hasConversation: false,
        hasStatus: false,
        hasCSAT: false,
        hasTicketAssignedAt: false,
        hasTicketClosedAt: false,
        hasTicketCreatedAt: false,
      },
    });
  },
  computed: {
    pageHeightClass() {
      return this.data.length <= 6 ? '!h-[99vh]' : 'h-auto';
    },
  },
  methods: {
    fetchFilters(filters: Filter) {
      this.filtersFromRouteQuery = JSON.stringify(filters);
      this.apiParams.filters = this.mapFilters(filters);

      this.$nextTick(() => {
        this.loaded = false;
        this.page = 1;
        this.data = [];
        this.loadData();
      });
    },
    bindUserDownloadReportingChannel() {
      this.$root.userChannel.bind('ReportingExportProgress', (data: PusherData) =>
        this.fetchDownloadReportingStatus(data),
      );
    },
    unbindUserDownloadReportingChannel() {
      this.$root.userChannel.unbind('ReportingExportProgress');
    },
    fetchDownloadReportingStatus(data: PusherData) {
      this.ticketInsightsStore.$patch({
        isDownloadInProgress: false,
      });
      if (data.status === 'COMPLETED') {
        this.downloadReportingCompletedStatus(data.file_path);
      }
      if (data.status === 'FAILED') {
        this.downloadReportingFailedStatus();
      }
    },
    async createReportingLinkAndDownload(file_path: string) {
      window.open(file_path, '_blank');
    },
    downloadReportingCompletedStatus(file_path: string) {
      this.toastMessageState.$patch({
        hasCloseIcon: false,
        iconType: 'success',
        hasIcon: true,
        text: this.$t('reports_v2.your_file_has_been_downloaded'),
      });
      this.createReportingLinkAndDownload(file_path);
      this.toastMessageState.hideToastMessage();
      this.unbindUserDownloadReportingChannel();
    },
    downloadReportingFailedStatus() {
      this.toastMessageState.$patch({
        hasCloseIcon: true,
        hasIcon: true,
        iconType: 'alert',
        text: this.$t('reports_v2.failed_to_download'),
      });
      this.unbindUserDownloadReportingChannel();
    },
    downloadReport() {
      const columns = Object.entries(this.availableColumns)
        .filter(([_, value]) => value)
        .map(([key]) => AvailableColumnsMapper[key as keyof typeof AvailableColumnsMapper])
        .filter(Boolean);

      callExportAPI({ columns }, this.sort, this.filtersFromRouteQuery).then(() => {
        this.toastMessageState.closeToastMessage();
        this.ticketInsightsStore.$patch({
          isDownloadInProgress: true,
        });
        this.toastMessageState.$patch({
          showToast: true,
          text: this.$t('reports_v2.preparing_download_file'),
        });
        this.bindUserDownloadReportingChannel();
      });
    },
    loadData() {
      fetchTicketInsights(this.apiParams.url, 1, this.apiParams.filters, {} as Sort)
        .then((res) => {
          this.data = [...this.data, ...res.data.data];
          this.loaded = true;
          this.reportStore.$patch({
            ticketsAmount: res.data.meta.total,
          });
        })
        .catch((error) => {
          console.error(error);
          this.loaded = true;
        });
    },
    sortBy(field: string, sort_order: 'asc' | 'desc') {
      if (!field || !sort_order) {
        return;
      }
      this.loaded = false;
      this.data = [];
      this.page = 1;
      this.sort = { order_by: field, sort_order };
      fetchTicketInsights(this.apiParams.url, 1, this.apiParams.filters, this.sort).then((res) => {
        this.data = [...this.data, ...res.data.data];
        this.loaded = true;
      });
    },
    async fetchMoreData(scroll: StateChanger) {
      this.tableWidth = this.$refs['ticket-insight-table'].clientWidth;
      this.hasSpinner = true;
      if (this.allDataLoaded) {
        scroll.complete();
        this.hasSpinner = false;
        return;
      }

      if (this.page === 1) {
        this.page++;
      }

      try {
        let ticketInsights = await fetchTicketInsights(
          this.apiParams.url,
          this.page,
          this.apiParams.filters,
          this.sort,
        );

        // Retry mechanism
        // it's currently necessary because the endpoint is unstable, and often returns less results than expected
        if (
          ticketInsights.data.meta.total !== ticketInsights.data.meta.to &&
          ticketInsights.data.data.length < ITEM_LIST_PAGE_SIZE
        ) {
          ticketInsights = await fetchTicketInsights(this.apiParams.url, this.page, this.apiParams.filters, this.sort);
        }

        if (ticketInsights.data.meta.total === ticketInsights.data.meta.to) {
          this.allDataLoaded = true;
          scroll.complete();
        } else {
          this.page++;
          scroll.loaded();
        }

        this.data = [...this.data, ...ticketInsights.data.data];
        this.hasSpinner = false;
      } catch {
        scroll.complete();
        this.hasSpinner = false;
      }
    },

    handleGetAvailableColumns(columns: AvaialableColumns) {
      this.availableColumns = columns;
      this.$nextTick(() => {
        this.tableWidth = this.$refs['ticket-insight-table'].clientWidth;
      });
    },

    parseUrlFromPage(page: string) {
      const { encodedStartDate, encodedEndDate } = this.getDatesFromLast4Weeks();

      this.filtersFromRouteQuery = this.$route.query.filters;
      const serializedFilters = this.filtersFromRouteQuery ? this.parseFilters(this.filtersFromRouteQuery) : '';

      const ticketsUrls = {
        new_conversation: {
          url: 'details/ticket',
          filters: `statuses[]=OPEN${serializedFilters ? '&' + serializedFilters : ''}`,
        },
        assigned_conversation: {
          url: 'details/ticket',
          filters: `statuses[]=ASSIGNED${serializedFilters ? '&' + serializedFilters : ''}`,
        },
        resolution_time: {
          url: 'details/ticket',
          filters: `closed_start_date=${encodedStartDate}&closed_end_date=${encodedEndDate}${
            serializedFilters ? '&' + serializedFilters : ''
          }`,
        },
        first_response_time: {
          url: 'details/ticket',
          filters: `created_start_date=${encodedStartDate}&created_end_date=${encodedEndDate}${
            serializedFilters ? '&' + serializedFilters : ''
          }`,
        },
        created_conversations: {
          url: 'details/ticket',
          filters: serializedFilters,
        },
        closed_conversations: {
          url: 'details/ticket',
          filters: serializedFilters,
        },
        reopened_conversations: {
          url: 'details/ticket',
          filters: serializedFilters,
        },
      };
      return ticketsUrls[page];
    },

    getDatesFromLast4Weeks() {
      const startDate = moment()
        .tz(this.$root.user.timezone)
        .subtract(4, 'week')
        .startOf('day')
        .format('YYYY-MM-DDTHH:mm:ssZ');
      const endDate = moment().tz(this.$root.user.timezone).format('YYYY-MM-DDTHH:mm:ssZ');
      const encodedStartDate = encodeURIComponent(startDate);
      const encodedEndDate = encodeURIComponent(endDate);

      return { encodedStartDate, encodedEndDate };
    },

    parseFilters(filters: string) {
      const filtersObject = JSON.parse(filters);

      return this.mapFilters(filtersObject);
    },

    mapFilters(filtersObject: object) {
      let serializedFilters = '';

      for (const key in filtersObject) {
        const currentFilter = filtersObject[key];
        let filterString;
        if (typeof currentFilter === 'object') {
          filterString = currentFilter.map((filter: number | string) => `${key}[]=${filter}`).join('&');
        } else {
          filterString = `${key}=${currentFilter}`;
        }
        serializedFilters = `${serializedFilters ? serializedFilters + '&' : ''}${filterString}`;
      }

      return serializedFilters;
    },
  },
});
</script>
