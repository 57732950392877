<template>
  <div>
    <permissions-list-item
        v-if="showFullAccess"
        :title="$t('user_management.full_access_to_trengo_ai')"
        :subtitle="$t('user_management.create_edit_delete_helpmates_and_journeys')"
    >
      <template #icon>
        <t-thumbnail variant="clean" class="bg-leaf-200">
          <icon-ai
              border-color="#000000"
              fill="#000000"
              width="18"
              height="18"
          />
        </t-thumbnail>
      </template>
    </permissions-list-item>
    <permissions-list-item
      v-if="showFullAccessToInbox"
      :title="$t('user_management.full_access_to_inbox')"
      :subtitle="$t('user_management.create_edit_assign_conversations')"
    >
      <template #icon>
        <t-thumbnail variant="clean" class="bg-purple-200">
          <inbox-linear />
        </t-thumbnail>
      </template>
    </permissions-list-item>

    <permissions-list-item
      v-if="showPartialToInbox"
      :title="$t('user_management.partial_access_to_inbox')"
      :subtitle="partialToInboxSubtitle"
    >
      <template #icon>
        <t-thumbnail variant="clean" class="bg-purple-200">
          <inbox-linear />
        </t-thumbnail>
      </template>
    </permissions-list-item>

    <permissions-list-item
      v-if="showFullAccessToContactsAndProfiles"
      :title="$t('user_management.full_access_to_contacts_profiles')"
      :subtitle="$t('user_management.create_edit_delete_contacts_profiles')"
    >
      <template #icon>
        <t-thumbnail variant="clean" class="bg-sky-200">
          <user-square-linear />
        </t-thumbnail>
      </template>
    </permissions-list-item>

    <permissions-list-item
      v-if="showPartialAccessToContactsAndProfiles"
      :title="$t('user_management.partial_access_to_contacts_profiles')"
      :subtitle="$t('user_management.create_view_edit_contacts_profiles')"
    >
      <template #icon>
        <t-thumbnail variant="clean" class="bg-sky-200">
          <user-square-linear />
        </t-thumbnail>
      </template>
    </permissions-list-item>

    <permissions-list-item
      v-if="showPartialAccessToContacts"
      :title="$t('user_management.partial_access_to_contacts_profiles')"
      :subtitle="$t('user_management.create_view_edit_contacts')"
    >
      <template #icon>
        <t-thumbnail variant="clean" class="bg-sky-200">
          <user-square-linear />
        </t-thumbnail>
      </template>
    </permissions-list-item>

    <permissions-list-item
      v-if="showFullAccessCompanySettings"
      :title="$t('user_management.full_access_company_settings')"
      :subtitle="$t('user_management.create_edit_delete_automations_channels_permissions')"
    >
      <template #icon>
        <t-thumbnail variant="clean" class="bg-sun-200">
          <gear-linear />
        </t-thumbnail>
      </template>
    </permissions-list-item>

    <permissions-list-item
      v-if="showPartialToCompanySettings"
      :title="$t('user_management.partial_access_company_settings')"
      :subtitle="$t('user_management.create_edit_delete_business_hours_locations')"
    >
      <template #icon>
        <t-thumbnail variant="clean" class="bg-sun-200">
          <gear-linear />
        </t-thumbnail>
      </template>
    </permissions-list-item>

    <permissions-list-item
      v-if="showFullAccess"
      :title="$t('user_management.full_access_subscription')"
      :subtitle="$t('user_management.start_update_subscription')"
    >
      <template #icon>
        <t-thumbnail variant="clean" class="bg-galaxy-200">
          <wallet-linear />
        </t-thumbnail>
      </template>
    </permissions-list-item>
  </div>
</template>

<script>
import { GearLinear, InboxLinear, UserSquareLinear, WalletLinear } from '@trengo/trengo-icons';

import iconAi from '@/components/sidebarNavigationIcons/iconAI.vue';
import PermissionsListItem from '@/components/Users/components/ManageRoles/PermissionsListItem';
import { USER_ROLE } from '@/Configs/Constants/UserRoles';

const { ADMINISTRATOR, SUPERVISOR, ADVANCED_AGENT, BASIC_AGENT, OBSERVER } = USER_ROLE;

export default {
  name: 'PermissionsList',
  components: {
    GearLinear,
    InboxLinear,
    PermissionsListItem,
    UserSquareLinear,
    WalletLinear,
    iconAi
  },
  props: {
    activeRole: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    partialToInboxSubtitle() {
      return this.activeRole.name === OBSERVER
        ? this.$t('user_management.view_comment_assigned_conversations')
        : this.$t('user_management.create_answer_conversations');
    },
    showFullAccess() {
      return [ADMINISTRATOR].includes(this.activeRole.name);
    },
    showFullAccessToInbox() {
      return [ADMINISTRATOR, SUPERVISOR, ADVANCED_AGENT].includes(this.activeRole.name);
    },
    showFullAccessCompanySettings() {
      return [ADMINISTRATOR].includes(this.activeRole.name);
    },
    showPartialToInbox() {
      return [OBSERVER, BASIC_AGENT].includes(this.activeRole.name);
    },
    showPartialToCompanySettings() {
      return [SUPERVISOR].includes(this.activeRole.name);
    },
    showFullAccessToContactsAndProfiles() {
      return [ADMINISTRATOR, SUPERVISOR].includes(this.activeRole.name);
    },
    showPartialAccessToContactsAndProfiles() {
      return [ADVANCED_AGENT].includes(this.activeRole.name);
    },
    showPartialAccessToContacts() {
      return [BASIC_AGENT, OBSERVER].includes(this.activeRole.name);
    },
  },
};
</script>
