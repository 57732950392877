<template>
  <div class="rounded-xl border border-solid border-grey-300 bg-grey-200 px-3 py-3">
    <p v-if="headerSubType === TEMPLATE_HEADER_TYPE.TEXT.toUpperCase()" class="mb-2 text-sm font-bold text-grey-700">
      {{ headerText }}
    </p>
    <t-file-input
      v-if="hasMedia"
      :id="`${composerInstanceId}-file-input`"
      class="mb-2"
      :variant="headerSubType"
      :value="selectedFile"
      :max-file-size="{
        size: maxFileSize,
        text: $t('whatsapp.max_file_size', { fileSize: convertBytesToMB(maxFileSize) }),
      }"
      @update:value="$emit('handle-file-change', $event)"
      @reset="$emit('reset')"
    />
    <p class="t-text-sm text-grey-60 mb-0">{{ body }}</p>
    <p v-if="footer" class="t-text-xs mb-0 mt-2 text-grey-600">{{ footer }}</p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import {
  TEMPLATE_HEADER_TYPE,
  TEMPLATE_HEADER_MEDIA_TYPES,
  FILE_SIZE_LIMIT_IN_DECIMAL_BYTES,
} from '@/Configs/Constants/whatsapp';
import { convertBytesToMB } from '@/util/fileHelper';

import type { TemplateHeaderType } from '@/components/WaTemplates/types';

export default defineComponent({
  name: 'WaTemplateMessage',
  props: {
    body: {
      type: String,
      default: '',
    },
    header: {
      type: Object,
      default: () => {},
    },
    footer: {
      type: String,
      default: '',
    },
    selectedFile: {
      type: Array,
      default: () => [],
    },
    forceFileValidation: {
      type: Boolean,
      default: false,
    },
    composerInstanceId: {
      type: String,
      default: '',
      required: true,
    },
  },
  emits: ['handle-file-change', 'reset'],
  data() {
    return {
      TEMPLATE_HEADER_TYPE,
      FILE_SIZE_LIMIT_IN_DECIMAL_BYTES,
    };
  },
  computed: {
    headerSubType(): TemplateHeaderType {
      return this.header?.sub_type?.toLowerCase();
    },
    hasMedia(): boolean {
      return TEMPLATE_HEADER_MEDIA_TYPES.includes(this.headerSubType);
    },
    headerText(): string {
      return this.header.sub_type === TEMPLATE_HEADER_TYPE.TEXT.toUpperCase() ? this.header?.value : '';
    },
    maxFileSize(): number {
      if (this.headerSubType === TEMPLATE_HEADER_TYPE.VIDEO) {
        return FILE_SIZE_LIMIT_IN_DECIMAL_BYTES.GENERAL;
      }
      return FILE_SIZE_LIMIT_IN_DECIMAL_BYTES.IMAGE;
    },
  },
  methods: {
    convertBytesToMB(bytes: number): number {
      return convertBytesToMB(bytes);
    },
  },
});
</script>
