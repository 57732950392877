<template>
  <div class="rounded-xl bg-grey-200 p-6">
    <h4 class="t-text-h5 text-grey-800">{{ $t('whatsapp.samples_for_body_content') }}</h4>
    <p class="t-text-md mb-0 text-grey-600">
      {{ $t('whatsapp.whatsapp_requires_you_to_prefill') }}
    </p>
    <div class="mt-6">
      <template-header-file-input
        v-if="headerIsMediaType"
        :selected-header-type="selectedHeaderType"
        @update-file="$emit('updateFile', $event)"
      />
      <div v-for="(variable, index) in templateVariablesCopy" :key="variable.key" class="mb-2">
        <t-input-text
          :id="`variableSample-${variable.key}`"
          v-model.trim="variable.value"
          :placeholder="
            $t('whatsapp.type_something_to_replace', {
              variable: variable.key,
            })
          "
          type="text"
          :label="
            $t('whatsapp.samples_for_body_variable', {
              variable: variable.key,
            })
          "
          :disabled="disabled"
          data-test="variable-sample-input"
          @input="updateTemplateVariable($event, index)"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, onMounted } from 'vue';

import TemplateHeaderFileInput from '../TemplateHeaderFileInput/TemplateHeaderFileInput.vue';

import type { TemplateVariable, TemplateHeaderType } from '../types';

const props = defineProps<{
  selectedHeaderType: TemplateHeaderType;
  templateVariables: TemplateVariable[];
  headerIsMediaType: boolean;
  disabled: boolean;
}>();

const emit = defineEmits<{
  updateFile: [files: File[]];
  updateTemplateVariables: [variables: TemplateVariable[]];
}>();

const templateVariablesCopy = ref<TemplateVariable[]>([]);

watch(
  () => props.templateVariables,
  (newVariables) => {
    templateVariablesCopy.value = newVariables;
  },
  { deep: true },
);

onMounted(() => {
  templateVariablesCopy.value = props.templateVariables.map((variable: TemplateVariable) => ({
    ...variable,
  }));
});

const updateTemplateVariable = (event: Event, index: number) => {
  templateVariablesCopy.value[index].value = (event.target as HTMLInputElement).value;
  emit('updateTemplateVariables', templateVariablesCopy.value);
};
</script>
