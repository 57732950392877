<template>
  <t-inline-banner v-if="modelValue" type="warning" class="tags-warning mt-2" data-test="recipients-warning-banner">
    <template #icon>
      <user-cross-linear />
    </template>
    <div>{{ $t('broadcast.some_numbers_are_not_valid_and_have_not_been_added') }}</div>
    <template #action>
      <t-button size="sm" btn-style="secondary" data-test="close-recipients-warning-button" @click="closeWarning">
        Close
      </t-button>
    </template>
  </t-inline-banner>
</template>

<script setup lang="ts">
import { UserCrossLinear } from '@trengo/trengo-icons';

defineProps<{
  modelValue: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
}>();

const closeWarning = () => {
  emit('update:modelValue', false);
};
</script>
