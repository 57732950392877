<template>
  <div class="wab-setting-item">
    <div>
      <div v-if="$slots.label" class="t-text-desktop-label-md mb-1"><slot name="label" /></div>
      <div v-if="$slots.desc" class="t-text-desktop-paragraph-xs mb-2 text-grey-600">
        <slot name="desc" />
      </div>

      <slot />
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'WabSettingItem',
};
</script>
