import { PERMISSION } from '@/Configs/Constants';

import type { RouteRecord } from '../types';

const labelsRoutes: RouteRecord[] = [
  {
    path: '/admin/labels',
    component: () => import(/* webpackChunkName: "Labels" */ '../../components/Labels/Index.vue'),
    meta: { permissionRequired: PERMISSION.SETTINGS__LABELS__MANAGE },
    children: [
      {
        path: '/admin/labels/create',
        component: () => import(/* webpackChunkName: "Labels" */ '../../components/Labels/Edit.vue'),
        props: { action: 'create' },
        meta: { permissionRequired: PERMISSION.SETTINGS__LABELS__MANAGE },
      },
      {
        path: '/admin/labels/:id',
        component: () => import(/* webpackChunkName: "Labels" */ '../../components/Labels/Edit.vue'),
        props: { action: 'edit' },
        meta: { permissionRequired: PERMISSION.SETTINGS__LABELS__MANAGE },
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "Labels" */ '../../components/AdminIndexBox.vue'),
        props: { boxName: 'labels' },
        meta: { permissionRequired: PERMISSION.SETTINGS__LABELS__MANAGE },
      },
    ],
  },
];

export default labelsRoutes;
