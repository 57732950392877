<template>
  <div ref="templateDropDownButton" class="relative w-fit">
    <t-button btn-style="secondary" size="sm" @click="toggleTemplateDropdown">
      <div class="flex flex-row items-center">
        <span>{{ waTemplate.title }}</span>
        <chevron-down-linear class="ml-2" size="1.5rem" />
      </div>
    </t-button>

    <div
      v-show="openTemplateDropdown"
      ref="templateDropDown"
      :class="[
        'absolute z-50 flex w-80 flex-col rounded-xl border border-grey-300 bg-white shadow-500',
        'max-h-[420px]',
        isDropdownPositionedAbove ? 'bottom-full mb-2' : 'top-full mt-2',
      ]"
    >
      <div class="flex flex-col">
        <div class="border-b border-grey-300 p-2">
          <t-input-search
            v-model="wa_template_search"
            size="md"
            variant="primary-alt"
            :placeholder="$t('tickets.wa_select_template_dropdown_placeholder')"
            @input="onSearchKeywordChange"
            @clear="onSearchKeywordClear"
            @keydown="onKeyDownWaTemplates"
          />
        </div>
      </div>
      <div
        v-if="filteredWhatsappTemplates.length === 0"
        class="flex max-h-[372px] flex-grow flex-col items-center justify-center gap-2 p-2"
      >
        <div class="border-gray-500 h-10 w-10 rounded-md border p-2">
          <SearchLinear size="1.5rem" />
        </div>
        <p class="line-clamp-2 h-fit max-w-full text-center">
          {{ $t('tickets.wa_select_template_dropdown_no_match') }} &apos;{{ wa_template_search }}&apos;
        </p>
      </div>
      <div v-else ref="templateDropDownList" class="flex grow flex-col overflow-y-auto overflow-x-hidden">
        <template v-for="(section, sectionIndex) in templateGroups" :key="sectionIndex">
          <div
            v-if="section.length"
            :class="['p-2', { 'border-gray-100 border-b': sectionIndex === 0 && shouldShowSectionDivider }]"
          >
            <t-list-item
              v-for="(template, index) in section.templates"
              :key="template.text"
              v-tooltip="{ content: template.text, delay: { show: 500, hide: 50 } }"
              tabindex="0"
              :class="[
                'cursor-pointer overflow-x-hidden focus:bg-grey-200',
                { 'focus:bg-grey-300': waTemplateSelectedIndex === index + section.indexOffset },
              ]"
              :label="template.text"
              :value="template.value"
              :active="waTemplateSelectedIndex === index + section.indexOffset"
              size="sm"
              @click="setWhatsappTemplate(template)"
              @keydown.enter="setWhatsappTemplate(template)"
              @mouseenter="updateHoveredTemplateIndex(index + section.indexOffset)"
              @focus="updateHoveredTemplateIndex(index + section.indexOffset)"
            >
              <template #postfix>
                <star-fill
                  v-if="displayGreyFavouriteIcon(template, index + section.indexOffset)"
                  class="cursor-pointer text-grey-600"
                  @click.stop="markTemplateAsFavourite(template)"
                />
                <star-fill
                  v-if="displayYellowFavouriteIcon(template)"
                  class="text-yellow"
                  @click.stop="unMarkTemplateAsFavourite(template)"
                />
              </template>
            </t-list-item>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ChevronDownLinear, SearchLinear, StarFill } from '@trengo/trengo-icons';
import { onClickOutside } from '@vueuse/core';
import { hideAllPoppers } from 'floating-vue';
import { ref, computed, onMounted, onUnmounted, nextTick } from 'vue';

import { starWaTemplate, unStarWaTemplate } from '../api';

import type { FilteredWhatsappTemplate } from '../types/composer';
import type { Template } from '@/types/waTemplates';

const props = defineProps<{ waTemplate: Template; waTemplateMessages: Template[] }>();

const emit = defineEmits(['set-whatsapp-template', 're-fetch-whatsapp-templates']);

const openTemplateDropdown = ref(false);
const wa_template_search = ref('');
const hoveredTemplateIndex = ref(-1);
const selectedWaTemplate = ref<Template | null>(null);
const waTemplateSelectedIndex = ref(-1);
const templateDropDownList = ref<HTMLElement | null>(null);
const templateDropDownButton = ref<HTMLElement | null>(null);
const templateDropDown = ref<HTMLElement | null>(null);
const isDropdownPositionedAbove = ref(false);

const setWhatsappTemplate = (template: FilteredWhatsappTemplate) => {
  selectedWaTemplate.value = template.value;
  emit('set-whatsapp-template', template);
  openTemplateDropdown.value = false;
};

const onScroll = () => {
  hideAllPoppers();
};

const handleDropdownClose = () => {
  openTemplateDropdown.value = false;
  wa_template_search.value = '';
  templateDropDownList.value?.removeEventListener('scroll', onScroll);
};

const handleDropdownOpen = async () => {
  openTemplateDropdown.value = true;
  await nextTick();
  calculatePosition();
  templateDropDownList.value?.addEventListener('scroll', onScroll);
  waTemplateSelectedIndex.value = filteredWhatsappTemplates.value.findIndex(
    (template: FilteredWhatsappTemplate) => template.slug === props.waTemplate.slug,
  );
};

const toggleTemplateDropdown = async (e: MouseEvent) => {
  e.preventDefault();
  e.stopPropagation();

  if (openTemplateDropdown.value) {
    handleDropdownClose();
  } else {
    await handleDropdownOpen();
  }
};

onClickOutside(templateDropDown, (event) => {
  if (templateDropDownButton.value?.contains(event.target as Node)) return;
  handleDropdownClose();
});

const onKeyDownWaTemplates = ($event: KeyboardEvent) => {
  if ($event.key === 'Enter') {
    $event.preventDefault();
    if (filteredWhatsappTemplates.value.length === 0) {
      return;
    }
    setWhatsappTemplate(filteredWhatsappTemplates.value[waTemplateSelectedIndex.value]);
    wa_template_search.value = '';
    openTemplateDropdown.value = false;
  }
  if ($event.key === 'ArrowDown' && filteredWhatsappTemplates.value.length > 0) {
    $event.preventDefault();
    if (waTemplateSelectedIndex.value < filteredWhatsappTemplates.value.length - 1) {
      waTemplateSelectedIndex.value++;
    }
  }
  if ($event.key === 'ArrowUp' && filteredWhatsappTemplates.value.length > 0) {
    $event.preventDefault();
    if (waTemplateSelectedIndex.value > 0) {
      waTemplateSelectedIndex.value--;
    }
  }
};

const calculatePosition = () => {
  if (!templateDropDownButton.value || !templateDropDown.value) return;

  const buttonRect = templateDropDownButton.value.getBoundingClientRect();
  const dropdownRect = templateDropDown.value.getBoundingClientRect();
  const viewportHeight = window.innerHeight;
  const spaceBelow = viewportHeight - buttonRect.bottom;
  const spaceAbove = buttonRect.top;
  const dropdownHeight = dropdownRect.height;

  if (spaceBelow > dropdownHeight) {
    isDropdownPositionedAbove.value = false;
  } else if (spaceAbove > dropdownHeight) {
    isDropdownPositionedAbove.value = true;
  } else {
    // change the max height of the dropdown when there is not enough space above or below
    const newMaxHeight = spaceAbove > spaceBelow ? spaceAbove : spaceBelow;
    templateDropDown.value.style.maxHeight = `${newMaxHeight - 36}px`;
    templateDropDown.value.style.overflowY = 'auto';
    isDropdownPositionedAbove.value = spaceAbove > spaceBelow;
    return;
  }

  templateDropDown.value.style.maxHeight = '420px';
};

const onSearchKeywordChange = (keyPhrase: string) => {
  wa_template_search.value = keyPhrase;
  waTemplateSelectedIndex.value =
    filteredWhatsappTemplates.value.findIndex((template) => template.slug === props.waTemplate?.slug) ?? -1;
};

const onSearchKeywordClear = () => {
  wa_template_search.value = '';
  waTemplateSelectedIndex.value = filteredWhatsappTemplates.value.findIndex(
    (template: FilteredWhatsappTemplate) => template.slug === props.waTemplate?.slug,
  );
};

const filteredWhatsappTemplates = computed(() => {
  return props.waTemplateMessages
    .filter((template: Template) => {
      return template.title.toLowerCase().includes(wa_template_search.value.toLowerCase());
    })
    .map((item: Template) => ({ value: item, text: `${item.title} - ${item.slug}`, slug: item.slug }));
});

const starredTemplates = computed(() => {
  return filteredWhatsappTemplates.value.filter((template: FilteredWhatsappTemplate) => template.value.is_starred);
});

const nonStarredTemplates = computed(() => {
  return filteredWhatsappTemplates.value.filter((template: FilteredWhatsappTemplate) => !template.value.is_starred);
});

const templateGroups = computed(() => [
  { templates: starredTemplates.value, length: starredTemplates.value.length, indexOffset: 0 },
  {
    templates: nonStarredTemplates.value,
    length: nonStarredTemplates.value.length,
    indexOffset: starredTemplates.value.length,
  },
]);

const shouldShowSectionDivider = computed(() => {
  const [starredTemplates, nonStarredTemplates] = templateGroups.value;
  return starredTemplates.length > 0 && nonStarredTemplates.length > 0;
});

const updateHoveredTemplateIndex = (currIndex: number) => {
  hoveredTemplateIndex.value = currIndex;
};

const displayGreyFavouriteIcon = (template: FilteredWhatsappTemplate, currIndex: number) => {
  const isStarred = template.value.is_starred;
  return hoveredTemplateIndex.value === currIndex && !isStarred;
};

const displayYellowFavouriteIcon = (template: FilteredWhatsappTemplate) => {
  const isStarred = template.value.is_starred;
  return isStarred;
};

const markTemplateAsFavourite = async (template: FilteredWhatsappTemplate) => {
  await starWaTemplate(template.value.id);
  emit('re-fetch-whatsapp-templates');
};

const unMarkTemplateAsFavourite = async (template: FilteredWhatsappTemplate) => {
  await unStarWaTemplate(template.value.id);
  emit('re-fetch-whatsapp-templates');
};

// Recalculate position on window resize
onMounted(() => {
  window.addEventListener('resize', calculatePosition);
});

onUnmounted(() => {
  window.removeEventListener('resize', calculatePosition);
});
</script>
