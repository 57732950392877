import { PERMISSION } from '@/Configs/Constants';

import type { RouteRecord } from '../types';

const flowBuilderRoutes: RouteRecord[] = [
  {
    path: '/admin/flows',
    component: () => import(/* webpackChunkName: "FlowBuilder" */ '../../components/FlowBuilder/Index.vue'),
    meta: { permissionRequired: PERMISSION.SETTINGS__FLOWBOTS__MANAGE },
    children: [
      {
        path: '/admin/flows/create',
        component: () => import(/* webpackChunkName: "FlowBuilder" */ '../../components/FlowBuilder/Edit.vue'),
        props: { action: 'create' },
        meta: { permissionRequired: PERMISSION.SETTINGS__FLOWBOTS__MANAGE },
      },
      {
        path: '/admin/flows/:id',
        component: () => import(/* webpackChunkName: "FlowBuilder" */ '../../components/FlowBuilder/Edit.vue'),
        props: { action: 'edit' },
        meta: { permissionRequired: PERMISSION.SETTINGS__FLOWBOTS__MANAGE },
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "FlowBuilder" */ '../../components/AdminIndexBox.vue'),
        props: { boxName: 'flows' },
        meta: { permissionRequired: PERMISSION.SETTINGS__FLOWBOTS__MANAGE },
      },
    ],
  },
];

export default flowBuilderRoutes;
