import { request } from '@/util/request';

import { ENDPOINT } from '../constants';

import type { Channel, ChannelsResponse } from '@/store/pinia/channels/types';
import type { MeChannelsResponse } from '@/store/pinia/meChannels/types';

export function fetchChannels() {
  return request<MeChannelsResponse>(ENDPOINT.ME_CHANNELS, 'GET');
}

export function fetchAllChannels() {
  return request<ChannelsResponse>(ENDPOINT.CHANNELS, 'GET');
}

export const getChannel = (channelId: number) => {
  return request<ChannelsResponse>(`${ENDPOINT.CHANNELS}/${channelId}`, 'GET');
};

export const updateChannel = (channelId: number, data: Channel) => {
  return request<ChannelsResponse>(`${ENDPOINT.CHANNELS}/${channelId}`, 'PUT', data);
};
